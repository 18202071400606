
import { Vue, Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import * as VAutocomplete from 'vuetify/es5/components/VAutocomplete'
import IBXService from '@services/ibx/IBXService'
import { EventBus, Events } from '@events'
import { Passage } from '@/components/ibx/base/Passage'
import _ from 'lodash'
import CONST from '@constants'

@Component({
  components: {
    ...VAutocomplete,
  },
})
export default class AuthorPassageSelect extends Mixins(Vue) {
  @Prop({ default: null }) passage: Passage // item linked passage
  @Prop({ default: false }) isLinked: boolean

  /**
   * Debounce search input
   */
  @Watch('search')
  onSearchChange(passageTitle: string = '') {
    if (passageTitle === null) return
    const value = passageTitle.trim()
    if (value.length > 2) {
      // Items have already been requested
      if (this.isLoading) return

      // Lazily load input items
      this.debounceFetchPassage(value)
    }
  }

  private selectedPassage: any = null
  private search: any = null
  private isLoading: boolean = false
  private passagesFound: [] = []
  private titleLimit: number = 60
  private debounceFetchPassage = _.debounce(this.fetchPassages, 1000)

  get items() {
    return this.passagesFound.map((entry: any) => {
      // update title length
      const title = entry.title.length > this.titleLimit ? entry.title.slice(0, this.titleLimit) + '...' : entry.title
      return Object.assign({}, entry, { title })
    })
  }

  get getTitle() {
    return this.isLinked && this.passage?.title ? this.passage?.title : ''
  }

  get passageLink() {
    return this.isLinked && this.passage?.passageRevId
  }

  fetchPassages(val: string) {
    this.isLoading = true

    return IBXService.passageSearchTitle({ passageTitle: val })
      .then((result) => {
        this.passagesFound = result.passages.map((el) => ({
          passageId: el.passageId,
          passageRevId: el.passageRevId,
          title: this.$options.filters.htmlDecode(el.title),
        }))
        // this.passagesFound = result.passages
      })
      .catch((error) => EventBus.$emit(Events.ERROR, { type: CONST.ERROR, error }))
      .finally(() => (this.isLoading = false))
  }

  async fetchSelectedPassage(passageId) {
    return await IBXService.passage(passageId).then((result) => {
      this.$emit('passageSelected', result.passage)
    })
  }

  onPassageSelect() {
    if (this.selectedPassage) {
      this.fetchSelectedPassage(this.selectedPassage.passageId)
    }
  }
}
