/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  Classification_1: {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<path pid="0" _fill="#152452" d="M29.92 105.37h53.2v53.2h-53.2z"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M132.63 161.2c16.265 0 29.45-13.185 29.45-29.45s-13.185-29.45-29.45-29.45-29.45 13.185-29.45 29.45 13.185 29.45 29.45 29.45zM96.14 30.8L65.27 84.27h61.74L96.14 30.8z" _fill="#152452"/>',
  },
})
