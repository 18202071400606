
// @ts-nocheck
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import AsmtPrint from './AsmtPrint'
import { EventBus, Events } from '@events'
import _ from 'lodash'

@Component({
  components: {
    AsmtPrint,
  },
  computed: {
    ...mapGetters('asmt', {
      allItemsArePrintable: 'allItemsArePrintable',
    }),
  },
})
export default class AsmtPrintDialog extends Mixins(Vue) {
  get printBtnAttrs() {
    return { color: '#008272', marginLeft: '8px' }
  }
  get closeBtnAttrs() {
    return { border: '1px solid #fff', 'background-color': '#008272' }
  }
  onClose() {
    EventBus.$emit(Events.ASSESSMENT_PRINT_PREVIEW, false)
  }
  onPrint() {
    window.print()
  }
}
