//import '@babel/polyfill'
//import 'babel-polyfill'
import Vue from 'vue'
import router from '@router'
import store from '@state/store'
import vuetify from '@plugins/vuetify'
import { initPie, initPiePrint } from '@plugins/pie'
import { initDatadog } from '@plugins/datadog'
import '@plugins/animejs'
import '@plugins/fontAwesome'
import '@plugins/ibxTooltip'
import '@plugins/ibxIcons'
import '@plugins/components'
import '@helpers/vueFilters'
import '@directives/closable'
import App from './App.vue'
import AppLoading from './AppLoading.vue'
import { httpResponseErrorInterceptor } from '@services/ibx/IBXService'
import { Events, EventBus } from '@events'
import { FLAG } from '@constants'

/**
 * Dynamically load standards-x module
 * and intialize standards application.
 * @returns makeStandardsApp function
 */
const initializeStandards = async () => {
  const { makeStandardsApp } = await import('@illuminateeducation/frontend-standards-x/src/index.js')
  const standardsApp: any = makeStandardsApp({
    store,
    STANDARDS_APP_DATA: {
      client: {
        host: process.env.VUE_APP_STANNIS_URI + '/api/v1/stannis',
        responseInterceptor: httpResponseErrorInterceptor,
      },

      // handle stickiness here
      standards: {
        host: process.env.VUE_APP_STANNIS_URI + '/api/v1/stannis',
        token: '',
        recent_standards: [],
        standard_collections: [],
        states: [],
        documents: [],
        subjects: [],
        grades: [],
      },
    },
  })

  return {
    standardsApp,
  }
}

declare global {
  interface Window {
    IBX_APP: any
    AppInit: any
    MessageBus: any
    PendoInit: (pendoVars: any) => void
  }
}
window.IBX_APP = window.IBX_APP || {}
Vue.config.productionTip = process.env.NODE_ENV === 'production'
let stannisInitialized = false
let loading = true
/*
 * App authenticated and ready to render
 */
const appReady = async (config: any | null) => {
  vmLoading.$destroy()
  if (!stannisInitialized) {
    const { standardsApp }: any = await initializeStandards()
    standardsApp.init()
    Vue.component('stannis', standardsApp.getAppComponent())
    stannisInitialized = true
  }

  new Vue({
    vuetify,
    router: router,
    store: store,
    render: (h: any) => h(App),
    async created() {
      await this.$store.dispatch('config/init', config)
      const ld = this.$store.getters['config/launchdarkly']
      const ldIsValid = ld?.flags?.$valid

      this.$store.commit('config/unsetProp', 'launchdarkly')
      await this.$store.dispatch('features/init', {
        user: config.user?.user,
        flags: ldIsValid ? ld.flags : null,
        hash: ldIsValid ? ld.hash : null,
      })
      await initPie(this.$store.getters['features/flag'](FLAG.PIE_API_COMPONENTS_VERSION))
      await initPiePrint(
        this.$store.getters['features/flag'](FLAG.PRINT_BOOKLETS),
        this.$store.getters['features/flag'](FLAG.PIE_PRINT_VERSION)
      )
      // initialize pendo if config has pendo data
      const pendo = this.$store.getters['config/pendo']
      if (Object.keys(pendo || {}).length) {
        window.PendoInit(this.$store.getters['config/pendo'])
      }
    },
  }).$mount('#app')
  loading = false
}

/*
 * Load app with laoding component
 */
let vmLoading: any = new Vue({
  vuetify,
  store: store,
  provide() {
    return Object.defineProperties(
      {},
      {
        appReady: { get: () => appReady },
      }
    )
  },
  render: (h) => h(AppLoading),
}).$mount('#app-loading')

/*
 * MessageBus Mux
 */
const onMessageBusMessage = (event: any) => {
  const message = window.MessageBus.getEventMessage(event)
  const EVENTS = window.MessageBus.EVENTS
  const busEvents = EventBus._events || {}

  switch (message) {
    case EVENTS.AUTHX_SESSION_STARTED:
      if (loading) {
        vmLoading.$emit('init')
      } else {
        appReady(null)
      }
      break
    case EVENTS.AUTHX_REAUTH:
      // remove window before unload handler
      if (busEvents[Events.DATA_DIRTY]) {
        EventBus.$emit(Events.DATA_DIRTY, false)
      }
      break
  }
}

/*
 * Initialize application with env
 * Initialize  and listen to MessageBus
 */
document.addEventListener('DOMContentLoaded', function () {
  window.AppInit({
    RP_URI: process.env.VUE_APP_AUTHX_RP_URI,
    RP_SESSION_CDN_URI: process.env.VUE_APP_AUTHX_RP_SESSION_CDN_URI,
    OP_URI: process.env.VUE_APP_AUTHX_OP_URI,
    OIDC_CLIENT_ID: process.env.VUE_APP_AUTHX_OIDC_CLIENT_ID,
    OIDC_SESSION_STATE_COOKIE: process.env.VUE_APP_AUTHX_OIDC_SESSION_STATE_COOKIE,
    OIDC_ISSUER: process.env.VUE_APP_AUTHX_OIDC_ISSUER,
  })
  window.MessageBus.addEventListener(onMessageBusMessage)
})

initDatadog()
