const authorSettings = {
  multiple_choice: {
    configSettings: {
      '@pie-element/multiple-choice': {
        answerChoiceCount: 4,
        maxAnswerChoices: 9,
        addChoiceButton: {
          settings: true,
          label: 'Add Choice',
        },
        choiceMode: {
          settings: false,
          label: 'Response Type',
        },
        lockChoiceOrder: {
          settings: true,
          label: 'Lock Choice Order',
        },
        limitChoicesNumber: {
          settings: false,
          label: 'Limit choices to',
        },
        partialScoring: {
          settings: false,
          label: 'Allow Partial Scoring',
          enabled: false,
        },
        choicePrefix: {
          settings: true,
          label: 'Choice Labels',
        },
        deleteChoice: {
          settings: true,
        },
        feedback: {
          settings: false,
          label: 'Student Feedback',
        },
        prompt: {
          settings: false,
          label: 'Item Stem',
        },
        rationale: {
          settings: true,
          label: 'Rationale',
        },
        studentInstructions: {
          settings: false,
          label: 'Student Instructions',
        },
        teacherInstructions: {
          settings: true,
          label: 'Teacher Instructions',
        },
        withRubric: { settings: false, label: 'Add Rubric', forceEnabled: false },
        language: {
          settings: false,
          label: 'Specify Language',
          enabled: false,
        },
        languageChoices: {
          label: 'Language Choices',
          options: [],
        },
        maxImageWidth: { teacherInstructions: 900, prompt: 900, rationale: 900, choices: 900 },
        maxImageHeight: { teacherInstructions: 900, prompt: 900, rationale: 400, choices: 400 },
      },
    },
    contentConfig: {
      id: 'p-00000000',
      elements: {
        'pie-multiple-choice': '@pie-element/multiple-choice@latest',
      },
      models: [
        {
          id: 'p-00000000',
          element: 'pie-multiple-choice',
          choices: [],
          choiceMode: 'radio',
          choicePrefix: 'letters',
          prompt: '',
          lockChoiceOrder: true,
          limitChoicesNumber: true,
          partialScoring: true,
          scoringType: 'auto',
          rationaleEnabled: false,
          teacherInstructionsEnabled: false,
          studentInstructionsEnabled: false,
          feedbackEnabled: false,
        },
      ],
      markup: '<pie-multiple-choice id="p-00000000"></pie-multiple-choice>',
    },
  },
  multiple_select: {
    configSettings: {
      '@pie-element/multiple-choice': {
        answerChoiceCount: 5,
        addChoiceButton: {
          settings: true,
          label: 'Add Choice',
        },
        choiceMode: {
          settings: false,
          label: 'Response Type',
        },
        lockChoiceOrder: {
          settings: true,
          label: 'Lock Choice Order',
        },
        limitChoicesNumber: {
          settings: false,
          label: 'Limit choices to',
        },
        partialScoring: {
          settings: false,
          label: 'Allow Partial Scoring',
        },
        choicePrefix: {
          settings: true,
          label: 'Choice Labels',
        },
        deleteChoice: {
          settings: true,
        },
        feedback: {
          settings: false,
          label: 'Student Feedback',
        },
        prompt: {
          settings: false,
          label: 'Item Stem',
        },
        rationale: {
          settings: true,
          label: 'Rationale',
        },
        studentInstructions: {
          settings: false,
          label: 'Student Instructions',
        },
        teacherInstructions: {
          settings: true,
          label: 'Teacher Instructions',
        },
        maxAnswerChoices: 9,
        withRubric: { settings: false, label: 'Add Rubric', forceEnabled: false },
        language: {
          settings: false,
          label: 'Specify Language',
          enabled: false,
        },
        languageChoices: {
          label: 'Language Choices',
          options: [],
        },
        maxImageWidth: { teacherInstructions: 900, prompt: 900, rationale: 900, choices: 900 },
        maxImageHeight: { teacherInstructions: 900, prompt: 900, rationale: 400, choices: 400 },
      },
    },
    contentConfig: {
      id: 'p-00000000',
      elements: {
        'pie-multiple-choice': '@pie-element/multiple-choice@latest',
      },
      models: [
        {
          id: 'p-00000000',
          element: 'pie-multiple-choice',
          choices: [],
          choiceMode: 'checkbox',
          choicePrefix: 'letters',
          prompt: '',
          lockChoiceOrder: true,
          limitChoicesNumber: true,
          partialScoring: true,
          scoringType: 'auto',
          rationaleEnabled: false,
          teacherInstructionsEnabled: false,
          studentInstructionsEnabled: false,
          feedbackEnabled: false,
        },
      ],
      markup: '<pie-multiple-choice id="p-00000000"></pie-multiple-choice>',
    },
  },
  constructed_response: {
    configSettings: {
      '@pie-element/extended-text-entry': {
        dimensions: {
          settings: true,
          label: 'Response Area Size',
        },
        equationEditor: {
          settings: true,
          label: '',
          enabled: true,
        },
        feedback: {
          settings: false,
          label: 'Feedback',
        },
        mathInput: {
          settings: true,
          label: 'Equation Editor',
          enabled: false,
        },
        multiple: {
          settings: false,
          label: 'Multiple Parts',
          enabled: false,
        },
        studentInstructions: {
          settings: false,
          label: 'Student Instructions',
        },
        teacherInstructions: {
          settings: true,
          label: 'Teacher Instructions',
        },
        prompt: {
          settings: true,
          label: 'Item Stem',
        },
        language: {
          settings: false,
          label: 'Specify Language',
          enabled: false,
        },
        languageChoices: {
          label: 'Language Choices',
          options: [],
        },
        maxImageWidth: { teacherInstructions: 900, prompt: 900 },
        maxImageHeight: { teacherInstructions: 900, prompt: 900 },
      },
      '@pie-element/complex-rubric': {
        multiTraitRubric: {
          defaultTraitLabel: 'Trait',
          showHalfScoring: {
            settings: false,
            label: 'Half scoring',
          },
          showVisibleToStudent: { settings: false, label: 'Visible to Student' },
          showMaxPoint: { settings: false, label: 'Multip Show max points dropdown' },
          dragAndDrop: { settings: true, label: 'Enable Drag and Drop', enabled: false },
        },
        simpleRubric: { maxMaxPoints: 75 },
        rubricOptions: ['simpleRubric', 'multiTraitRubric'],
      },
    },
    contentConfig: {
      id: 'p-00000000',
      elements: {
        'pie-constructed-response': '@pie-element/extended-text-entry@latest',
        'complex-rubric': '@pie-element/complex-rubric@latest',
      },
      models: [
        {
          id: 'p-00000000',
          element: 'pie-constructed-response',
          dimensions: { height: 100, width: 500 },
          prompt: '',
          mathInput: false,
          equationEditor: 'Grade 8 - HS',
          feedbackEnabled: false,
          rationaleEnabled: false,
          promptEnabled: true,
          teacherInstructionsEnabled: false,
          studentInstructionsEnabled: false,
          feedback: {
            type: 'default',
            default: '',
          },
          showMathInput: false,
          rubricEnabled: true,
        },
      ],
      markup: '<pie-constructed-response id="p-00000000"></pie-constructed-response>',
    },
  },
  explicit_constructed_response: {
    configSettings: {
      '@pie-element/explicit-constructed-response': {
        prompt: {
          settings: false,
          label: 'Item Stem',
        },
        partialScoring: {
          settings: false,
          label: 'Allow Partial Scoring',
        },
        rationale: {
          settings: true,
          label: 'Rationale',
        },
        teacherInstructions: {
          settings: true,
          label: 'Teacher Instructions',
        },
        maxLengthPerChoice: {
          settings: false,
          label: 'Maximum Length Per Choice',
        },
        withRubric: { settings: false, label: 'Add Rubric', forceEnabled: false },
        language: {
          settings: false,
          label: 'Specify Language',
          enabled: false,
        },
        languageChoices: {
          label: 'Language Choices',
          options: [],
        },
        maxImageWidth: { teacherInstructions: 900, prompt: 900, rationale: 900 },
        maxImageHeight: { teacherInstructions: 900, prompt: 900, rationale: 900 },
      },
    },
    contentConfig: {
      id: 'p-00000000',
      elements: {
        'pie-explicit-constructed-response': '@pie-element/explicit-constructed-response@latest',
      },
      models: [
        {
          id: 'p-00000000',
          element: 'pie-explicit-constructed-response',
          disabled: false,
          mode: 'gather',
          prompt: '<div></div>',
          shuffle: true,
          choices: {},
          slateMarkup: '<div></div>',
          rationaleEnabled: false,
          promptEnabled: false,
          teacherInstructionsEnabled: false,
          studentInstructionsEnabled: false,
          rubricEnabled: false,
        },
      ],
      markup: '<pie-explicit-constructed-response id="p-00000000"></pie-explicit-constructed-response>',
    },
  },
  math_equation_response: {
    configSettings: {
      '@pie-element/math-inline': {
        responseType: {
          settings: false,
          label: 'Response type',
        },
        rationale: {
          settings: true,
          label: 'Rationale',
        },
        scoringType: {
          settings: false,
          label: 'Scoring Type',
        },
        studentInstructions: {
          settings: false,
          label: 'Student Instructions',
        },
        teacherInstructions: {
          settings: false,
          label: 'Teacher Instructions',
        },
        partialScoring: {
          settings: false,
          label: 'Allow Partial Scoring',
        },
        prompt: {
          settings: true,
          label: 'Item Stem',
        },
        feedback: {
          settings: false,
          label: 'Feedback',
        },
        ignoreOrder: {
          settings: false,
          label: 'Ignore Order',
          enabled: true,
        },
        allowTrailingZeros: {
          settings: false,
          label: 'Allow Trailing Zeros',
          enabled: true,
        },
        withRubric: { settings: false, label: 'Add Rubric', forceEnabled: false },
        language: {
          settings: false,
          label: 'Specify Language',
          enabled: false,
        },
        languageChoices: {
          label: 'Language Choices',
          options: [],
        },
        maxImageWidth: { teacherInstructions: 900, prompt: 900, rationale: 900 },
        maxImageHeight: { teacherInstructions: 900, prompt: 900, rationale: 900 },
      },
    },
    contentConfig: {
      id: 'p-00000000',
      elements: {
        'pie-math-inline': '@pie-element/math-inline@latest',
      },
      models: [
        {
          id: 'p-00000000',
          partialScoring: true,
          responseType: 'Simple',
          element: 'pie-math-inline',
          equationEditor: '3',
          expression: '',
          rationale: '',
          prompt: '',
          responses: [
            {
              id: '1',
              answer: '',
              validation: 'literal',
            },
          ],
          customKeys: ['\\left(\\right)', '\\frac{}{}', 'x\\frac{}{}'],
          scoringType: 'auto',
          response: {
            answer: '',
            validation: 'literal',
          },
          feedbackEnabled: false,
          rationaleEnabled: false,
          promptEnabled: true,
          teacherInstructionsEnabled: false,
          studentInstructionsEnabled: false,
        },
      ],
      markup: '<pie-math-inline id="p-00000000"></pie-math-inline>',
    },
  },
  evidence_based_selected_response: {
    configSettings: {
      '@pie-element/ebsr': {
        partialScoring: {
          label: 'Allow Partial Scoring',
          settings: false,
        },
        scoringType: {
          settings: false,
          label: 'Scoring Type',
        },
        partA: {
          addChoiceButton: {
            settings: true,
            label: 'Add Choice',
          },
          choiceMode: {
            settings: false,
            label: 'Response Type',
          },
          choicePrefix: {
            settings: true,
            label: 'Choice Labels',
          },
          deleteChoice: {
            settings: true,
          },
          feedback: {
            settings: false,
            label: 'Feedback',
          },
          lockChoiceOrder: {
            settings: true,
            label: 'Lock Choice Order',
          },
          prompt: {
            settings: false,
            label: 'Item Stem',
          },
          rationale: {
            settings: true,
            label: 'Rationale',
          },
          studentInstructions: {
            settings: false,
            label: 'Student Instructions',
          },
          teacherInstructions: {
            settings: true,
            label: 'Teacher Instructions',
          },
          choicesLayout: {
            settings: false,
            label: 'Choices Layout',
          },
          gridColumns: {
            label: 'Grid columns',
          },
          spellCheck: {
            label: 'Spellcheck',
            settings: false,
            enabled: false,
          },
          language: {
            settings: false,
            label: 'Specify Language',
            enabled: false,
          },
          languageChoices: {
            label: 'Language Choices',
            options: [],
          },
          maxImageWidth: { teacherInstructions: 900, prompt: 900, rationale: 900, Choices: 900 },
          maxImageHeight: { teacherInstructions: 900, prompt: 900, rationale: 400, Choices: 400 },
        },
        partB: {
          addChoiceButton: {
            settings: true,
            label: 'Add Choice',
          },
          choiceMode: {
            settings: true,
            label: 'Response Type',
          },
          choicePrefix: {
            settings: true,
            label: 'Choice Labels',
          },
          deleteChoice: {
            settings: true,
          },
          feedback: {
            settings: false,
            label: 'Feedback',
          },
          lockChoiceOrder: {
            settings: true,
            label: 'Lock Choice Order',
          },
          prompt: {
            settings: false,
            label: 'Item Stem',
          },
          rationale: {
            settings: true,
            label: 'Rationale',
          },
          studentInstructions: {
            settings: false,
            label: 'Student Instructions',
          },
          teacherInstructions: {
            settings: true,
            label: 'Teacher Instructions',
          },
          choicesLayout: {
            settings: false,
            label: 'Choices Layout',
          },
          gridColumns: {
            label: 'Grid columns',
          },
          spellCheck: {
            label: 'Spellcheck',
            settings: false,
            enabled: false,
          },
          language: {
            settings: false,
            label: 'Specify Language',
            enabled: false,
          },
          languageChoices: {
            label: 'Language Choices',
            options: [],
          },
          maxImageWidth: { teacherInstructions: 900, prompt: 900, rationale: 900, Choices: 900 },
          maxImageHeight: { teacherInstructions: 900, prompt: 900, rationale: 400, Choices: 400 },
        },
        partLabels: {
          settings: false,
          label: 'Part Labels',
        },
        settingsPanelDisabled: true,
        answerChoiceCount: 4,
      },
    },
    contentConfig: {
      id: 'p-00000000',
      elements: {
        'pie-element-ebsr': '@pie-element/ebsr@latest',
      },
      models: [
        {
          id: 'p-00000000',
          element: 'pie-element-ebsr',
          partA: {
            choices: [],
            choiceMode: 'radio',
            choicePrefix: 'letters',
            prompt: '',
            lockChoiceOrder: true,
            partialScoring: true,
            scoringType: 'auto',
            keyMode: 'letters',
            partialScoringLabel: '',
            shuffle: false,
            showCorrect: false,
            allowFeedback: false,
            feedbackEnabled: false,
            promptEnabled: true,
            rationaleEnabled: false,
            teacherInstructionsEnabled: false,
            studentInstructionsEnabled: false,
          },
          partB: {
            choices: [],
            choiceMode: 'radio',
            choicePrefix: 'letters',
            prompt: '',
            lockChoiceOrder: true,
            partialScoring: true,
            scoringType: 'auto',
            keyMode: 'numbers',
            partialScoringLabel: '',
            shuffle: false,
            allowFeedback: false,
            feedbackEnabled: false,
            promptEnabled: true,
            rationaleEnabled: false,
            teacherInstructionsEnabled: false,
            studentInstructionsEnabled: false,
          },
        },
      ],
      markup: '<pie-element-ebsr id="p-00000000"></pie-element-ebsr>',
    },
  },
  inline_dropdown: {
    configSettings: {
      '@pie-element/inline-dropdown': {
        prompt: {
          settings: false,
          label: 'Item Stem',
        },
        lockChoiceOrder: {
          settings: true,
          label: 'Lock Choice Order',
        },
        partialScoring: {
          settings: false,
          label: 'Allow Partial Scoring',
        },
        teacherInstructions: {
          settings: true,
          label: 'Teacher Instructions',
        },
        withRubric: { settings: false, label: 'Add Rubric', forceEnabled: false },
        language: {
          settings: false,
          label: 'Specify Language',
          enabled: false,
        },
        languageChoices: {
          label: 'Language Choices',
          options: [],
        },
        maxImageWidth: { teacherInstructions: 900, prompt: 900, rationale: 900 },
        maxImageHeight: { teacherInstructions: 900, prompt: 900, rationale: 900 },
      },
    },
    contentConfig: {
      id: 'p-00000000',
      elements: {
        'pie-inline-dropdown': '@pie-element/inline-dropdown@latest',
      },
      models: [
        {
          id: 'p-00000000',
          element: 'pie-inline-dropdown',
          disabled: false,
          mode: 'gather',
          prompt: '<div></div>',
          shuffle: true,
          choices: {},
          slateMarkup: '<div></div>',
          lockChoiceOrder: false,
          promptEnabled: false,
          rationaleEnabled: false,
          teacherInstructionsEnabled: false,
          studentInstructionsEnabled: false,
        },
      ],
      markup: '<pie-inline-dropdown id="p-00000000"></pie-inline-dropdown>',
    },
  },
  hot_text: {
    configSettings: {
      '@pie-element/select-text': {
        minTokens: 2,
        selectionCount: {
          settings: false,
          label: 'Selection Count',
        },
        correctAnswer: {
          settings: false,
          label: 'Correct Answers',
        },
        selections: {
          settings: false,
          label: 'Selections Available',
        },
        highlightChoices: {
          settings: true,
          label: 'Highlight Choices',
        },
        rationale: {
          settings: true,
          label: 'Rationale',
        },
        scoringType: {
          settings: false,
          label: 'Scoring Type',
        },
        studentInstructions: {
          settings: false,
          label: 'Student Instructions',
        },
        teacherInstructions: {
          settings: true,
          label: 'Teacher Instructions',
        },
        prompt: {
          label: 'Item Stem',
        },
        text: {
          settings: true,
          label: 'Enter Content',
        },
        tokens: {
          settings: true,
          label: 'Tokens',
        },
        feedback: {
          settings: false,
          label: 'Student Feedback',
        },
        partialScoring: {
          settings: false,
          label: 'Allow Partial Scoring',
        },
        mode: {
          settings: false,
          label: 'Mode',
        },
        withRubric: { settings: false, label: 'Add Rubric', forceEnabled: false },
        language: {
          settings: false,
          label: 'Specify Language',
          enabled: false,
        },
        languageChoices: {
          label: 'Language Choices',
          options: [],
        },
        maxImageWidth: { teacherInstructions: 900, prompt: 900, rationale: 900 },
        maxImageHeight: { teacherInstructions: 900, prompt: 900, rationale: 900 },
      },
    },
    contentConfig: {
      id: 'p-00000000',
      elements: {
        'pie-select-text': '@pie-element/select-text@latest',
      },
      models: [
        {
          id: 'p-00000000',
          element: 'pie-select-text',
          highlightChoices: true,
          partialScoring: true,
          maxSelections: 2,
          mode: 'sentence',
          prompt: '<div></div>',
          text: '',
          tokens: [],
          scoringType: 'auto',
          feedback: {
            correct: {
              type: 'default',
              default: 'Correct',
            },
            incorrect: {
              type: 'default',
              default: 'Incorrect',
            },
            partial: {
              type: 'default',
              default: 'Nearly',
            },
          },
          feedbackEnabled: false,
          rationaleEnabled: false,
          promptEnabled: true,
          teacherInstructionsEnabled: false,
          studentInstructionsEnabled: false,
        },
      ],
      markup: '<pie-select-text id="p-00000000"></pie-select-text>',
    },
  },
  hot_spot: {
    configSettings: {
      '@pie-element/hotspot': {
        minShapes: 2,
        multipleCorrect: {
          settings: true,
          label: 'Multiple Correct Responses',
          enabled: false,
        },
        partialScoring: {
          settings: false,
          label: 'Allow Partial Scoring',
        },
        rationale: {
          settings: true,
          label: 'Rationale',
        },
        teacherInstructions: {
          settings: true,
          label: 'Teacher Instructions',
        },
        prompt: {
          settings: false,
          label: 'Item Stem',
          enabled: true,
        },
        withRubric: { settings: false, label: 'Add Rubric', forceEnabled: false },
        language: {
          settings: false,
          label: 'Specify Language',
          enabled: false,
        },
        languageChoices: {
          label: 'Language Choices',
          options: [],
        },
        maxImageWidth: { teacherInstructions: 900, prompt: 900, rationale: 900 },
        maxImageHeight: { teacherInstructions: 900, prompt: 900, rationale: 900 },
      },
    },
    contentConfig: {
      id: 'p-00000000',
      elements: {
        'pie-hotspot': '@pie-element/hotspot@latest',
      },
      models: [
        {
          id: 'p-00000000',
          element: 'pie-hotspot',
          prompt: '',
          imageUrl: '',
          shapes: {
            rectangles: [],
            polygons: [],
          },
          multipleCorrect: false,
          partialScoring: true,
          dimensions: {
            height: 0,
            width: 0,
          },
          hotspotColor: 'rgba(137, 183, 244, 0.5)',
          hotspotList: [
            'rgba(137, 183, 244, 0.5)',
            'rgba(217, 30, 24, 0.25)',
            'rgba(254, 241, 96, 0.25)',
            'rgba(0, 0, 0, 0.1)',
          ],
          outlineColor: 'blue',
          outlineList: ['blue', 'red', 'yellow'],
          rationaleEnabled: false,
          teacherInstructionsEnabled: false,
          studentInstructionsEnabled: false,
        },
      ],
      markup: '<pie-hotspot id="p-00000000"></pie-hotspot>',
    },
  },
  drag_in_the_blank: {
    configSettings: {
      '@pie-element/drag-in-the-blank': {
        prompt: {
          settings: true,
          label: 'Item Stem',
        },
        duplicates: {
          settings: true,
          label: 'Allow Duplicates',
        },
        lockChoiceOrder: {
          settings: true,
          label: 'Lock Choice Order',
        },
        partialScoring: {
          settings: false,
          label: 'Allow Partial Scoring',
        },
        rationale: {
          settings: true,
          label: 'Rationale',
        },
        studentInstructions: {
          settings: false,
          label: 'Student Instructions',
        },
        teacherInstructions: {
          settings: true,
          label: 'Teacher Instructions',
        },
        withRubric: { settings: false, label: 'Add Rubric', forceEnabled: false },
        language: {
          settings: false,
          label: 'Specify Language',
          enabled: false,
        },
        languageChoices: {
          label: 'Language Choices',
          options: [],
        },
        maxImageWidth: { teacherInstructions: 900, prompt: 900, rationale: 900 },
        maxImageHeight: { teacherInstructions: 900, prompt: 900, rationale: 900 },
      },
    },
    contentConfig: {
      id: 'p-00000000',
      elements: {
        'pie-drag-in-the-blank': '@pie-element/drag-in-the-blank@latest',
      },
      models: [
        {
          id: 'p-00000000',
          element: 'pie-drag-in-the-blank',
          disabled: false,
          mode: 'gather',
          shuffle: true,
          prompt: '<div></div>',
          choices: [],
          choicesPosition: 'below',
          correctResponse: {},
          duplicates: false,
          partialScoring: true,
          slateMarkup: '<div></div>',
          rationaleEnabled: false,
          promptEnabled: false,
          teacherInstructionsEnabled: false,
          studentInstructionsEnabled: false,
        },
      ],
      markup: '<pie-drag-in-the-blank id="p-00000000"></pie-drag-in-the-blank>',
    },
  },
  drawing_response: {
    configSettings: {
      '@pie-element/drawing-response': {
        backgroundImage: {
          settings: true,
          label: 'Background Image',
          enabled: true,
        },
        prompt: {
          settings: true,
          label: 'Item Stem',
        },
        teacherInstructions: {
          settings: true,
          label: 'Teacher Instructions',
        },
        language: {
          settings: false,
          label: 'Specify Language',
          enabled: false,
        },
        languageChoices: {
          label: 'Language Choices',
          options: [],
        },
        maxImageWidth: {
          teacherInstructions: 900,
          prompt: 900,
        },
        maxImageHeight: { teacherInstructions: 900, prompt: 900 },
      },
    },
    contentConfig: {
      id: 'p-00000000',
      elements: {
        'pie-drawing-response': '@pie-element/drawing-response@latest',
      },
      models: [
        {
          id: 'p-00000000',
          element: 'pie-drawing-response',
          disabled: false,
          mode: 'gather',
          prompt: '',
          imageUrl: '',
          imageDimensions: {
            height: 0,
            width: 0,
          },
          promptEnabled: true,
          rationaleEnabled: false,
          teacherInstructionsEnabled: false,
          studentInstructionsEnabled: true,
          toolbarEditorPosition: 'bottom',
          rubricEnabled: false,
        },
      ],
      markup: '<pie-drawing-response id="p-00000000"></pie-drawing-response>',
    },
  },
  ordering: {
    configSettings: {
      '@pie-element/placement-ordering': {
        choiceLabel: { settings: true, label: 'Choice label' },
        choices: { settings: true, label: 'Choices' },
        enableImages: { settings: true, label: 'Enable Images' },
        feedback: { settings: false, label: 'Feedback', enabled: false },
        prompt: { settings: false, label: 'Item Stem' },
        numberedGuides: { settings: true, label: 'Numbered guides' },
        orientation: { settings: true, label: 'Orientation' },
        partialScoring: { settings: false, label: 'Allow Partial Scoring' },
        placementArea: { settings: true, label: 'Placement Area' },
        rationale: { settings: true, label: 'Rationale' },
        removeTilesAfterPlacing: { settings: false, label: 'Remove Tiles after placing' },
        scoringType: { settings: false, label: 'Scoring Type' },
        studentInstructions: { settings: false, label: 'Student Instructions' },
        targetLabel: { settings: true, label: 'Target label' },
        spellCheck: { label: 'Spellcheck', settings: false, enabled: true },
        teacherInstructions: { settings: true, label: 'Teacher Instructions' },
        maxImageWidth: {
          teacherInstructions: 900,
          prompt: 900,
          rationale: 900,
          choicesWithPlacementArea: 240,
          choicesWithoutPlacementArea: 480,
        },
        maxImageHeight: { teacherInstructions: 900, prompt: 900, rationale: 900, choices: 150 },
        withRubric: { settings: false, label: 'Add Rubric', forceEnabled: false },
        language: {
          settings: false,
          label: 'Specify Language',
          enabled: false,
        },
        languageChoices: {
          label: 'Language Choices',
          options: [],
        },
      },
    },
    contentConfig: {
      id: 'p-00000000',
      elements: {
        'pie-placement-ordering': '@pie-element/placement-ordering@latest',
      },
      models: [
        {
          id: 'p-00000000',
          element: 'pie-placement-ordering',
          choiceLabel: 'Choices',
          choices: [
            { id: 'c1', label: '' },
            { id: 'c2', label: '' },
            { id: 'c3', label: '' },
            { id: 'c4', label: '' },
          ],
          correctResponse: [
            { id: 'c1', weight: 0 },
            { id: 'c2', weight: 0 },
            { id: 'c3', weight: 0 },
            { id: 'c4', weight: 0 },
          ],
          enableImages: false,
          feedback: {
            correct: { type: 'none', custom: '' },
            incorrect: { type: 'none', custom: '' },
            partial: { type: 'none', custom: '' },
          },
          feedbackEnabled: false,
          prompt: '',
          promptEnabled: true,
          lockChoiceOrder: false,
          numberedGuides: false,
          orientation: 'vertical',
          partialScoring: true,
          placementArea: false,
          rationaleEnabled: false,
          scoringType: 'auto',
          targetLabel: 'Answers',
          teacherInstructionsEnabled: false,
        },
      ],
      markup: '<pie-placement-ordering id="p-00000000"></pie-placement-ordering>',
    },
  },
  matching_table: {
    configSettings: {
      '@pie-element/match': {
        enableImages: { settings: true, label: 'Enable Images' },
        feedback: { settings: false, label: 'Feedback', enabled: false },
        headers: { settings: true },
        layout: { settings: true, label: 'Layout' },
        lockChoiceOrder: { settings: false, label: 'Lock Choice Order' },
        partialScoring: { settings: false, label: 'Allow Partial Scoring' },
        choiceMode: { settings: false, label: 'Response Type' },
        prompt: { settings: false, label: 'Item Stem' },
        rationale: { settings: true, label: 'Rationale' },
        spellCheck: { label: 'Spellcheck', settings: false, enabled: true },
        scoringType: { settings: false, label: 'Scoring Type' },
        studentInstructions: { settings: false, label: 'Student Instructions' },
        teacherInstructions: { settings: true, label: 'Teacher Instructions' },
        withRubric: { settings: false, label: 'Add Rubric', forceEnabled: false },
        language: {
          settings: false,
          label: 'Specify Language',
          enabled: false,
        },
        languageChoices: {
          label: 'Language Choices',
          options: [],
        },
        maxImageWidth: { teacherInstructions: 900, prompt: 900, rationale: 900, rowTitles: 300 },
        maxImageHeight: { teacherInstructions: 900, prompt: 900, rationale: 900, rowTitles: 275 },
      },
    },
    contentConfig: {
      id: 'p-00000000',
      elements: {
        'match-element': '@pie-element/match@latest',
      },
      models: [
        {
          id: 'p-00000000',
          element: 'match-element',
          enableImages: false,
          feedbackEnabled: false,
          headers: ['', 'Column 2', 'Column 3'],
          layout: 3,
          lockChoiceOrder: true,
          partialScoring: true,
          choiceMode: 'checkbox',
          rows: [
            { id: 1, title: 'Question Text 1', values: [false, false] },
            { id: 2, title: 'Question Text 2', values: [false, false] },
            { id: 3, title: 'Question Text 3', values: [false, false] },
          ],
          promptEnabled: true,
          prompt: '',
          teacherInstructionsEnabled: false,
          rationaleEnabled: false,
        },
      ],
      markup: '<match-element id="p-00000000"></match-element>',
    },
  },
  multiple_binary: {
    configSettings: {
      '@pie-element/match': {
        enableImages: { settings: true, label: 'Enable Images' },
        feedback: { settings: false, label: 'Feedback', enabled: false },
        headers: { settings: true },
        layout: { settings: true, label: 'Layout' },
        lockChoiceOrder: { settings: false, label: 'Lock Choice Order' },
        partialScoring: { settings: false, label: 'Allow Partial Scoring' },
        choiceMode: { settings: false, label: 'Response Type' },
        prompt: { settings: false, label: 'Item Stem' },
        rationale: { settings: true, label: 'Rationale' },
        spellCheck: { label: 'Spellcheck', settings: false, enabled: true },
        scoringType: { settings: false, label: 'Scoring Type' },
        studentInstructions: { settings: false, label: 'Student Instructions' },
        teacherInstructions: { settings: true, label: 'Teacher Instructions' },
        withRubric: { settings: false, label: 'Add Rubric', forceEnabled: false },
        language: {
          settings: false,
          label: 'Specify Language',
          enabled: false,
        },
        languageChoices: {
          label: 'Language Choices',
          options: [],
        },
        maxImageWidth: { teacherInstructions: 900, prompt: 900, rationale: 900, rowTitles: 300 },
        maxImageHeight: { teacherInstructions: 900, prompt: 900, rationale: 900, rowTitles: 275 },
      },
    },
    contentConfig: {
      id: 'p-00000000',
      elements: {
        'match-element': '@pie-element/match@latest',
      },
      models: [
        {
          id: 'p-00000000',
          element: 'match-element',
          enableImages: false,
          feedbackEnabled: false,
          headers: ['', 'Column 2', 'Column 3'],
          layout: 3,
          lockChoiceOrder: true,
          partialScoring: true,
          choiceMode: 'radio',
          rows: [
            { id: 1, title: 'Question Text 1', values: [false, false] },
            { id: 2, title: 'Question Text 2', values: [false, false] },
            { id: 3, title: 'Question Text 3', values: [false, false] },
          ],
          promptEnabled: true,
          prompt: '',
          teacherInstructionsEnabled: false,
          rationaleEnabled: false,
        },
      ],
      markup: '<match-element id="p-00000000"></match-element>',
    },
  },
  graphing: {
    configSettings: {
      '@pie-element/graphing': {
        availableTools: ['circle', 'line', 'label', 'parabola', 'point', 'polygon', 'ray', 'segment', 'sine', 'vector'],
        authoring: {
          settings: false,
          label: 'Customize Grid Setup',
          enabled: true,
          includeAxesEnabled: true,
          standardGridEnabled: true,
          min: { label: 'Min Value', enabled: true },
          max: { label: 'Max Value', enabled: true },
          axisLabel: { label: 'Label', enabled: true },
          step: { label: 'Grid Interval', enabled: true },
          labelStep: { label: 'Label Interval', enabled: true },
        },
        arrows: {
          settings: true,
          label: 'Include Arrows',
          left: { label: 'left' },
          right: { label: 'right' },
          up: { label: 'up' },
          down: { label: 'down' },
        },
        gridConfigurations: [
          {
            label: '4-quadrant coordinate grid, -10 to 10',
            arrows: { left: true, right: true, up: true, down: true },
            domain: {
              min: -10,
              max: 10,
              step: 1,
              padding: 0,
              labelStep: 1,
              axisLabel: '<i>x</i>',
            },
            graph: { width: 480, height: 480 },
            includeAxes: true,
            labels: { top: '', right: '', bottom: '', left: '' },
            padding: true,
            range: {
              min: -10,
              max: 10,
              step: 1,
              padding: 0,
              labelStep: 1,
              axisLabel: '<i>y</i>',
            },
            standardGrid: true,
            title: '',
          },
          {
            label: '0 to 10 on both axes',
            arrows: { left: false, right: true, up: true, down: false },
            domain: { min: 0, max: 10, step: 1, padding: 0, labelStep: 1, axisLabel: '<i>x</i>' },
            graph: { width: 480, height: 480 },
            includeAxes: true,
            labels: { top: '', right: '', bottom: '', left: '' },
            padding: true,
            range: { min: 0, max: 10, step: 1, padding: 0, labelStep: 1, axisLabel: '<i>y</i>' },
            standardGrid: true,
            title: '',
          },
          {
            label: '0 to 20 on both axes',
            arrows: { left: false, right: true, up: true, down: false },
            domain: { min: 0, max: 20, step: 1, padding: 0, labelStep: 1, axisLabel: '<i>x</i>' },
            graph: { width: 480, height: 480 },
            includeAxes: true,
            labels: { top: '', right: '', bottom: '', left: '' },
            padding: true,
            range: { min: 0, max: 20, step: 1, padding: 0, labelStep: 1, axisLabel: '<i>y</i>' },
            standardGrid: true,
            title: '',
          },
          {
            label: 'Sample Data Graph',
            arrows: { left: false, right: true, up: true, down: false },
            domain: { min: 0, max: 30, step: 1, padding: 0, labelStep: 2, axisLabel: '<i>t</i>' },
            graph: { width: 480, height: 480 },
            includeAxes: true,
            labels: { top: '', right: '', bottom: 'Time (seconds)', left: 'Distance (meters)' },
            padding: true,
            range: { min: 0, max: 80, step: 5, padding: 0, labelStep: 10, axisLabel: '<i>d</i>' },
            standardGrid: false,
            title: 'Distance as a function of time',
          },
          {
            label: 'No Visible Axes',
            arrows: { left: false, right: false, up: false, down: false },
            domain: { min: 1, max: 21, step: 1, padding: 0, labelStep: 0, axisLabel: '' },
            graph: { width: 480, height: 480 },
            includeAxes: false,
            labels: { top: '', right: '', bottom: '', left: '' },
            padding: true,
            range: { min: 1, max: 21, step: 1, padding: 0, labelStep: 0, axisLabel: '' },
            standardGrid: false,
            title: '',
          },
        ],
        graphDimensions: {
          settings: false,
          label: 'Graph Dimensions',
          enabled: true,
          min: 250,
          max: 800,
          step: 20,
        },
        padding: { settings: false, label: 'Padding' },
        labels: {
          settings: true,
          label: 'Graph Labels',
          enabled: true,
          top: 'Click here to add a top label',
          right: 'Click here to add a right label',
          bottom: 'Click here to add a bottom label',
          left: 'Click here to add a left label',
        },
        prompt: { settings: true, label: 'Item Stem' },
        rationale: { settings: true, label: 'Rationale' },
        scoringType: { settings: false, label: 'Scoring Type' },
        studentInstructions: { settings: false, label: 'Student Instructions' },
        teacherInstructions: { settings: true, label: 'Teacher Instructions' },
        title: {
          settings: true,
          label: 'Graph Title',
          enabled: true,
          placeholder: 'Click here to add a title for this graph',
        },
        instruction: {
          settings: false,
          label:
            'Graphing questions involve plotting points, lines, or other objects on a graph. To create one, first configure the grid, then select the plotting tools students will be offered, and use them to define the correct answer.',
        },
        settingsPanelDisabled: false,
        spellCheck: { label: 'Spellcheck', settings: false, enabled: true },
        coordinatesOnHover: { settings: true, label: 'Coordinates on Hover' },
        maxImageWidth: { teacherInstructions: 900, prompt: 900, rationale: 900 },
        maxImageHeight: { teacherInstructions: 900, prompt: 900, rationale: 900 },
        withRubric: { settings: false, label: 'Add Rubric', forceEnabled: false },
        language: {
          settings: false,
          label: 'Specify Language',
          enabled: false,
        },
        languageChoices: {
          label: 'Language Choices',
          options: [],
        },
      },
    },
    contentConfig: {
      id: 'p-00000000',
      elements: {
        'graphing-element': '@pie-element/graphing@latest',
      },
      models: [
        {
          id: 'p-00000000',
          element: 'graphing-element',
          promptEnabled: true,
          domain: { min: -10, max: 10, step: 1, labelStep: 1, padding: 0, axisLabel: '<i>x</i>' },
          range: { min: -10, max: 10, step: 1, labelStep: 1, padding: 0, axisLabel: '<i>y</i>' },
          backgroundMarks: [],
          answers: { correctAnswer: { name: 'Correct Answer', marks: [] } },
          arrows: { left: true, right: true, up: true, down: true },
          graph: { width: 480, height: 480 },
          includeAxes: true,
          labels: {},
          labelsEnabled: true,
          padding: false,
          prompt: '',
          rationale: '',
          standardGrid: true,
          title: '',
          titleEnabled: true,
          toolbarTools: ['point'],
          coordinatesOnHover: false,
          rationaleEnabled: false,
          teacherInstructionsEnabled: false,
          studentInstructionsEnabled: false,
          defaultTool: 'point',
        },
      ],
      markup: '<graphing-element id="p-00000000"></graphing-element>',
    },
  },
  classification: {
    configSettings: {
      '@pie-element/categorize': {
        spellCheck: { label: 'Spellcheck', settings: false, enabled: true },
        feedback: { settings: false, label: 'Feedback', enabled: false },
        lockChoiceOrder: { settings: true, label: 'Lock Choice Order' },
        choicesPosition: { settings: true, label: 'Choices Position' },
        allowMultiplePlacements: { settings: true, label: 'Allow Multiple Placements' },
        allowAlternate: { settings: false, label: 'Allow Alternate Correct Answers' },
        categoriesPerRow: { settings: true, label: 'Categories per row' },
        partialScoring: { settings: false, label: 'Allow Partial Scoring' },
        prompt: { settings: true, label: 'Item Stem' },
        rationale: { settings: true, label: 'Rationale' },
        scoringType: { settings: false, label: 'Scoring Type' },
        settingsPanelDisabled: false,
        studentInstructions: { settings: false, label: 'Student Instructions' },
        teacherInstructions: { settings: true, label: 'Teacher Instructions' },
        toolbarEditorPosition: { settings: false, label: 'Toolbar Editor Position' },
        maxImageWidth: {
          teacherInstructions: 900,
          prompt: 900,
          rationale: 900,
          rowLabel: 200,
          categoryLabel: 260,
          choices: 240,
        },
        maxImageHeight: {
          teacherInstructions: 900,
          prompt: 900,
          rationale: 900,
          rowLabel: 100,
          categoryLabel: 100,
          choices: 150,
        },
        withRubric: { settings: false, label: 'Add Rubric' },
        minCategoriesPerRow: 1,
        mathMlOptions: { mmlOutput: false, mmlEditing: false },
        language: {
          settings: false,
          label: 'Specify Language',
          enabled: false,
        },
        languageChoices: {
          label: 'Language Choices',
          options: [],
        },
      },
    },
    contentConfig: {
      id: 'p-00000000',
      elements: {
        'pie-element-categorize': '@pie-element/categorize@latest',
      },
      models: [
        {
          id: 'p-00000000',
          element: 'pie-element-categorize',
          promptEnabled: true,
          lockChoiceOrder: false,
          allowMultiplePlacementsEnabled: 'No',
          choicesPosition: 'below',
          allowAlternateEnabled: false,
          teacherInstructionsEnabled: false,
          maxAnswerChoices: 10,
          rationaleEnabled: false,
          choices: [
            {
              id: '0',
              content: 'Choice 0',
              correctResponseCount: 1,
            },
            {
              id: '1',
              content: 'Choice 1',
              correctResponseCount: 1,
            },
          ],
          categories: [
            {
              id: '0',
              label: 'Category 0',
            },
            {
              id: '1',
              label: 'Category 1',
            },
          ],
          rowLabels: [''],
        },
      ],
      markup: '<pie-element-categorize id="p-00000000"></pie-element-categorize>',
    },
  },
  charting: {
    configSettings: {
      '@pie-element/charting': {
        chartDimensions: {
          settings: false,
          label: 'Chart Dimensions',
          showInConfigPanel: true,
          width: { min: 50, max: 700, step: 20 },
          height: { min: 400, max: 700, step: 20 },
        },
        authorNewCategoryDefaults: { settings: false, label: 'Category', interactive: true, editable: false },
        availableChartTypes: {
          bar: 'Bar Chart',
          histogram: 'Histogram',
          lineDot: 'Line Chart ●',
          lineCross: 'Line Chart x',
          dotPlot: 'Dot/Line Plot ⬤',
          linePlot: 'Dot/Line Plot X',
        },
        chartTypeLabel: 'Chart Type',
        studentNewCategoryDefaultLabel: { settings: false, label: 'Category' },
        prompt: { settings: true, label: 'Item Stem' },
        rationale: { settings: true, label: 'Rationale' },
        scoringType: { settings: false, label: 'Scoring Type' },
        settingsPanelDisabled: false,
        studentInstructions: { settings: false, label: 'Student Instructions' },
        teacherInstructions: { settings: true, label: 'Teacher Instructions' },
        title: { settings: false, label: 'Chart Title' },
        titlePlaceholder: { settings: false, label: 'Click here to add a title' },
        labelsPlaceholders: {
          left: 'Click here to add a label for this axis',
          right: '',
          top: '',
          bottom: 'Click here to add a label for this axis',
        },
        maxImageWidth: { teacherInstructions: 900, prompt: 900, rationale: 900 },
        maxImageHeight: { teacherInstructions: 900, prompt: 900, rationale: 900 },
        withRubric: { settings: false, label: 'Add Rubric' },
        language: { settings: false, label: 'Specify Language', enabled: false },
        languageChoices: { label: 'Language Choices', options: [] },
        chartingOptions: {
          changeInteractive: {
            settings: false,
            authoringLabel: 'Student can set value',
            settingsLabel: 'Allow non-interactive categories',
          },
          changeEditable: {
            settings: false,
            authoringLabel: 'Student can edit name',
            settingsLabel: 'Allow editable category names',
          },
          addCategory: {
            settings: false,
            authoringLabel: 'Student can add categories',
            settingsLabel: 'Allow changes to whether students can add categories',
          },
          mathMlOptions: { mmlOutput: false, mmlEditing: false },
        },
      },
    },
    contentConfig: {
      id: 'p-00000000',
      elements: {
        'charting-element': '@pie-element/charting@latest',
      },
      models: [
        {
          id: 'p-00000000',
          element: 'charting-element',
          promptEnabled: true,
          chartType: 'bar',
          data: [],
          addCategoryEnabled: false,
          correctAnswer: { data: [] },
          domain: { label: '<div></div>' },
          graph: { width: 480, height: 480 },
          prompt: '<div></div>',
          rationale: '',
          range: { label: '<div></div>', max: 10, min: 0, step: 1, labelStep: 1 },
          scoringType: 'all or nothing',
          title: '<div></div>',
          rationaleEnabled: false,
          teacherInstructionsEnabled: false,
          studentInstructionsEnabled: true,
          studentNewCategoryDefaultLabel: 'New Category',
          changeInteractiveEnabled: false,
          changeEditableEnabled: false,
          changeAddCategoryEnabled: false,
          errors: {},
        },
      ],
      markup: '<charting-element id="p-00000000"></charting-element>',
    },
  },
  number_line: {
    configSettings: {
      '@pie-element/number-line': {
        baseInputConfiguration: {
          audio: { disabled: false },
          video: { disabled: false },
          image: { disabled: false },
        },
        instruction: {
          settings: false,
          enabled: false,
          label:
            'Number line questions involve plotting points or other objects. To create one, first set up the number line, then select the plotting tools students will be offered and use them to define the correct answer.',
        },
        prompt: {
          settings: true,
          label: 'Item Stem',
          required: true,
          inputConfiguration: {
            audio: { disabled: false },
            video: { disabled: false },
            image: { disabled: false },
          },
        },
        teacherInstructions: {
          settings: true,
          label: 'Teacher Instructions',
          required: false,
          inputConfiguration: {
            audio: { disabled: false },
            video: { disabled: false },
            image: { disabled: false },
          },
        },
        title: {
          label: 'Title',
          inputConfiguration: {
            audio: { disabled: false },
            video: { disabled: false },
            image: { disabled: false },
          },
        },
        numberLineDimensions: { settings: true, label: 'Width', enabled: true, min: 200, max: 800, step: 20 },
        spellCheck: { label: 'Spellcheck', settings: false, enabled: true },
        mathMlOptions: { mmlOutput: false, mmlEditing: false },
        language: { settings: false, label: 'Specify Language', enabled: false },
        languageChoices: { label: 'Language Choices', options: [] },
        maxMaxElements: 20,
        hidePointConfigButtons: false,
        availableTools: ['PF', 'LFF', 'LEF', 'LFE', 'LEE', 'RFN', 'RFP', 'REN', 'REP'],
      },
    },
    contentConfig: {
      id: 'p-00000000',
      elements: {
        'number-element': '@pie-element/number-line@latest',
      },
      models: [
        {
          id: 'p-00000000',
          element: 'number-element',
          correctResponse: [],
          feedback: {
            correct: { type: 'none', default: 'Correct' },
            partial: { type: 'none', default: 'Nearly' },
            incorrect: { type: 'none', custom: '<h1>Incorrect</h1>' },
          },
          prompt: '<div></div>',
          feedbackEnabled: false,
          graph: {
            title: '<div></div>',
            arrows: { left: true, right: true },
            width: 500,
            height: 100,
            domain: { min: -1, max: 1 },
            ticks: {
              minor: 0.25,
              major: 0.5,
              tickIntervalType: 'Fraction',
              integerTick: 1,
              decimalTick: 0.05,
              fractionTick: '1/4',
            },
            initialElements: [],
            maxNumberOfPoints: 1,
            showMinorTicks: true,
            snapPerTick: 1,
            tickLabelOverrides: [],
            initialType: 'PF',
            exhibitOnly: false,
            availableTypes: {
              PF: true,
              LFF: false,
              LEF: false,
              LFE: false,
              LEE: false,
              RFN: false,
              RFP: false,
              REN: false,
              REP: false,
            },
            fraction: true,
          },
          widthEnabled: true,
          language: '',
          teacherInstructionsEnabled: false,
        },
      ],
      markup: '<number-element id="p-00000000"></number-element>',
    },
  },
}

const ibxItemTypes = [
  {
    name: 'Multiple Choice',
    id: 'multiple_choice',
    symbol: 'Multiple Choice_1',
  },
  {
    name: 'Multiple Select',
    id: 'multiple_select',
    symbol: 'Multiple Select_1',
  },
  {
    name: 'Constructed Response',
    id: 'constructed_response',
    symbol: 'Constructed Response_1',
  },
  {
    name: 'Explicit Constructed Response',
    id: 'explicit_constructed_response',
    symbol: 'Explicit Constructed Response_1',
  },
  {
    name: 'Math Equation Response',
    id: 'math_equation_response',
    symbol: 'Math Equation Response_1',
  },
  {
    name: 'EBSR',
    id: 'evidence_based_selected_response',
    symbol: 'Evidence-Based Selected Response_1',
  },
  {
    name: 'Inline Dropdown',
    id: 'inline_dropdown',
    symbol: 'Inline Dropdown_1',
  },
  {
    name: 'Hot Text',
    id: 'hot_text',
    symbol: 'Hot Text_1',
  },
  {
    name: 'Hot Spot',
    id: 'hot_spot',
    symbol: 'Hot Spot_1',
  },
  {
    name: 'Drag in the Blank',
    id: 'drag_in_the_blank',
    symbol: 'Drag and Drop_1',
  },
  {
    name: 'Drawing Response',
    id: 'drawing_response',
    symbol: 'Drawing Response_1',
  },
  {
    name: 'Multiple Binary',
    id: 'multiple_binary',
    symbol: 'Multiple Binary',
  },
  {
    name: 'Ordering',
    id: 'ordering',
    symbol: 'Ordering_1',
  },
  {
    name: 'Drawing Response',
    id: 'drawing_response',
    symbol: 'Drawing Response_1',
  },
  {
    name: 'Charting',
    id: 'charting',
    symbol: 'Charting_1',
  },
  {
    name: 'Classification',
    id: 'classification',
    symbol: 'Classification_1',
  },
  {
    name: 'Graphing',
    id: 'graphing',
    symbol: 'Graphing_1',
  },
  {
    name: 'Matching Table',
    id: 'matching_table',
    symbol: 'Matching Table_1',
  },

  {
    name: 'Match List',
    id: 'match_list',
    symbol: 'Match List_1',
  },
  {
    name: 'Multi-Part',
    id: 'multi_part',
    symbol: 'Multi Part_1',
  },
  {
    name: 'Number Line',
    id: 'number_line',
    symbol: 'Number Line_1',
  },
  {
    name: 'Image Drag and Drop',
    id: 'image_drag_and_drop',
    symbol: 'cloze-association',
  },
]

const defaultRubricConfig = {
  rubrics: {
    simpleRubric: {
      points: ['', '', '', ''],
      sampleAnswers: [null, null, null, null],
      maxPoints: 3,
      excludeZero: false,
    },
    multiTraitRubric: {
      visibleToStudent: false,
      halfScoring: false,
      pointLabels: true,
      description: false,
      standards: false,
      dragAndDrop: false,
      scales: [
        {
          excludeZero: false,
          maxPoints: 2,
          scorePointsLabels: ['', ''],
          traitLabel: 'Trait',
          traits: [
            {
              name: '',
              description: '',
              standards: [],
              scorePointsDescriptors: ['', ''],
            },
            {
              name: '',
              description: '',
              standards: [],
              scorePointsDescriptors: ['', ''],
            },
          ],
        },
        {
          excludeZero: false,
          maxPoints: 2,
          scorePointsLabels: ['', ''],
          traitLabel: 'Trait',
          traits: [
            {
              name: '',
              description: '',
              standards: [],
              scorePointsDescriptors: ['', ''],
            },
            {
              name: '',
              description: '',
              standards: [],
              scorePointsDescriptors: ['', ''],
            },
          ],
        },
      ],
      excludeZero: true,
      maxPointsEnabled: true,
      addScaleEnabled: false,
    },
  },
  rubricType: 'simpleRubric',
}
export { authorSettings, ibxItemTypes, defaultRubricConfig }
