let token = ''
const host = `${process.env.VUE_APP_PIE_URI}`
const api = `${host}/api/legacy/graphql`

const PIEService = {
  setToken: (value) => {
    token = value
  },
  query: async ({ query, variables }) => {
    if (!token) {
      return Promise.reject('No token provided')
    }

    try {
      const response = await fetch(api, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Accept-Encoding': 'gzip, deflate, br',
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
        body: JSON.stringify({
          query,
          variables,
        }),
      })
      const jsonBody = await response.json()
      const { data } = await jsonBody
      return data
    } catch (error) {
      console.log('ERROR: ', error)
      return Promise.reject(error)
    }
  },
}

export default PIEService
export { PIEService }
