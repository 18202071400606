import { makeEventBusInjection } from '@events'

const lazyLoadMixin = {
  inject: {
    ...makeEventBusInjection(),
  },

  props: {
    lazy: { type: Boolean, default: false }, // lazy load flag
    lazyLoadTrigger: { type: Boolean, default: false }, // lazy load trigger
  },

  methods: {
    lazyLoad() {},
  },

  mounted() {
    if (this.lazy && !this.lazyLoadTrigger) {
      const unwatch = this.$watch('lazyLoadTrigger', (v) => {
        if (v) {
          this.lazyLoad()
          unwatch()
        }
      })
    } else {
      this.lazyLoad()
    }
  },
}

export default lazyLoadMixin
export { lazyLoadMixin }
