<template>
  <div class="build-header-actions">
    <!-- description -->
    <v-tooltip
      bottom
      nudge-bottom="4"
      open-delay="0"
      close-delay="0"
      transition="fade"
      content-class="custom-tooltip desc-tooltip"
      max-width="300"
      :disabled="!hasDescription"
    >
      <template v-slot:activator="{ on: tooltip }">
        <v-chip
          v-on="{ ...tooltip }"
          :color="tagColor('description')"
          :text-color="textColor"
          small
          @click="onClickDescription"
        >
          <v-avatar>
            <font-awesome-icon :icon="['fas', 'plus']" />
          </v-avatar>
          Description
        </v-chip>
      </template>
      <span>{{ getDescription }}</span>
    </v-tooltip>

    <!-- tags -->
    <v-menu open-on-hover offset-y :disabled="!hasTag">
      <template v-slot:activator="{ on: menu }">
        <v-chip :color="tagColor('tag')" :text-color="textColor" v-on="{ ...menu }" @click="onClickAmstSettings">
          <v-avatar>
            <font-awesome-icon :icon="['fas', 'plus']" />
          </v-avatar>
          {{ getNumberOfTags }} Tags
        </v-chip>
      </template>
      <v-list class="build-header-actions__tags-list">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="tags-title-tile">Tags</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-for="tag in getTags" :key="tag.tagId">
          <v-list-item-content>
            {{ tag.tagName }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <ibx-assessment-description-modal :show="showDescription" @show="onShowDesc" :hadDescription="hasDescription" />
  </div>
</template>

<script>
import { VChip, VAvatar } from 'vuetify'
import { AsmtMixin, PermsMixin } from '@mixins'
import { Events, EventBus } from '@events'
import IbxAssessmentDescriptionModal from '@components/ibx/IbxAssessmentDescriptionModal'
import * as VTooltip from 'vuetify/es5/components/VTooltip'
import CONST from '@constants'

export default {
  name: 'BuildHeaderActions',

  mixins: [AsmtMixin, PermsMixin],

  components: {
    VChip,
    VAvatar,
    IbxAssessmentDescriptionModal,
    ...VTooltip,
  },

  data: () => ({
    color: '#365b97',
    textColor: '#ebeff5',
    showDescription: false,
  }),

  computed: {
    getDescription() {
      if (this.asmtDescription) {
        if (this.asmtDescription.length > 120) {
          return this.asmtDescription.indexOf(' ') == -1
            ? `${this.asmtDescription.substring(0, 30)}...`
            : `${this.asmtDescription.substring(0, 117)}...`
        } else {
          return this.asmtDescription
        }
      } else {
        return ''
      }
    },
    getTags() {
      var tags = []
      // concatinate all assessment tags
      for (var key in this.asmtTags) {
        if (!{}.propertyIsEnumerable.call(this.asmtTags, key)) continue

        var tagObj = this.asmtTags[key]['tags']
        for (var i = 0; i < tagObj.length; i++) {
          tags.push(tagObj[i])
        }
      }
      return tags || {}
    },
    hasDescription() {
      return this.asmtDescription && this.asmtDescription.length > 0
    },
    hasTag() {
      return Object.keys(this.getTags).length > 0
    },
    getNumberOfTags() {
      return this.hasTag ? Object.keys(this.getTags).length : ''
    },
  },

  filters: {
    truncateTag(v = '') {
      const max = 20
      if (v.length > max) return `${v.substring(0, max - 3)}...`
      else return v
    },
  },

  methods: {
    onClickDescription() {
      this.showDescription = !this.showDescription
    },
    onClickAmstSettings() {
      EventBus.$emit(Events.ASSESSMENT_SETTINGS, CONST.SETTINGS_GENERAL_TAB)
    },
    onClickTags() {
      EventBus.$emit(Events.ASSESSMENT_SETTINGS, CONST.SETTINGS_GENERAL_TAB)
    },
    onShowDesc(show) {
      this.showDescription = show
    },
    tagColor(v) {
      if (v === 'description') return this.hasDescription ? '#050F2D' : '#1F685B'
      else if (v === 'tag') return this.hasTag ? '#050F2D' : '#1F685B'
      else return '#1F685B'
    },
  },

  created() {
    EventBus.$on(Events.SHOW_DESCRIPTION, (show) => (this.showDescription = show))
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.build-header-actions {
  margin-top: -4px;
  .v-chip,
  .v-chip.theme--light {
    margin: 0 10px 0 0;
    border-radius: 4px;
    padding: 0px !important;

    &:hover {
      background: rgba(5, 15, 45, 0.4) !important;
    }

    &::after {
      content: '';
      display: none !important;
    }

    .v-chip__content {
      user-select: none;
      padding: 0px 10px 0px 2px;

      &:hover {
        cursor: pointer !important;
      }

      .v-avatar {
        margin-right: 0px;
        min-width: 24px !important;
        width: 24px !important;
      }
    }
  }
}

.build-header-actions__tags-list {
  .tags-title-tile {
    font-weight: bold;
  }
  .v-item__tile {
    font-size: 12px;
    height: 32px;
  }
}

.desc-tooltip {
  font-size: 14px !important;
  padding: 16px !important;
}
</style>
