/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'Number Line_2': {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M183.5 63.31V73.5H152v-5a4 4 0 00-8 0v5H99.5v-5a4 4 0 00-8 0v5H47v-5a4 4 0 00-8 0v5H8.5V63.31c0-5.418 4.392-9.81 9.81-9.81h155.38c5.418 0 9.81 4.392 9.81 9.81zM152 79.5h31.5v49.19c0 5.418-4.392 9.81-9.81 9.81H18.31c-5.418 0-9.81-4.392-9.81-9.81V79.5H39v5a4 4 0 008 0v-5h44.5v5a4 4 0 008 0v-5H144v5a4 4 0 008 0v-5z" _fill="#152452"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M96.07 101.379a7.61 7.61 0 015.93 2.841c1.373 1.893 2.06 4.72 2.06 8.48 0 3.76-.69 6.59-2.07 8.49a7.57 7.57 0 01-11.84 0c-1.38-1.9-2.07-4.73-2.07-8.49s.687-6.587 2.06-8.48a7.61 7.61 0 015.93-2.841zm.01 18.651a2.92 2.92 0 002.65-1.81l-.03.05a12.702 12.702 0 001.02-5.62 12.483 12.483 0 00-1-5.56 2.9 2.9 0 00-2.64-1.81c-2.4-.007-3.6 2.45-3.6 7.37s1.2 7.38 3.6 7.38zM20.92 113.77v3.68h10.94v-3.68H20.92zM42.82 101.68l-5.26 4.72v4.52l5.06-4.72v17.54H47v-22.06h-4.18zM147.42 101.68l-5.26 4.72v4.52l5.06-4.72v17.54h4.38v-22.06h-4.18zM183.5 73.5v6H152v5a4 4 0 01-8 0v-5H99.5v5a4 4 0 01-8 0v-5H47v5a4 4 0 01-8 0v-5H8.5v-6H39v-5a4 4 0 018 0v5h44.5v-5a4 4 0 018 0v5H144v-5a4 4 0 018 0v5h31.5z" _fill="#fff"/>',
  },
})
