import { FLAG } from '@constants'
import { AutoBind } from '@helpers'
import * as LDClient from 'launchdarkly-js-client-sdk'

/**
 * Get feature flag from session storage if allowed
 * @param flag feature flag key
 * @param defaultValue feature flag default value
 */
export const getLocalFlag = (flag: string, defaultValue: boolean) => {
  const allow = process.env.NODE_ENV !== 'production'
  const sessionFlag = allow ? JSON.parse(sessionStorage.getItem(flag)) : defaultValue
  if (allow && sessionFlag == null) sessionStorage.setItem(flag, String(defaultValue))
  return allow && sessionFlag != null ? sessionFlag : defaultValue
}

export interface Flags {
  [key: string]: boolean
}

export class FeatureFlagService {
  private user: any = {}
  private ldClient: any = null
  private hash: string = null
  private subscribers: Array<(data: any) => void> = []
  private localFlags: { [key: string]: any } = {
    [FLAG.PARTIAL_SCORING]: false,
    [FLAG.PARTIAL_SCORING_ITEM_TYPES]: {
      items: [],
    },
    [FLAG.PIE_API_COMPONENTS_VERSION]: '3.0.3',
    [FLAG.PIE_PRINT_VERSION]: '2.1.4',
    [FLAG.PRINT_BOOKLETS]: true,
    [FLAG.ITEM_LOCKS]: true,
    [FLAG.ITEM_REVISION_OPTIONS]: true,
    [FLAG.PASSAGE_AUTHORING]: false,
    [FLAG.ASMT_LIMIT]: 220,
    [FLAG.DRAWING_RESPONSE]: true,
    [FLAG.PRINT_ITEM_TYPES]: {
      itemTypes: ['multiple_select', 'multiple_choice', 'constructed_response', 'evidence_based_selected_response'],
    },
    [FLAG.PRINT_ITEM_TYPE_VERSIONS]: {
      'complex-rubric': '1.11.3',
      ebsr: '7.0.25',
      'explicit-constructed-response': '3.13.3',
      'extended-text-entry': '6.8.12',
      'math-inline': '5.9.11',
      'multiple-choice': '6.3.3',
      passage: '1.7.9',
      rubric: '1.8.10',
      'select-text': '6.6.3',
    },
    [FLAG.APOLLO_CACHE_VERSION]: 'v2',
    [FLAG.AUTHORING_ORDERING]: true,
    [FLAG.AUTHORING_MATCHING_TABLE]: true,
    [FLAG.AUTHORING_MULTIPLE_BYNARY]: true,
    [FLAG.MULTI_LEVEL_RUBRICS]: false,
    [FLAG.AUTHORING_GRAPHING]: false,
    [FLAG.AUTHORING_CLASSIFICATION]: false,
    [FLAG.AUTHORING_CHARTING]: false,
    [FLAG.CAN_DUPLICATE_ITEMS]: false,
    [FLAG.AUTHORING_NUMBER_LINE]: false,
  }

  /**
   * LD env client id
   */
  private get ldClientId(): string {
    return process.env.VUE_APP_LAUNCH_DARKLY_CLIENT_ID
  }

  /**
   * Get LD options based on env
   */
  private get ldOptions(): { [key: string]: any } {
    const options: any = {
      sendEventsOnlyForVariation: true,
    }

    // secure mode hash
    if (this.hash) options.hash = this.hash

    switch (process.env.NODE_ENV) {
      case 'development':
      case 'staging':
        options.bootstrap = 'localStorage'
        break
    }

    return options
  }

  /**
   * Marshal User for LD
   * @param userId
   * @returns object marshalled LD user
   */
  private marshalUser({ userId }: { userId: number | string }): {
    [key: string]: any
  } {
    return {
      key: userId,
      custom: {
        userId,
        client: 'ibx-frontend',
      },
    }
  }

  /**
   * Marshal LD changes
   * @param settings LD flag changes
   */
  private marshalLdChange(settings: any = {}): { [key: string]: any } {
    const changedFlags = Object.keys(settings).reduce((m: any, k: string) => {
      m[k] = settings[k].current || false
      return m
    }, {})

    return Object.assign({}, this.localFlags, changedFlags)
  }

  /**
   * On LD Flags change callback.
   * Broadcasts to subscribers.
   * @param settings LD change data
   */
  @AutoBind
  private onLdChange(settings: any) {
    const flags = this.marshalLdChange(settings)
    this.subscribers.forEach((callback: any) => {
      if (typeof callback == 'function') {
        callback(flags)
      }
    })
  }

  /**
   * Initialize Launch Darkly client.
   * Development and Staging will use local storage
   * and subscribe to LD change event.
   * @param user user object
   */
  public async init({ user = {}, hash }: { user: any; hash?: string }): Promise<any> {
    try {
      this.hash = hash
      this.user = this.marshalUser(user)
      this.ldClient = LDClient.initialize(this.ldClientId, this.user, this.ldOptions)
      if (process.env.NODE_ENV != 'production') {
        this.ldClient.on('change', this.onLdChange)
      }
      await this.ldClient.waitUntilReady()
    } catch (e) {
      console.warn(e)
    }
  }

  /**
   * Subscribe to callback function
   * @param callback function
   */
  public subscribe(callback: (data: any) => void) {
    this.subscribers.push(callback)
  }

  /**
   * Fetch feature flags
   * @returns Promise<Flags>
   */
  public async fetchFlags(): Promise<Flags> {
    const flags = this.ldClient?.allFlags() || {}
    return Object.assign({}, this.localFlags, flags)
  }

  /**
   * Get default flags
   */
  public get defaults(): { [key: string]: any } {
    return this.localFlags
  }
}
