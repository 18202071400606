<template>
  <div class="build-asmt-message" :class="getClasses">
    <div class="build-asmt-message__content">
      <div>
        <strong
          >Your assessment contains {{ getOmittedCount }} legacy items(s) that did not carry over. Please remove or
          replace these before publishing.</strong
        >
      </div>
      <div><strong>Item Revision IDs in Legacy Item Bank: </strong> {{ getItemIds }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BuildAsmtMessage',

  computed: {
    ...mapGetters('asmt', ['hasErrors', 'omittedQuestions']),

    getClasses() {
      return this.hasErrors ? ['build-asmt-message--error'] : []
    },

    getOmittedCount() {
      return this.omittedQuestions.length
    },

    getItemIds() {
      return this.omittedQuestions.map((error) => error.legacyItemRevId).join(', ')
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.build-asmt-message {
  display: flex;
  border-radius: 4px;
  padding: 20px;

  &--error {
    background: $R50;
  }

  &__content {
    flex: 1;
    color: #b25c58;
  }
}
</style>
