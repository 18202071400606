import { mapGetters } from 'vuex'
import { ItemTypeIconMapper, ItemTypeLabelMapper } from '@helpers/itemTypeMaps.js'

const metaInterface = {
  id: '',
  name: '',
  items: [],
  meta: {},
}

const ItemMixin = {
  props: {
    itemId: { type: [String, Number], required: false }, // ibx item id
    itemRevId: { type: [String, Number], required: false }, // ibx item id
    itemData: { type: Object, default: () => ({}) }, // item data provided via context provide
    remoteId: { type: [String, Number], default: null }, // vendor item id
    context: { type: String, default: 'browse' }, // browse/build/self mode context
  },

  data: () => ({
    item: {}, // item data
    //itemRevId: null,  // assessment item id
    itemReady: false, // true when item data is retrieved from store (browse or asmt)
  }),

  computed: {
    ...mapGetters('browseItems', {
      browseItemsItems: 'items',
    }),
    ...mapGetters('asmt', {
      asmtItems: 'items',
    }),
    ...mapGetters('auth', {
      itemPieToken: 'pieToken',
    }),
    itemToken() {
      return this.itemPieToken
    },
    itemVendorItemId() {
      return this.item.remoteId
    },
    itemVendorItemIdVersioned() {
      return `${this.item.remoteId}@${this.item.version}`
    },
    itemVersion() {
      return this.item.version
    },
    itemLocked() {
      return this.item.locked
    },
    itemItemType() {
      return this.item.meta.itemType || metaInterface
    },
    itemItemTypeLabel() {
      //return ItemTypeLabelMapper(this.itemType)
      return this.item.meta.itemType.items[0].name
    },
    itemItemTypeIcon() {
      return ItemTypeIconMapper(this.item.meta.itemType.items[0].name)
    },
    itemDok() {
      return this.item.meta.depth_of_knowledge || metaInterface
    },
    itemStandard() {
      return this.item.meta.standard || metaInterface
    },
    itemItemBank() {
      return this.item.meta.bank || metaInterface
    },
    itemTeacherInstructions() {
      return this.item.teacher_instructions
    },
    itemCreatedDate() {
      return this.item.createdAt
    },
    itemUpdatedDate() {
      return this.item.updatedAt
    },
    itemLanguage() {
      return this.item.meta.language || metaInterface
    },
    itemSystemTags() {
      return [
        this.itemDok,
        this.item.meta.blooms_taxonomy,
        this.item.meta.revised_blooms_taxonomy,
        this.item.meta.responseLanguage,
        this.item.meta.sbac_claim,
        this.item.meta.sbac_subclaim,
        this.item.meta.sbac_secondary_claim,
        this.item.meta.sbac_claim_target,
        this.item.meta.sbac_secondary_claimTarget,
        this.item.meta.sbac_content_category,
        this.item.meta.sbac_secondary_contentCategory,
        this.item.meta.parcc_claim,
        this.item.meta.parcc_secondary_claim,
        this.item.meta.parcc_subclaim,
        this.item.meta.parcc_secondary_subclaim,
        this.item.meta.difficulty,
      ]
    },
    itemMediaType() {
      return this.item.meta.media || metaInterface
    },
    itemUserId() {
      return this.item.user ? this.item.user.userId : null
    },
    itemType() {
      return this.item.itemType
    },
    itemIsDraft() {
      return !this.item.published
    },
    checkFeedbackPerm() {
      return this.item.feedbackEnabled
    },
    checkIfCanDuplicate() {
      return this.item.canDuplicate
    },
    // IS THIS USED?
    getContext() {
      const { context } = this
      const valid = ['browse', 'build']
      return valid.includes(context) ? context : 'browse'
    },
  },

  filters: {
    itemFilterDokLevel(data = '') {
      return data.replace(/^(.+?) -.*$/, '$1')
    },
    itemFilterPluralize(count, label) {
      return count > 1 ? `${count} ${label}s` : `${count} ${label}`
    },
  },

  methods: {
    itemMeta(id) {
      return this.item.meta[id] || metaInterface
    },
    itemMetaName(id) {
      return this.itemMeta(id).name
    },
    itemMetaItems(id) {
      return this.itemMeta(id).items
    },
    itemSetBrowseItem() {
      const data = this.browseItemsItems.find((o) => o.itemRevId == this.itemRevId)
      if (data) {
        this.item = data
        this.itemReady = true
      }
    },
    itemSetBuildItem() {
      const data = this.asmtItems.find((o) => o.item.itemRevId == this.itemRevId)
      if (data) {
        this.item = data.item
        this.itemReady = true
      }
    },
    itemSetProvideItem() {
      this.item = this.itemData
      this.itemReady = true
    },
    itemInit() {
      if (this.context == 'provide') this.itemSetProvideItem()
      // get item data from provided data
      else if (this.context == 'browse') this.itemSetBrowseItem()
      // get item data from browse items
      else this.itemSetBuildItem() // get item data from asmt data
    },
  },

  created() {
    this.itemInit()
  },
}

export { ItemMixin }
