<template>
  <div class="x-filters-settings">
    <!-- HIDE THIS FOR NOW. WE MAY NEED THIS LATER 
        <div class="x-filters-options">
            <div 
                v-for="(o, i) in options"
                :key="i + 1"
                class="x-filters-options-item"
            >
                <div class="x-filters-options-item__label">{{ o.name }}</div>
                <v-switch 
                    v-model="o.selected"
                    class="x-filters-options-item__toggle"
                    color="success"
                    hide-details
                    @change="(selected) => onOptionChange(o.id, selected)"
                />
            </div>
        </div>-->

    <div class="x-filters-order">
      <div class="x-filters-order__header">
        <div class="x-filters-order__title">Reorder Filters</div>
      </div>
      <draggable
        v-if="true"
        element="div"
        v-model="getFilters"
        v-bind="dragOptions"
        @start="drag = true"
        @end="drag = false"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null" class="x-filters-order-wrapper">
          <div
            v-for="f in getFilters"
            :key="f.id"
            class="x-filters-order-item"
            :class="canRemove(f) ? 'x-filters-order-item--can-remove' : ''"
          >
            <v-tooltip
              color="#050F2D"
              bottom
              open-delay="0"
              close-delay="0"
              content-class="custom-tooltip"
              transition="none"
            >
              <template v-slot:activator="{ on }">
                <div class="x-filters-order-item__dragger" v-on="on">
                  <font-awesome-icon :icon="['far', 'ellipsis-v']" class="icon" />
                  <font-awesome-icon :icon="['far', 'ellipsis-v']" class="icon" />
                </div>
              </template>
              <span> <strong>Drag</strong> to move </span>
            </v-tooltip>
            <div class="x-filters-order-item__label">{{ f.name }}</div>
            <div v-if="canRemove(f)" class="x-filters-order-item__remove" @click="remove(f.id)">
              <font-awesome-icon :icon="['fal', 'times']" />
            </div>
          </div>
        </transition-group>
      </draggable>
      <div class="x-filters-order__title x-filters-add__title">Add More Filters</div>
      <x-dropdown
        class="x-filters-settings__add-options"
        :items="sortedInActiveFilters"
        label="More Filters"
        @change="selectAddFilter"
      />
      <x-btn class="x-filters-settings__add" :disabled="!hasSelectedFilter" @click="onAddFilter">Add Filter</x-btn>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { Events } from '@events'
import { FilterEvents } from './xFilterEvents'
import * as VSwitch from 'vuetify/es5/components/VSwitch'
import XBtn from '@xLib/XBtn'
import XDropdown from '@xLib/XDropdown'
import * as VTooltip from 'vuetify/es5/components/VTooltip'
import _ from 'lodash'

export default {
  name: 'XFilterGroupSettins',

  components: {
    draggable,
    ...VSwitch,
    ...VTooltip,
    XBtn,
    XDropdown,
  },

  props: {
    filters: { type: Array, default: () => [] }, // active filters
    options: { type: Array, default: () => [] }, // filter options
    inActiveFilters: { type: Array, default: () => [] }, // non visible filters
  },

  data: () => ({
    drag: true,
    selectedFilterId: null,
  }),

  computed: {
    dragOptions() {
      return {
        animation: 300,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
        handle: '.x-filters-order-item__dragger',
      }
    },
    getFilters: {
      get() {
        return this.filters
      },
      set(orderedFilters) {
        this.$emit(FilterEvents.FILTERS_ORDER, orderedFilters)
      },
    },
    hasSelectedFilter() {
      return Boolean(this.selectedFilterId)
    },
    sortedInActiveFilters() {
      return _.orderBy(this.inActiveFilters, ['name'])
    },
  },

  methods: {
    remove(id) {
      this.$emit(FilterEvents.FILTER_REMOVE, id)
    },
    onAddFilter() {
      if (this.hasSelectedFilter) {
        this.$emit(FilterEvents.FILTER_ADD, this.selectedFilterId)
        this.selectedFilterId = null
      }
    },
    onOptionChange(id, data) {
      this.$emit(Events.CHANGE, { id, data })
    },
    canRemove(filter) {
      return filter.canRemove
    },
    selectAddFilter({ id }) {
      this.selectedFilterId = id
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/main';
@import '@/styles/components/XFilter';

.x-filters-settings {
  width: 100%;
  padding: $x-filter-padding-lg;
  background: white;
  max-height: calc(100vh - 58px - 52px - 20px);
  overflow-y: auto;

  &__add-options {
    margin-top: 12px !important;
  }

  &__add {
    margin-left: 0px;
    width: 100%;
  }

  .x-filters-order {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      margin-bottom: 12px;
    }

    &__title {
      font-weight: bold;
    }

    &__edit {
      cursor: pointer;
      color: $B300;
    }

    .x-filters-order-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px 11px;
      border-radius: 5px;
      border: 1px dashed $N30;
      color: black;
      background: white;
      cursor: default;

      &:not(:first-child) {
        margin-top: 12px;
      }

      &--can-remove {
        border-color: black;
      }

      &__dragger {
        width: 20px;
        cursor: grab;
        color: $N30;
        font-size: 13px;
      }

      &__dragger:active {
        cursor: grabbing;
      }

      &__label {
        font-size: 13px;
      }

      &__remove {
        margin-left: auto;
        cursor: pointer;
        color: $N50;
      }
    }
  }

  .x-filters-options {
    margin-bottom: 28px;
  }

  .x-filters-options-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    font-size: 12px;

    &__label {
      width: 200px;
    }

    &__toggle {
      margin: 0 0 0 auto;

      .v-input--selection-controls__input {
        margin-left: auto;
      }
    }
  }
}

.x-filters-add__title {
  margin-top: 24px;
}

/* spacing of drag icon */
.svg-inline--fa.fa-w-4 {
  width: 0.3em;
}
</style>
