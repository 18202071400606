const XTheme = {
  primary: { base: '#008272' },
  secondary: { base: '#008272' },
  'secondary-2': { base: '#B3B7C5' },
  tertiary: { base: '#ECEDF1' },
  disabled: { base: '#E0E0E0' },
  danger: { base: '#F02D1F' },
  help: { base: '#5A53C9' },
  success: { base: '#0EA449', 'lighten-1': '#49C379', 'lighten-2': '#71CA94' },
  warning: { base: '#FFA200' },
  white: { base: '#FFFFFF' },
  info: { base: '#008272' },
}

export default XTheme
export { XTheme }
