import { makeEventBusInjection } from '@events'
import XFilterNoItems from '@xLib/XFilter/subcomponents/XFilterNoItems'

/*
 * This mixin is required to be used in
 * conjunction with xFilterMixin
 */
const xFilterListMixin = {
  components: {
    XFilterNoItems,
  },

  inject: {
    ...makeEventBusInjection(),
  },

  props: {
    id: { type: [String, Number], required: true }, // component id
    multiselect: { type: Boolean, default: true }, // allow more that one selection
    selectAll: { type: Boolean, default: false }, // prepend select all option
    auxilliaryItems: { type: Array, default: () => [] }, // append auxilliaryItems,
    disabled: { type: Boolean, default: false }, // disable any selections
    allSelected: { type: Boolean, default: false }, // all items selected
    useTooltip: { type: Boolean, default: false }, // reder tooltips
  },

  data: () => ({
    load: false,
  }),

  computed: {
    hasItems() {
      return this.items.length > 0
    },
  },

  methods: {
    isSelected(id) {
      return this.selectedItems.includes(id)
    },
    change(itemId, add) {
      this.$emit(this.events.FILTER_CHANGE, {
        id: this.id,
        itemIds: [itemId],
        add,
      })
    },
    onSelectAll() {
      this.$emit(this.events.FILTER_SELECT_ALL)
    },
  },

  created() {
    if (!this.items) throw `Filter: ${this.id}: Prop "items" not found. xFilterMixin is a required.`
  },
}

export default xFilterListMixin
export { xFilterListMixin }
