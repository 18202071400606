import Vue from 'vue'

const pluralizeString = (count, str) => {
  return count > 1 ? `${count} ${str}s` : `${count} ${str}`
}
const htmlDecode = (input) => {
  var doc = new DOMParser().parseFromString(input, 'text/html')
  return doc.documentElement.textContent
}
Vue.filter('htmlDecode', htmlDecode)
Vue.filter('pluralizeString', pluralizeString)
