<template>
  <x-summary-view>
    <template v-slot:summary-view-icon>
      <svgicon width="25" height="18" name="Summary" class="icon" id="summary-icon"></svgicon>
    </template>
    <template v-slot:summary-view-title>
      <div class="summary-view-title">
        <span class="summary-view-title__title">{{ title }}</span>
        <v-chip class="summary-view-title__chip">{{ summaryAsmtItemCount }}</v-chip>
      </div>
    </template>
    <x-quick-data-view
      v-for="(summary, index) in dataView"
      :key="index"
      :title="summary.title"
      :count="summary.items.length"
      :items="summary.items"
      :emptyText="summary.emptyText"
      :standards="standards"
    >
    </x-quick-data-view>
  </x-summary-view>
</template>

<script>
import XSummaryView from '../xLib/XSummaryView'
import XQuickDataView from '../xLib/XQuickDataView'
import { AsmtMixin, ItemMixin, StandardLabelMixin } from '@/mixins'

import IBXService from '@services/ibx/IBXService'
import _ from 'lodash'
import '@plugins/vue-rx'
import { tap, debounceTime } from 'rxjs/operators'
import { mapActions } from 'vuex'

export default {
  name: 'IbxAssessmentSummary',

  mixins: [AsmtMixin, ItemMixin, StandardLabelMixin],

  data: () => ({
    title: 'Assessment Summary',
    standards: [],
  }),

  components: {
    XSummaryView,
    XQuickDataView,
  },

  computed: {
    summaryAsmtItems() {
      if (this.asmtId) {
        return this.asmtItems
      }

      return Object.values(this.asmtItemsToAddData).filter((item) => this.asmtItemsToAdd.includes(item.itemRevId))
    },
    summaryAsmtItemCount() {
      return this.asmtId ? this.asmtItemCount : this.asmtItemsToAddCount
    },
    dataView() {
      const defaultDataView = {
        standards: {
          title: 'Standards',
          items: [],
          emptyText: 'No standards',
        },
        item_types: {
          title: 'Item Types',
          items: [],
          emptyText: 'No item types',
        },
        dok: {
          title: 'DOK',
          items: [],
          emptyText: 'No DOK',
        },
        passages: {
          title: 'Passages',
          items: [],
          emptyText: 'No passages',
        },
      }

      let dataViews = this.summaryAsmtItems.reduce(this.mapDataView, defaultDataView)
      dataViews.standards.items = this.standards
      this.sort(dataViews)

      return dataViews
    },
    getAllQuestionStandardsGuids() {
      return this.summaryAsmtItems
        .reduce((m, o) => {
          const standards = o.standards || o.baseStandards || []
          return [...m, ...standards]
        }, [])
        .filter((v) => v !== null)
    },
  },

  subscriptions() {
    this.summaryAsmtItems$ = this.$watchAsObservable('summaryAsmtItems')

    return {
      /*
       *
       * Watch asmtItems change, debounce standards change.
       * Fetch from backend only if needed
       */
      onStandardsChange: this.summaryAsmtItems$.pipe(
        debounceTime(1000),
        tap(() => this.setStandards())
      ),
    }
  },

  methods: {
    ...mapActions('standardsItems', {
      standardItemsGetItems: 'getItems',
    }),
    sort: function (dataViews) {
      Object.keys(dataViews).forEach((dataViewKey) => {
        const dataView = dataViews[dataViewKey]
        dataView.items = _.orderBy(dataView.items, ['count', 'title'], ['desc', 'asc'])
      })
    },

    mapDataView: function (dataViews, asmtItem) {
      this.item = asmtItem.item ? asmtItem.item : asmtItem
      const itemDataFinder = {
        standards: () => [],
        item_types: () => [this.itemItemTypeLabel],
        dok: () => this.itemDok.items.map((v) => v.name),
        passages: () => (this.item.passage ? [this.item.passage.title] : []),
      }

      Object.keys(dataViews).forEach((dataViewKey) => {
        const dataView = dataViews[dataViewKey]
        itemDataFinder[dataViewKey]().forEach((dataName) => {
          let dataViewItem = dataView.items.find((item) => item.title === dataName)
          if (!dataViewItem) {
            dataViewItem = { title: dataName, count: 0 }
            dataView.items.push(dataViewItem)
          }
          dataViewItem.count++
        })
      })
      return dataViews
    },

    mapStandards(allGuids = [], data = []) {
      const counts = _.countBy(allGuids)
      return _.orderBy(
        data.map((o) => {
          return {
            title: this.stdLabel(o, 25),
            count: counts[o.guid] || 0,
            guid: o.guid,
            code: o.code,
          }
        }),
        ['count', 'title'],
        ['desc', 'asc']
      )
    },

    /**
     * Get standards from API or cache
     */
    async setStandards() {
      const allGuids = this.getAllQuestionStandardsGuids
      if (allGuids.length) {
        const data = await this.standardItemsGetItems(_.uniq(allGuids))
        this.standards = this.mapStandards(allGuids, data)
      }
    },
  },

  mounted() {
    this.setStandards()
  },
}
</script>

<style lang="scss">
#summary-icon {
  .summary-icon-0 {
    fill: #f7941e;
  }
  .summary-icon-1 {
    fill: #27aae1;
  }
  .summary-icon-2 {
    fill: #8dc63f;
  }
  .summary-icon-3 {
    fill: #27aae1;
  }
  .summary-icon-4 {
    fill: #1c75bc;
  }
}

.summary-view-title {
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1;
  width: 100%;

  &__title {
    padding-right: 8px;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
  }

  .v-chip {
    width: 25px;
    max-width: 32px !important;

    .v-chip__content {
      font-weight: 600 !important;
    }
  }
}
</style>
