/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'Multiple Binary': {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<g clip-path="url(#clip0_1523_325)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M25.56 132.73c0-17.916 14.524-32.44 32.44-32.44 17.916 0 32.44 14.524 32.44 32.44 0 17.916-14.524 32.44-32.44 32.44-17.916 0-32.44-14.524-32.44-32.44zm54.88 0c0-12.393-10.047-22.44-22.44-22.44-12.386.017-22.423 10.054-22.44 22.44 0 12.393 10.047 22.44 22.44 22.44s22.44-10.047 22.44-22.44zM26.1 58.73c0-17.618 14.282-31.9 31.9-31.9a31.9 31.9 0 0131.9 31.9c0 17.618-14.282 31.9-31.9 31.9s-31.9-14.282-31.9-31.9zm53.8 0c0-12.095-9.805-21.9-21.9-21.9-12.09.011-21.889 9.81-21.9 21.9 0 12.095 9.805 21.9 21.9 21.9 12.095 0 21.9-9.805 21.9-21.9zM102.1 58.73c0-17.618 14.282-31.9 31.9-31.9a31.9 31.9 0 0131.9 31.9c0 17.618-14.282 31.9-31.9 31.9s-31.9-14.282-31.9-31.9zm53.8 0c0-12.095-9.805-21.9-21.9-21.9-12.09.011-21.889 9.81-21.9 21.9 0 12.095 9.805 21.9 21.9 21.9 12.095 0 21.9-9.805 21.9-21.9zM101.56 132.73c0-17.916 14.524-32.44 32.44-32.44 17.916 0 32.44 14.524 32.44 32.44 0 17.916-14.524 32.44-32.44 32.44-17.916 0-32.44-14.524-32.44-32.44zm54.88 0c0-12.393-10.047-22.44-22.44-22.44-12.386.017-22.423 10.054-22.44 22.44 0 12.393 10.047 22.44 22.44 22.44s22.44-10.047 22.44-22.44z" _fill="#152452"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M58.4 147.67c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15zM134.4 73.67c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15z" _fill="#152452"/><path pid="2" _stroke="#152452" stroke-width="28" d="M84 58h25M84 132h25"/></g><defs><clipPath id="clip0_1523_325"><path pid="3" _fill="#fff" d="M0 0h192v192H0z"/></clipPath></defs>',
  },
})
