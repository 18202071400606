/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'Drag and Drop_1': {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M24.1 37.38c.368.079.743.12 1.12.12a5 5 0 004.87-3.88 3.74 3.74 0 013.51-2.9 5 5 0 00-.17-10h-.16a13.72 13.72 0 00-9.07 3.84l-.06.06h-.9v.1a.18.18 0 00-.05.07l-.06.08-.06.09a13.551 13.551 0 00-2.3 5 5 5 0 003.33 7.44v-.02zm-4.1 30v-18a5 5 0 0110 0v18a5 5 0 01-10 0zm85.25-36.66h-18v.89a5 5 0 010-10h20.53a13.718 13.718 0 017.79 4.11h.25l.23.28a5 5 0 01-.83 7 4.93 4.93 0 01-3.04 1.05 5 5 0 01-3.93-1.9 3.73 3.73 0 00-3-1.43zM109 43.3v18a5 5 0 0010 0v-18a5 5 0 00-10 0zM69.25 20.74a5 5 0 010 10h-18a5 5 0 010-10h18zm-23.71 89H33.75a3.754 3.754 0 01-1.63-.37 5.006 5.006 0 00-4.38 9 13.594 13.594 0 003.91 1.22h13.89a5 5 0 000-10v.15zM30 85.38v18a5 5 0 01-10 0v-18a5 5 0 1110 0zm132.38-17H78.71A9.62 9.62 0 0069.09 78v31.77h-5.55a5 5 0 000 10h5.55v41.9a9.62 9.62 0 009.62 9.62h83.67a9.62 9.62 0 009.62-9.62V78a9.62 9.62 0 00-9.62-9.65v.03zm-7.02 53.83a2 2 0 00.64-1.47 2 2 0 00-.64-1.44l-11.44-10.77a2 2 0 00-3.4 1.47v4.68h-13.46v-13.46h4.71a2.001 2.001 0 001.47-3.4l-10.77-11.45a2.001 2.001 0 00-2.94 0l-10.77 11.45a2 2 0 001.47 3.4h4.71v13.46h-13.46v-4.71a2.001 2.001 0 00-3.4-1.47l-11.44 10.77a2.001 2.001 0 000 2.94l11.44 10.77a2 2 0 003.4-1.47v-4.71h13.46v13.46h-4.71a2.001 2.001 0 00-1.47 3.4l10.77 11.44a2.003 2.003 0 002.94 0l10.77-11.44a2 2 0 00-1.47-3.4h-4.71V126.8h13.46v4.73a2.001 2.001 0 003.4 1.47l11.44-10.79z" _fill="#152452"/>',
  },
})
