<template>
  <div class="ibx-item">
    <IbxItemError v-if="hasError" :message="errorMessage" />
    <pie-player v-else class="ibx-vendor-item" ref="player" @load-complete="onLoaded" @player-error="onError" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import IbxItemError from './IbxItemError'
import CONST from '@constants'
import { playerEndpoints } from '@/plugins/pie'

export default {
  name: 'IbxItem',

  components: {
    IbxItemError,
  },

  props: {
    addCorrectResponse: { type: Boolean, default: true },
    id: { type: String, default: null },
    lockChoiceOrder: { type: Boolean, default: false },
    mode: { type: String, default: 'view' },
    role: { type: String, default: 'instructor' },
  },

  data: () => ({
    optimize: false,
    loaded: false,
    hasError: false,
    errorMessage: 'Loading Error',
  }),

  methods: {
    ...mapActions('itemConfigs', ['getItemConfig', 'getItemMetaConfig']),
    onLoaded(e) {
      this.loaded = true
      this.$emit('loaded', e)
    },
    onError(error) {
      this.hasError = true
      this.$emit('player-error', error)
      console.warn(error)
    },
    async initPlayer() {
      const player = this.$refs.player
      try {
        const { data } = await this.getItemConfig({ versionedId: this.id })
        if (data.contentItem == null) {
          const metaResponse = await this.getItemMetaConfig({ versionedId: this.id.split('@')[0], cacheFirst: false })
          if (metaResponse?.data?.contentItemMetaData?.k12_status === 'RETIRED') {
            this.errorMessage = CONST.RETIRED_ITEMS_ERROR
            throw new Error(CONST.RETIRED_ITEMS_ERROR)
          }
        }
        this.config = data.contentItem.config
        player.env = {
          ...player.env,
          ...{
            '@pie-element': {
              lockChoiceOrder: this.mode === 'view' ? true : this.lockChoiceOrder,
            },
          },
          mode: this.mode,
          role: this.role,
        }
        player.host = `${process.env.VUE_APP_PIE_PLAYER_ENV}`
        player.bundleHost = `${process.env.VUE_APP_PIE_PLAYER_ENV}`
        player.bundleEndpoints = playerEndpoints
        player.addCorrectResponse = this.addCorrectResponse
        player.optimize = false
        player.config = this.config
        this.$emit('playerConfig', data)
      } catch (error) {
        this.onError(error)
      }
    },
  },

  mounted() {
    this.initPlayer()
  },
}
</script>

<style lang="scss">
.ibx-item {
  width: 100%;
  .ibx-vendor-item {
    transform-origin: top left;
    overflow: auto;
  }
}
</style>
