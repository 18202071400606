import Vue from 'vue'
import { ItemPlayerPlugin } from '@illuminateeducation/illuminate-components-assessments'
import store from '@state/store'

const unwatch = store.watch(
  (store) => store.auth.pieToken,
  (val) => {
    Vue.use(ItemPlayerPlugin, {
      pie: {
        token: val,
        host: process.env.VUE_APP_PIE_PLAYER_ENV,
      },
    })
    unwatch()
  }
)

Vue.config.ignoredElements = ['pie-api-player', 'pie-api-configure', 'pie-api-author', 'pie-print']

/**
 * Gets array of pie-api-components JS sources from CDNS.
 * @param {string} version pie player version
 * @returns Array<string>
 */
const getPieApiComponentsSources = (version) => {
  return [
    `https://cdn.jsdelivr.net/npm/@pie-api/pie-api-components@${version}/dist/pie-api-components/pie-api-components.esm.js?version=${version}`,
    `https://unpkg.com/@pie-api/pie-api-components@${version}/dist/pie-api-components/pie-api-components.esm.js?version=${version}`,
  ]
}

/**
 * Create pie player scrcipt tag
 * @param {string} version pie player version
 * @param {string} id script tag id
 * @returns Promise<any>
 */
const initPieScript = async (src, id) => {
  return new Promise((resolve, reject) => {
    const piePlayerScript = document.createElement('script')
    piePlayerScript.setAttribute('id', `piePlayerScript-${id}`)
    piePlayerScript.setAttribute('type', 'module')
    piePlayerScript.setAttribute('src', src)
    piePlayerScript.setAttribute('crossorigin', true)
    piePlayerScript.onload = () => resolve(true)
    piePlayerScript.onerror = (error) => reject(error)
    document.head.appendChild(piePlayerScript)
  })
}

/**
 * Load PIE player components script with fallback
 * Awaits for script to load before attempting fallback(s)
 * @param {string} version
 * @returns Promise<any>
 */
const initPie = async (version) => {
  const srcs = getPieApiComponentsSources(version)

  for (let i = 0; i < srcs.length; i++) {
    const src = srcs[i]
    try {
      const loaded = await initPieScript(src, i + 1)
      if (loaded) {
        return true
      }
    } catch (error) {
      // script load failed. Loop will attempt to load fallback(s)
      console.warn(`Failed to load player src: ${src}`)
    }
  }

  // no pie player scripts loaded
  return false
}

const initPiePrint = async (flagOn, version) => {
  if (flagOn) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.setAttribute('type', 'module')
      script.setAttribute('id', 'piePlayerPrintScript')
      script.setAttribute('src', `https://cdn.jsdelivr.net/npm/@pie-framework/pie-print@${version}/lib/pie-print.js`)
      script.setAttribute('crossorigin', true)
      script.onload = () => resolve()
      script.onerror = (error) => reject(error)
      document.head.appendChild(script)
    })
  }
}

const playerEndpoints = {
  bundleBase: 'https://pits-cdn.pie-api.io/bundles/',
  buildServiceBase: 'https://proxy.pie-api.com/bundles/',
}

export { initPie, initPiePrint, playerEndpoints }
