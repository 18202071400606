
import * as VCard from 'vuetify/es5/components/VCard'
import * as VDivider from 'vuetify/es5/components/VDivider'
import { EventBus, Events } from '@events'
import { mapGetters } from 'vuex'
import { CONST, FLAG } from '@constants'
import ItemAuthorActionsBanks from '@components/itemAuthor/ItemAuthorActionsBanks'
import ItemAuthorActionsAsmtOptions from '@components/itemAuthor/ItemAuthorActionsAsmtOptions'
import { Vue, Component, Mixins } from 'vue-property-decorator'
import { StateAuthorItems, StateFeatures } from '@/helpers/state'
import { ItemAuthoringMode, ItemAuthorSaveAction } from '@/state/modules/authorItems'
import ItemAuthorActionItem from './ItemAuthorActionItem.vue'

@Component({
  components: {
    ...VCard,
    ...VDivider,
    ItemAuthorActionsBanks,
    ItemAuthorActionItem,
    ItemAuthorActionsAsmtOptions,
  },
  computed: {
    ...mapGetters('itemAuthor', ['updated']),
  },
})
export default class ItemAuthorActions extends Mixins(Vue, StateAuthorItems, StateFeatures) {
  private selectedBanks: ['myItems']

  get CONST(): any {
    return CONST
  }

  get FLAG(): { [key: string]: string } {
    return FLAG
  }

  get getPluralForm(): string {
    return this.authorItems?.length > 1 ? 's' : ''
  }

  get isEditMode(): boolean {
    return this.authorMode == ItemAuthoringMode.EDIT
  }

  get isCreationMode(): boolean {
    return this.authorMode == ItemAuthoringMode.CREATE
  }

  get isDraft(): boolean {
    const solitaryItem = this.authorItems?.[0]
    return this.isEditMode && solitaryItem && !solitaryItem?.published
  }

  async savePublish() {
    await this.waitForSave()
    this.$emit('main-menu-action', {
      action: ItemAuthorSaveAction.PUBLISH,
    })
  }

  async savePublishNew() {
    await this.waitForSave()
    this.$emit('main-menu-action', {
      action: ItemAuthorSaveAction.PUBLISH_NEW,
    })
  }

  async saveDraft() {
    await this.waitForSave()
    this.$emit('main-menu-action', {
      action: ItemAuthorSaveAction.DRAFT,
    })
  }

  discard(action: string) {
    this.$emit('main-menu-action', {
      action,
      confirm: this.authorHasChange,
    })
  }

  /**
   * Wait for saving to complete
   * @returns Promise resolve promise when saving is done
   */
  async waitForSave(): Promise<boolean> {
    if (this.authorIsSaving) {
      return new Promise((resolve) => {
        const unwatch = this.$watch('authorIsSaving', (saving) => {
          if (!saving) {
            unwatch()
            resolve(saving)
          }
        })
      })
    } else {
      return Promise.resolve(false)
    }
  }
}
