/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'Matching Table_1': {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M20.93 32.5h150.14a12.419 12.419 0 0112.43 12.43v102.14a12.416 12.416 0 01-3.638 8.792 12.416 12.416 0 01-8.792 3.638H20.93A12.417 12.417 0 018.5 147.07V44.93A12.42 12.42 0 0120.93 32.5zm108.57 48h46v-10h-46v-31h-10v31h-47v-31h-10v31h-46v10h46v31h-46v10h46v31h10v-31h47v31h10v-31h46v-10h-46v-31z" _fill="#152452"/><path pid="1" _fill="#152452" d="M72.5 80.5h47v31h-47z"/>',
  },
})
