/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'Explicit Constructed Response_2': {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<rect pid="0" x="32.5" y="8.5" width="127" height="175" rx="12.17" _fill="#152452"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M54.13 26.75h83.92v.01c4.12 0 7.45 4.54 7.45 10.14v42.4c0 5.6-3.33 10.14-7.45 10.14h-84.1c-4.12 0-7.45-4.54-7.45-10.14V37.13c0-5.73 3.41-10.38 7.63-10.38zm-1.36 75.81h8.91l.01-.79a1.94 1.94 0 010 3.87h-7.55c-.39.007-.77.121-1.1.33a1.93 1.93 0 01-2-3.3l.2-.11h1.53zm59.82 0h-10.82a1.942 1.942 0 00-1.796 2.945c.379.62 1.072.977 1.796.925h10.82a1.942 1.942 0 001.796-2.945 1.942 1.942 0 00-1.796-.925zm-25.45 0H76.31a1.94 1.94 0 000 3.87h10.83a1.94 1.94 0 000-3.87zm-38.71 12.12a1.94 1.94 0 00-1.93 1.93v10.83a1.941 1.941 0 103.87 0v-10.83a1.94 1.94 0 00-1.94-1.93zm-1.93 27.39a1.93 1.93 0 011.93-1.93 1.93 1.93 0 011.94 1.93v10.83a1.94 1.94 0 01-3.87 0v-10.83zm6.5 19.17a1.94 1.94 0 00-.5 3.81l.4.09h10.41a1.94 1.94 0 000-3.87H54a1.71 1.71 0 01-.45-.06 2.222 2.222 0 00-.5-.07l-.05.1zm24.94.13h10.83a1.94 1.94 0 010 3.87H77.94a1.94 1.94 0 010-3.87zm36.28 0H103.4a1.94 1.94 0 100 3.87h10.82a1.942 1.942 0 001.796-2.945 1.942 1.942 0 00-1.796-.925zm24.33-.07a1.77 1.77 0 01.55-.09v.02a1.94 1.94 0 01.56 3.79 6.014 6.014 0 01-1.12.22h-9.69a1.94 1.94 0 010-3.87h9.2c.169.001.338-.022.5-.07zm5.02-21.3a1.94 1.94 0 00-1.94 1.94v10.83a1.94 1.94 0 003.87 0v-10.86a1.94 1.94 0 00-1.93-1.94v.03zm-1.371-24.888a1.924 1.924 0 011.371-.562v-.03a1.93 1.93 0 011.93 1.93v10.86a1.94 1.94 0 01-3.87 0v-10.83c0-.514.205-1.006.569-1.368zm-4.149-12.562h-10.83a1.94 1.94 0 000 3.87h10.83c.352-.002.697.091 1 .27a1.92 1.92 0 002.64-.72 1.93 1.93 0 00-.71-2.63 5.925 5.925 0 00-.76-.37h-.37c-.21-.06-.42-.12-.63-.16h-1.13l-.04-.26zM68.93 39.21H89a7 7 0 010 13.93H68.93a7 7 0 010-13.93zm0 36.35h53.37a7 7 0 000-13.92H68.93a7 7 0 000 13.92zm53.37 76.58H68.93a7 7 0 110-14h53.37a7 7 0 110 14zm-53.37-22.43H89a7 7 0 100-14H68.93a7 7 0 100 14z" _fill="#fff"/>',
  },
})
