<template>
  <v-card class="x-filter" :class="classes">
    <v-card-title class="x-filter-header">
      <div
        class="x-filter-header-title"
        :class="headerClasses"
        v-on="!selectAllHeader && canSelectAll && collapsable ? { click: toggle } : {}"
      >
        <div class="x-filter-header-title__label">{{ label }}</div>
        <div class="x-filter-header-title__right">
          <div
            v-if="selectAllHeader && canSelectAll && show"
            class="x-filter-header-title__select-all"
            @click="onFilterSelectAll"
          >
            {{ selectAllLabel }}
          </div>
          <div
            v-if="collapsable"
            class="x-filter-header-title__activator"
            v-on="selectAllHeader ? { click: toggle } : {}"
          >
            <font-awesome-icon :icon="expandIcon" />
          </div>
        </div>
      </div>
    </v-card-title>

    <v-card-actions class="x-filter-items">
      <div v-if="summary" class="x-filter-items__summary">
        <x-filter-chip-list
          v-if="collapsable && !show"
          :id="id"
          :items="getSelectedItems"
          :itemId="itemID"
          :itemLabel="itemLabel"
          :multiple="canSelectMultiple"
          v-bind="$attrs"
          v-on="$listeners"
          @filter-change="onFilterChange"
          @show-more="toggle"
        />
      </div>
      <v-expand-transition>
        <div v-show="show">
          <div v-if="!loaded">loading...</div>
          <div v-show="loaded" class="x-filter-items__content" :style="contentStyles">
            <x-filter-search
              v-if="showSearch"
              class="x-filter-content__search"
              :disabled="!hasFilteredItems && !query"
              @filter-searching="() => (searching = true)"
              @filter-search="searchItems"
            />

            <div v-if="!hasFilteredItems" class="x-filter-content__no-results">
              {{ noItemsMessage }}
            </div>
            <component
              v-show="loaded"
              class="x-filter-content__items"
              :id="id"
              :class="searchingClass"
              :is="component"
              :items="getItems"
              :itemId="itemID"
              :itemLabel="itemLabel"
              :selectedItems="selected"
              :allSelected="allSelected"
              :lazy="lazy"
              :lazyLoadTrigger="show"
              v-bind="$attrs"
              v-on="$listeners"
              @loaded="onLoaded"
              @filter-change="onFilterChange"
              @filter-select-all="onFilterSelectAll"
              @filter-aux-action="onFilterAuxAction"
            />
          </div>
        </div>
      </v-expand-transition>
      <div v-if="showMore && this.show && !this.query" class="x-filter__showmore" @click="onShowAll">
        {{ showMoreLabel }}
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import xFilterMixin from './mixins/xFilterMixin'
import * as VCard from 'vuetify/es5/components/VCard'
import { makeEventBus, Events } from '@events'
import { FilterEvents } from './xFilterEvents'
import _ from 'lodash'

const eventBus = makeEventBus()
const events = Object.assign({}, Events, FilterEvents)

export default {
  name: 'XFilter',

  inheritAttrs: true, // true or false?

  mixins: [xFilterMixin],

  components: {
    ...VCard,
    'x-filter-search': () => import('./subcomponents/XFilterSearch'),
    'x-filter-checkbox-list': () => import('./subcomponents/XFilterCheckboxList'),
    'x-filter-radio-list': () => import('./subcomponents/XFilterRadioList'),
    'x-filter-button-list': () => import('./subcomponents/XFilterButtonList'),
    'x-filter-chip-list': () => import('./subcomponents/XFilterChipList'),
  },

  provide() {
    /* provide eventBus and consolidated events to all children */
    return Object.defineProperties(
      {},
      {
        eventBus: { get: () => eventBus },
        events: { get: () => events },
      }
    )
  },

  props: {
    type: {
      type: String,
      default: 'checkbox',
      validator: (v) => ['checkbox', 'radio', 'button'].includes(v),
    }, // control type
    id: { type: [String, Number], required: true }, // component id
    title: { type: String, default: 'Filter' }, // hearder title
    selecAllHeader: { type: Boolean, default: false }, // add select all in header
    collapsable: { type: Boolean, default: true }, // enable collapase
    count: { type: Boolean, default: false }, // enable count next to header label
    search: { type: Boolean, default: false }, // enable searchbox
    expanded: { type: Boolean, default: true }, // expanded state
    summary: { type: Boolean, default: true }, // show summary (chip list)
    maxContentHeight: { type: Number, default: 500 }, // max content height,
    selectAllHeader: { type: Boolean, default: false }, // select all in header,
    lazy: { type: Boolean, default: false }, // lazy load flag
    standalone: { type: Boolean, default: false }, // use this if within XFilterGroup?,
    treshold: { type: Number, default: 5 }, // overflow threshold for show more
  },

  data: () => ({
    show: false,
    query: null,
    searching: false,
    loaded: false,
    selected: [],
    showAll: false,
    stickyItems: [],
  }),

  computed: {
    component() {
      switch (this.type) {
        case 'button':
          return 'x-filter-button-list'
        case 'radio':
          return 'x-filter-radio-list'
        case 'checkbox':
          return 'x-filter-checkbox-list'
        default:
          return 'x-filter-checkbox-list'
      }
    },
    classes() {
      return this.standalone ? ['x-filter--standalone'] : []
    },
    getItems() {
      if (this.query) {
        return this.items.filter((o) => {
          const check = new RegExp(this.query, 'ig')
          const match = o[this.itemLabel].match(check) !== null
          return match
        })
      } else if (this.showAll) {
        return this.itemsState
      } else {
        return this.itemsState.slice(0, this.getThreshold)
      }
    },
    itemsState() {
      const sorted = this.items.reduce(
        (m, o) => {
          if (this.stickyItems.includes(o.id)) m.sticky.push(o)
          else m.items.push(o)
          return m
        },
        { sticky: [], items: [] }
      )
      return [..._.sortBy(sorted.sticky, ['id']), ...sorted.items]
    },
    expandIcon() {
      return this.show ? ['fas', 'chevron-down'] : ['fas', 'chevron-up']
    },
    label() {
      return this.canShowCount ? `${this.title} (${this.selectedItems.length})` : this.title
    },
    canShowCount() {
      const noCountTypes = ['radio']
      return this.count && !noCountTypes.includes(this.type)
    },
    canSelectAll() {
      return !['radio'].includes(this.type)
    },
    canSelectMultiple() {
      return !['radio'].includes(this.type)
    },
    getSelectedItems() {
      return this.selected.map((id) => {
        return this.items.find((o) => o[this.itemID] == id)
      })
    },
    searchingClass() {
      return this.searching ? ['x-filter-content__items--searching'] : []
    },
    contentStyles() {
      return { 'max-height': this.contentMaxHeight }
    },
    noItemsMessage() {
      return this.query ? 'No results found' : 'No items available'
    },
    hasItems() {
      return this.getItems.length > 0
    },
    hasFilteredItems() {
      return this.getItems.length > 0
    },
    allItemIDs() {
      return this.getItems.map((o) => o[this.itemID])
    },
    selectAllLabel() {
      return this.allSelected ? 'Deselect All' : 'Select All'
    },
    headerClasses() {
      return !this.selectAllHeader ? ['x-filter-header-title--expander'] : []
    },
    /* all selected from visible list (can be filtered items via search) */
    allSelected() {
      return _.isEqual(_.sortBy(this.allItemIDs), _.sortBy(this.selected))
    },
    getThreshold() {
      const difference = this.items.length - this.treshold
      return difference <= 2 ? this.treshold + 2 : this.treshold
    },
    overflowCount() {
      const difference = this.items.length - this.getThreshold
      return difference > 0 ? difference : 0
    },
    showMore() {
      return this.overflowCount > 0
    },
    showMoreLabel() {
      return this.showAll ? `Show Less` : `Show ${this.overflowCount} More`
    },
    showSearch() {
      return (this.search && this.loaded) || (this.showMore && this.loaded)
    },
  },

  watch: {
    expanded() {
      this.syncShow()
    },
    selectedItems() {
      this.syncSelected()
    },
  },

  methods: {
    searchItems(query) {
      this.query = query
      this.searching = false
    },
    toggle() {
      this.show = !this.show
      this.expandCollapse()
    },
    expandCollapse() {
      this.$emit(events.FILTER_EXPAND, {
        id: this.id,
        expanded: this.show,
      })
      if (!this.show) {
        eventBus.$emit(events.FILTER_COLLAPSE, this.show)
        this.makeStickyItems()
      }
    },
    makeStickyItems() {
      this.stickyItems = [...this.selected].sort()
    },
    onFilterChange({ id, itemIds = [], add }) {
      const selected = this.canSelectMultiple ? this.selected.filter((itemId) => this.allItemIDs.includes(itemId)) : []

      if (add) this.selected = _.union(itemIds, selected)
      else this.selected = _.difference(selected, itemIds)

      this.$emit(events.CHANGE, {
        id: this.id,
        data: this.selected,
      })
    },
    onFilterSelectAll() {
      this.selected = !this.allSelected ? this.allItemIDs : []
      this.$emit(events.CHANGE, {
        id: this.id,
        data: this.selected,
      })
    },
    onFilterAuxAction({ id }) {
      this.$emit(Events.AUX_ACTION, { id })
    },
    onShowAll() {
      this.showAll = !this.showAll
      if (!this.showAll) this.makeStickyItems()
    },
    /* child component loaded content */
    onLoaded() {
      this.loaded = true
    },
    /* sync show with expanded prop */
    syncShow() {
      this.show = this.collapsable ? this.expanded : true
    },
    syncSelected() {
      this.selected = this.selectedItems
    },
  },

  mounted() {
    this.syncShow()
    this.syncSelected()
    this.stickyItems = this.selected
  },
}
</script>

<style lang="scss">
@import '@/styles/main';
@import '@/styles/components/XFilter';

.x-filter {
  display: block;
  border-radius: 0;
  box-shadow: none !important;
  min-height: 142px;

  &.v-card,
  &.v-card.theme--light {
    border: none;
    border-radius: 0;
  }

  &--standalone {
    border: 1px solid $N20;
  }

  &__showmore {
    @include font-size-sm;
    display: flex;
    justify-content: center;
    cursor: pointer;
    color: $N130;
    margin-top: 16px;
    text-decoration: underline;
  }

  &__showmore:hover {
    color: $B300;
  }

  .x-filter-header {
    &.v-card__title {
      padding: $x-filter-padding-lg $x-filter-padding-lg $x-filter-padding-sm $x-filter-padding-lg;
    }

    .x-filter-header-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-flow: row nowrap;
      width: 100%;

      &--expander {
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }

      &__label {
        font-weight: bold;
        font-size: 14px;
      }

      &__right {
        display: flex;
        align-items: center;
        flex-flow: row nowrap;
      }

      &__activator {
        cursor: pointer;
        margin-left: 12px;
        font-size: 14px;
      }

      &__select-all {
        @include font-size-sm;
        cursor: pointer;
        color: $B300;
      }
    }
  }

  .x-filter-items {
    display: block;

    &__content {
      display: block;
    }
    &.v-card__actions {
      padding: 0 20px 20px 20px !important;
    }

    .v-list {
      padding: 0;

      .v-item__tile {
        padding: 0px;
      }
    }
  }

  .x-filter-items__summary {
    //...
  }

  .x-filter-content__no-results {
    color: $N60;
  }

  .x-filter-content__search {
    display: flex;
    width: 100%;
    margin-bottom: 16px;
  }

  .x-filter-items__content {
    transition: opacity 0.3s ease-in-out;
    display: block;
  }

  .x-filter-content__items {
    &--searching {
      opacity: 0.3;
    }
  }

  .x-filter-auxilliary-items {
    &__item {
      margin-top: 6px;
      cursor: pointer;

      &__icon {
        margin-right: 8px;
        font-size: 12px;
      }

      &__label {
        font-size: 14px;
        border-bottom: 1px dotted $B300;
        padding-bottom: 1px;
        transition: 0.3s ease all;

        &:hover {
          color: $B400;
          border-bottom: 1px dotted $B400;
        }
      }
    }
  }
}
</style>
