<template>
  <v-card id="summary-view" :class="{ collapsed: collapsed }">
    <v-toolbar @click="toggleSummary()">
      <span class="pr-3"> <slot name="summary-view-icon" class="pr-3"></slot></span>

      <slot name="summary-view-title"></slot>
      <v-btn icon text lazy class="ibx-item-card-header__more-menu">
        <v-icon class="toggle-summary-icon">expand_more</v-icon>
      </v-btn>
    </v-toolbar>
    <div id="summary-view-cards-container">
      <slot></slot>
    </div>
  </v-card>
</template>

<script>
import * as VCard from 'vuetify/es5/components/VCard'
import * as VToolbar from 'vuetify/es5/components/VToolbar'

export default {
  name: 'XSummaryView',

  data() {
    return {
      collapsed: true,
    }
  },

  components: {
    ...VCard,
    ...VToolbar,
  },

  methods: {
    toggleSummary() {
      this.collapsed = !this.collapsed
    },
  },
}
</script>

<style lang="scss">
#summary-view {
  background: #ecedf1;
  border: 1px solid #ecedf1;
  box-sizing: border-box;
  box-shadow: -1px -1px 10px rgba(5, 15, 45, 0.1);
  border-radius: 3px 3px 0px 0px;
  position: fixed;
  right: 32px;
  bottom: 0;
  height: calc(70% - 100px);
  width: 330px;
  z-index: 2;
  transition: height 0.35s linear;

  .v-toolbar {
    background: #ffffff;
    border-radius: 3px 3px 0px 0px;
    margin-bottom: 14px;
    box-shadow: none;

    .v-toolbar__content {
      padding: 0px 20px;
    }

    .v-toolbar__title:not(:first-child) {
      margin-left: 12px;
      height: 100%;
      line-height: 64px;
    }

    .v-toolbar__title {
      font-family: 'CerebriSans', Roboto, sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;

      &:hover {
        cursor: default;
      }
    }

    .toggle-summary-icon {
      transition: transform 150ms ease-out;
    }
  }

  .v-chip {
    padding: 0 0 !important;
    height: 18px;
    max-width: 25px;
    margin-left: auto;

    .v-chip__content {
      display: flex;
      justify-content: center;
      flex: 1;
      font-family: 'CerebriSans', Roboto, sans-serif;
      line-height: 12px;
      font-weight: 400;
    }
  }

  .ibx-item-card-header__more-menu {
    background-color: #fff;
    width: 32px !important;
    height: 32px !important;
    margin-left: 20px !important;

    &::before {
      border-radius: 50% !important;
    }
  }
}

#summary-view.collapsed {
  height: 64px;

  .toggle-summary-icon {
    transform: rotate(180deg);
  }
}

#summary-view-cards-container {
  height: 90%;
  overflow-y: auto;
  font-family: 'CerebriSans', Roboto, sans-serif;
}
</style>
