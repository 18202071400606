/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'Multiple Select_1': {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<g clip-path="url(#clip0_1523_344)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M127.34 81.123a4.999 4.999 0 003.45-1.7l31.37-36a4.998 4.998 0 00-2.742-8.34 5 5 0 00-4.788 1.77l-28.07 32.19-15.73-14a5.001 5.001 0 00-6.64 7.48l19.51 17.35a4.999 4.999 0 003.3 1.26l.34-.01zM127.34 156.123a4.999 4.999 0 003.45-1.7l31.37-36a5 5 0 10-7.53-6.57l-28.07 32.19-15.73-14a5.001 5.001 0 00-6.64 7.48l19.51 17.35a4.994 4.994 0 003.3 1.26l.34-.01zM52.831 156.123a5 5 0 003.45-1.7l31.37-36a5 5 0 10-7.53-6.57l-28.07 32.19-15.73-14a5.001 5.001 0 00-6.64 7.48l19.51 17.35a4.995 4.995 0 003.3 1.26l.34-.01z" _fill="#152452"/><rect pid="1" x="36" y="40" width="45" height="45" rx="5" _stroke="#000" stroke-width="10"/></g><defs><clipPath id="clip0_1523_344"><path pid="2" _fill="#fff" d="M0 0h192v192H0z"/></clipPath></defs>',
  },
})
