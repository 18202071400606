import { ItemOptions, FetchItemConditions, PieApi, ResponseData, ReleaseType } from './PieAuthorServiceBase'
import { wait } from '@/helpers'
import _ from 'lodash'

/**
 * Handles item interfacing with PIE API GraphQL
 */
export class PieAuthorItemService extends PieApi {
  /**
   * Validates PIE API mutation saveContentItem as published
   * @param data data to validate
   * @returns { valid: true, error: null }
   */
  private saveContentItemPublishValidator(data: any): { valid: boolean; error: string } {
    const _saveContentItem = data._saveContentItem

    if (_saveContentItem == null) {
      return { valid: false, error: `_saveContentItem is null` }
    }

    if (_saveContentItem.version.prerelease.tag != null || _saveContentItem.version.prerelease.version != null) {
      return { valid: false, error: `_saveContentItem did not return released item` }
    }

    return { valid: true, error: null }
  }
  /**
   * Validates PIE API mutation saveContentItem as draft
   * @param data data to validate
   * @returns { valid: true, error: null }
   */
  private saveContentItemDraftValidator(data: any): { valid: boolean; error: string } {
    const _saveContentItem = data._saveContentItem

    if (_saveContentItem == null) {
      return { valid: false, error: `_saveContentItem is null` }
    }

    if (_saveContentItem.version.prerelease.tag != 'draft') {
      return { valid: false, error: `_saveContentItem did not return draft item` }
    }

    return { valid: true, error: null }
  }

  /**
   * Exponential retry for PIE requests
   * @param query GraphQL query
   * @param variables GraphQL variables
   * @param depth Current number of retries for recursion
   * @returns response data
   * @throws { Error } If max retries fail
   */
  private async saveItemWithRetry({
    query,
    variables = {},
    release = true,
    depth = 0,
    validate = false,
  }: {
    query: string
    variables: any
    release?: boolean
    depth?: number
    validate?: boolean
  }): Promise<any> {
    try {
      const data = await this.pieService.query({ query, variables })

      if (validate) {
        const validator = release ? this.saveContentItemPublishValidator : this.saveContentItemDraftValidator
        const validation = validator(data)

        if (!validation.valid) {
          throw validation.error
        }
      }

      return data
    } catch (e) {
      if (depth > 2) {
        throw e
      }

      // backoff = 500, 1000, 2000
      await wait(2 ** depth * 500)
      const data = await this.saveItemWithRetry({ query, variables, release, depth: depth + 1, validate })
      return data
    }
  }

  /**
   * Get item save mutation variables
   * @returns object
   */
  private makeItemSaveVariables({
    itemConfig,
    itemVersionedID,
    releaseType,
    itemMetaData,
    stimulusVersionedID,
  }: {
    itemConfig?: any
    itemVersionedID?: string
    releaseType?: ReleaseType
    itemMetaData?: any
    stimulusVersionedID?: string
  }): any {
    // required by all queries
    const variables: any = { versionedID: itemVersionedID }

    // item config mutate variables
    if (itemConfig) {
      variables.input = {
        collectionIds: null,
        config: itemConfig,
        releaseType,
      }
    }

    // passage link/unlink
    if (stimulusVersionedID) {
      variables.stimulusVersionedID = stimulusVersionedID
    }

    // meta data requires exisiting item
    if (itemVersionedID && itemMetaData) {
      variables.metaData = itemMetaData
    }

    return variables
  }

  /**
   * Construct Save item mutation
   * @param relatedContent if true include related content fragment
   * @returns GraphQL string
   */
  private saveItemMutation({ relatedContent = false, create }: { relatedContent: boolean; create?: boolean }): string {
    return `
            _saveContentItem: saveContentItem(
                ${!create ? 'versionedID: $versionedID' : ''}
                input: $input
            ) {
                ...item
                ${relatedContent ? '...itemRelatedContent' : ''}
            }
        `
  }

  /**
   * Get item meta data mutation
   * @returns GraphQL string
   */
  private itemMetaDataMutation(): string {
    return `
            _addContentItemMetaData: addContentItemMetaData(
                id: $versionedID
                metaData: $metaData
                replace: false
            )
        `
  }

  /**
   * Get item release muation
   * @returns GraphQL string
   */
  private releaseItemMutation(): string {
    return `
            _releaseContent: releaseContentItem(
                versionedID: $versionedID
            ) {
                ...item
            }
        `
  }

  /**
   * Get link stimulus mutation
   * @returns GraphQL string
   */
  private linkStimulusMutation(): string {
    return `
            _linkStimulus: linkStimulus(
                contentItem: $versionedID
                stimulus: $stimulusVersionedID
                autopatch: true
            ) {
                ...item
                ...itemRelatedContent
            }
        `
  }

  /**
   * Get unlink stimulus mutation
   * @returns GraphQL string
   */
  private unlinkStimulusMutation(): string {
    return `
            _unlinkStimulus: unlinkStimulus(
                contentItem: $versionedID
                autopatch: true
            ) {
                ...item
                ...itemRelatedContent
            }
        `
  }

  /**
   * Get query fragments
   * @returns GraphQL string
   */
  private fragments({ item, relatedContent }: { item: boolean; relatedContent: boolean }): string {
    const itemFragment = `
            fragment item on ContentItem {
                id
                config
                version {
                    major
                    minor
                    patch
                    prerelease
                }
            }
        `

    const relatedContentFragment = `
            fragment itemRelatedContent on ContentItem {
                relatedContent {
                    stimulus {
                        id
                    }
                }
            }
        `

    return `
            ${item ? itemFragment : ''} 
            ${relatedContent ? relatedContentFragment : ''}
        `
  }

  /**
   * Generate dynamic query
   * @returns string generated aliased mutation query
   */
  private makeMutationQuery({
    create = false,
    itemVersionedID,
    itemConfig,
    itemMetaData,
    stimulusVersionedID,
    linkStimulus,
  }: {
    create?: boolean
    release?: boolean
    itemVersionedID?: string
    itemConfig?: any
    itemMetaData?: any
    stimulusVersionedID?: string | null
    linkStimulus?: boolean | null
  }): string {
    // conditions
    const hasMeta = itemVersionedID && itemMetaData
    const linkPassage = stimulusVersionedID && linkStimulus === true
    const unlinkPassage = stimulusVersionedID && linkStimulus === false

    // construct query
    return `
            mutation save(
                ${!create ? '$versionedID: VersionedID!' : ''}
                ${itemConfig ? '$input: ContentItemInput!' : ''}
                ${hasMeta ? '$metaData: JSON!' : ''}
                ${linkPassage ? '$stimulusVersionedID: VersionedID!' : ''}
            ) {
                ${itemConfig ? this.saveItemMutation({ create, relatedContent: Boolean(stimulusVersionedID) }) : ''}
                ${hasMeta ? this.itemMetaDataMutation() : ''}
                ${linkPassage ? this.linkStimulusMutation() : ''} 
                ${unlinkPassage ? this.unlinkStimulusMutation() : ''}
            }
            ${this.fragments({
              item: Boolean(itemConfig || stimulusVersionedID),
              relatedContent: Boolean(stimulusVersionedID),
            })}
        `
  }

  /**
   * Marshal response data.
   * Returns data object contantaining the itemd id for last mutation that altered the item version
   * return orginal item id if not provided from mutations
   * @param responseData data returned from response
   * @param itemId item id passed when running query
   * @return ResponseData
   */
  private marshalResponseData(responseData: any, itemId?: string, version?: any): ResponseData {
    let data = null
    if (responseData._linkStimulus) data = responseData._linkStimulus
    else if (responseData._unlinkStimulus) data = responseData._unlinkStimulus
    else if (responseData._saveContentItem) data = responseData._saveContentItem
    else if (responseData._contentItem) data = responseData._contentItem
    else if (responseData._item) data = responseData._item
    else data = Object.assign({ version }, responseData, { id: itemId })

    const versionedId =
      data.id && data.version ? this.getVersionedIdString({ id: data.id, version: data.version }) : itemId

    // item meta data
    if (responseData._itemMetaData) data.metaData = responseData._itemMetaData
    return {
      data: Object.assign({}, data, { versionedId }),
      responseData,
    }
  }

  /**
   * Save/upsert item
   * @param itemVersionedID item remoteId. If empty query created new item otherwise update item and item meta data
   * @param releaseType item releaseType
   * @param itemMetaData item metaData object
   * @param stimulusVersionedID Passage remoteId
   * @param linkStimulus If true link, false unlink, null ignore stimulus query
   * @returns Promise<any>
   * @throws error
   */
  public async saveItem({
    itemVersionedID = '',
    releaseType = ReleaseType.PATCH,
    release = false,
    itemConfig,
    itemMetaData,
    stimulusVersionedID = null,
    linkStimulus = null,
  }: {
    itemVersionedID: string
    releaseType?: ReleaseType
    release?: boolean
    itemConfig?: any
    itemMetaData?: any
    stimulusVersionedID?: string | null
    linkStimulus?: boolean | null
  }): Promise<any> {
    let variables: any = {}
    let query = ''
    let responseData: any = null

    try {
      // create new item if no versionID
      if (_.isEmpty(itemVersionedID)) {
        variables = this.makeItemSaveVariables({
          itemConfig,
          releaseType: ReleaseType.PATCH,
        })

        query = this.makeMutationQuery({
          create: true,
          itemConfig,
        })

        responseData = await this.saveItemWithRetry({
          query,
          variables,
        })

        itemVersionedID = responseData._saveContentItem.id
        itemConfig = null // set null to prevent resaving item

        // if no more mutations return result
        if (!itemMetaData && !stimulusVersionedID) {
          return this.marshalResponseData(responseData)
        }
      }

      // update item, metadata, and/or link/unlink stimulus
      if (_.isEmpty(itemVersionedID)) {
        throw 'Cannot save item without out itemVersionedID'
      }

      responseData = null
      variables = this.makeItemSaveVariables({
        itemVersionedID,
        itemConfig,
        releaseType,
        itemMetaData,
        stimulusVersionedID,
      })
      query = this.makeMutationQuery({
        itemConfig,
        itemVersionedID,
        itemMetaData,
        stimulusVersionedID,
        linkStimulus,
      })

      responseData = await this.saveItemWithRetry({
        query,
        variables,
        release,
      })

      return this.marshalResponseData(responseData, itemVersionedID)
    } catch (e) {
      console.error(e)
      throw 'pie-saving-error'
    }
  }

  /**
   * Get contentItem query
   * @param relatedContent inlcude releatedContent flag
   * @returns GraphQL string
   */
  private contentItemQuery({ relatedContent = false }: { relatedContent: boolean }): string {
    return `
            _item: contentItem(versionedID: $versionedID) {
                ...item
                ${relatedContent ? '...itemRelatedContent' : ''}
            }
        `
  }

  /**
   * Get contentItemMetaData query
   * @returns GraphQL string
   */
  private contentItemMetaDataQuery(): string {
    return `
            _itemMetaData: contentItemMetaData(id: $versionedID) {
                k12_abStandard
            }
        `
  }

  /**
   * Convert item options to booleans
   * @param options item options to fetch
   */
  private getItemConditions(options: ItemOptions[]): FetchItemConditions {
    return {
      config: options.includes(ItemOptions.CONFIG),
      passage: options.includes(ItemOptions.PASSAGE),
      metadata: options.includes(ItemOptions.METADATA),
    }
  }

  /**
   * Generate fetch item query
   * @param options array of ItemOptions to fetch
   */
  private makeQuery(options: ItemOptions[]): string {
    const { config, passage, metadata } = this.getItemConditions(options)

    return `
            query item(
                $versionedID: VersionedID!
            ) {
                ${config || passage ? this.contentItemQuery({ relatedContent: passage }) : ''}
                ${metadata ? this.contentItemMetaDataQuery() : ''}
            }
            ${config || passage ? this.fragments({ item: true, relatedContent: passage }) : ''}
        `
  }

  /**
   * Fetch item with optional item options.
   * If no options only item config is fetched
   * else only passaed options will be fetched.
   * @param versionedID
   * @param options array of ItemOptions to fetch
   */
  public async getItem({
    versionedID,
    options = [ItemOptions.CONFIG],
  }: {
    versionedID: string
    options?: ItemOptions[]
  }): Promise<any> {
    const { config, passage, metadata } = this.getItemConditions(options)

    const variables = {
      versionedID: versionedID,
    }
    const query = this.makeQuery(options)

    try {
      const responseData = await this.pieService.query({
        query,
        variables,
      })
      return this.marshalResponseData(responseData, versionedID)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
