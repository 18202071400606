/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'Open Response_2': {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<rect pid="0" x="20.5" y="20.5" width="151" height="151" rx="12.59" _fill="#152452"/><rect pid="1" x="48.5" y="87.75" width="17.5" height="17.5" rx="8.75" _fill="#fff"/><rect pid="2" x="77.25" y="87.75" width="71.25" height="17.5" rx="8.75" _fill="#fff"/><rect pid="3" x="48.5" y="46.5" width="100" height="17.5" rx="8.75" _fill="#fff"/><rect pid="4" x="48.5" y="129" width="17.5" height="17.5" rx="8.75" _fill="#fff"/><rect pid="5" x="77.25" y="129" width="71.25" height="17.5" rx="8.75" _fill="#fff"/>',
  },
})
