import Vue from 'vue'

const GenericEvents = {
  ACTION: 'action',
  ADD: 'add',
  CHANGE: 'change',
  CHANGE_ALL: 'change-all',
  CHANGE_PAGE: 'change-page',
  CLOSE: 'close',
  COLLAPSE: 'collapse',
  EXPAND: 'expand',
  LAZY_LOAD: 'lazy-load',
  LOADED: 'loaded',
  LOAD_ERROR: 'load-error',
  ORDER: 'order',
  READY: 'ready',
  REMOVE: 'remove',
  AUX_ACTION: 'aux-action',
  SHOW: 'show',
  HIDE: 'hide',
  SAVE_CHANGED: 'save-changed',
  SAVE_SAVING: 'save-saving',
  SAVE_SAVED: 'save-saved',
  SAVE_DONE: 'save-done',
  DO_NOTHING: 'do-nothing',
  SNACKBAR: 'snack-bar',
  DIALOG_FULL: 'dialog-full',
  SCROLL_INTO_VIEW: 'scroll-into-view',
  CLEAR: 'clear',
}
const ApplicationEvents = {
  ITEMS_LIST_VIEW: 'items-list-view',
  BROWSE_EXPAND: 'browse-exapand',
  BROWSE_FILTERS_CHANGE: 'browse-filters-change',
  PRINT_OPTIONS_CHANGE: 'print-options-change',
  BROWSE_RELOAD_ITEMS: 'browse-reload-items',
  ADD_ITEMS: 'add-items',
  ADD_ITEM: 'add-item',
  CREATE_ITEMS: 'create-items',
  REMOVE_ITEM: 'remove-item',
  REMOVE_ITEMS: 'remove-items',
  REMOVED_ITEMS: 'removed-items',
  EDIT_ITEM: 'edit-item',
  EDIT_PASSAGE_ITEM: 'edit-passage-item',
  CREATE_PASSAGE_ITEM: 'create-passage-item',
  EDIT_ITEMS_DONE: 'edit-items-done',
  DELETE_ITEM: 'delete-item',
  DUPLICATE_ITEM: 'duplicate-item',
  CREATE_ASSESSMENT: 'create-assessment',
  CREATE_ASSESSMENT_CANCEL: 'create-assessment-cancel',
  EXIT_ASSESSMENT: 'exit-assessment',
  ITEM_FULL_SCREEN: 'item-full-screen',
  FULL_SCREEN: 'full-screen',
  ADD_PASSAGE_ITEMS: 'add-passage-items',
  ASSESSMENT_ITEM_CHANGE: 'assessment-item-change',
  ASSESSMENT_ITEMS_ORDER: 'assessment-items-order',
  ASSESSMENT_ITEM_ACTION: 'assessment-item-action',
  ASSESSMENT_DESCRIPTION: 'assessment-description',
  ASSESSMENT_TAGS: 'assessment-tags',
  ASSESSMENT_SETTINGS: 'assessment-settings',
  ASSESSMENT_MATERIALS: 'assessment-materials',
  ASSESSMENT_PREVIEW: 'assessment-preview',
  ASSESSMENT_PRINT_PREVIEW: 'assessment-print-preview',
  STANNIS_SHOW: 'stannis-show',
  STANNIS_HIDE: 'stannis-hide',
  STANNIS_CHANGE: 'stannis-change',
  ASSESSMENT_ITEM_LIMIT: 'assessment-item-limit',
  ERROR: 'error',
  ERROR_REQUEST: 'error-request',
  STATUS_404: 'status-404',
  DATA_DIRTY: 'data-dirty',
  WARNING_SHOW: 'show-warning',
  SHOW_DESCRIPTION: 'show-description',
  SHOW_TAGS: 'show-tags',
  SHOW_EMBED_VIDEO_DIALOG: 'show-embed-video-dialog',
  SHOW_EMBED_AUDIO_DIALOG: 'show-embed-audio-dialog',
  ASSESSMENT_DUPLICATE: 'assessment-duplicate',
  REPORT_ISSUE: 'report-issue',
  CONFIRMATION_SHOW: 'confirmation_show',
}

const AuthorItems = {
  AUTHOR_ITEMS_CREATE: 'author-items-create',
  AUTHOR_ITEMS_READY: 'author-items-ready',
  AUTHOR_ITEMS_SAVE: 'author-items-save',
  AUTHOR_ITEMS_SAVED: 'author-items-saved',
  AUTHOR_ITEMS_SAVE_ERROR: 'author-items-save-error',
}

const RouteEvents = {
  ROUTE_GOTO: 'route-goto',
}

const Events = {
  ...GenericEvents,
  ...ApplicationEvents,
  ...RouteEvents,
  ...AuthorItems,
}

/* glabal event bus */
const EventBus = new Vue()

/* make local event bus */
const makeEventBus = () => new Vue()

/* make event bus interface */
const makeEventBusInterface = () => ({
  $on: () => {},
  $off: () => {},
})

/* make component event bus injection object */
const makeEventBusInjection = () => ({
  eventBus: { default: () => makeEventBusInterface() },
  events: { default: () => ({}) },
})

export default Events
export { Events, EventBus, makeEventBus, makeEventBusInterface, makeEventBusInjection }
