import { authorSettings, ibxItemTypes } from '@components/itemAuthor/configs/itemConfigs.js'

/**
 * id type
 */
export type Id = string | number

/**
 * Key value pair mapping
 */
export type IdMap = {
  id: Id
  name: string
}

/**
 * Generic entity for item and passage data
 */
export interface IEntityData {
  createdAt: string
  id?: Id
  inUse: boolean
  externalIds: IdMap[]
  legacyIds: IdMap[]
  newIndex?: number // only used for new items
  meta: object | any
  omittedAt: object
  published: boolean
  revisionId: Id
  remoteId: Id
  remoteIdName: string
  remoteIdVersioned: Id
  source: string
  teacherInstructions: string
  updatedAt: string
  user: object | any
  version: string
}

/**
 * Generic Entity class for items and passages
 * @param {Generic} data can be anything that extends IEntityData like IItemData & IPassageData
 */
export class Entity<T extends IEntityData> {
  protected data: T
  protected _publishedGenesis: boolean = false // original published mode

  constructor(data: T) {
    this._publishedGenesis = data.published
    this.data = data
  }

  /**
   *
   * Getters
   */
  get createdAt(): string {
    return this.data.createdAt
  }
  get externalId(): Id | undefined {
    return this.data.externalIds?.[0]?.id
  }
  get id(): Id {
    return this.data.id
  }
  get isNew(): boolean {
    return Boolean(this.data.newIndex)
  }
  get inUse(): boolean {
    return this.data.inUse
  }
  get legacyId(): Id | undefined {
    return this.data.legacyIds?.[0]?.id
  }
  get meta(): object | any {
    return this.data.meta
  }
  get omittedAt(): object {
    return this.data.omittedAt
  }
  get published(): boolean {
    return this.data.published
  }
  get revisionId(): Id {
    return this.data.revisionId
  }
  get remoteId(): Id {
    return this.data.remoteId
  }
  get remoteIdName(): string {
    return this.data.remoteIdName
  }
  get remoteIdVersioned(): Id {
    return this.data.remoteIdVersioned
  }
  get source(): string {
    return this.data.source
  }
  get teacherInstructions(): string {
    return this.data.teacherInstructions
  }
  get updatedAt(): string {
    return this.data.updatedAt
  }
  get user(): any {
    return this.data.user
  }
  get version(): string {
    return this.data.version
  }

  /**
   * Helpers
   */
  get isDraft(): boolean {
    return !this.data.published
  }
  get hasLanguage(): boolean {
    return this.data[0]?.meta?.language?.[0]?.items
  }
  get language(): string {
    return this.data[0]?.meta?.language?.[0]?.items || ''
  }
  get hasTeacherInstructions(): boolean {
    return Boolean(this.data.teacherInstructions)
  }
  get mediaType(): string {
    return this.meta.media
  }
  get publishedGenesis(): boolean {
    return this._publishedGenesis
  }
}

/**
 * Facotry like contructor
 */
export interface EntityContructor<T, U> {
  new (data: U): T
}

/**
 * Bank type
 */
export type Bank = IdMap

/**
 * Item meta keys
 * TODO: get name values
 */
export const metaProps = new Map([
  ['blooms_taxonomy', 'Blooms Taxonomy'],
  ['difficulty', 'Difficulty'],
  ['depth_of_knowledge', 'Depth of Knowlege'],
  ['parcc_claim', '1'],
  ['parcc_secondary_claim', '2'],
  ['parcc_subclaim', '3'],
  ['revised_blooms_taxonomy', '4'],
  ['responseLanguage', '5'],
  ['sbac_claim', '6'],
  ['sbac_subclaim', ''],
  ['sbac_secondary_claim', '7'],
  ['sbac_claim_target', ''],
  ['sbac_secondary_claimTarget', '8'],
  ['sbac_content_category', '9'],
  ['sbac_secondary_contentCategory', '10'],
  ['standard', 'Standard'],
  ['language', 'Language'],
])

/**
 * Item settings and configs
 */
export interface ItemConfig {
  [k: string]: any
}
export class ItemConfigs {
  /**
   * Singleton
   */
  private static instance: ItemConfigs
  static getInstance(): ItemConfigs {
    if (!ItemConfigs.instance) {
      ItemConfigs.instance = new ItemConfigs(authorSettings)
    }
    return ItemConfigs.instance
  }

  private data: ItemConfig

  private constructor(data: ItemConfigs) {
    this.data = data
  }

  private get(k: string): any {
    return this.data[k]
  }

  public getSettings(id: string): any {
    return this.get(id)?.configSettings
  }

  public getContentConfig(id: string): any {
    return this.get(id)?.contentConfig
  }
}

/**
 * Item types
 */
export interface ItemType {
  id: string
  name: string
  symbol: string
}
export class ItemTypes {
  /**
   * Singleton
   */
  private static instance: ItemTypes
  static getInstance(): ItemTypes {
    if (!ItemTypes.instance) {
      ItemTypes.instance = new ItemTypes(ibxItemTypes)
    }
    return ItemTypes.instance
  }

  private data: ItemType[]

  private constructor(data: ItemType[]) {
    this.data = data
  }

  public get default(): ItemType {
    return this.get('multiple_choice')
  }

  public get(id: string): ItemType {
    return this.data.find((o: ItemType) => o.id == id)
  }

  public getAll(): ItemType[] {
    return this.data
  }
}
