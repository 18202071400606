import { mapGetters, mapActions } from 'vuex'
import { Events, EventBus } from '@events'
import CONST from '@constants'
import * as UTIL from '@helpers'

const FiltersQ = UTIL.makeDebouncedQueue()

const UserSelectionsMixin = {
  data: () => ({
    stannisChanged: false,
  }),

  computed: {
    ...mapGetters('standards', {
      stannisGet: 'get',
      stannisGetFiltered: 'getFiltered',
    }),
    ...mapGetters('browseFilters', {
      bfSelectedFilters: 'selectedFilters',
      bfFiltersOrder: 'filtersOrder',
      bfActiveFilters: 'activeFilters',
      bfExpandedFilters: 'expandedFilters',
    }),
    ...mapGetters('asmtSettings', {
      asmtPrintOptions: 'printOptions',
    }),
  },

  methods: {
    ...mapActions('user', ['setUserSelection']),
    usOnStannisChange() {
      this.stannisChanged = true
    },
    usOnStannisClose() {
      if (this.stannisChanged) {
        this.setUserSelection({
          context: CONST.CONTEXT_BASE_STANDARDS,
          value: {
            documents: this.stannisGetFiltered('documents'),
            subjects: this.stannisGetFiltered('subjects'),
            grade_level_sets: this.stannisGetFiltered('grade_level_sets'),
            selected_standards: this.stannisGet('selected_standards'),
          },
        })
      }
      this.stannisChanged = false
    },
    usOnBrowseFiltersChange() {
      FiltersQ.add({
        id: CONST.CONTEXT_BROWSE_FILTERS,
        value: {
          selectedFilters: this.bfSelectedFilters,
          filtersOrder: this.bfFiltersOrder,
          activeFilters: this.bfActiveFilters,
        },
      })
    },
    usSaveBrowseFilters(data) {
      this.setUserSelection({
        context: CONST.CONTEXT_BROWSE_FILTERS,
        value: {
          selectedFilters: data.selectedFilters,
          filtersOrder: data.filtersOrder,
          activeFilters: data.activeFilters,
        },
      })
    },
    asmtOnPrintOptionsChange() {
      FiltersQ.add({
        id: CONST.CONTEXT_PRINT_OPTIONS,
        value: {
          printOptions: this.asmtPrintOptions,
        },
      })
    },
    asmtSavePrintOptions(data) {
      this.setUserSelection({
        context: CONST.CONTEXT_PRINT_OPTIONS,
        value: {
          printOptions: data.printOptions,
        },
      })
    },
  },

  created() {
    EventBus.$on(Events.STANNIS_CHANGE, this.usOnStannisChange)
    EventBus.$on(Events.STANNIS_HIDE, this.usOnStannisClose)
    EventBus.$on(Events.BROWSE_FILTERS_CHANGE, this.usOnBrowseFiltersChange)
    FiltersQ.subscribe({ id: CONST.CONTEXT_BROWSE_FILTERS, time: 2000 }, this.usSaveBrowseFilters)
    EventBus.$on(Events.PRINT_OPTIONS_CHANGE, this.asmtOnPrintOptionsChange)
    FiltersQ.subscribe({ id: CONST.CONTEXT_PRINT_OPTIONS, time: 2000 }, this.asmtSavePrintOptions)
  },

  beforeDestroyed() {
    EventBus.$off(Events.STANNIS_CHANGE, this.onStannisChange)
    EventBus.$off(Events.STANNIS_HIDE, this.usOnStannisClose)
    FiltersQ.subscribe({ id: CONST.CONTEXT_BROWSE_FILTERS, complete: true })
    FiltersQ.subscribe({ id: CONST.CONTEXT_PRINT_OPTIONS, complete: true })
  },
}

export { UserSelectionsMixin }
