
// @ts-nocheck
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { EventBus, Events } from '@events'
import IbxButton from '@components/ibx/IbxButton'
import { AsmtMixin, SavingMessageMixin, PermsMixin } from '@mixins'
import '@plugins/vue-rx'
import IbxSavingMessage from '@components/ibx/IbxSavingMessage'
import * as VTooltip from 'vuetify/es5/components/VTooltip'
import * as VTextField from 'vuetify/es5/components/VTextField'
import * as VDivider from 'vuetify/es5/components/VDivider'
import * as VHover from 'vuetify/es5/components/VHover'
import IbxAppHeaderViewOnly from '@components/ibx/IbxAppHeaderViewOnlyBanner'
import { CONST, FLAG } from '@constants'
import * as UTIL from '@helpers'
import IBXService from '@services/ibx/IBXService'
import { Vue, Component, Mixins } from 'vue-property-decorator'
import { StateAuthorItems } from '@/helpers/state'

const AsmtQ = UTIL.makeDebouncedQueue()

@Component({
  components: {
    IbxButton,
    IbxSavingMessage,
    ...VTooltip,
    ...VTextField,
    ...VDivider,
    ...VHover,
    IbxAppHeaderViewOnly,
    'ibx-materials': () => import('@components/ibx/IbxMaterials'),
    'ibx-settings': () => import('@components/ibx/IbxSettings'),
    'build-header-actions': () => import('@components/build/BuildHeaderActions'),
    'build-asmt-actions': () => import('@components/build/BuildAsmtActions'),
    'ibx-duplicate': () => import('@components/ibx/IbxDuplicate'),
  },
  computed: {
    ...mapGetters('ui', ['saveMessage', 'toolTipProps']),
    ...mapGetters('config', ['appExitUrl', 'appPublishUrl', 'helpUrl', 'appHomeUrl']),
    ...mapGetters('asmt', ['checkAllAsmtItemLockStatus']),
    ...mapGetters('features', {
      featureFlag: 'flag',
    }),
    FLAG() {
      return FLAG
    },
    passageAuthoringEnabled() {
      return this.featureFlag(FLAG.PASSAGE_AUTHORING)
    },
  },
  methods: {
    ...mapActions('ui', ['setHeaderIsShort', 'setCurrentRoute']),
    ...mapMutations('asmt', ['setTitle']),
    ...mapActions('asmtSettings', {
      setLocked: 'setLocked',
    }),
  },
  watch: {
    asmtItemCount() {
      this.updatePop()
      setTimeout(this.updatePop, 0)
    },
    asmtItemsToAddCount() {
      this.updatePop()
      setTimeout(this.updatePop, 0)
    },
    itemAuthoringActive(v) {
      EventBus.$emit(Events.DIALOG_FULL, v)
    },
    titleOfAsmt(title) {
      var newVal = title
      var specialChars = CONST.unicodeRegex

      // remove anything not supported by UTF-8
      if (specialChars.test(title)) {
        newVal = title.replace(specialChars, '')
        this.$nextTick(() => this.$set(this, 'titleOfAsmt', newVal))
      }

      // only save if it's a new title and not empty
      if (newVal != this.asmtTitle && newVal != '') {
        this.setAsmtTitle(newVal)
      }
    },
    asmtTitle(v) {
      this.titleOfAsmt = v
    },
  },
})
export default class IbxAppHeader extends Mixins(Vue, StateAuthorItems, AsmtMixin, SavingMessageMixin, PermsMixin) {
  private controls: boolean = false
  private info: boolean = false
  private showEditTitleTooltip: boolean = false

  private pop: boolean = true
  private updatedTitle: any = null
  private titleOfAsmt: string = ''
  get classes() {
    const classes = []
    if (this.controls) classes.push('ibx-app-header--hasControls')
    return classes
  }
  get headerTitle() {
    return 'Browse Items'
  }

  get isBrowse() {
    return this.$route.name == 'browse'
  }

  get browseBtnAttrs() {
    const isBrowse = this.$route.name == 'browse'
    return {
      color: 'tabular',
      small: !isBrowse,
      class: isBrowse ? ['border-branding'] : [''],
    }
  }

  get buildBtnAttrs() {
    const isBuild = this.$route.name == 'assessment'
    return {
      color: 'tabular',
      small: !isBuild,
      class: isBuild ? ['border-branding'] : [''],
    }
  }

  get isBuildMode() {
    return this.asmtHasID
  }

  get iconAttrs() {
    return {
      color: 'white',
      style: { opacity: 1 },
    }
  }

  get createBtnColor() {
    const isBuild = this.$route.name == 'assessment'
    if (isBuild) return 'white'
    else if (!isBuild && this.asmtHasID) return 'white'
    else return 'branding'
  }

  get showToggle() {
    return this.isBuildMode && !this.permIsViewOnly && !this.permIsPublished
  }

  toggleColor(view) {
    if (this.$route.name === view) return ['tertiary']
    else return ['tertiary']
  }

  toggleView() {
    if (this.$route.name === 'browse') this.$router.push({ name: 'assessment' })
    else this.$router.push({ name: 'browse' })
  }

  goToView(view) {
    view == 'browse' ? this.setCurrentRoute('browse') : this.setCurrentRoute('build')
    EventBus.$emit(Events.ROUTE_GOTO, { name: view })
  }

  createAssessment() {
    EventBus.$emit(Events.CREATE_ASSESSMENT)
  }

  onClickBack() {
    if (this.isBuildMode) {
      window.location.href = `${process.env.VUE_APP_BASE_URI}`
    } else {
      window.location.href = this.appExitUrl
    }
  }

  onClickLogo() {
    window.location.href = this.appHomeUrl
  }

  onClickAmstSettings() {
    EventBus.$emit(Events.ASSESSMENT_SETTINGS, 0)
    this.setLocked(this.checkAllAsmtItemLockStatus)
  }

  onClickAmstMaterials() {
    EventBus.$emit(Events.ASSESSMENT_MATERIALS, true)
  }

  duplicateAssessment() {
    EventBus.$emit(Events.ASSESSMENT_DUPLICATE, true)
  }

  onClickAmstPreview() {
    EventBus.$emit(Events.ASSESSMENT_PREVIEW, true)
  }

  updatePop() {
    this.pop = !this.pop
  }

  setAsmtTitle(title) {
    if (this.asmtHasID) {
      this.smSaving()
      const titleData = this.updatedTitle || { title }
      this.updatedTitle = Object.assign({}, titleData, { title })
      this.asmtSetDirty(true)
      AsmtQ.add({ id: 'amst-title', value: this.updatedTitle })
    }
  }
  /*
   * Save data from queue when queue timer resolves
   */
  onSaveAssessmentTitle(data) {
    if (this.asmtHasID) {
      const items = Object.keys(data).map((k) => data[k])
      this.smSaving()
      this.asmtUpdateTitle(items[0])
        .then(() => this.smSaved())
        .catch((error) =>
          EventBus.$emit(Events.ERROR, {
            type: CONST.ERROR,
            error,
            text: 'Failed to save title',
            subtext: 'Please refresh the page',
          })
        )
    }
  }

  /**
   * Done handler for published assessment
   */
  onDonePublishedAsmt() {
    if (this.permIsPublished) {
      this.sendToAsmtOverview()
    } else {
      this.sendToAsmtList()
    }
  }

  /*
   * go back to assessment overview
   */
  sendToAsmtOverview() {
    window.location.href = `${this.appPublishUrl}&itembank_assessment_id=${this.asmtId}`
  }

  /*
   * go back to assessment list
   */
  sendToAsmtList() {
    window.location.href = this.appExitUrl
  }

  /**
   * Create Item button handler
   */
  onCreateItem() {
    EventBus.$emit(Events.AUTHOR_ITEMS_CREATE)
  }
  /**
   * Create Passage button handler
   */
  onCreatePassage() {
    EventBus.$emit(Events.CREATE_PASSAGE_ITEM)
  }

  onHelpClick() {
    window.open(`${this.helpUrl}/categories/30099387656731-Assessment-Types`)
  }

  async mounted() {
    AsmtQ.subscribe({ id: 'amst-title', time: 1000 }, this.onSaveAssessmentTitle)
    this.titleOfAsmt = this.asmtTitle
  }

  async updated() {
    if (this.$route.name == 'assessment') this.setCurrentRoute('build')
  }
}
