import { makeDevRoutes } from './routesDev'

const appRoutes = [
  {
    path: '/',
    name: 'home',
    redirect: '/browse',
  },

  {
    path: '/browse',
    name: 'browse',
    component: () => import('@views/BrowseView'),
  },

  {
    path: '/create-assessment',
    name: 'assessment-create',
    component: () => import('@views/AssessmentCreateView'),
  },

  {
    path: '/assessment/:assessmentId?',
    name: 'assessment',
    component: () => import('@views/BuildView'),
  },

  {
    path: '/itemcard',
    name: 'itemcard',
    component: () => import('@views/ItemCardView'),
  },

  {
    path: '/404',
    name: '404',
    component: () => import('@views/404'),
  },
]

// add dev only routes
let devRoutes = []

if (process.env.VUE_APP_ITEM_INSPECTOR == '1' && process.env.NODE_ENV === 'development') {
  devRoutes.push({
    path: '/inspector',
    name: 'inspector',
    component: () => import('@/clouseau/Inspector'),
  })
}

if (process.env.NODE_ENV === 'development') {
  // devRoutes.push({
  //   path: '/sandbox',
  //   name: 'sandbox',
  //   component: () => import('@views/Sandbox'),
  //   children: makeDevRoutes(),
  // })
  // devRoutes.push(
  //     {
  //         path: '/author-items-test',
  //         name: 'author-items-test',
  //         component: () => import('@/views/AuthorItemsTest'),
  //     }
  // )
  // devRoutes.push(
  //     {
  //         path: '/author-passages-test',
  //         name: 'author-passages-test',
  //         component: () => import('@/views/AuthorPassagesTest'),
  //     }
  // )
}

const routes = [...appRoutes, ...devRoutes]

export default routes
