import { StoreOptions } from 'vuex'
import Vue from 'vue'
import _ from 'lodash'
import { Flags, FeatureFlagService } from '@/services/featureFlagService'
import { FLAG } from '@constants'

/**
 * State class
 */
export class State {
  initialized: boolean = false
  flags: Flags = Object.freeze({})
  supportedItemTypes: { [key: string]: boolean } = {
    multiple_choice: true,
    multiple_select: true,
    constructed_response: true,
    explicit_constructed_response: true,
    math_equation_response: true,
    evidence_based_selected_response: true,
    inline_dropdown: true,
    hot_text: true,
    hot_spot: true,
    drag_in_the_blank: true,
    drawing_response: true,
    ordering: false,
    matching_table: false,
    multiple_binary: false,
    graphing: false,
    classification: false,
    charting: false,
    number_line: false,
  }
}

/**
 * Feature flag serive instance
 */
let service = new FeatureFlagService()

/**
 * Vuex module
 */
const features = {
  namespaced: true,
  state: new State(),

  getters: {
    /**
     * Get initialized
     */
    initialized: (state: State): boolean => state.initialized,

    /**
     * Get FeatureFlagService instance
     */
    service: (state: State): FeatureFlagService => service,

    /**
     * Get all flags
     */
    flags: (state: State): Flags => state.flags,

    /**
     * Get flag value by key
     * @param key flag key
     * @returns any (defaults to false if flag and default is not found)
     */
    flag: (state: State): ((key: string) => any) => {
      return (key: string): any => {
        const stateValue = state.flags?.[key]

        if (stateValue !== undefined) {
          return stateValue
        } else {
          return service.defaults?.[key] || false
        }
      }
    },
    supportedItemTypes: (state: State): any => state.supportedItemTypes,
  },

  mutations: {
    /**
     * Set initialized
     * @param value
     */
    setInitialized: (state: State, value: boolean) => {
      state.initialized = value
    },

    /**
     * Set all flags and freeze object
     * @param value Flags object
     */
    setFlags: (state: State, value: Flags) => {
      Vue.set(state, 'flags', Object.freeze({ ...value }))
    },

    /**
     * Set/add flag and freeze object
     * @param flag Flag { key, value }
     */
    setFlag: (state: State, flag: Flags) => {
      const flags = Object.assign({}, _.cloneDeep(state.flags), flag)
      Vue.set(state, 'flags', Object.freeze(flags))
    },
    setSupportedItemType: (state: State, { key, value }: { key: string; value: boolean }) => {
      Vue.set(state.supportedItemTypes, key, value)
    },
  },

  actions: {
    /**
     * Initialize feature flags
     * @param flags feature flags object
     */
    init: async (
      { commit, getters, dispatch, rootGetters },
      { flags, hash, user = {} }: { flags: Flags; hash?: string; user: any }
    ): Promise<any> => {
      if (!getters.initialized) {
        if (!flags) {
          await service.init({ user, hash })
          service.subscribe((flags: Flags) => dispatch('setFlags', flags))
          flags = await service.fetchFlags()
        }

        commit('setFlags', flags)
        commit('setInitialized', true)
      }

      // check feature flagged item type
      commit('setSupportedItemType', {
        key: 'drawing_response',
        value: rootGetters['features/flag'](FLAG.DRAWING_RESPONSE),
      })
      commit('setSupportedItemType', {
        key: 'ordering',
        value: rootGetters['features/flag'](FLAG.AUTHORING_ORDERING),
      })
      commit('setSupportedItemType', {
        key: 'matching_table',
        value: rootGetters['features/flag'](FLAG.AUTHORING_MATCHING_TABLE),
      })
      commit('setSupportedItemType', {
        key: 'multiple_binary',
        value: rootGetters['features/flag'](FLAG.AUTHORING_MULTIPLE_BYNARY),
      })
      commit('setSupportedItemType', {
        key: 'graphing',
        value: rootGetters['features/flag'](FLAG.AUTHORING_GRAPHING),
      })
      commit('setSupportedItemType', {
        key: 'classification',
        value: rootGetters['features/flag'](FLAG.AUTHORING_CLASSIFICATION),
      })
      commit('setSupportedItemType', {
        key: 'charting',
        value: rootGetters['features/flag'](FLAG.AUTHORING_CHARTING),
      })
      commit('setSupportedItemType', {
        key: 'number_line',
        value: rootGetters['features/flag'](FLAG.AUTHORING_NUMBER_LINE),
      })
    },

    /**
     * Set Flags
     * @param flags Flags object
     */
    setFlags: async ({ commit }, flags: Flags): Promise<any> => {
      commit('setFlags', flags)
    },

    /**
     * Set Flag
     * @param flag Flag { key, value }
     */
    setFlag: async ({ commit }, flag: Flags): Promise<any> => {
      commit('setFlag', flag)
    },

    /**
     * Reset state
     */
    reset: async ({ commit }): Promise<any> => {
      commit('setInitialized', false)
      commit('setFlags', {})
    },
  },
} as StoreOptions<State>

export { features }
