/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  expanded: {
    width: 15,
    height: 14,
    viewBox: '0 0 15 14',
    data: '<rect pid="0" width="15" height="14" rx="1" _fill="#0049FF" fill-opacity=".3"/>',
  },
})
