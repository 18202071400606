import { makeBrowseMediator } from './modules/browseMediator'
import { makeAsmtMediator } from './modules/asmtMediator'
import * as UTIL from '@helpers'

const makeMediator = () => {
  return (store) => {
    const syncQ = UTIL.makeDebouncedQueue()
    const browseMediator = makeBrowseMediator(store, syncQ)
    const asmtMediator = makeAsmtMediator(store)
  }
}

export { makeMediator }
