import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
import { clouseau } from './modules/clouseau'
import { makeMediator } from './mediator'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const persitedModules = ['standardsItems']

// Item Inspector Tool
if (process.env.VUE_APP_ITEM_INSPECTOR == '1' && process.env.NODE_ENV === 'development') {
  //persitedModules.push('clouseau')
  modules.clouseau = clouseau
}

const dataState = createPersistedState({
  paths: persitedModules,
})

const store = new Vuex.Store({
  modules,
  namespaced: true,
  strict: process.env.NODE_ENV !== 'production',
  plugins: [makeMediator(), dataState],
})

export default store
