import Vue from 'vue'
import { AsyncQueue } from '@helpers'
import { Subject, fromEvent } from 'rxjs'
import { throttleTime, debounceTime, takeUntil } from 'rxjs/operators'
import CONST from '@constants'

const ItemListMixin = {
  data: () => ({
    asyncQInitialized: false, // true when initItemListQueue is called
    asyncQ: null, // queue
    loadingDone$: new Subject(), // item loaded observable
    scroll$: null, // scroll observable
    windowResize$: null, // window resize observable
    lazyLoadTriggers: {}, // tiggers used for triggering item loading,
    context: CONST.MODE_BUILD, // used for card unique keys/ids
    hasItems: false, // sync this to items list
    viewThreshold: 0, // in view threshold beyong view port height
  }),

  methods: {
    /*
     * Get unique id for item card based on passage or item data
     * @param data passage/item data
     */
    getCardId(data) {
      const passageRevId =
        data.passageRevId || data.passage ? (data.passageRevId ? data.passageRevId : data.passage.passageRevId) : null

      const version = !passageRevId && data.item ? data.item.version : ''

      return passageRevId
        ? `${this.context}-passage-${passageRevId}`
        : `${this.context}-item-${data.itemRevId}-${version}`
    },
    /*
     * Trigger lazy load
     * @param ids array of item/passage card to load
     */
    loadItems(ids = []) {
      ids.forEach((id) => Vue.set(this.lazyLoadTriggers, id, true))
    },
    /*
     * Notify Queue that item has loaded
     */
    onItemLoaded({ id }) {
      if (id) this.asyncQ.onItemDone(id)
    },
    /*
     * Player error handler
     * Call item load to allow queue to continue
     */
    onItemLoadError({ id }) {
      this.onItemLoaded({ id })
    },
    /*
     * Check if item card is view. On Scroll or window resize
     * if in view then queue item for rendering
     */
    checkItemsInView() {
      if (!this.hasItems) return
      const threshold = window.innerHeight + this.viewThreshold
      const cards = this.$refs.cards || []
      cards.forEach((cardComponent) => {
        const id = cardComponent.id || cardComponent.$attrs.id
        const rect = cardComponent.$el.getBoundingClientRect()
        const inView = rect.top < threshold
        if (inView && !this.lazyLoadTriggers[id]) {
          this.asyncQ.addItems([id])
        }
      })
    },
    /*
     * Initialize observables and observers for window scrollign and resizing
     */
    subscribeToLazyLoading() {
      // create scroll observable
      if (!this.scroll$) {
        this.scroll$ = fromEvent(window, 'scroll').pipe(
          takeUntil(this.loadingDone$),
          throttleTime(500, undefined, { leading: true, trailing: true })
        )
      }
      // create scroll observable
      if (!this.windowResize$) {
        this.windowResize$ = fromEvent(window, 'resize').pipe(takeUntil(this.loadingDone$), debounceTime(1000))
      }
      // subscriptions
      this.scroll$.subscribe(this.checkItemsInView)
      this.windowResize$.subscribe(this.checkItemsInView)
    },
    /*
     * Ubsubscribe observers
     */
    unbscribeToLazyLoading() {
      this.loadingDone$.next()
    },
    /*
     * Set all lazy load triggers to value. Triggers are keys by getCardId()
     * @param items object[]
     * @param value boolean
     */
    initLazyLoadTriggers(items = [], value = false) {
      items.forEach((o) => Vue.set(this.lazyLoadTriggers, this.getCardId(o), value))
    },
    /*
     * Reset items and queu
     * clear triggers and remove possible references
     */
    resetQueue(items = []) {
      this.asyncQ.reset()
      Object.keys(this.lazyLoadTriggers).forEach((k) => (this.lazyLoadTriggers[k] = null))
      this.lazyLoadTriggers = {}
      this.initLazyLoadTriggers(items)
    },
    /*
     * Initialized the async queue and subscriptions
     * @param items object[]
     * @param max number: the max items that be render at one time
     * @param debounce: time to debounce adding items to queue
     * @param context: sets view context. used in getCardId
     */
    initItemListQueue(p = { items: [], max: 3, debounce: 5000, context: 'build' }) {
      this.context = p.context
      this.initLazyLoadTriggers(p.items, false)
      this.asyncQ = new AsyncQueue({ max: p.max, observer: this.loadItems, debounce: p.debounce })
      this.subscribeToLazyLoading()
      this.asyncQInitialized = true
    },
  },

  beforeDestroy() {
    this.unbscribeToLazyLoading()
    this.loadingDone$.complete()
  },
}

export default ItemListMixin
export { ItemListMixin }
