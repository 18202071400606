/**
 * Validate item model
 * @param ibxItemType ibx systemKey
 * @param config PIE item config
 * @returns any[]
 */
export const validate = (ibxItemType: string, config: any): any[] => {
  switch (ibxItemType) {
    case 'multiple_choice':
    case 'multiple_select':
      return validateMultipleChoice(config)
  }

  return []
}

/**
 * Validate Multiple Choice Model
 * Checks for: correct choice, max/min choice options
 * @param config PIE item config
 * @returns any[]
 */
export const validateMultipleChoice = (config: any): any[] => {
  const errors = []
  const model = config.models?.[0] || { choices: [] }
  const choiceLength = model.choices?.length || 0
  const correct = model.choices.reduce((m, choice) => {
    if (choice.correct) {
      m = choice.correct
    }
    return m
  }, null)

  // validate has choices
  if (choiceLength == 0) {
    errors.push({ message: 'Item has no choices.' })
  }

  // validate has less than one choice
  if (choiceLength == 1) {
    errors.push({ message: `A minimum of 2 choices allowed. Item has ${choiceLength} choice.` })
  }

  // validate choices >= allowed max
  if (choiceLength > 9) {
    errors.push({ message: `A maximum of 9 choices allowed. Item has ${choiceLength} choices.` })
  }

  // validate has correct choice
  if (correct !== true) {
    errors.push({ message: 'Item requires correct answer.' })
  }

  return errors
}
