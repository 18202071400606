import { IBXService } from '@services/ibx/IBXService'
import { StoreOptions } from 'vuex'
import _ from 'lodash'

interface IState {
  banks: object[]
  banksFetched: boolean
  selectedBanks: string[]
  updated: boolean
  loaded: boolean
  itemCount: number
  showPassCheckbox: boolean
  savedItemCount: number
  errorItemCount: number
  itemSavingFailed: boolean
  saveInProgress: boolean
}

class State implements IState {
  banks: object[] = []
  banksFetched: boolean = false
  selectedBanks: string[] = []
  updated: boolean = false
  loaded: boolean = false
  itemCount: number = 0
  showPassCheckbox: boolean = false
  savedItemCount: number = 0
  errorItemCount: number = 0
  itemSavingFailed: boolean = false
  saveInProgress: boolean = false
}

const itemAuthor = {
  namespaced: true,
  state: new State(),

  getters: {
    banks: (state: IState) => state.banks,
    banksFetched: (state: IState) => state.banksFetched,
    selectedBanks: (state: IState) => state.selectedBanks,
    updated: (state: IState) => state.updated,
    loaded: (state: IState) => state.loaded,
    itemCount: (state: IState) => state.itemCount,
    showPassCheckbox: (state: IState) => state.showPassCheckbox,
    savedItemCount: (state: IState) => state.savedItemCount,
    errorItemCount: (state: IState) => state.errorItemCount,
    itemSavingFailed: (state: IState) => state.itemSavingFailed,
    saveInProgress: (state: IState) => state.saveInProgress,
  },

  mutations: {
    setBanks: (state: IState, value: object[] = []) => {
      state.banks = value || []
    },
    setBanksFetched: (state: IState, value: boolean) => {
      state.banksFetched = value
    },
    setSelectedBanks: (state: IState, value: string[] = []) => {
      state.selectedBanks = value || []
    },
    setUpdated: (state: IState, value: boolean) => {
      state.updated = value
    },
    setLoaded: (state: IState, value: boolean) => {
      state.loaded = value
    },
    setItemCount: (state: IState, value: number) => {
      state.itemCount = value
    },
    setShowPassCheckbox: (state: IState, value: boolean) => {
      state.showPassCheckbox = value
    },
    setSavedItemCount: (state: IState, value: number) => {
      state.savedItemCount = value
    },
    incrementSavedItemCount: (state: IState) => {
      state.savedItemCount = state.savedItemCount + 1
    },
    resetSavedItemCount: (state: IState) => {
      state.savedItemCount = 0
    },
    incrementErrorItemCount: (state: IState) => {
      state.errorItemCount = state.errorItemCount + 1
    },
    resetErrorItemCount: (state: IState) => {
      state.errorItemCount = 0
    },
    setItemSavingFailed: (state: IState, value: boolean) => {
      state.itemSavingFailed = value
    },
    setSaveInProgress: (state: IState, value: boolean) => {
      state.saveInProgress = value
    },
  },

  actions: {
    fetchBanks: async ({ commit }) => {
      const { banks } = await IBXService.banks({ canEdit: 1, bankType: ['district', 'district_default', 'publisher'] })
      commit('setBanks', banks)
      commit('setBanksFetched', true)
    },
    setSelectedBanks: async ({ commit, getters }, value: string[] = []) => {
      const allowedBanksIds = getters.banks.map(({ bankId }) => bankId)
      let intersection = _.intersectionWith(value, allowedBanksIds, (a, b) => _.isEqual(parseInt(a), parseInt(b)))
      value = intersection.map(Number)
      commit('setSelectedBanks', value)
    },
    setUpdated: async ({ commit }, value) => {
      commit('setUpdated', value)
    },
    setLoaded: async ({ commit }, value) => {
      commit('setLoaded', value)
    },
    setItemCount: async ({ commit }, value: number) => {
      commit('setItemCount', value)
    },
    setShowPassCheckbox: async ({ commit }, value: boolean) => {
      commit('setShowPassCheckbox', value)
    },
    setSavedItemCount: async ({ commit }, value: number) => {
      commit('setSavedItemCount', value)
    },
    incrementSavedItemCount: async ({ commit }) => {
      commit('incrementSavedItemCount')
    },
    resetSavedItemCount: async ({ commit }) => {
      commit('resetSavedItemCount')
    },
    incrementErrorItemCount: async ({ commit }) => {
      commit('incrementErrorItemCount')
    },
    resetErrorItemCount: async ({ commit }) => {
      commit('resetErrorItemCount')
    },
    setItemSavingFailed: async ({ commit }, value: boolean) => {
      commit('setItemSavingFailed', value)
    },
    setSaveInProgress: async ({ commit }, value: boolean) => {
      commit('setSaveInProgress', value)
    },
  },
} as StoreOptions<IState>

export { itemAuthor, IState }
