<template>
  <div class="ibx-passage-items-card">
    <div class="ibx-passage-items-card-header">
      <div class="ibx-passage-items-card_header__passage">
        <div class="ibx-passage-items-card_header__passage__icon">
          <font-awesome-icon
            :icon="['fas', 'book']"
            class="icon icon-passage"
            v-bind="getTitleIconAttrs"
            color="#fff"
          ></font-awesome-icon>
        </div>
        <div class="ibx-passage-items-card_header__passage__title">{{ passageHeaderTitle }}</div>
      </div>
      <div class="x-content-card-header__items-top">
        <div class="x-content-card-header__items-top__matching-toggle">
          <x-btn class="toggle left" :color="getMatchingToggleColor('matching')" @click="matching = true"
            >{{ itemsMatching.length }} Matching Criteria</x-btn
          >
          <x-btn class="toggle right" :color="getMatchingToggleColor('all')" @click="matching = false"
            >{{ itemsAll.length }} Total Items</x-btn
          >
        </div>
        <font-awesome-icon
          :icon="['fal', 'times']"
          class="x-content-card-header__items-top__close"
          @click="onCloseClick"
        />
      </div>
    </div>

    <div class="ibx-passage-items-card-content">
      <div class="ibx-passage-items-card-content__passage">
        <ibx-passage
          v-if="!reseting"
          :load="true"
          :passage="passage"
          initial-mode="view"
          v-bind="$attrs"
          @loaded="onPassageLoaded"
        />
      </div>
      <div class="ibx-passage-items-card-content__items">
        <div v-if="loading">Loading...</div>
        <div v-if="error">Error</div>
        <div v-if="!loading">
          <ibx-item-card
            v-for="(item, i) in getPassageItems"
            class="ibx-passage-items-card-content__items__item"
            :itemRevId="item.itemRevId"
            :itemData="item"
            :passageRevId="passage.passageRevId"
            :preview="true"
            context="provide"
            :key="`passage-item-${item.itemRevId}`"
            :index="i"
            lazy
            :lazyLoadTrigger="getLazyLoadTriggers[i]"
            stickyHeader
            :fullscreen="false"
            showLock
            :canLock="permCanLockItems"
            @close-passage-card="onCloseClick"
          ></ibx-item-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IbxPassage from '@components/ibx/IbxPassage'
import IbxItemCard from '@components/ibx/_IbxItemCard'
import { AsmtMixin, PermsMixin } from '@mixins'
import { IBXService } from '@services/ibx/IBXService'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import XBtn from '@xLib/XBtn'
import { EventBus, Events } from '@events'
import CONST from '@constants'

export default {
  name: 'IbxPassageItemsCard',

  mixins: [AsmtMixin, PermsMixin],

  components: {
    IbxPassage,
    IbxItemCard,
  },

  props: {
    passage: { type: Object, default: () => ({}) },
  },

  data: () => ({
    reseting: false,
    itemsAll: [],
    itemsMatching: [],
    matching: true,
    loading: true,
    lazyLoadTriggersAll: [],
    lazyLoadTriggersMatching: [],
    error: false,
  }),

  computed: {
    ...mapGetters('browseFilters', ['selectedFilters']),
    getTitleIconAttrs() {
      return {
        name: 'Passage_1',
        style: { 'background-color': '#FF8E07' },
      }
    },
    passageId() {
      return this.passage.passageRevId
    },
    passageItemsIds() {
      return this.passage.items || []
    },
    passageHeaderTitle() {
      return `Passage (${this.itemsMatching.length} Linked Items)`
    },
    getPassageItems() {
      return this.matching ? this.itemsMatching : this.itemsAll
    },
    getLazyLoadTriggers() {
      return this.matching ? this.lazyLoadTriggersMatching : this.lazyLoadTriggersAll
    },
  },

  methods: {
    ...mapMutations('browseItems', ['setPassageItems']),
    onPassageLoaded() {
      //...
    },
    onCloseClick() {
      this.$emit(Events.CLOSE)
    },
    getMatchingToggleColor(type) {
      if (this.matching && type == 'matching') return 'tertiary'
      if (!this.matching && type == 'all') return 'tertiary'
      return 'white'
    },
    fetchItems() {
      IBXService.passageRevisionItems(this.passageId)
        .then(async ({ items }) => {
          this.itemsAll = items
          this.setPassageItems(items)
          this.lazyLoadTriggersAll = items.map(() => false)
          return items
        })
        .then((items) => {
          const matchingItemRevIds = this.passageItemsIds.map((o) => o.itemRevId)
          this.itemsMatching = items.filter((o) => matchingItemRevIds.includes(o.itemRevId))
          this.$nextTick(() => (this.loading = false))
        })
        .catch((error) => {
          this.error = true
          EventBus.$emit(Events.ERROR, { type: CONST.ERROR, error, text: CONST.BROWSE_ITEMS_LOAD_ERROR })
        })
    },
    addPassageItems(data) {
      if (data.passageId == this.passageId) {
        this.asmtAddItems(this.getPassageItems.map((o) => o.itemId)).catch((error) => {
          if (error != Events.ASSESSMENT_ITEM_LIMIT) {
            EventBus.$emit(Events.ERROR, {
              type: CONST.ERROR,
              error,
              text: 'Failed to add item',
              subtext: 'Please refresh the page',
            })
          }
        })
      }
    },
    /*
     * Lazy/Stagger load items
     */
    lazyLoad(index = 0) {
      if (index <= this.getPassageItems.length - 1 && index < 50) {
        if (this.matching) {
          let triggers = [...this.lazyLoadTriggersMatching]
          triggers[index] = true
          this.lazyLoadTriggersMatching = triggers
          setTimeout(() => this.lazyLoad(index + 1), 500)
        } else {
          let triggers = [...this.lazyLoadTriggersAll]
          triggers[index] = true
          this.lazyLoadTriggersAll = triggers
          setTimeout(() => this.lazyLoad(index + 1), 500)
        }
      }
    },
    reset() {
      this.reseting = true
      this.itemsAll = []
      this.itemsMatching = []
      this.matching = true
      this.loading = true
      this.lazyLoadTriggersAll = []
      this.lazyLoadTriggersMatching = []
      this.error = false
      this.$nextTick(() => {
        this.reseting = false
        this.init()
      })
    },
    init() {
      var unwatchLoading = this.$watch('loading', (loading) => {
        if (!loading) {
          this.lazyLoad(0)
          unwatchLoading()
        }
      })

      var unwatchMatchingToggle = this.$watch('matching', (v) => {
        this.lazyLoad(0)
        unwatchMatchingToggle()
      })

      this.fetchItems()
    },
  },

  watch: {
    passage(v) {
      this.reset()
    },
  },

  mounted() {
    this.setPassageItems([])
    this.init()
  },
}
</script>

<style lang="scss">
@import '@/styles/main';
.ibx-passage-items-card {
  $left-width: 300px;
  display: block;
  background: white;
  padding: 0 16px;
  border-radius: 5px;

  .ibx-passage-items-card-header {
    position: sticky;
    top: 0;
    display: flex;
    height: 55px;
    z-index: 301;
    background: #fff;
    width: 100%;

    .ibx-passage-items-card_header__passage {
      display: flex;
      align-items: center;
      flex: 0 0 $left-width;

      &__title {
        color: $Y500;
        font-weight: bold;
      }

      &__icon {
        display: flex;
        align-items: center;

        .icon {
          width: 30px;
          height: 30px;
          fill: white;
          padding: 2px 2px;
          border-radius: 5px;
          margin-right: 10px;

          &-passage {
            padding: 5px !important;
          }
        }
      }
    }

    .x-content-card-header__items-top {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex: 1;

      &__matching-toggle {
        margin-left: auto;
        margin-right: auto;
        .toggle {
          margin: 0px;
          border: 1px solid $N30 !important;

          &.left {
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
          }

          &.right {
            border-top-left-radius: 0px !important;
            border-bottom-left-radius: 0px !important;
            border-left: none !important;
          }
        }
      }

      &__close {
        margin-left: auto;
        color: $N90;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }

  .ibx-passage-items-card-content {
    display: flex;
    align-items: flex-start;

    &__passage {
      width: 40%;
      padding: 0px;
      border: 1px solid $N20;
      border-radius: 5px;
      max-height: 72vh;
      overflow: scroll;
      position: -webkit-sticky;
      position: sticky;
      top: 55px;
    }

    &__items {
      width: 60%;
      padding: 0px 0px 0px 20px;

      &__item {
        &:not(:first-child) {
          margin-top: 20px;
        }
      }

      .x-content-card {
        border: 1px solid $N20 !important;
        border-top: none !important;
      }
    }
  }
}
</style>
