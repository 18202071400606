<template>
  <div class="x-filter-chip-list">
    <div v-if="hasItems" class="x-filter-chip-list-chips" ref="chipsContainer">
      <span v-for="(item, i) in getIems" :key="`$chip-item-${i}`" class="x-filter-chip-list-chips__item" ref="items">
        <v-chip
          class="x-filter-chip-list-chips__control"
          close
          @click:close="change(getItemId(item), false)"
          @click="change(getItemId(item), false)"
        >
          {{ getItemLabel(item) }}
        </v-chip>
      </span>
    </div>
    <div v-else>No filters selected</div>
    <div class="x-filter-chip-list__more" v-if="hasItems">
      <span class="x-filter-chip-list__more-label" @click="onShowMore">
        {{ moreLabel }}
      </span>
    </div>
  </div>
</template>

<script>
import xFilterMixin from '../mixins/xFilterMixin'
import xFilterListMixin from '../mixins/xFilterListMixin'
import { pluralizeString } from '@helpers'
import * as VChip from 'vuetify/es5/components/VChip'

export default {
  name: 'XFilterChipList',

  mixins: [xFilterMixin, xFilterListMixin],

  components: {
    ...VChip,
  },

  props: {
    multiple: { type: Boolean, default: true }, // can list/select multiple
  },

  data: () => ({
    mounted: false,
    width: 200,
    metrics: [],
    moreCount: 0,
  }),

  computed: {
    getIems() {
      return this.multiple ? this.items : [this.items[0]]
    },
    moreLabel() {
      return this.hasMore ? `+${pluralizeString(this.moreCount, 'more filter')}` : ''
    },
    hasMore() {
      return this.moreCount > 0
    },
  },

  methods: {
    setMetrics() {
      if (this.hasItems) {
        const container = this.$refs.chipsContainer
        const containerRect = container.getBoundingClientRect()
        const metrics = this.$refs.items.map((el) => ({ overflow: el.offsetTop > 0 }))

        this.metrics = metrics
        this.moreCount = metrics.reduce((m, o) => (o.overflow ? m + 1 : m), 0)
      } else {
        this.metrics = []
        this.moreCount = 0
      }
    },
    onShowMore() {
      this.$emit('show-more')
    },
  },

  mounted() {
    /* setTimeout fixes issue offset top in setMetrics being incorrect even with $nextTick */
    setTimeout((_) => this.setMetrics(), 0)
  },

  updated() {
    this.setMetrics()
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.x-filter-chip-list {
  width: 100%;

  .x-filter-chip-list-chips {
    display: block;
    overflow: hidden;
    height: 32px;
    position: relative;

    &__item {
      display: inline-block;
      margin: 0px 8px 8px 0px;
      opacity: 1;
    }

    &__control {
      margin: 0px 0px 0px 0px;
    }
  }
}

.x-filter-chip-list__more-label {
  color: $N60;
  cursor: pointer;

  &:hover {
    color: $N90;
  }
}
</style>
