<template>
  <div class="item-card">
    <x-content-card
      :data="getItemData"
      :cardTitle="itemItemTypeLabel"
      :titleIcon="itemItemTypeIcon"
      :details="getDetails"
      :standards="getStandards"
      asyncContent
      heightAuto
      noFooter
      v-bind="$attrs"
      v-on="$listeners"
    >
      <ibx-item
        slot="content"
        slot-scope="{ load, onAsyncContentLoaded }"
        :load="load"
        :id="itemVendorItemIdVersioned"
        :initial-mode="mode"
        :initial-role="role"
        :role="role"
        :optimize="true"
        :preview="preview"
        @loaded="() => onLoaded(onAsyncContentLoaded)"
        @player-error="() => onLoaded(onAsyncContentLoaded)"
      />
      <ibx-card-meta slot="meta-data" :itemData="itemData" />
    </x-content-card>
  </div>
</template>

<script>
import { ItemMixin } from '@mixins'
import { XContentCard, XContentCardHeader } from '@xLib/XContentCard'
import { Events, EventBus } from '@events'
import IbxItem from '@components/ibx/IbxItem'
import IbxCardMeta from '@components/ibx/cardMeta/IbxCardMeta'

export default {
  name: 'ItemCard',

  mixins: [ItemMixin],

  components: {
    XContentCard,
    IbxItem,
    IbxCardMeta,
  },

  props: {
    mode: { type: String, default: 'view' },
    role: { type: String, default: 'instructor' },
    preview: { type: Boolean, default: false },
  },

  computed: {
    getItemData() {
      return {
        remoteId: this.itemVendorItemId,
      }
    },
    getDetails() {
      return [this.itemDok]
    },
    getStandards() {
      return this.itemStandard
    },
    getActions() {
      return []
    },
  },

  methods: {
    onLoaded(onAsyncContentLoaded) {
      if (typeof onAsyncContentLoaded === 'function') {
        onAsyncContentLoaded()
      }
      this.$emit(Events.LOADED, this.index)
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/main';
</style>
