/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'Hot Text_2': {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M158.91 20.5H33.09c-6.953 0-12.59 5.637-12.59 12.59v125.82c0 6.953 5.637 12.59 12.59 12.59h125.82c6.953 0 12.59-5.637 12.59-12.59V33.09c0-6.953-5.637-12.59-12.59-12.59zm-60.34 99.93H88V99.55H66.54v20.88H56v-50.8h10.54v20.75H88V69.63h10.57v50.8zm30.55-41.52h12.06v-9.28h-34.63v9.28h12.13v41.52h10.44V78.91z" _fill="#152452"/>',
  },
})
