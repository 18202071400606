<template class="build-position">
  <v-card class="build-asmt-actions" max-width="460px">
    <v-card-text class="draft-items-warning" v-show="asmtHasDraftItems">
      <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="icon" />
      To publish this assessment, please publish your draft item{{ getPluralForm }}
      first.
    </v-card-text>
    <div class="build-asmt-actions-item">
      <div class="build-asmt-actions-item__icon">
        <font-awesome-icon :icon="['fal', 'file-export']" />
      </div>
      <div class="build-asmt-actions-item__content">
        <div class="build-asmt-actions-item__content__title">Publish Assessment</div>
        <div class="build-asmt-actions-item__content__text">
          Publishes your assessment. Only minor edits can be made.
        </div>
      </div>

      <div class="build-asmt-actions-item__action">
        <x-btn
          class=""
          color="primary"
          :disabled="asmtIsPublished || asmtItemCount < 1 || asmtHasDraftItems || !amstReadyForPublish"
          @click="publishAsmt()"
          >Publish</x-btn
        >
      </div>
    </div>

    <div class="build-asmt-actions-item">
      <div class="build-asmt-actions-item__icon">
        <font-awesome-icon :icon="['fal', 'save']" />
      </div>
      <div class="build-asmt-actions-item__content">
        <div class="build-asmt-actions-item__content__title">
          Save as Draft
          <div class="build-asmt-actions-item__content__text">
            Saves a draft of your assessment. You can edit it at any time.
          </div>
        </div>
      </div>
      <div class="build-asmt-actions-item__action">
        <x-btn color="tertiary" class="action-btn" @click="saveDraft()">Save Draft</x-btn>
      </div>
    </div>

    <div class="build-asmt-actions-item">
      <div class="build-asmt-actions-item__icon">
        <font-awesome-icon :icon="['fal', 'trash']" />
      </div>
      <div class="build-asmt-actions-item__content">
        <div class="build-asmt-actions-item__content__title">
          Discard Draft
          <div class="build-asmt-actions-item__content__text">Deletes your assessment completely. Use caution!</div>
        </div>
      </div>
      <div class="build-asmt-actions-item__action">
        <build-discard-asmt-dialog class="discard-dialog" />
      </div>
    </div>
  </v-card>
</template>

<script>
import { AsmtMixin } from '@mixins'
import * as VCard from 'vuetify/es5/components/VCard'
import * as VDivider from 'vuetify/es5/components/VDivider'
import BuildDiscardAsmtDialog from '@components/build/BuildDiscardAsmtDialog'
import { EventBus, Events } from '@events'
import { mapGetters, mapActions } from 'vuex'
import CONST from '@constants'
import { validate } from '@/helpers/itemValidation'
import _ from 'lodash'

export default {
  name: 'BuildAsmtActions',

  mixins: [AsmtMixin],

  components: {
    ...VCard,
    ...VDivider,
    BuildDiscardAsmtDialog,
  },

  computed: {
    ...mapGetters('config', ['appExitUrl', 'appPublishUrl', 'appDraftUrl']),

    getPluralForm() {
      return this.asmtDraftItemsCount == 1 ? '' : 's'
    },
  },

  methods: {
    ...mapActions('itemConfigs', ['getItemConfig']),
    async publishAsmt() {
      // validate items
      const { hasErrors } = await this.amstValidateItems()

      if (hasErrors) {
        EventBus.$emit(Events.ERROR, {
          type: CONST.ERROR,
          error: 'Invalid items',
          text: ' Failed to Publish',
          subtext: 'Item validation errors. Please fix items before publishing assessment.',
        })
        return
      }

      // publish
      this.asmtSetPublishedAt()
        .then(() => {
          EventBus.$emit(Events.SNACKBAR, {
            type: 'success',
            text: 'Assessment has been published',
            timeout: 10000,
          })
          setTimeout(() => {
            window.location = `${this.appPublishUrl}&itembank_assessment_id=${this.asmtId}`
          }, 1000)
        })
        .catch((error) => EventBus.$emit(Events.ERROR, { type: CONST.ERROR, error, subtext: error.subtext }))
    },
    saveDraft() {
      EventBus.$emit(Events.SNACKBAR, {
        type: 'success',
        text: 'Assessment draft has been saved',
        timeout: 10000,
      })
      setTimeout(() => {
        window.location = `${this.appDraftUrl}`
      }, 1000)
    },
  },
}
</script>

<style lang="scss">
.build-asmt-actions {
  .actions-avatar {
    padding: 16px;
    font-size: 20px;
  }

  .v-list {
    padding-top: 8px;
  }

  .actions-content.v-card {
    font-family: 'CerebriSans', Roboto, sans-serif;
    box-shadow: none;
    border: 0;
    max-width: 320px;
    padding-right: 16px;

    .v-card__title.v-card__title--primary {
      padding: 0;
      padding-bottom: 0px;
      font-weight: bold;
    }

    .v-card__text {
      padding: 0 !important;
      font-size: 14px;
      // padding-bottom:10px;
    }
  }
  .discard-dialog {
    padding-right: 7px;
  }

  .actions-content {
    font-size: 14px;
  }
  .v-list__item__action {
    //width: 95%;
    padding-left: 16px;
    padding-right: 16px;
  }
  .action-btn {
    color: black !important;
  }

  .draft-items-warning {
    margin-top: 15px;
    font-size: 12px;
    font-weight: 600; // semibold
    color: #7e8494;
    text-align: center;

    .icon {
      color: #5a53c9;
      font-size: 16px;
      margin-right: 8px;
    }
  }

  .build-asmt-actions-item {
    $padding: 16px;

    display: flex;
    align-items: stretch;
    padding: $padding;
    border-bottom: 1px solid #e0e1e6;

    &:last-child {
      border-bottom: none;
    }

    &__icon {
      padding: $padding;
      font-size: 20px;
    }

    &__content {
      padding: 0 $padding;
      font-size: 14px;

      &__title {
        font-weight: 700;
      }

      &__text {
        font-weight: 400;
      }
    }

    &__action {
      padding: $padding;
      align-self: center;
    }
  }
}
</style>
