<template>
  <div class="editor">
    <TextEditorImageSelector ref="ytmodal" @onConfirm="addCommand" />

    <text-editor-video-dialog
      :show="showVideoDialog"
      :command="mediaDialogCommand"
      :updateCommand="mediaDialogUpdateCommand"
      :attributes="mediaDialogAttributes"
      @cancel="videoDialogCancel"
      @insert="videoDialogInsert"
      @update="videoDialogUpdate"
    />

    <text-editor-audio-dialog
      :show="showAudioDialog"
      :command="mediaDialogCommand"
      :updateCommand="mediaDialogUpdateCommand"
      :attributes="mediaDialogAttributes"
      @cancel="audioDialogCancel"
      @insert="audioDialogInsert"
      @update="audioDialogUpdate"
    />

    <EditorMenuBar :editor="editor" style="font-size: 14px">
      <div class="menubar" slot-scope="{ commands, isActive }">
        <div class="menubar__relative">
          <button class="menubar__button" :class="{ 'is-active': isActive.bold() }" @click="commands.bold" title="bold">
            <font-awesome-icon :icon="['fas', 'bold']" />
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.italic() }"
            @click="commands.italic"
            title="italic"
          >
            <font-awesome-icon :icon="['fas', 'italic']" />
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.strike() }"
            @click="commands.strike"
            title="strike"
          >
            <font-awesome-icon :icon="['fas', 'strikethrough']" />
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.underline() }"
            @click="commands.underline"
            title="underline"
          >
            <font-awesome-icon :icon="['fas', 'underline']" />
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 1 }) }"
            @click="commands.heading({ level: 1 })"
            title="header level 1"
          >
            <font-awesome-icon :icon="['fas', 'h1']" />
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 2 }) }"
            @click="commands.heading({ level: 2 })"
            title="header level 2"
          >
            <font-awesome-icon :icon="['fas', 'h2']" />
          </button>

          <button class="menubar__button" :class="{ 'is-active': isActive.blockquote() }" @click="commands.blockquote">
            <font-awesome-icon :icon="['fas', 'quote-right']" />
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.ordered_list() }"
            @click="commands.ordered_list"
          >
            <font-awesome-icon :icon="['fas', 'list-ol']" />
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.bullet_list() }"
            @click="commands.bullet_list"
          >
            <font-awesome-icon :icon="['fas', 'list-ul']" />
          </button>

          <button title="add image" class="menubar__button" @click="openModal(commands.image)">
            <svg width="14" height="14" viewBox="0 0 58 58">
              <path d="M57 6H1a1 1 0 0 0-1 1v44c0 .6.4 1 1 1h56c.6 0 1-.4 1-1V7c0-.6-.4-1-1-1zm-1 44H2V8h54v42z" />
              <path
                d="M16 28.1A5.6 5.6 0 1 0 16 17 5.6 5.6 0 0 0 16 28zm0-9.1a3.6 3.6 0 1 1 0 7.1 3.6 3.6 0 0 1 0-7.1zM7 46c.2 0 .5 0 .7-.2L24 31.4l10.3 10.3a1 1 0 1 0 1.4-1.4l-4.8-4.8 9.2-10 11.2 10.2a1 1 0 0 0 1.4-1.4l-12-11a1 1 0 0 0-1.4 0l-9.8 10.8-4.8-4.8a1 1 0 0 0-1.3 0l-17 15A1 1 0 0 0 7 46z"
              />
            </svg>
          </button>

          <button
            title="add video"
            class="menu_bar__button mr-2"
            ref="videoDialogButton"
            @click="openVideoDialog({ command: commands.video })"
          >
            <font-awesome-icon :icon="['fas', 'film-alt']" class="menubar__icon-video" />
          </button>

          <button
            title="add audio"
            class="menu_bar__button"
            ref="audioDialogButton"
            @click="openAudioDialog({ command: commands.video })"
          >
            <font-awesome-icon :icon="['fas', 'volume']" class="menubar__icon-audio" />
          </button>

          <button class="menubar__button" @click="commands.undo" title="undo">
            <font-awesome-icon :icon="['fas', 'undo']" />
          </button>

          <button class="menubar__button" @click="commands.redo" title="redo">
            <font-awesome-icon :icon="['fas', 'redo']" />
          </button>
        </div>
      </div>
    </EditorMenuBar>

    <EditorContent id="editorContent" :class="contentClass" :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Blockquote,
  Bold,
  Italic,
  Strike,
  Underline,
  Heading,
  Image,
  History,
  ListItem,
  BulletList,
  OrderedList,
} from 'tiptap-extensions'
import TextEditorImageSelector from '@/components/itemAuthor/passages/TextEditor/TextEditorImageSelector.vue'
import TextEditorVideo from '@/components/itemAuthor/passages/TextEditor/TextEditorVideo.ts'
import TextEditorVideoDialog from '@/components/itemAuthor/passages/TextEditor/TextEditorVideoDialog'
import TextEditorAudioDialog from '@/components/itemAuthor/passages/TextEditor/TextEditorAudioDialog'
import { Events, EventBus } from '@events'

export default {
  name: 'TextEditor',

  components: {
    EditorContent,
    EditorMenuBar,
    TextEditorImageSelector,
    TextEditorVideoDialog,
    TextEditorAudioDialog,
  },

  props: {
    content: { type: String, default: '' },
    valid: { type: Boolean, default: true },
  },

  data() {
    return {
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new Bold(),
          new Italic(),
          new Strike(),
          new Underline(),
          new Heading({ levels: [1, 2] }),
          new Image(),
          new History(),
          new ListItem(),
          new OrderedList(),
          new BulletList(),
          new TextEditorVideo(),
        ],
        content: '',
        onUpdate: ({ getHTML }) => {
          this.updated = true
          this.html = getHTML()
          this.initialized = true
        },
      }),
      initialized: false,
      html: '',
      updated: false,
      contentClass: 'editor__content',
      showVideoDialog: false,
      mediaDialogCommand: null,
      mediaDialogAttributes: null,
      mediaDialogUpdateCommand: null,
      showAudioDialog: false,
    }
  },

  methods: {
    openModal(command) {
      this.$refs.ytmodal.showModal(command)
    },
    addCommand(data) {
      if (data.command !== null) {
        data.command(data.data)
      }
    },

    setContent(value) {
      this.editor.setContent(value)
      if (value) this.html = this.editor.getHTML()
    },

    getContent() {
      return this.addBlockquoteCss(this.html)
    },

    getPassageData() {
      this.$emit('passageData', this.addBlockquoteCss(this.html))
    },

    addBlockquoteCss(html) {
      var style = '<blockquote style="border-left: 3px solid rgba(0, 0, 0, .1);padding-left: .8rem;">'
      return html.replace(new RegExp('<blockquote>', 'g'), style)
    },

    /**
     * Show embed video dialog
     * @param command editor command
     * @param data dialog initial data
     */
    openVideoDialog({ command, updateCommand = null, data = {} }) {
      this.mediaDialogCommand = command
      this.mediaDialogUpdateCommand = updateCommand
      this.showVideoDialog = true
      this.mediaDialogAttributes = data
    },

    /**
     * Cancel video dialog
     */
    videoDialogCancel() {
      this.showVideoDialog = false
    },

    /**
     * Insert Video from dialog
     */
    videoDialogInsert({ command, data }) {
      this.showVideoDialog = false
      this.addCommand({ command, data })
    },

    /**
     * Update Video from dialog
     */
    videoDialogUpdate({ updateCommand, data, reload = false }) {
      this.showVideoDialog = false
      if (updateCommand && typeof updateCommand == 'function') {
        updateCommand(data, reload)
      }
    },

    /**
     * Show embed audio dialog
     * @param command editor command
     * @param data dialog initial data
     */
    openAudioDialog({ command, updateCommand = null, data = {} }) {
      this.mediaDialogCommand = command
      this.mediaDialogUpdateCommand = updateCommand
      this.showAudioDialog = true
      this.mediaDialogAttributes = data
    },

    /**
     * Cancel Audio dialog
     */
    audioDialogCancel() {
      this.showAudioDialog = false
    },

    /**
     * Insert Audio from dialog
     */
    audioDialogInsert({ command, data }) {
      this.showAudioDialog = false
      this.addCommand({ command, data })
    },

    /**
     * Update Audio from dialog
     */
    audioDialogUpdate({ updateCommand, data }) {
      this.showAudioDialog = false
      if (updateCommand && typeof updateCommand == 'function') {
        updateCommand(data)
      }
    },

    /**
     * On show video dialog
     */
    onShowEmbedVideoDialog(data = {}) {
      this.openVideoDialog({
        command: this.editor.commands.video,
        updateCommand: data.updateCommand,
        data,
      })
    },

    /**
     * On show video dialog
     */
    onShowEmbedAudioDialog(data = {}) {
      this.openAudioDialog({
        command: this.editor.commands.video,
        updateCommand: data.updateCommand,
        data,
      })
    },
  },

  watch: {
    content(newContent) {
      this.setContent(newContent)
    },
    html(value) {
      if (this.initialized) this.$emit('contentHtml', value)
    },

    valid(v) {
      this.contentClass = v ? 'editor__content' : 'editor__content empty'
    },
  },

  created() {
    EventBus.$on(Events.SHOW_EMBED_VIDEO_DIALOG, this.onShowEmbedVideoDialog)
    EventBus.$on(Events.SHOW_EMBED_AUDIO_DIALOG, this.onShowEmbedAudioDialog)
  },

  beforeDestroy() {
    this.editor.destroy()
    EventBus.$off(Events.SHOW_EMBED_VIDEO_DIALOG, this.onShowEmbedVideoDialog)
    EventBus.$off(Events.SHOW_EMBED_AUDIO_DIALOG, this.onShowEmbedAudioDialog)
  },

  mounted() {
    this.setContent(this.content)

    if (!this.updated) {
      this.isEmpty = false
    }
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/main';
.menubar {
  display: flex;
  &__relative {
    position: relative;
    align-items: center;
    border: 1px solid rgb(192, 195, 207);
    background-color: white;
    border-left: 1px solid #c0c3cf;
    border-right: 1px solid #c0c3cf;
    border-radius: 4px 4px 0 0;
    width: 100%; //calc(100% - 40px);
  }

  &__icon-video {
    color: $N100;
  }

  &__icon-audio {
    color: $N100;
    font-size: 16px;
  }
}

.menubar__button {
  font-weight: 700;
  display: inline-block;
  width: 22px;
  height: 22px;
  background: rgba(0, 0, 0, 0);
  border: 0;
  padding: 0;
  color: #000;
  cursor: pointer;
  border-right: 1px solid #222;
  position: relative;
}

.editor__content {
  position: relative;
  width: 100%;
}
.editor__content.empty ::v-deep .ProseMirror {
  border: 1px solid red;
}

.editor__content ::v-deep .ProseMirror {
  text-align: left;
  /* background-color: rgb(242, 242, 242);  */
  border-left: 1px solid #c0c3cf;
  border-right: 1px solid #c0c3cf;
  border-bottom: 1px solid #c0c3cf;
  min-height: 200px;
  border-radius: 0 0 4px 4px;
  padding: 16px 10px;
  outline: none;
  max-height: 215px;
  overflow-y: auto;
  h1 {
    line-height: 1.3em !important;
    font-size: 36px;
  }
  h2 {
    line-height: 1.5em !important;
    font-size: 27px;
  }
  // needed for text editor
  blockquote {
    border-left: 3px solid rgba(0, 0, 0, 0.1);
    padding-left: 0.8rem;
  }
}

.warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
}

.menubar__button {
  background-color: white;
  border: 0px;
  border-radius: 5px 5px 0 0;
  width: 36px;
  height: 40px;
  color: #c0c3cf;
  outline: none;
}

.menubar__button.is-active {
  color: #000 !important;
  outline: none;
}

.editor {
  background-color: white;
  border: 0px solid #c0c3cf;
  border-radius: 0 0 5px 5px;
  line-height: 1em !important;
}

/* .editor__content {
    margin: 20px;
    background-color: white !important;
    border-color: white !important;
}

.ProseMirror {
    background-color: white !important;
    border-color: white !important;
} */

.editor__content .ProseMirror {
  background-color: white !important;
}

/* .editor:selection {
    background: yellow;
} */
</style>
