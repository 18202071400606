<template>
  <!-- create assessment modal -->
  <v-dialog class="ibx-create-assessment-modal" v-model="show" persistent max-width="500">
    <v-card class="x-create-assessment-modal__content" :class="classes">
      <v-card-title class="new-headline">Create a New Assessment</v-card-title>
      <span class="title-label">Assessment Title</span>
      <v-form v-model="valid" lazy-validation ref="titleForm" v-if="show" @submit="onFormSubmit">
        <v-card-text>
          <v-text-field
            v-if="show"
            v-model="title"
            class="new-assessment-text-field x-text-field tall"
            label="Untitled Assessment"
            placeholder="Untitled Assessment"
            type="text"
            autofocus
            single-line
            outlined
            :rules="nameRules"
            required
            color="#050F2D"
            :disabled="saving"
            @keyup.enter="onCreateAssessment"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <x-btn color="ghost" @click="onCancel" v-if="!saving">Cancel</x-btn>
          <x-btn color="primary" :disabled="saving || !valid" @click="onCreateAssessment">{{ createLabel }}</x-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import CONST from '@constants'
import { EventBus, Events } from '@events'
import { AsmtMixin } from '@mixins'
import * as VDialog from 'vuetify/es5/components/VDialog'
import * as VTextField from 'vuetify/es5/components/VTextField'
import * as VCard from 'vuetify/es5/components/VCard'
import * as VForm from 'vuetify/es5/components/VForm'

export default {
  name: 'IbxCreateAssessmentModal',

  mixins: [AsmtMixin],

  components: {
    ...VDialog,
    ...VTextField,
    ...VCard,
    ...VForm,
  },

  data: () => ({
    show: false,
    saving: false,
    valid: true,
    nameRules: [
      (v) => !!v || 'A title is required',
      (v) => (v && v.length <= 255) || 'Title must be less than 255 characters',
    ],
    title: null,
  }),

  watch: {
    show(v) {
      if (!v) {
        this.title = null
      }
    },
    title(newVal) {
      // remove anything not supported by UTF-8
      var specialChars = CONST.unicodeRegex
      if (specialChars.test(newVal)) {
        this.$nextTick(() => this.$set(this, 'title', newVal.replace(specialChars, '')))
      }
    },
  },

  computed: {
    classes() {
      return this.saving ? ['ibx-create-assessment-modal__content--saving'] : []
    },
    createLabel() {
      return this.saving ? 'Saving...' : 'Create'
    },
  },

  methods: {
    onCreateAssessment(e) {
      e?.preventDefault()
      this.saving = true
      this.asmtSetTitle(this.title)
        .then(() => this.asmtCreateAssessment())
        .then(() => (this.show = false))
        .then(() => EventBus.$emit(Events.ROUTE_GOTO, { name: 'assessment' }))
        .catch((error) => {
          this.saving = false
          EventBus.$emit(Events.ERROR, {
            type: CONST.ERROR,
            error,
            timeout: 4000,
          })
          this.$refs.titleForm.validate()
        })
        .finally(() => (this.saving = false))
      return false
    },

    onFormSubmit(e) {
      e?.preventDefault()
    },

    onCancel() {
      EventBus.$emit(Events.CREATE_ASSESSMENT_CANCEL)
      this.show = false
    },
  },

  created() {
    EventBus.$on(Events.CREATE_ASSESSMENT, () => {
      this.show = true
    })
  },
}
</script>

<style lang="scss">
@import '@/styles/main';
.ibx-create-assessment-modal__content {
  &--saving {
    opacity: 0.9;
  }
}

.new-headline {
  font-family: 'CerebriSans', Roboto, sans-serif;
  font-size: 20px !important;
  font-weight: 500 !important;
}

.new-assessment-text-field .v-input__slot {
  border: 1px solid !important;
  margin: 0;
  font-weight: 600;
}

.new-assessment-text-field-hidden {
  display: none;
}

.theme--light.v-input:not(.v-input--is-disabled) input {
  color: $N130 !important;
}

.ibx .v-card .v-card__text {
  padding: 5px 20px 0px 20px !important;
}

.title-label {
  margin: 0px 0px 0px 20px;
  font-weight: 400;
}

.v-messages__message {
  margin-left: -10px;
  margin-top: 4px;
}
</style>
