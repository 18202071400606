<template>
  <div class="item-author-banks">
    <div class="item-author-banks__title" v-if="banks.length">Item Banks</div>
    <div v-for="(o, i) in banks" :key="i" class="item-author-banks--bank">
      <v-switch
        class="item-author-banks--bank__switch"
        :ripple="false"
        :input-value="selectedBanks.some((bankId) => bankId == o.bankId)"
        @change="(selected) => onChange(o.bankId, selected)"
      ></v-switch>
      <span class="item-author-banks--bank__label">{{ o.name }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import _ from 'lodash'

export default {
  name: 'ItemAthorBanks',

  computed: {
    ...mapGetters('itemAuthor', ['banks', 'selectedBanks']),
    ...mapGetters('browseFilters', {
      browseFilters: 'filters',
    }),
  },

  methods: {
    ...mapActions('itemAuthor', ['setSelectedBanks']),
    ...mapMutations('authorItems', ['setBanksChanged']),
    onChange(id, selected) {
      const banks = selected ? _.uniq([...this.selectedBanks, id]) : this.selectedBanks.filter((v) => v != id)
      this.setSelectedBanks(banks)
      this.setBanksChanged(true)
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.item-author-banks {
  &__title {
    font-weight: 700;
  }

  .item-author-banks--bank {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 8px;

    &__switch {
      width: auto;
      flex: none;
      margin: 0px;
    }

    &__label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 200px;
      padding-left: 16px;
      font-size: 14px;
    }

    .v-messages {
      display: none;
    }
  }
}
</style>
