
// @ts-nocheck
import { ItemMixin, SavingMessageMixin, PermsMixin, AsmtMixin } from '@mixins'
import { XContentCard, XContentCardHeader } from '@xLib/XContentCard'
import IbxItem from '@components/ibx/IbxItem'
import IbxAsmtItemValidation from './IbxAsmtItemValidation'
import IbxCardMeta from '@components/ibx/cardMeta/IbxCardMeta'
import { Events, EventBus } from '@events'
import { VTextField } from 'vuetify'
import BuildStandards from '@components/build/BuildStandards'
import BuildQuestionGroups from '@components/build/BuildQuestionGroups'
import * as VCheckbox from 'vuetify/es5/components/VCheckbox'
import * as VChip from 'vuetify/es5/components/VChip'
import * as VTooltip from 'vuetify/es5/components/VTooltip'
import IBXService from '@services/ibx/IBXService'
import { CONST, FLAG } from '@constants'
import { mapActions, mapGetters } from 'vuex'
import * as VList from 'vuetify/es5/components/VList'
import * as VDivider from 'vuetify/es5/components/VDivider'
import { getMoment } from '@helpers'
import XSwitch from '@xLib/XSwitch'
import { hasPartialScoring } from '@helpers/itemHelpers'
import { checkIfSimpleRubric } from '@helpers/itemHelpers'
let moment

export default {
  name: 'IbxAssessmentItemCard',

  mixins: [ItemMixin, SavingMessageMixin, PermsMixin, AsmtMixin],

  components: {
    XContentCard,
    IbxItem,
    IbxCardMeta,
    IbxAsmtItemValidation,
    VTextField,
    BuildStandards,
    BuildQuestionGroups,
    XSwitch,
    ...VCheckbox,
    ...VChip,
    ...VTooltip,
    ...VList,
    ...VDivider,
  },

  props: {
    id: { type: [String, Number], default: null }, // unique id
    index: { type: Number, default: 0 }, // index when part of list
    actions: { type: Array, defualt: () => null }, // user defined action buttons
    asmtItem: { type: Object, defualt: () => ({}) }, // assessment item data
    passageRevId: { type: [String, Number], default: null }, // linked passageId
  },

  data: () => ({
    ready: false,
    itemConfig: null,
    hasSimpleRubric: false,
  }),

  computed: {
    ...mapGetters('ui', ['isItemFullScreen']),
    ...mapGetters('features', {
      featureFlag: 'flag',
      supportedItemTypes: 'supportedItemTypes',
    }),
    ...mapGetters('asmt', ['checkAllAsmtItemLockStatus']),
    getItemData() {
      return {
        remoteId: this.itemVendorItemId,
      }
    },
    supportsPartialScoring() {
      return (
        this.featureFlag(FLAG.PARTIAL_SCORING) &&
        this.featureFlag(FLAG.PARTIAL_SCORING_ITEM_TYPES).items.includes(this.item?.itemType)
      )
    },
    FLAG() {
      return FLAG
    },
    getDetails() {
      return []
    },
    getStandards() {
      return this.itemStandard
    },
    partialScoringClass() {
      const classes = ['ibx-asmt-item-card__partial-scoring']

      if (!this.canEditPartialScoring) {
        classes.push('ibx-asmt-item-card__partial-scoring--disabled')
      } else if (this.itemPartialScoring) {
        classes.push('ibx-asmt-item-card__partial-scoring--on')
      }
      if (this.permIsPublished || this.permIsViewOnly) {
        classes.push('ibx-asmt-item-card__partial-scoring--disabled')
      }

      return classes
    },
    getAuxilliaryActions() {
      if (this.permIsViewOnly) return []
      const actions = [
        {
          id: Events.EDIT_ITEM,
          name: 'Edit Item',
          icon: 'far fa-edit',
          disabled: this.hasSimpleRubric
            ? false
            : this.item.itemType == 'constructed_response' && this.item.published
            ? true
            : false,
        },
        {
          id: Events.DUPLICATE_ITEM,
          name: 'Duplicate Item',
          icon: 'far fa-copy',
        },
        {
          id: Events.REMOVE_ITEMS,
          name: 'Delete Item',
          icon: 'far fa-trash',
        },
      ]
      if (this.checkFeedbackPerm) {
        actions.push({
          id: Events.REPORT_ISSUE,
          name: 'Report an Issue',
          icon: 'far fa-exclamation-circle',
        })
      }
      if (this.permUserCanEditItem(this.itemUserId)) {
        return this.itemIsDraft ? actions.filter((o) => o.id != Events.DUPLICATE_ITEM) : actions
      }
      // Enabling duplicate for publihser bank if permission.
      else if (
        this.checkIfCanDuplicate &&
        this.supportedItemTypes[this.item.itemType] &&
        this.featureFlag(FLAG.CAN_DUPLICATE_ITEMS)
      ) {
        return actions.filter((o) => o.id != Events.EDIT_ITEM)
      } else {
        return actions.filter((o) => o.id != Events.EDIT_ITEM && o.id != Events.DUPLICATE_ITEM)
      }
    },
    inUse() {
      return this.item.inUse || false
    },
    weight: {
      get() {
        return this.asmtItem.weight
      },
      set(value) {
        let v = parseFloat(value)
        if (v < 0) v = 1
        if (v > 100) v = 100

        // update ui before saving
        this.setItemWeight({ itemRevId: this.itemRevId, value: v })
        this.onChange({ key: 'weight', value: v })
      },
    },
    extraCredit: {
      get() {
        return this.asmtItem.extraCredit || false
      },
      set(value) {
        this.onChange({ key: 'extraCredit', value: Boolean(value) })
      },
    },
    locked() {
      return this.asmtItem.item.locked || false
    },
    questionLabel() {
      return `Q${this.asmtItem.order + 1}`
    },
    getMenuZIndex() {
      // for v-menu to show in ui, but not above ibx header
      return this.isItemFullScreen ? 9999 : 7
    },
    showLastEditedNotice() {
      return this.asmtIsPublished && moment(this.itemUpdatedDate).isAfter(this.asmtPublishedAt)
    },
    itemUpdatedAtLabel() {
      return moment(this.itemUpdatedDate).format('MM/DD/YYYY LT')
    },
    itemPartialScoring() {
      return this.asmtItem.partialScoring
    },
    canEditWeight() {
      return !['constructed_response', 'drawing_response'].includes(this.asmtItem.item.itemType)
    },
    canEditPartialScoring() {
      return this.itemConfig ? hasPartialScoring(this.itemConfig) : false
    },
    disablePartialScoring() {
      return this.permIsPublished || this.permIsViewOnly ? false : true
    },
  },

  methods: {
    ...mapActions('asmt', ['setItemWeight', 'setItemPartialScoring', 'setLockItem']),
    ...mapActions('asmtSettings', {
      setLocked: 'setLocked',
    }),
    ...mapActions('browseItems', {
      browseItemsSetStale: 'setStale',
    }),
    ...mapActions('itemConfigs', ['getItemConfig']),
    onWeightKeyup(e) {
      if (e.key == '-') {
        e.target.value = e.target.value.replace(/-/g, '')
        e.preventDefault()
        return false
      }
    },
    onAction({ id }) {
      if (id == Events.EDIT_ITEM || id == Events.DUPLICATE_ITEM) {
        EventBus.$emit(id, {
          itemData: this.item,
        })
      } else {
        this.$emit(Events.ASSESSMENT_ITEM_ACTION, {
          actionId: id,
          itemRevId: this.itemRevId,
          data: null,
        })
      }
    },
    onLoaded(onAsyncContentLoaded) {
      if (typeof onAsyncContentLoaded === 'function') {
        onAsyncContentLoaded()
      }
      this.$emit(Events.LOADED, { index: this.index, id: this.id })
    },
    onPlayerError(onAsyncContentLoaded, error) {
      this.onLoaded(onAsyncContentLoaded)
    },
    onPlayerConfigLoaded(config) {
      if (config?.contentItem) {
        this.hasSimpleRubric = checkIfSimpleRubric(config?.contentItem?.config)
      }
    },
    onLockItem(value) {
      this.browseItemsSetStale(true)
      this.smSaving()
      IBXService.updateItems({
        itemId: this.item.itemId,
        props: { locked: value },
      })
        .then(() => {
          this.smSaved()
          EventBus.$emit(Events.SNACKBAR, {
            type: 'success',
            text: value ? 'Item Locked' : 'Item Unlocked',
            timeout: 10000,
          })
        })
        .then(() => {
          this.setLockItem({ itemRevId: this.item.itemRevId, locked: value })
          this.setLocked(this.checkAllAsmtItemLockStatus)
        })
        .catch((error) => {
          EventBus.$emit(Events.ERROR, {
            type: CONST.ERROR,
            error,
            text: 'Failed to lock item',
            subtext: 'Please refresh the page',
          })
        })
    },
    onChange({ key, value }) {
      EventBus.$emit(Events.ASSESSMENT_ITEM_CHANGE, {
        itemRevId: this.itemRevId,
        key,
        value,
      })
    },
    onAuxilliaryActionClick(aux, index) {
      if (aux.id == Events.EDIT_ITEM && aux.disabled) {
        return
      }
      let id = null
      if (aux.id === 'edit-item') {
        id = Events.EDIT_ITEM
      } else if (aux.id === 'remove-items') {
        id = Events.REMOVE_ITEMS
      } else if (aux.id === 'duplicate-item') {
        id = Events.DUPLICATE_ITEM
      } else if (aux.id == Events.REPORT_ISSUE) {
        EventBus.$emit(Events.REPORT_ISSUE, {
          itemData: this.item,
        })
      }
      this.onAction({ id })
    },
    onUpdatePartialScoring(value) {
      if (this.supportsPartialScoring) {
        this.setItemPartialScoring({
          itemRevId: this.itemRevId,
          value: value,
        })
        this.onChange({ key: 'partialScoring', value: value })
      }
    },
    checkIfDisabled(aux) {
      return aux.id == Events.EDIT_ITEM && aux.disabled ? true : false
    },
  },

  async mounted() {
    moment = await getMoment()
    const { data } = await this.getItemConfig({ versionedId: this.asmtItem.item.remoteIdVersioned })
    this.itemConfig = data?.contentItem?.config
    this.ready = true
    this.itemInit()
  },
}
