<template>
  <v-layout class="build-discard-asmt-dialog" row justify-center>
    <v-dialog v-model="showDialog" persistent max-width="600">
      <template v-slot:activator="{ on }">
        <x-btn v-on="on" color="tertiary">Discard</x-btn>
      </template>
      <v-card>
        <v-card-title class="headline">
          Discard Changes?
          <v-spacer></v-spacer>
          <font-awesome-icon :icon="['fal', 'times']" class="icon" @click="showDialog = false" />
        </v-card-title>
        <v-card-text>
          Leaving this page without saving will discard any changes you have made. To save your changes and revisit this
          assessment later, consider saving this assessment as a draft.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :style="cancelBtnAttrs" text @click="showDialog = false">Cancel</v-btn>
          <v-btn :style="discardBtnAttrs" light text @click="discardAsmt(), (showDialog = false)"
            >Discard Changes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import * as VDialog from 'vuetify/es5/components/VDialog'
import { mapGetters } from 'vuex'
import { AsmtMixin } from '@mixins'

export default {
  name: 'BuildDiscardAsmtDialog',

  mixins: [AsmtMixin],

  components: {
    ...VDialog,
  },

  data: () => ({
    showDialog: false,
  }),

  computed: {
    ...mapGetters('config', ['appExitUrl', 'appPublishUrl', 'appDraftUrl']),
    discardBtnAttrs() {
      return {
        'background-color': '#F02D1F',
        color: '#FFFFFF',
      }
    },
    cancelBtnAttrs() {
      return {
        'background-color': '#FFFFFF',
        color: '#050F2D',
      }
    },
    dialogBtnAttrs() {
      return {
        'background-color': '#FFFFFF',
        color: '#050F2D',
      }
    },
  },

  methods: {
    discardAsmt() {
      this.asmtDelete().then(() => {
        window.location = `${process.env.VUE_APP_BASE_URI}`
      })
    },
  },
}
</script>

<style lang="scss">
.build-discard-asmt-dialog {
}
</style>
