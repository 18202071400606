
// @ts-nocheck
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'
import { Events, EventBus } from '@events'
import CONST from '@constants'

@Component({
  computed: {
    ...mapGetters('asmtSettings', {
      asmtPrintOptions: 'printOptions',
    }),
    ...mapGetters('user', {
      userUserSelection: 'userSelection',
    }),
  },
  methods: {
    ...mapActions('asmtSettings', {
      asmtSetPrintOptions: 'setPrintOptions',
    }),
  },
})
export default class AsmtPrintSetting extends Mixins(Vue) {
  @Prop({ default: false }) hasResponseItem: boolean
  private defaultPrintConfig: Object = {
    'Print Mode': {
      type: 'select',
      items: [
        { settingId: 'instructor', settingName: 'Instructor' },
        { settingId: 'student', settingName: 'Student' },
      ],
      field: 'role',
      label: 'Select an option',
    },
    Layout: {
      type: 'select',
      items: [
        { settingId: '12', settingName: 'One Col' },
        { settingId: '6', settingName: 'Two Col' },
      ],
      field: 'layout',
      label: 'Select an option',
    },
    'Choice Spacing': {
      type: 'select',
      items: [
        { settingId: 'small', settingName: 'Small' },
        { settingId: 'medium', settingName: 'Medium' },
        { settingId: 'large', settingName: 'Large' },
      ],
      field: 'spacing',
      label: 'Select an option',
    },
    'Font Size': {
      type: 'select',
      items: [
        { settingId: '8pt', settingName: 'Tiny-8pt' },
        { settingId: '10pt', settingName: 'Small-10pt' },
        { settingId: '12pt', settingName: 'Med-12pt' },
        { settingId: '14pt', settingName: 'L-14pt' },
        { settingId: '16pt', settingName: 'XL-16pt' },
      ],
      field: 'fontSize',
      label: 'Select an option',
    },
  }
  private responseAreaConfig: Object = {
    'Response Area': {
      type: 'select',
      items: [
        { settingId: 'show-response-area-editor', settingName: 'Show' },
        { settingId: 'hide-response-area-editor', settingName: 'Hide' },
      ],
      field: 'responseArea',
      label: 'Select an option',
    },
  }
  get printConfig() {
    return this.hasResponseItem === true
      ? { ...this.defaultPrintConfig, ...this.responseAreaConfig }
      : this.defaultPrintConfig
  }
  emitOptionChange() {
    EventBus.$emit(Events.PRINT_OPTIONS_CHANGE)
  }
  setPrintModeField(value, field) {
    this.asmtSetPrintOptions({ ...this.asmtPrintOptions, [field]: value })
    this.emitOptionChange()
  }
  getPrintModeValues(field) {
    if (this.asmtPrintOptions) return this.asmtPrintOptions[field]
  }
  mounted() {
    this.init()
  }
  init() {
    const userSelection = this.userUserSelection(CONST.CONTEXT_PRINT_OPTIONS).value
    if (userSelection.printOptions) this.asmtSetPrintOptions(userSelection.printOptions)
  }
}
