<template>
  <content-loader
    :speed="2"
    :animate="true"
    :height="totalHeight"
    :primaryColor="primaryColor"
    :secondaryColor="secondaryColor"
  >
    <rect v-for="(height, i) in items" :key="i" x="0" :y="getY(i)" rx="2" ry="2" width="100%" :height="height" />
  </content-loader>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
  name: 'IbxLoadingList',

  components: {
    ContentLoader,
  },

  props: {
    items: { type: Array, default: () => [80, 80, 80] },
    margin: { type: Number, default: 20 },
    primaryColor: { type: String, default: '#E0E1E6' },
    secondaryColor: { type: String, default: '#d2d4df' },
  },

  computed: {
    totalHeight() {
      return this.items.reduce((total, height, i) => {
        return total + height + i * this.margin
      }, 0)
    },
  },

  methods: {
    getY(index) {
      return this.items.slice(0, index).reduce((m, h) => m + h + this.margin, 0)
    },
  },
}
</script>
