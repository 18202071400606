
// @ts-nocheck
import * as VCheckbox from 'vuetify/es5/components/VCheckbox'
import { mapGetters, mapActions } from 'vuex'
import TextEditor from '@components/itemAuthor/passages/TextEditor/TextEditor'
import * as VTextField from 'vuetify/es5/components/VTextField'
import * as VDivider from 'vuetify/es5/components/VDivider'
import * as VRadioGroup from 'vuetify/es5/components/VRadioGroup'
import * as VProgressCircular from 'vuetify/es5/components/VProgressCircular'
import * as VForm from 'vuetify/es5/components/VForm'

import IBXService from '@services/ibx/IBXService'
import PIEService from '@services/PIEService'
import CONST from '@constants'
import { EventBus, Events } from '@events'

// new

import { Vue, Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { StateAuthorItems, StateAuth, StateAuthorPassages } from '@/helpers/state'
import { Id } from '@/components/ibx/base/Types'
import { PassageAuthor, IPassageData } from '@/components/ibx/base/Passage'
import IbxAppNotification from '@components/ibx/IbxAppNotification'
import ItemAuthorPassageConfirmDialog from '@components/itemAuthor/passages/AuthorPassageConfirmDialog'
import { PieAuthorPassageService } from '@/services/pie'
import _ from 'lodash'

@Component({
  components: {
    ...VCheckbox,
    ...VTextField,
    ...VDivider,
    ...VRadioGroup,
    ...VProgressCircular,
    ...VForm,
    TextEditor,
    ItemAuthorPassageConfirmDialog,
    IbxAppNotification,
  },
  computed: {
    ...mapGetters('auth', {
      authPieToken: 'pieToken',
    }),
    ...mapGetters('passageAuthor', [
      'attributes',
      'attributeValues',
      'getAttributeValue',
      'allowedAdvancedAttributes',
      'contentLanguages',
      'advancedSettings',
    ]),
    ...mapGetters('itemAuthor', ['selectedBanks']),
  },
  methods: {
    ...mapActions('passageAuthor', {
      passageAuthorInit: 'init',
    }),
    ...mapActions('itemConfigs', ['clearCache']),
  },
})
export default class PassageEditor extends Mixins(Vue, StateAuth, StateAuthorPassages) {
  @Prop({ default: false }) passageExpanded: boolean
  @Prop({ default: null }) passage: PassageAuthor
  @Prop({ default: true }) linkPassage: boolean
  @Prop({ default: false }) passageSaveConfirmAction: boolean
  @Prop({ default: false }) fullHeightModel: boolean
  @Prop({ default: false }) hideSaveActions: boolean

  private passageContent = ''
  private rules = {
    required: (value) => !!value || 'Required',
    max: (value) => (value && value.length <= 255) || 'Max 255 characters',
  }
  private linked = true
  private displayPassageTitles = 'yes'
  private lexile = ''
  private wordCount = ''
  private currentPassage = null
  private metaToSave = {}
  private isSavingPassage = false
  private validTitle = true
  private validMeta = true
  private validContent = true
  private disableSave = true
  private language = null
  private errorMessage = null
  private isAdvancedSettingsExpanded = false
  private piePassageService: PieAuthorPassageService = null
  private onContentChange = _.debounce(this.onChange, 500)
  private onTitleChange = _.debounce(this.onChange, 500)
  private initialized: boolean = false
  private passageExitConfirmAction: string = null

  @Watch('disableSave')
  onDisableSave(v) {
    this.$emit('disable-save', v)
  }

  get passageTitle(): string {
    return this?.passage?.title || ''
  }
  get passageItemCount(): string {
    return (this?.passage?.items || []).length
  }
  set passageTitle(value: string) {
    this.passage?.set('title', value)
    this.onTitleChange()
  }

  get getSelectedBanks() {
    return this.selectedBanks.map((id) => ({ id: String(id) }))
  }

  /**
   * Passage remoteId
   */
  get remoteId(): Id {
    return this.passage?.remoteId || null
  }

  /**
   * Get passageRevId
   */
  get passageRevId(): number | null {
    return this?.passage?.passageRevId ? Number(this?.passage?.passageRevId) : null
  }

  get passageId(): number {
    return this?.passage?.passageId
  }

  /**
   * If passage exists
   */
  get editMode(): boolean {
    return Boolean(this.remoteId)
  }

  /**
   * Get passage body from editor
   */
  get body(): string {
    return this?.$refs?.editor?.getContent() || ''
  }

  /**
   * Get passage meta
   */
  get meta(): any {
    return this.passage.meta
  }

  /**
   * Pie passage config
   */
  get config(): any {
    return {
      buildInfo: [
        {
          name: '@pie-element/passage',
          version: 'latest',
        },
      ],
      layout: 'layout',
      elements: {
        'pie-passage': '@pie-element/passage@latest',
      },
      models: [
        {
          id: 'p-00000000',
          element: 'pie-passage',
          passages: [
            {
              title: this.passageTitle,
              text: this.body,
            },
          ],
        },
      ],
      markup: "<pie-passage id='p-00000000'></pie-passage>",
    }
  }

  /**
   * Get class for expanded passage
   */
  get expandedClass(): string[] {
    return this.passageExpanded ? ['expanded'] : []
  }

  /**
   * Get class for parent div
   */
  get getParentPassageAddNewClass(): string[] {
    return this.fullHeightModel ? ['author-passage-add-new-full-height'] : ['author-passage-add-new']
  }

  /**
   * Emit passage changed when
   * passage mutates prior to saving
   */
  passageChanged(changed: boolean) {
    if (this.initialized) this.$emit('passage-changed', changed)
  }

  /**
   * Check validity and enable/disable saving.
   * Emit passage-changed value
   *
   */
  onChange() {
    this.errorMessage = null
    if (this.isValid()) {
      this.disableSave = false
      this.passageChanged(true)
    } else {
      this.disableSave = true
    }
  }

  async getRemotePassage(versionedID) {
    let query =
      `
        query {
                stimulus( versionedID: "` +
      versionedID +
      `"){
                    id
                    name
                    config
                }
            }
        `

    return await PIEService.query({
      query: query,
      variables: {
        versionedID: versionedID,
      },
    })
  }

  async onNotificationCancel() {
    this.errorMessage = null
  }
  /**
   * Take confirmed action from passage exit dialog
   * @param action action to that was confirmed
   * @param save save or cancel flag
   */
  async onPassageEditorConfirmAction({ action, save }: { action: string; save: boolean }) {
    this.passageExitConfirmAction = null
    if (action == 'save' && save) {
      await this.savePassage(false)
      return
    }
    // In case discard confirmation, emit cancel-confirmed.
    this.isSavingPassage = false
    this.$emit('cancel-confirmed')
  }
  /**
   * Validate and save (upsert) passage
   */
  async savePassage(passageSaveConfirmAction) {
    let passage = null
    this.errorMessage = null
    if (passageSaveConfirmAction) {
      this.passageExitConfirmAction = 'save'
      return
    }
    if (this.isValid()) {
      this.isSavingPassage = true
      this.$emit('saving', true)
      // create or update passage
      try {
        if (this.editMode) {
          const data = await this.updatePassage()
          if (!data.passage) throw CONST.PASSAGE_UPDATE_ERROR
          passage = data.passage
        } else {
          const data = await this.createPassage()
          if (!data.passage) throw CONST.PASSAGE_CREATE_ERROR
          passage = data.passage
        }
      } catch (error) {
        EventBus.$emit(Events.ERROR, { type: CONST.ERROR, error })
        console.warn(error)
        this.errorMessage = this.editMode ? CONST.PASSAGE_UPDATE_ERROR : CONST.PASSAGE_CREATE_ERROR
      } finally {
        this.isSavingPassage = false
        this.$emit('saving', false)
        this.passageChanged(false)
      }
      if (this.errorMessage != null) {
        return this.errorMessage
      }
      // emit passage aff
      this.$emit('update-and-link-passage', {
        passage,
        link: this.linked,
      })
    }
  }

  /**
   * Create passage
   * @returns Promise<data: { id }> remote Id
   */
  async createPassage(): Promise<any> {
    const { data } = await this.createPiePassage()
    if (!data?.id) throw 'Remote passage not created'
    return this.createIbxPassage(data.id)
  }

  /**
   * Create Pie passage
   * @returns Promise<data: { id }> remote Id
   */
  async createPiePassage(): Promise<any> {
    return await this.piePassageService.savePassage({
      versionedID: '',
      config: this.config,
      release: true,
    })
  }

  /**
   * Create Ibx passage
   * @param remoteId pie stimules id
   * @returns Promise<Ibx passage data>
   */
  async createIbxPassage(remoteId: string) {
    return await IBXService.passageCreate({
      remoteId: remoteId,
      version: '',
      title: this.passageTitle,
      sections: [{ body: this.body || '' }],
      published: true,
      meta: Object.assign({}, this.meta, this.metaToSave), // refactor with meta data redo
    })
  }

  /**
   * Update Pie and Ibx passage
   * @returns Promise<Ibx passage data>
   */
  async updatePassage(): Promise<any> {
    const { data } = await this.updatePiePassage()
    if (!data?.id) throw 'Remote passage not updated'
    return this.updateIbxPassage()
  }

  /**
   * Update Ibx passage
   * @returns Promise<data: { id }> remote Id
   */
  async updatePiePassage(): Promise<any> {
    if (!this.remoteId) throw 'remoteId required for passage update'
    this.clearCache(this.remoteId)

    return await this.piePassageService.savePassage({
      versionedID: this.remoteId,
      config: this.config,
      release: true,
    })
  }

  /**
   * Update Ibx passage
   * @returns Promise<Ibx passage data>
   */
  async updateIbxPassage(): Promise<any> {
    if (!this.passage.passageId) throw 'passageId required for passage upadate'

    return await IBXService.passageRevisionCreate(this.passage.passageId, {
      version: '',
      title: this.passageTitle,
      sections: [{ body: this.body || '' }],
      published: true,
      meta: Object.assign({}, this.meta, this.metaToSave), // this needs endpoint update
    })
  }

  onCancel() {
    this.isSavingPassage = false
    this.$emit('cancel')
  }

  getMetaValue(field) {
    if (this.passage && this.passage.meta && this.passage.meta[field]) {
      if (field == 'display_passage_titles') return this.passage.meta[field].items[0].value
      else return Number(this.passage.meta[field].items[0].id)
    }
  }

  setMetaValue(id, field) {
    this.metaToSave[field] = id !== undefined && id !== null ? { items: [{ id: String(id) }] } : { items: [] }

    this.$refs?.passageMeta?.validate()
    this.$nextTick(() => this.onChange())
  }

  async pieGetPassageContent(remoteId) {
    let remotePassage = await this.getRemotePassage(remoteId)
    let isError = false
    let errorText = 'Unable to load passage content.'

    if (remotePassage && remotePassage.stimulus && remotePassage.stimulus.config) {
      let config = remotePassage.stimulus.config
      if (config.models.length == 1 && config.models[0].passages.length == 1) {
        this.passageContent = config.models[0].passages[0].text
      } else {
        isError = true
      }
    } else {
      isError = true
    }

    if (isError) {
      this.passageContent = errorText
    }
  }

  isValid() {
    this.$refs.passageTitle.validate()
    this.$refs.passageMeta.validate()
    var v = this.$refs.editor.getContent()
    this.validContent = !v || v == '' || v == '<p></p>' ? false : true
    return this.validTitle && this.validMeta && this.validContent
  }

  toggleAdvancedSettings() {
    this.isAdvancedSettingsExpanded = !this.isAdvancedSettingsExpanded
  }

  setDefaults() {
    const english = this.contentLanguages.items.find((o) => o.name == 'English')

    if (this.passage && !this.passageRevId && english) {
      // set language default
      this.passage.dataObj.meta.language = {
        id: 'language',
        items: [{ id: String(english.id) }],
      }
    }
  }

  /**
   * Vue hook
   */
  created() {
    this.piePassageService = new PieAuthorPassageService({
      token: this.authPieToken,
      apiUrl: process.env.VUE_APP_PIE_URI,
    })
  }

  /**
   * Vue hook
   */
  async mounted() {
    await this.passageAuthorInit()
    if (this.remoteId) await this.pieGetPassageContent(this.remoteId)
    if (this.fullHeightModel) this.isAdvancedSettingsExpanded = true
    this.setDefaults()
    this.initialized = true
  }
}
