import { IBXService } from '@services/ibx/IBXService'
import { StoreOptions } from 'vuex'
import _ from 'lodash'
import Vue from 'vue'
import { itemTypesMap } from '@/helpers'

interface IState {
  year: string[] | number[]
  scope: string[]
  subject: string[]
  grade: string[]
  autoQuestionGroups: object[]
  printOptions: object
  locked: boolean
}

interface Tag {
  typeId: string
  tags: object[]
}

class State implements IState {
  year: string[] = []
  scope: string[] = []
  subject: string[] = []
  grade: string[] = []
  autoQuestionGroups: object[] = []
  printOptions: object = {
    role: 'student',
    layout: '12',
    responseArea: 'hide-response-area-editor',
    spacing: 'medium',
    fontSize: '12pt',
  }
  locked: boolean = false
}

const asmtSettings = {
  namespaced: true,

  state: new State(),

  getters: {
    year: (state: IState) => {
      return _.orderBy(state.year, 'tagId', 'desc')
    },
    scope: (state: IState) => state.scope,
    subject: (state: IState) => state.subject,
    grade: (state: IState) => state.grade,
    printOptions: (state: IState) => state.printOptions,
    autoQuestionGroups: (state: IState) => {
      return _.orderBy(state.autoQuestionGroups, 'name', 'asc')
    },
    enabledAutoQuestionGroupIds: (state: IState) => {
      return state.autoQuestionGroups.filter((o: any) => o.enabled).map((o: any) => o.autoQuestionGroupId)
    },
    locked: (state: IState) => state.locked,
    itemTypeQuestionGroupsEnabled: (state: IState, getters: any, rootState: any, rootGetters: any): boolean => {
      return rootGetters['asmt/items']
        .flatMap((q) => q.customQuestionGroups)
        .toString()
        .includes('Item Type:')
    },
  },

  mutations: {
    setYear: (state: IState, value: string[] = []) => {
      state.year = value
    },
    setScope: (state: IState, value: string[] = []) => {
      state.scope = value
    },
    setSubject: (state: IState, value: string[] = []) => {
      state.subject = value
    },
    setGrade: (state: IState, value: string[] = []) => {
      state.grade = value
    },
    setAutoQuestionGroups: (state: IState, value: object[] = []) => {
      state.autoQuestionGroups = value
    },
    enableAutoQuestionGroups: (state: IState, { autoQuestionGroupId, enabled }) => {
      const qg = state.autoQuestionGroups.find((o: any) => o.autoQuestionGroupId == autoQuestionGroupId)
      if (qg) {
        Vue.set(qg, 'enabled', enabled)
      }
    },
    setPrintOptions: (state: IState, value: object = {}) => {
      state.printOptions = value
    },
    setLocked: (state: IState, value) => {
      state.locked = value
    },
    setItemTypeQuestionGroupEnabled: (state: IState, value: boolean) => {
      const itemTypeQGs = state.autoQuestionGroups.find((o: any) => o.autoQuestionGroupId == 'item_type')
      if (itemTypeQGs) {
        Vue.set(itemTypeQGs, 'enabled', value)
      }
    },
  },

  actions: {
    init: async ({ commit }) => {
      const tags = await IBXService.assessmentTags()
      tags.tags.forEach((tag: Tag) => {
        switch (tag.typeId) {
          case 'academic_year':
            // Add Repeats yearly...
            tag.tags.push({
              tagId: null,
              tagName: 'Repeats Yearly',
            })
            commit('setYear', tag.tags)
            break
          case 'scope':
            commit('setScope', tag.tags)
            break
          case 'subject':
            commit('setSubject', tag.tags)
            break
          case 'grade':
            commit('setGrade', tag.tags)
            break
        }
      })
    },
    fetchAutoQuestionGroups: async ({ commit, dispatch }, { assessmentId }) => {
      const { autoQuestionGroups } = await IBXService.assessmentAutoQuestionGroups({ assessmentId })
      const itemTypeQGs = await dispatch('getItemTypeQuestionGroups')
      autoQuestionGroups.push(itemTypeQGs)

      commit('setAutoQuestionGroups', autoQuestionGroups)
    },
    getItemTypeQuestionGroups: async ({ rootGetters, getters }) => {
      const questions = rootGetters['asmt/items']
      const itemTypes = _.uniq(questions.map((q) => q.item.itemType))
      const questionTypeMap = questions.reduce((m, q) => {
        const item = q.item
        const type = q.item.itemType
        const qData = {
          itemRevId: item.itemRevId,
          questionOrder: q.order,
        }

        if (m[type]) {
          m[type].push(qData)
        } else {
          m[type] = [qData]
        }

        return m
      }, {})

      const values = itemTypes.map((itemType) => {
        return {
          autoQuestionGroupValueId: null,
          name: itemTypesMap[itemType],
          questions: questionTypeMap[itemType],
        }
      })

      const group = {
        autoQuestionGroupId: 'item_type',
        name: 'Item Type',
        enabled: getters.itemTypeQuestionGroupsEnabled,
        values,
      }

      return group
    },
    enableAutoQuestionGroups: async ({ commit }, { autoQuestionGroupId, enabled }) => {
      commit('enableAutoQuestionGroups', { autoQuestionGroupId, enabled })
    },
    setPrintOptions: async ({ commit }, options) => {
      commit('setPrintOptions', options)
    },
    setLocked: async ({ commit }, value) => {
      commit('setLocked', value)
    },
    setItemTypeQuestionGroupEnabled: async ({ commit, getters }) => {
      commit('setItemTypeQuestionGroupEnabled', getters.itemTypeQuestionGroupsEnabled)
    },
  },
} as StoreOptions<IState>

export { asmtSettings, IState }
