<template>
  <v-layout class="ibx-confirmation-modal" row justify-center>
    <v-dialog v-model="showDialog" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">
          {{ title }}
          <v-spacer></v-spacer>
          <font-awesome-icon :icon="['fal', 'times']" class="icon close-discard-dialog" @click="showDialog = false" />
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :style="cancelBtnAttrs" text @click="showDialog = false">{{ cancelBtnLabel }}</v-btn>
          <v-btn :style="confirmBtnAttrs" light text @click="$emit('exit', { data }), (showDialog = false)">{{
            confirmBtnLabel
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import * as VDialog from 'vuetify/es5/components/VDialog'
import { EventBus, Events } from '@events'

export default {
  name: 'IbxConfirmationModal',

  components: {
    ...VDialog,
  },

  data: () => ({
    showDialog: false,
    title: '',
    message: '',
    cancelBtnLabel: '',
    confirmBtnLabel: '',
    data: null,
  }),

  computed: {
    cancelBtnAttrs() {
      return {
        'background-color': '#FFFFFF',
        color: '#050F2D',
      }
    },
    confirmBtnAttrs() {
      return {
        'background-color': '#0049ff',
        color: '#FFFFFF',
      }
    },
  },
  methods: {
    init({ title, message, data, cancelBtnLabel, confirmBtnLabel }) {
      this.title = title
      this.message = message
      this.cancelBtnLabel = cancelBtnLabel
      this.confirmBtnLabel = confirmBtnLabel
      this.data = data
      this.showDialog = true
    },
  },
  created() {
    EventBus.$on(Events.CONFIRMATION_SHOW, this.init)
  },
}
</script>

<style lang="scss" scoped>
.ibx-confirmation-modal {
  .activator-btn.v-btn {
    color: #050f2d !important;
    background-color: #f5f5f5 !important;
  }
}
.close-icon {
  color: white;
  cursor: pointer;
  font-size: 22px;
}

.close-discard-dialog {
  cursor: pointer;
}
</style>
