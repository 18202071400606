<template>
  <div class="ibx-saving-message" :class="classes">
    <font-awesome-icon
      v-if="smIsSaving"
      :icon="['fal', 'circle-notch']"
      class="ibx-saving-message__icon ibx-saving-message__icon--saving fa-spin"
      :style="styles"
    />
    <font-awesome-icon
      v-if="smIsSaved"
      :icon="['fal', 'check']"
      class="ibx-saving-message__icon ibx-saving-message__icon--saved"
      :style="styles"
    />
    <span class="ibx-saving-message__label" :style="styles">{{ saveMessage(saveContext) }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { SavingMessageMixin } from '@mixins'

export default {
  name: 'IbxSavingMessage',

  mixins: [SavingMessageMixin],

  props: {
    align: { type: String, default: 'right' },
    color: { type: String, default: '#E0E1E6' },
    context: { type: String, default: 'build' },
  },

  computed: {
    ...mapGetters('ui', ['saveState', 'saveMessage']),
    classes() {
      return this.align == 'right' ? ['ibx-saving-message--align-right'] : []
    },
    styles() {
      return { color: this.color }
    },
  },

  created() {
    this.saveContext = this.context
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.ibx-saving-message {
  display: flex;
  align-items: center;
  width: 150px;
  font-size: 14px;

  &--align-right {
    justify-content: flex-end;
  }
  &__label {
    font-family: 'CerebriSans', Roboto, sans-serif;
    font-weight: 300;
    text-transform: none;
  }
  &__icon {
    margin-right: 8px;
    font-size: 14px;
  }
}
</style>
