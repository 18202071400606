import { mapGetters, mapActions, mapMutations } from 'vuex'
import _ from 'lodash'

const AsmtMixin = {
  computed: {
    ...mapGetters('asmt', {
      asmtId: 'assessmentId',
      asmtItems: 'items',
      asmtItemsToAdd: 'itemsToAdd',
      asmtItemIds: 'itemIds',
      asmtItemsToAddData: 'itemsToAddData',
      asmtTitle: 'title',
      asmtTags: 'tags',
      asmtDescription: 'description',
      amstAutoQuestionGroups: 'autoQuestionGroups',
      asmtCustomQuestionGroups: 'customQuestionGroups',
      asmtGlobalQuestionGroupObjects: 'globalQuestionGroupObjects',
      asmtGlobalQuestionGroupObjectByItemRevId: 'globalQuestionGroupObjectByItemRevId',
      asmtLocalAssessmentId: 'localAssessmentId',
      asmtPublishedAt: 'publishedAt',
      asmtAdministeredAt: 'administeredAt',
      asmtLastDirty: 'lastDirty',
      asmtDirty: 'dirty',
      asmtLoading: 'loading',
      amstViewOnly: 'viewOnly',
      asmtItemSupportsRubric: 'itemSupportsRubric',
      asmtCanDuplicate: 'canDuplicate',
      asmtItemValidationErrors: 'itemValidationErrors',
    }),
    asmtHasID() {
      return Boolean(this.asmtId)
    },
    asmtItemCount() {
      return this.asmtItems.length
    },
    asmtIsPublished() {
      return Boolean(this.asmtPublishedAt)
    },
    asmtItemsToAddCount() {
      return this.asmtItemsToAdd.length
    },
    asmtHasItems() {
      return this.asmtItemCount > 0
    },
    asmtHasItemsToAdd() {
      return this.asmtItemsToAddCount > 0
    },
    asmtHasTitle() {
      return this.asmtTitle ? this.asmtTitle.length > 0 : false
    },
    asmtAllQuestionGroups() {
      return _.uniq([...this.amstAutoQuestionGroups, ...this.asmtCustomQuestionGroups])
    },
    asmtHasDescription() {
      return this.asmtDescription ? this.asmtDescription.length > 0 : false
    },
    asmtCanDelete() {
      return true
    },
    asmtDraftItemsCount() {
      return this.asmtItems.filter((asmtItem) => !asmtItem.item.published).length
    },
    asmtHasDraftItems() {
      return this.asmtDraftItemsCount > 0
    },
    amstReadyForPublish() {
      return !this.asmtDirty && !this.asmtLoading
    },
  },

  methods: {
    ...mapMutations('asmt', {
      asmtSetDirty: 'setDirty',
      asmtSetLoading: 'setLoading',
    }),
    ...mapActions('asmt', {
      asmtSetTitle: 'setTitle',
      asmtUpdateTitle: 'updateTitle',
      amstSetDescription: 'setDescription',
      asmtSetItems: 'setItems',
      asmtAddItem: 'addItem',
      asmtRemoveItem: 'removeItem',
      asmtAddItems: 'addItems',
      asmtRemoveItems: 'removeItems',
      asmtRemoveItemsToAdd: 'removeItemsToAdd',
      asmtCreateAssessment: 'createAssessment',
      amstFetchAssessment: 'fetchAssessment',
      asmtFetchAssessmentItems: 'fetchAssessmentItems',
      asmtSaveItemsToAdd: 'saveItemsToAdd',
      asmtUpateItems: 'updateItems',
      asmtSetAutoQuestionGroups: 'setCustomAutotionGroups',
      asmtSetItemCustomQuestionGroups: 'setItemCustomQuestionGroups',
      asmtSetItemAutoQuestionGroups: 'setItemAutoQuestionGroups',
      amstAddCustomQuestionGroup: 'addCustomQuestionGroup',
      amstRemoveCustomQuestionGroup: 'removeCustomQuestionGroup',
      asmtSetItemStandards: 'setItemStandards',
      asmtSetTags: 'setTags',
      asmtSetLocalAssessmentId: 'setLocalAssessmentId',
      amstSaveAdministeredAt: 'setAdministeredAt',
      amstEnableAutoQuestionGroups: 'enableAutoQuestionGroups',
      asmtSetPublishedAt: 'setPublishedAt',
      asmtDelete: 'deleteAssessment',
      asmtReset: 'reset',
      asmtSetDirty: 'setDirty',
      asmtSetRubricItemWeight: 'setRubricItemWeight',
      asmtSetLockAllItems: 'setLockAllItems',
      asmtUpdateItemsToAdd: 'updateItemsToAdd',
      asmtSetPendingRequest: 'setPendingRequest',
      amstValidateItems: 'validateItems',
      asmtClearValidationErrors: 'clearValidationErrors',
    }),
    asmtItemCustomQuestionGroups(itemRevId) {
      return this.asmtItems.find((o) => o.itemRevId == itemRevId).customQuestionGroups || []
    },
    asmtItemAutoQuestionGroups(itemRevId) {
      return this.asmtItems.find((o) => o.itemRevId == itemRevId).autoQuestionGroups || []
    },
    asmtItemAlignedStandards(itemRevId) {
      return this.asmtItems.find((o) => o.itemRevId == itemRevId).standards || []
    },
    amstItemStandards(itemRevId) {
      const itemMeta = this.asmtItems.find((o) => o.itemRevId == itemRevId).item.meta
      return itemMeta.standard ? itemMeta.standard.items : []
    },
    asmtGetItemValidationErrors(itemRevId) {
      return this.asmtItemValidationErrors[itemRevId] || []
    },
  },
}

export { AsmtMixin }
