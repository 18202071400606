<template>
  <div v-if="load" class="x-filter-button-list">
    <div class="x-filter-button-list__control" v-for="(item, i) in items" :key="i">
      <x-btn
        class="x-filter-button-list__button"
        :color="color(getItemId(item))"
        :outline="isOutline(getItemId(item))"
        @click="onButtonClick(getItemId(item))"
      >
        {{ getItemLabel(item) }}
      </x-btn>
    </div>
  </div>
  <div v-else>loading...</div>
</template>

<script>
import { Events } from '@events'
import xFilterMixin from '../mixins/xFilterMixin'
import xFilterListMixin from '../mixins/xFilterListMixin'
import lazyLoadMixin from '@mixins/lazyLoadMixin'
import XBtn from '@xLib/XBtn'

export default {
  name: 'XFilterButtonList',

  mixins: [xFilterMixin, xFilterListMixin, lazyLoadMixin],

  components: {
    XBtn,
  },

  methods: {
    lazyLoad() {
      this.load = true
      this.$emit(Events.LOADED, true)
    },
    color(id) {
      return this.isSelected(id) ? 'primary lighten-1' : '#747F9E'
    },
    isOutline(id) {
      return !this.isSelected(id) ? true : false
    },
    onButtonClick(id) {
      this.change(id, !this.isSelected(id))
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.x-filter-button-list {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding-top: 16px;
  margin-left: -8px;

  &__control {
    padding: 8px;
  }

  &__button {
    &.v-btn {
      margin-left: 0px !important;
    }
  }
}
</style>
