<template>
  <div class="filter-wrapper">
    <x-filter-group
      :data="filters"
      :activeFilters="activeFilters"
      :selectedItems="selectedItems"
      :options="filterOptions"
      settings
      maxHeight="80vh"
      maxWidth="400"
      @expand="onExpand"
      @order="onOrder"
      @remove="onRemove"
      @change="onChange"
    />
  </div>
</template>

<script>
import XFilterGroup from '@xLib/XFilter/XFilterGroup'
import XFilter from '@xLib/XFilter'

const mockItems = Array.from(Array(10).keys(), (n) => ({ id: n, name: `Item ${n + 1}` }))

export default {
  components: {
    XFilterGroup,
  },

  data: () => ({
    /*
     * Mock filter data:
     * items: is required.
     * id: will default to index id not provided.
     * All other properties are optional
     * itemID: key for item id. Default is id
     * itemLabel: key for item label. Default is name
     * canRemove: allow filter to be removed
     * active: removes filter
     */
    filters: [
      {
        id: 1,
        type: 'checkbox',
        name: 'Checkbox List',
        items: mockItems,
        search: true,
        selectAll: true,
        selectAllHeader: true,
        lazy: true,
        expanded: true,
        collapsable: true,
      },
      {
        id: 2,
        type: 'radio',
        name: 'Radio List',
        items: mockItems,
        search: true,
        selectAll: true,
        selectAllHeader: true,
        lazy: true,
        expanded: true,
        collapsable: true,
        canRemove: true,
      },
      {
        id: 3,
        type: 'button',
        name: 'Button List',
        items: mockItems,
        search: true,
        selectAll: true,
        selectAllHeader: true,
        lazy: true,
        expanded: false,
        collapsable: false,
        canRemove: true,
      },
      {
        id: 4,
        type: 'checkbox',
        name: 'Checkbox List 2',
        items: mockItems,
        search: true,
        selectAll: true,
        selectAllHeader: true,
        lazy: true,
        expanded: true,
        collapsable: true,
        canRemove: true,
      },
      {
        id: 5,
        type: 'checkbox',
        name: 'Checkbox List 3',
        items: mockItems,
        search: true,
        selectAll: true,
        selectAllHeader: true,
        lazy: true,
        expanded: true,
        collapsable: true,
        canRemove: true,
      },
    ],

    /* visible filters */
    activeFilters: [1, 2, 3],

    /* selected flter items: id=filter id, items=selected filter items */
    selectedItems: [
      { id: 1, items: [0, 2, 3] },
      { id: 2, items: [3, 4, 5] },
      { id: 3, items: [1, 2, 3] },
    ],

    /* other filter options */
    filterOptions: [
      {
        id: 'items-no-standards',
        type: 'toggle',
        name: 'Include items with no standards',
        selected: true,
      },
      {
        id: 'items-no-dok',
        type: 'toggle',
        name: 'Include items with no DOK',
        selected: false,
      },
    ],
  }),

  methods: {
    /*
     * id: filter id
     * data: any type. Can be array of selected item ids, key value pair, bool, etc..
     */
    onChange({ id, data }) {
      console.log('CHANGE: ', id, data)
    },

    /*
     * id: filter id
     * expanded: Boolean value of filter expanded state
     */
    onExpand({ id, expanded }) {
      console.log('EXPAND: ', id, expanded)
    },

    /*
     * Reorder filters from filters settings
     * order: array of ordered filter ids
     */
    onOrder({ order = [] }) {
      console.log('ORDER: ', order)
    },

    /*
     * active: array of active filter ids
     * removed: array of removed filter ids
     */
    onRemove({ active = [], removed = [] }) {
      console.log('REMOVE: ', active, removed)
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-wrapper {
  width: 500px;
}
</style>
