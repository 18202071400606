<template>
  <v-menu
    class="build-stds"
    v-model="menu"
    bottom
    offset-y
    origin="left"
    right
    content-class="build-stds__menu"
    :close-on-content-click="false"
    :close-on-click="closeOnClick"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on: menu }">
      <x-btn class="build-stds__activator" v-on="{ ...menu }" small v-bind="activatorAttrs">
        <font-awesome-icon v-if="!count" :icon="['far', 'plus']" class="build-stds_activator__icon" />
        <span class="build-stds__activator__count" v-if="hasAlignments">{{ selectedCountText }}</span>
        {{ label }}
        <font-awesome-icon v-if="count" color="#fff" class="ml-2 media-type-label" :icon="['fas', 'caret-down']" />
      </x-btn>
    </template>
    <div :class="getContentClasses" v-if="menu">
      <div class="build-stds-content__header" v-if="!viewOnly">
        <font-awesome-icon
          v-if="isAddView"
          :icon="['far', 'chevron-left']"
          class="build-stds-content__header__icon"
          @click="changeView('align')"
        />
        {{ headerLabel }}
      </div>

      <div class="build-stds-content__items">
        <!-- aligin items -->
        <div class="build-stds-align" v-if="isAlignView">
          <v-tooltip
            v-for="(item, i) in getAlignStandards"
            :key="`std-align-${i}`"
            right
            open-delay="0"
            close-delay="0"
            transition="fade"
            content-class="custom-tooltip"
          >
            <template v-slot:activator="{ on }">
              <div
                v-on="on"
                class="build-stds-align__item"
                :class="getAlignItemClasses(item)"
                @click="onAlignItemClick(item)"
                ref="alignItem"
              >
                <span class="build-stds-align__item__icon">
                  <font-awesome-icon :icon="['fas', 'check']" v-if="isAlignItemSelected(item) && !viewOnly"
                /></span>
                <span class="build-stds-align__item__label">{{ stdLabel(item) }}</span>
              </div>
            </template>
            <ibx-standard-tooltip :standard="item" />
          </v-tooltip>
        </div>

        <div v-if="viewOnly && isAlignView && !hasAlignments" class="build-stds__no-items">No Items</div>

        <!-- add items -->
        <div class="build-stds-add" v-show="isAddView">
          <div class="build-stds-add__title">
            Base Standards ({{ baseStandardCount }})
            <font-awesome-icon :icon="['fas', 'edit']" class="build-stds-add__title__icon" @click="showStannis" />
          </div>
          <v-tooltip
            v-for="(item, i) in addStandards"
            :key="`std-align-${i}`"
            right
            open-delay="0"
            close-delay="0"
            transition="fade"
            content-class="custom-tooltip"
          >
            <template v-slot:activator="{ on }">
              <div v-on="on" class="build-stds-add__item" :class="getAlignItemClasses(item)">
                <v-checkbox
                  class="build-stds-add__checkbox"
                  :input-value="isAddItemSelected(item)"
                  :label="stdLabel(item)"
                  color="#008272"
                  @change="(selected) => onAddStd(item, selected)"
                ></v-checkbox>
              </div>
            </template>
            <ibx-standard-tooltip :standard="item" />
          </v-tooltip>
        </div>
      </div>

      <div class="build-stds-content__footer" v-if="isAlignView && !viewOnly">
        <x-btn small class="add-more" @click="changeView('add')">Add More</x-btn>
      </div>
    </div>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AsmtMixin, StandardLabelMixin } from '@mixins'
import * as VMenu from 'vuetify/es5/components/VMenu'
import * as VCheckbox from 'vuetify/es5/components/VCheckbox'
import { Events, EventBus } from '@events'
import _ from 'lodash'
import IbxStandardTooltip from '@components/ibx/IbxStandardTooltip'
import CONST from '@constants'

const Views = {
  ALIGN: 'align',
  ADD: 'add',
}

export default {
  name: 'BuildStandards',

  mixins: [AsmtMixin, StandardLabelMixin],

  components: {
    ...VMenu,
    ...VCheckbox,
    IbxStandardTooltip,
  },

  props: {
    itemRevId: { type: [String, Number], required: true },
    disabled: { type: Boolean, default: false },
    viewOnly: { type: Boolean, default: false },
  },

  data: () => ({
    menu: false,
    label: 'Standards',
    view: Views.ALIGN,
    alignStandards: [],
    alignStandardsLoaded: false,
  }),

  watch: {
    menu(v) {
      if (v) {
        if (!this.alignStandardsLoaded) this.fetchAlignStandards()
        this.view = Views.ALIGN
      }
    },
  },

  computed: {
    ...mapGetters('standards', {
      stannisGet: 'get',
    }),
    ...mapGetters('ui', ['stannisActive']),
    ...mapGetters('user', {
      userUserSelection: 'userSelection',
    }),
    getAlignStandards() {
      return this.viewOnly
        ? this.alignStandards.filter((o) => this.questionStandardGuids.includes(o.guid))
        : this.alignStandards
    },
    getContentClasses() {
      const classes = ['build-stds-content']
      if (this.viewOnly) {
        classes.push('build-stds-content--view-only')
      }
      return classes
    },
    addStandards() {
      return this.stannisGet('selected_standards').length || this.stannisGet('initialized')
        ? this.stannisGet('selected_standards')
        : this.userBaseStandards
    },
    questionStandardGuids() {
      return this.asmtItemAlignedStandards(this.itemRevId) || []
    },
    itemStandardGuids() {
      return this.amstItemStandards(this.itemRevId).map((o) => o.id)
    },
    count() {
      return this.questionStandardGuids.length
    },
    selectedCountText() {
      return this.count > 0 ? this.count : ''
    },
    hasAlignments() {
      return this.questionStandardGuids.length > 0
    },
    headerLabel() {
      return this.isAlignView ? 'Standards' : 'Add Your Own'
    },
    isAlignView() {
      return this.view == Views.ALIGN
    },
    isAddView() {
      return this.view == Views.ADD
    },
    baseStandardCount() {
      return this.addStandards.length
    },
    activatorAttrs() {
      return {
        color: this.count > 0 ? '#6A78A1' : 'secondary-2',
      }
    },
    closeOnClick() {
      // prevent menu close when outside menu if stannis is active
      return !this.stannisActive
    },
    userBaseStandards() {
      const usBaseStandards = this.userUserSelection(CONST.CONTEXT_BASE_STANDARDS)
      return usBaseStandards && usBaseStandards.value && usBaseStandards.value.selected_standards
        ? usBaseStandards.value.selected_standards
        : []
    },
  },

  methods: {
    ...mapActions('standardsItems', {
      standardItemsGetItems: 'getItems',
    }),
    getAlignItemClasses(item) {
      return []
    },
    changeView(view) {
      this.view = view
      if (view == 'align') {
        this.matchBaseAndAlignedStds()
      }
    },
    onAddStd(item, selected) {
      if (selected && !this.alignStandards.find((o) => o.guid == item.guid)) {
        this.alignStandards.push(item)
      }
      this.addRemoveStandard(item, selected)
      this.alignStandardsLoaded = false // to refresh list on next menu show
    },
    onAlignItemClick(item) {
      if (!this.viewOnly) {
        this.addRemoveStandard(item)
        this.matchBaseAndAlignedStds()
      }
    },
    addRemoveStandard(item, add = false) {
      const selected = add ? false : this.isAddItemSelected(item)
      const standards = selected
        ? this.questionStandardGuids.filter((guid) => guid != item.guid)
        : _.uniq([...this.questionStandardGuids, item.guid])

      // update ui state (item)
      this.asmtSetItemStandards({
        itemRevId: this.itemRevId,
        value: standards,
      })

      // update backend
      EventBus.$emit(Events.ASSESSMENT_ITEM_CHANGE, {
        itemRevId: this.itemRevId,
        key: 'standards',
        value: standards,
      })
    },
    isAlignItemSelected(item) {
      return this.questionStandardGuids.includes(item.guid)
    },
    isAddItemSelected(item) {
      return this.questionStandardGuids.includes(item.guid)
    },
    showStannis() {
      this.$emit(Events.STANNIS_SHOW, true)
    },
    matchBaseAndAlignedStds() {
      this.alignStandards = this.alignStandards.filter(
        (o) => this.questionStandardGuids.includes(o.guid) || this.itemStandardGuids.includes(o.guid)
      )
    },
    async fetchStandardsByGuid(guids = []) {
      return this.standardItemsGetItems(guids)
    },
    async fetchAlignStandards() {
      const questionGuids = this.questionStandardGuids
      const itemGuids = this.itemStandardGuids
      const allGuids = _.uniq([...questionGuids, ...itemGuids])
      const standardsData = await this.fetchStandardsByGuid(allGuids)
      const standardsMap = _.keyBy(standardsData, 'guid')
      this.alignStandards = allGuids.map((guid) => {
        return {
          ...standardsMap[guid],
          type: questionGuids.includes(guid) ? 'question' : 'item',
        }
      })
      this.alignStandardsLoaded = true
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/main';
/* --------------------
 * Mixins
 * -------------------- */
@mixin display-flex {
  display: flex;
  align-items: center;
}
@mixin std-label {
  color: $N100;
  font-size: 14px !important;
  max-width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@mixin no-user-select {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

/* --------------------
 * Base
 * -------------------- */
.build-stds {
  &__menu.v-menu__content {
    background: white;
    width: 232px;
    height: auto !important;
    border-radius: 5px;
    overflow: hidden;
    font-family: 'CerebriSans', Roboto, sans-serif;
    z-index: 2 !important;
  }

  &__activator {
    &__icon {
      width: 17px;
    }

    &__count {
      width: 17px;
      // @include display-flex;
      // justify-content: center;
      // border-radius: 50%;
      // width: 16px;
      // height: 16px;
      // font-weight: bold;
      // margin-right: 4px;
      // background: white;
      // color: $N80;
    }
  }

  &__no-items {
    padding-top: 16px;
    padding-left: 16px;
  }
}

/* --------------------
 * Content
 * -------------------- */
.build-stds-content {
  position: relative;
  padding-bottom: 16px;

  &__header {
    @include display-flex;
    font-size: 14px;
    font-weight: bold;
    height: 48px;
    padding: 0px 16px;
    border-bottom: 1px solid $N20;

    &:hover {
      cursor: default;
    }

    &__icon {
      margin-right: 14px;
      font-size: 14px;
      font-weight: normal;
      padding: 0px;
      cursor: pointer;
    }
  }

  &__items {
    padding: 0px;
    max-height: 295px;
    overflow-y: scroll;
  }

  &__footer {
    @include display-flex;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0px;
    height: 32px;
    width: 100%;
    padding: 0px 16px;
    margin-top: 12px;

    .add-more {
      margin: 0 0 0 auto;
    }
  }
}

.build-stds-content--view-only {
  .build-stds-align__item {
    cursor: default;
    &__label {
      cursor: default;
    }
  }
}

/* --------------------
 * Align Standards
 * -------------------- */
.build-stds-align {
  &__item {
    @include display-flex;
    @include no-user-select;
    height: 30px;
    padding: 16px;
    cursor: pointer;

    &:first-child {
      padding-top: 24px;
    }

    &:hover {
      background: $N10;
    }

    &__label {
      @include std-label;
      @include no-user-select;
      cursor: pointer;
      font-size: 14px;
      position: absolute;
      left: 30px;
    }

    &__icon {
      position: absolute;
      left: 8px;
      color: $G200;
    }
  }
}

/* --------------------
 * Add Standards
 * -------------------- */
.build-stds-add {
  padding: 16px;

  &__title {
    @include display-flex;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 8px;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    &__icon {
      margin-left: auto;
      font-size: 13px;
      cursor: pointer;
      &:hover {
        color: $B300;
      }
    }
  }

  &__item {
    @include display-flex;
    height: 30px;
    cursor: pointer;

    &__checkbox {
      @include std-label;
      background: red;
      height: 30px;
    }
  }
}
.build-stds-add__checkbox.v-input {
  .v-input__slot {
    margin-bottom: 14px;
  }
  .v-label,
  .v-label.theme--light {
    color: red;
    @include std-label;
  }
}
</style>
