
import { Passage, IPassageData } from '@/components/ibx/base/Passage.ts'
import { Vue, Component, Prop, Mixins } from 'vue-property-decorator'
import IbxCardMetaBase, { ICardMeta } from '@/components/ibx/cardMeta/IbxCardMetaBase.vue'

@Component
export default class IbxCardMetaPassage extends IbxCardMetaBase<Passage, IPassageData> implements ICardMeta {
  get id(): string {
    return this.idLabel(this.entity.id, 'Passage')
  }
  get revisionId(): string {
    return this.idLabel(this.entity.passageRevId, 'Passage Rev')
  }
  created() {
    this.initialize(Passage)
  }
}
