/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'Drawing Response_2': {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<rect pid="0" x="32.5" y="8.5" width="127" height="175" rx="12.17" _fill="#152452"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M138.173 42.89A4 4 0 01137 45.72l-7.37 7.33-15-15.05 7.33-7.33a3.999 3.999 0 015.66 0l9.38 9.39a4 4 0 011.173 2.83zM66 101.67v-15l44.37-44.36 15 15L81 101.67H66zM58.32 109.74c-5.35 5.94-5.4 14.6-.91 21.12 5.46 7.94 16.08 10 25.09 9.22 10.62-.92 22.58-6.09 33-1.58 8.53 3.7 10.83 15.27 4.6 22.13-3.51 3.86 2.21 9.62 5.74 5.74 12-13.21 4.41-33.9-12.87-37.06-11-2-21.28 2.24-32.14 2.77-5.23.25-11.36-.41-15.34-4.22-3.25-3.12-4.64-8.78-1.39-12.37 3.49-3.88-2.24-9.64-5.75-5.75h-.03z" _fill="#fff"/>',
  },
})
