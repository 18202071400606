import { IBXService } from '@services/ibx/IBXService'
import { StoreOptions } from 'vuex'
import _ from 'lodash'
import Vue from 'vue'

type PassageItemsAff = {
  passageRevId: string | number
  items: string[] | number[] // { itemRevId }
}

interface IState {
  attributesHydrated: boolean
  attributes: object[]
  attributeValues: object
  passageAffs: PassageItemsAff[]
  allowedAdvancedAttributes: any[]
}

class State implements IState {
  attributesHydrated: boolean = false
  attributes: object[] = []
  attributeValues: object = {}
  passageAffs: PassageItemsAff[] = []
  allowedAdvancedAttributes: any[] = [
    { field: 'media', label: 'Media Type' },
    { field: 'passage_category', label: 'Passage Category' },
    { field: 'passage_genre', label: 'Passage Genre' },
    { field: 'passage_subgenre', label: 'Passage Sub-Genre' },
    { field: 'flesch_kincaid', label: 'Flesch Kincaid' },
    { field: 'level_of_meaning', label: 'Level of Meaning/Purpose' },
    { field: 'structure', label: 'Structure' },
    { field: 'knowledge_demands', label: 'Knowledge Demands' },
    { field: 'language_clarity', label: 'Language Clarity' },
    { field: 'copyright_owner', label: 'Copyright Owner' },
    { field: 'author_name', label: 'Original Author' },
    { field: 'original_source', label: 'Original Source' },
  ]
}

const passageAuthor = {
  namespaced: true,
  state: new State(),

  getters: {
    attributesHydrated: (state: IState) => state.attributesHydrated,
    attributes: (state: IState) => state.attributes,
    attributeValues: (state: IState) => state.attributeValues,
    getAttributeValue: (state: IState | any) => {
      return (field: string) => {
        const attribute: any = state.attributes.find((o: any) => o.field == field)
        return attribute ? state.attributeValues[attribute.attributeId] : {}
      }
    },
    getPassageItemsAff: (state: IState) => {
      return (passageRevId: string | number) =>
        state.passageAffs.find((o: PassageItemsAff) => o.passageRevId == passageRevId) || {
          passageRevId,
          items: [],
        }
    },
    allowedAdvancedAttributes: (state: IState): any => state.allowedAdvancedAttributes,
    contentLanguages: (state: IState, getters) => {
      const attrValues = getters.getAttributeValue('language')
      return {
        field: 'language',
        label: 'Language',
        items: attrValues.map((attr) => ({
          id: attr.attributeValueId,
          name: attr.value,
        })),
      }
    },
    advancedSettings: (state: IState, getters): any[] => {
      return getters.allowedAdvancedAttributes.map(({ field, label }) => {
        const attrValues = getters.getAttributeValue(field)
        return {
          field,
          label,
          items: attrValues.map((attr) => ({
            id: attr.attributeValueId,
            name: attr.value,
          })),
        }
      })
    },
  },

  mutations: {
    setAttributesHydrated: (state: IState, value: boolean = false) => {
      state.attributesHydrated = value
    },
    setAttributes: (state: IState, value: object[] = []) => {
      state.attributes = value || []
    },
    setAttributeValues: (state: IState, value: object[] = []) => {
      state.attributeValues = value || []
    },
    setPassageAff: (state: IState, data: PassageItemsAff) => {
      const pia = state.passageAffs.find((o: PassageItemsAff) => o.passageRevId == data.passageRevId)
      if (pia) {
        Vue.set(pia, 'items', data.items)
      } else {
        state.passageAffs.push(data)
      }
    },
  },

  actions: {
    init: async ({ dispatch, commit, getters }) => {
      if (!getters.attributesHydrated) {
        await dispatch('fetchAttributes')
        await dispatch('fetchAttributeAllValues')
        commit('setAttributesHydrated', true)
      }
    },
    fetchAttributes: async ({ commit, getters }) => {
      const { attributes } = await IBXService.attributes({ resource: 'passages' })
      commit('setAttributes', attributes)
    },
    fetchAttributeAllValues: async ({ commit, getters }) => {
      const attributeIds = getters.attributes.map((o: any) => o.attributeId)
      const { values } = await IBXService.attributes({
        resource: 'values',
        params: { attributeId: attributeIds },
      })
      const groupedValues = _.groupBy(values, 'attributeId')
      commit('setAttributeValues', groupedValues)
    },
    addPassageItemAff: ({ commit, getters }, { passageRevId, itemRevId }) => {
      const passageItemAff = getters.getPassageItemsAff(passageRevId)
      const items = _.uniq([...passageItemAff.items, itemRevId])
      commit('setPassageAff', { passageRevId, items })
    },
    removePassageItemAff: ({ commit, getters }, { passageRevId, itemRevId }) => {
      const passageItemAff = getters.getPassageItemsAff(passageRevId)
      const items = passageItemAff.items.filter((v: string | number) => v != itemRevId)
      commit('setPassageAff', { passageRevId, items })
    },
  },
} as StoreOptions<IState>

export { passageAuthor, IState }
