<template>
  <div class="x-filter-group" :style="styles">
    <transition name="transition-slide-x">
      <x-filter-group-settings
        v-if="settings && settingsActive"
        :filters="getActiveFilters"
        :inActiveFilters="getInActiveFilters"
        class="x-filter-group__settings"
        v-bind="$attrs"
        v-on="$listeners"
        @filters-order="onReorder"
        @filter-remove="onRemove"
        @filter-add="onAdd"
      />
    </transition>

    <div class="x-filter-group-header">
      <div
        class="x-filter-group-header-title"
        :class="headerClasses"
        v-on="settingsActive ? { click: toggleSettings } : {}"
      >
        <font-awesome-icon
          v-if="settingsActive"
          :icon="['fas', 'chevron-left']"
          class="x-filter-group-header-title__icon"
        />
        <span class="x-filter-group-header-title__title">{{ getTitle }}</span>
      </div>
      <span v-show="!settingsActive" class="x-filter-group-header__clear-all" @click="onClearAll">Clear All </span>
      <div class="x-filter-group-header__settings-activator" :class="settingsActivatorClasses" @click="toggleSettings">
        <v-tooltip
          color="#050F2D"
          bottom
          open-delay="0"
          close-delay="0"
          content-class="custom-tooltip"
          transition="none"
        >
          <template v-slot:activator="{ on }">
            <font-awesome-icon :icon="['fas', 'cog']" v-on="on" />
          </template>
          <span>Settings</span>
        </v-tooltip>
      </div>
    </div>

    <div class="x-filter-group__content-fader"></div>
    <div class="x-filter-group__content" :class="contentClasses" :style="contentStyles">
      <x-filter
        v-for="(f, i) in getActiveFilters"
        :key="f.id"
        :id="getFilterID(f.id, i)"
        :type="f.type"
        :title="f.name"
        :items="f.items"
        :auxilliaryItems="f.auxilliaryItems"
        :selectedItems="getSelectedItems(f.id)"
        :itemId="f.itemID || 'id'"
        :itemLabel="f.itemLabel || 'name'"
        :search="f.search"
        :expanded="f.expanded"
        :selectAll="f.selectAll"
        :selectAllHeader="f.selectAllHeader"
        :collapsable="f.collapsable"
        :lazy="f.lazy"
        :useTooltip="f.useTooltip"
        @filter-expand="onExpand"
        v-bind="$attrs"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<script>
import { Events } from '@events'
import * as VTooltip from 'vuetify/es5/components/VTooltip'
import { FilterEvents } from './xFilterEvents'
import XFilter from './XFilter'
import _ from 'lodash'

export default {
  name: 'XFilterGroup',

  components: {
    ...VTooltip,
    XFilter,
    'x-filter-group-settings': () => import('./XFilterGroupSettings'),
  },

  props: {
    settings: { type: Boolean, default: false }, // use settings
    title: { type: String, default: 'Filters' }, // header title,
    data: { type: Array, default: () => [] }, // filter data,
    activeFilters: { type: Array, default: () => [] }, // active/visible filters
    expandedFilters: { type: Array, default: () => [] }, // expanded filters
    selectedItems: { type: Array, default: () => [] }, // selected filter items
    order: { type: Array, default: () => [] }, // order array of ids
    maxHeight: { type: String, default: '100vh' }, // max content height
    maxWidth: { type: String, default: '256' },
  },

  data: () => ({
    active: [], // internal active and ordered filter ids,
    expanded: [], // internal expanded filters
    settingsActive: false, // settings panel is active
  }),

  computed: {
    getActiveFilters() {
      const active = _.union(this.order, this.active)
        .map((id) => this.data.find((o) => o.id == id))
        .filter((o) => o !== undefined)
      return active
    },
    getInActiveFilters() {
      return this.data.filter((o) => !this.active.includes(o.id))
    },
    settingsActivatorClasses() {
      return this.settingsActive ? ['x-filter-group-header__settings-activator--active'] : []
    },
    contentClasses() {
      return this.settingsActive ? ['x-filter-group__content--hidden'] : []
    },
    headerClasses() {
      return this.settingsActive ? ['x-filter-group-header-title--settings-active'] : []
    },
    styles() {
      return {}
      // const maxW = (this.maxWidth.includes('vh')) ? this.maxWidth : `${this.maxWidth}px`
      // return { 'max-width': maxW }
    },
    contentStyles() {
      return {}
      // const maxH = (this.maxHeight.includes('vh')) ? this.maxHeight : `${this.maxHeight}px`
      // return { 'max-height': maxH }
    },
    getTitle() {
      return this.settingsActive ? 'Back to Filters' : this.title
    },
  },

  watch: {
    activeFilters() {
      this.syncActive()
    },
    expandedFilters() {
      this.syncExpanded()
    },
  },

  methods: {
    getFilterID(id, index) {
      return id ? id : index
    },
    getFilter(id) {
      return this.filters.find((o) => o.id == id)
    },
    syncActive() {
      // if no settings then include all filters in data
      this.active = this.settings ? this.activeFilters : this.data.map((o) => o.id)
    },
    syncExpanded() {
      this.expanded = this.expandedFilters
    },
    onReorder(order = []) {
      this.active = order.map((o) => o.id)
      this.$emit(Events.ORDER, {
        order: this.active,
      })
    },
    onRemove(id) {
      this.active = this.active.filter((v) => v != id)
      this.$emit(Events.REMOVE, {
        active: this.active,
        removed: [id],
      })
    },
    onAdd(id) {
      this.active = _.uniq([...this.active, id])
      this.$emit(Events.ADD, {
        active: this.active,
        added: [id],
      })
    },
    toggleSettings() {
      this.settingsActive = !this.settingsActive
    },
    getSelectedItems(id) {
      const selected = this.selectedItems.find((o) => o.id == id)
      return selected ? selected.items : []
    },
    onExpand({ id, expanded }) {
      if (expanded) {
        this.expanded = _.uniq([...this.expanded, id])
      } else {
        this.expanded = this.expanded.filter((_id) => _id != id)
      }
      this.$emit(Events.EXPAND, { expandedIds: this.expanded })
    },
    onClearAll() {
      this.$emit('clear')
    },
  },

  created() {
    this.syncActive()
    this.syncExpanded()
  },
}
</script>

<style lang="scss">
@import '@/styles/main';
@import '@/styles/components/XFilter';

.x-filter-group {
  @include font-family;
  position: relative;
  border: 1px solid $N20;
  border-top: none;
  background-color: $N00;
  overflow: hidden;
  color: $N130;
  z-index: 1;
  height: 100vh;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  .x-filter-group-header {
    height: 52px;
    padding: $x-filter-padding-sm 12px $x-filter-padding-sm $x-filter-padding-sm;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $N20;

    &__settings-activator {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      height: 28px;
      width: 28px;
      border-radius: 50%;
      cursor: pointer;
      transition: background-color 0.4s ease-in-out; // background transition
      transition: transform 0.4s ease-in-out; // icon transition

      &--active {
        background-color: $N10;
        transform: rotate(45deg);
      }
    }

    &__clear-all {
      margin-left: auto;
      margin-right: 20px;
      font-size: 13px;
      cursor: pointer;
      &:hover {
        color: $B300;
      }
    }
  }

  .x-filter-group-header-title {
    cursor: default;

    &--settings-active {
      cursor: pointer;
    }

    &__title {
      font-size: 16px;
      font-weight: 500;
    }

    &__icon {
      font-size: 12px;
      margin-right: 8px;
    }
  }

  &__content {
    overflow-y: scroll;
    transition: opacity 0.4s ease-in-out;
    // height: 400px;

    &--hidden {
      opacity: 0;
    }
  }

  &__content-fader {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 30px;
    margin: 0;
    padding: 0;
    z-index: 3;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
  }

  .x-filter {
    &:not(:first-child) {
      border-top: 1px solid $N20;
    }
  }

  &__settings {
    position: absolute;
    left: 0;
    top: 53px;
    bottom: 0;
    z-index: 2;
  }
}
</style>
