/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  Ordering_1: {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<rect pid="0" x="67.25" y="127.56" width="103.75" height="21.25" rx="10.62" _fill="#152452"/><rect pid="1" x="67.25" y="85.69" width="76.25" height="21.25" rx="10.62" _fill="#152452"/><rect pid="2" x="67.25" y="43.19" width="51.25" height="21.25" rx="10.62" _fill="#152452"/><rect pid="3" x="21" y="40.06" width="27.5" height="27.5" rx="13.75" _fill="#152452"/><rect pid="4" x="21" y="82.56" width="27.5" height="27.5" rx="13.75" _fill="#152452"/><rect pid="5" x="21" y="124.44" width="27.5" height="27.5" rx="13.75" _fill="#152452"/>',
  },
})
