<template>
  <v-card class="ibx-asmt-passage-card x-content-card">
    <v-card-title class="x-content-card__header">
      <div v-if="!permIsPublished && !permIsViewOnly" class="ibx-asmt-passage-card__selector">
        <v-checkbox class="checkbox" color="info" :value="allItemsSelected" @change="onPassageSelect" />
      </div>
      <x-content-card-header
        :cardTitle="getPassageLabel"
        titleColor="#FF8E07"
        titleIcon="Passage_1"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <slot name="header-actions" slot="actions">
          <x-btn color="tertiary" icon="eye" @click="onViewPassage">View Passage</x-btn>
        </slot>
      </x-content-card-header>
    </v-card-title>

    <div class="ibx-asmt-passage-card__passage">
      <ibx-passage
        :load="lazyLoadTrigger"
        :passage="passage"
        initial-mode="view"
        v-bind="$attrs"
        @loaded="() => onPassageLoaded()"
      />
    </div>
    <div v-if="loading" class="ibx-asmt-passage-card__items">Loading Items...</div>
    <div v-else class="ibx-asmt-passage-card__items">
      <draggable element="div" v-model="passageItemsSortable" v-bind="dragOptions">
        <transition-group type="transition" name="list" class="build-item-transition-wrapper">
          <div
            v-for="(item, i) in passageItemsSortable"
            :key="`passage-item-${item.itemRevId}`"
            class="ibx-asmt-passage-card__item_item-wrap"
          >
            <div v-if="!permIsPublished && !permIsViewOnly" class="ibx-asmt-passage-card__item_item-wrap__selector">
              <v-checkbox
                class="checkbox"
                color="#008272"
                :value="isItemSelected(item)"
                @change="(selected) => onPassageItemSelect(item, selected)"
              />
            </div>
            <ibx-assessment-item-card
              class="ibx-asmt-passage-card__items__item"
              :itemRevId="item.itemRevId"
              :itemData="item.item"
              :asmtItem="item"
              :passageRevId="passage.passageRevId"
              :preview="true"
              :index="index"
              lazy
              :lazyLoadTrigger="lazyLoadTriggers[i]"
              context="provide"
              stickyHeader
              dragClass="drag-handle-passage-item"
              v-bind="$attrs"
              v-on="$listeners"
              @player-error="onPlayerError"
            ></ibx-assessment-item-card>
          </div>
        </transition-group>
      </draggable>
    </div>
  </v-card>
</template>

<script>
import { XContentCardHeader } from '@xLib/XContentCard'
import IbxAssessmentItemCard from '@components/ibx/_IbxAssessmentItemCard'
import IbxPassage from '@components/ibx/IbxPassage'
import { Events, EventBus } from '@events'
import * as VCard from 'vuetify/es5/components/VCard'
import * as VCheckbox from 'vuetify/es5/components/VCheckbox'
import XBtn from '@xLib/XBtn'
import draggable from 'vuedraggable'
import _ from 'lodash'
import { pluralizeString } from '@helpers'
import { PermsMixin, lazyLoadMixin } from '@mixins'

const metaInterface = {
  id: '',
  name: '',
  items: [],
  meta: {},
}

export default {
  name: 'IbxAssessmentPassageCard',

  mixins: [PermsMixin, lazyLoadMixin],

  components: {
    XContentCardHeader,
    IbxAssessmentItemCard,
    IbxPassage,
    ...VCard,
    ...VCheckbox,
    XBtn,
    draggable,
  },

  props: {
    passage: { type: Object, default: () => ({}) }, // passage data,
    itemsSelected: { type: Array, default: () => [] },
    index: { type: Number, default: 0 },
    id: { type: [String, Number], default: null }, // unique id
  },

  data: () => ({
    loading: true, // items fetching
    lazyLoadTriggers: [], // triggers for lozy loading
  }),

  computed: {
    passageItemsSortable: {
      get() {
        return _.sortBy(this.passageItems, [
          function (o) {
            return o.order
          },
        ])
      },
      set(sortedItems) {
        const base = this.passage.order
        const sorted = sortedItems.map((o, i) => {
          return {
            itemRevId: o.itemRevId,
            order: base + i,
          }
        })
        EventBus.$emit(Events.ASSESSMENT_ITEMS_ORDER, {
          sorted: sorted,
          subgroup: true,
        })
      },
    },
    dragOptions() {
      return {
        animation: 300,
        disabled: this.permIsPublished || this.permIsViewOnly,
        ghostClass: 'ghost',
        handle: '.drag-handle-passage-item',
        group: `asmt-passage-${this.passage.passageRevId}-item`,
      }
    },
    getPassageLabel() {
      return `Passage (${pluralizeString(this.passageItems.length, 'Item')})`
    },
    passageItems() {
      return this.passage.items
    },
    passageItemRevIds() {
      return this.passage.items.map((o) => o.itemRevId)
    },
    getAsmtItemdata() {
      return this.$attrs.asmtItem
    },
    getPassageData() {
      return this.getAsmtItemdata.passage
    },
    getAuxilliaryActions() {
      return [
        {
          id: Events.REMOVE_ITEM,
          name: 'Remove Item',
        },
      ]
    },
    allItemsSelected() {
      if (this.itemsSelected.length == 0) return false
      const intersect = _.intersection(this.itemsSelected, this.passageItemRevIds)
      return this.passageItemRevIds.length == intersect.length
    },
  },

  methods: {
    onAction({ id }) {
      this.$emit(id, {
        itemId: this.itemId,
        itemRevId: this.itemRevId,
      })
    },
    onLoaded(onAsyncContentLoaded) {
      if (typeof onAsyncContentLoaded === 'function') {
        onAsyncContentLoaded()
      }
      this.$emit(Events.LOADED, { index: this.index, id: this.id })
    },
    onPlayerError() {
      this.$emit(Events.LOAD_ERROR, { index: this.index, id: this.id })
    },
    lazyLoad() {
      this.lazyLoadTriggers = this.passageItems.map(() => false)
      this.loading = false
      this.lazyLoadItems()
    },
    lazyLoadItems(index = 0) {
      if (index <= this.passageItems.length - 1 && index < 50) {
        let triggers = [...this.lazyLoadTriggers]
        triggers[index] = true
        this.lazyLoadTriggers = triggers
        setTimeout(() => this.lazyLoadItems(index + 1), 500)
      }
    },
    onPassageLoaded() {
      //console.log("PASSAGE LOADED")
    },
    onPassageSelect(selected) {
      // oh yeah, it's a hack ;)
      this.passageItemRevIds.forEach((itemRevId) => {
        this.$emit('item-selected', {
          itemRevId,
          selected,
        })
      })
    },
    onPassageItemSelect(item, selected) {
      this.$emit('item-selected', {
        itemRevId: item.itemRevId,
        selected,
      })
    },
    isItemSelected(item) {
      return this.itemsSelected.includes(item.itemRevId)
    },
    onViewPassage() {
      this.$emit('view-passage', {
        passage: this.passage,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.ibx-asmt-passage-card {
  width: 100%;
  border: 1px solid $N20;

  .x-content-card__header {
    position: relative;
  }

  &__selector {
    position: absolute;
    width: 44px;
    left: -44px;
    display: flex;
    align-items: center;
    justify-content: center;

    * {
      margin: 0px !important;
      font-size: 24px !important;
    }

    .checkbox {
      .v-input__control {
        width: 100% !important;
        .v-input__slot {
          width: 100% !important;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .v-input--selection-controls__ripple {
        left: -3px;
        width: 30px;
        height: 30px;
        top: calc(50% - 16px);
      }
    }
  }

  .ibx-asmt-passage-card__item_item-wrap__selector {
    * {
      font-size: 24px !important;
    }

    .v-input--selection-controls__ripple {
      left: -3px;
      width: 30px;
      height: 30px;
      top: calc(50% - 16px);
    }
  }

  &__passage {
    padding: 20px 20px 0px 20px;
    margin-bottom: 30px;
    height: 136px;
    overflow: hidden;
  }

  &__items {
    padding: 10px;
    background: $N20;

    &__item {
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }
}

.ibx-asmt-passage-card__item_item-wrap {
  position: relative;

  &__selector {
    position: absolute;
    width: 44px;
    left: -54px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    * {
      margin: 0px !important;
    }

    .checkbox {
      .v-input__control {
        width: 100% !important;
        .v-input__slot {
          width: 100% !important;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.ibx-passage {
  font-family: 'CerebriSans', Roboto, sans-serif;
  margin-top: 10px;
}

.ibx-asmt-passage-card__selector,
.ibx-asmt-passage-card__item_item-wrap__selector {
  .checkbox.v-input.checkbox.v-input--is-dirty {
    .v-icon {
      color: #008272 !important;
    }
  }
}
</style>
