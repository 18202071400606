const getStdLabel = (standard = {}, lastIndex = 50) => {
  if (!standard) return ''
  return standard?.code || (standard?.description ? standard.description.substring(0, lastIndex) : standard?.guid || '')
}

const StandardLabelMixin = {
  methods: {
    stdLabel: getStdLabel,
  },
}

export { StandardLabelMixin, getStdLabel }
