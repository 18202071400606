<template>
  <div class="ibx-chip-list">
    <template v-if="hasItems">
      <v-chip
        v-for="(item, i) in slicedItems"
        :key="i"
        :small="small"
        class="ibx-chip-list-chip"
        close
        @input="remove(item[itemId])"
      >
        {{ item[itemLabel] | label }}
      </v-chip>
      <span v-if="hasMore" class="ibx-chip-list-more" @click="onMore">{{ moreText }} </span>
    </template>
    <template v-else>
      <div class="ibx-chip-list-none-message">{{ noneMessage }}</div>
    </template>
  </div>
</template>

<script>
import * as VChip from 'vuetify/es5/components/VChip'

export default {
  name: 'IbxChipList',

  components: {
    ...VChip,
  },

  props: {
    items: { type: Array, default: () => [] },
    itemLabel: { type: String, default: 'name' },
    itemId: { type: String, default: 'id' },
    small: { type: Boolean, default: false },
    meta: { type: Object, default: () => ({}) },
    max: { type: Number, default: 3, validator: (val) => Math.max(val, 3) },
    noneMessage: { type: String, default: 'No Selections' },
  },

  filters: {
    label(value = '') {
      const limit = 30
      return value.length > limit ? `${value.substring(0, limit)}...` : value
    },
  },

  computed: {
    hasItems() {
      return this.items.length > 0
    },
    slicedItems() {
      return this.items.slice(0, this.max)
    },
    hasMore() {
      return this.items.length > this.max
    },
    moreText() {
      return `+${this.items.length - this.slicedItems.length} more filters`
    },
  },

  methods: {
    remove(id) {
      this.$emit('remove', { id })
    },
    onMore() {
      this.$emit('more', { firstOverflowID: this.items[this.max][this.itemId] })
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/components/IbxChipList.scss';
</style>
