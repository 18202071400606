/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  Charting_1: {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M33.09 20.5h125.82c6.953 0 12.59 5.637 12.59 12.59v125.82c0 6.953-5.637 12.59-12.59 12.59H33.09c-6.953 0-12.59-5.637-12.59-12.59V33.09c0-6.953 5.637-12.59 12.59-12.59zm127.651 10.759a2.588 2.588 0 00-1.831-.759H33.09a2.59 2.59 0 00-2.59 2.59v125.82a2.588 2.588 0 002.59 2.59h125.82c.687 0 1.346-.273 1.831-.759a2.587 2.587 0 00.759-1.831V33.09a2.59 2.59 0 00-.759-1.831z" _fill="#152452"/><rect pid="1" x="143.28" y="151" width="19.91" height="75.86" rx="9.955" transform="rotate(180 143.28 151)" _fill="#152452"/><rect pid="2" x="106.3" y="151" width="19.91" height="39.83" rx="9.955" transform="rotate(180 106.3 151)" _fill="#152452"/><rect pid="3" x="69.31" y="151" width="19.91" height="52.16" rx="9.955" transform="rotate(180 69.31 151)" _fill="#152452"/><path pid="4" fill-rule="evenodd" clip-rule="evenodd" d="M143.44 51c-.027 5.503-4.497 9.95-10 9.95a9.851 9.851 0 01-6.47-2.4L103.5 79.12a9.95 9.95 0 01-8 15.93c-5.523 0-10-4.477-10-10 .003-.492.037-.983.1-1.47l-17.16-4.5a9.94 9.94 0 111-4.42 10.753 10.753 0 01-.14 1.71l17.22 4.53a9.94 9.94 0 0115-3.79l23.65-20.73A10.001 10.001 0 11143.44 51z" _fill="#152452"/>',
  },
})
