import { mapGetters, mapActions } from 'vuex'
import CONST from '@constants'
import { EventBus, Events } from '@events'

const AppMixin = {
  data: () => ({
    context: 'browse',
  }),

  computed: {
    ...mapGetters('auth', {
      authInitialized: 'initialized',
    }),
    ...mapGetters('user', {
      userUserSelection: 'userSelection',
    }),
  },

  methods: {
    ...mapActions('config', {
      initConfig: 'init',
    }),
    ...mapActions('auth', {
      authInit: 'init',
    }),
    ...mapActions('user', {
      userFetchUserSelections: 'fetchUserSelections',
    }),
    appInit({ context = CONST.MODE_BROWSE }) {
      this.context = context

      if (!this.authInitialized) {
        this.initConfig()
          .then(() => this.authInit())
          .then(() => this.userFetchUserSelections())
          .then(() => this.onAppInit())
          .catch((error) => EventBus.$emit(Events.ERROR, { type: CONST.ERROR, error }))
      } else {
        this.initConfig()
          .then(() => this.userFetchUserSelections())
          .then(() => this.onAppInit())
          .catch((error) => EventBus.$emit(Events.ERROR, { type: CONST.ERROR, error }))
      }
    },

    onAppInit() {
      //... implement in consumer
    },
  },
}

export { AppMixin }
