<template>
  <v-dialog class="ibx-assessment-description-modal" v-model="showDialog" :persistent="!permIsViewOnly" max-width="500">
    <v-card class="x-assessment-description-modal__content">
      <div v-if="!permIsViewOnly">
        <v-card-title class="headline">{{ actionTitle }} Description</v-card-title>
        <v-card-text>
          <v-textarea
            class="ibx-assessment-description-modal__text-area"
            v-model="description"
            label="Description"
            type="text"
            autofocus
            outlined
            solo
            :rules="rules"
            counter="750"
            @input="userInput = true"
          ></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <x-btn color="ghost" @click="close">Cancel</x-btn>
          <x-btn color="primary" :disabled="!valid" @click="save">Save</x-btn>
        </v-card-actions>
      </div>

      <!-- view-only -->
      <div v-else>
        <v-card-title class="headline">Description</v-card-title>
        <v-textarea
          class="ibx-assessment-description-modal__text-area view-only"
          :value="description"
          label="Description"
          type="text"
          solo
          readonly
        ></v-textarea>
        <v-card-actions>
          <v-spacer></v-spacer>
          <x-btn color="tertiary" @click="close">Close</x-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus, Events } from '@events'
import { AsmtMixin, SavingMessageMixin, PermsMixin } from '@mixins'
import * as VDialog from 'vuetify/es5/components/VDialog'
import * as VTextField from 'vuetify/es5/components/VTextField'
import * as VTextarea from 'vuetify/es5/components/VTextarea'
import * as VCard from 'vuetify/es5/components/VCard'
import Vue from 'vue'
import CONST from '@constants'

export default {
  name: 'IbxAssessmentDescriptionModal',

  mixins: [AsmtMixin, SavingMessageMixin, PermsMixin],

  components: {
    ...VDialog,
    ...VTextarea,
    ...VCard,
  },

  props: {
    show: { type: Boolean, default: false },
    hadDescription: { type: Boolean, default: false },
  },

  data: () => ({
    saving: false,
    description: '',
    userInput: false,
    rules: [(v) => (v && v.length <= 750) || 'Description must be less than 750 characters'],
  }),

  computed: {
    showDialog: {
      get() {
        this.syncDescription()
        return this.show
      },
      set(v) {
        this.$emit(Events.SHOW, v)
      },
    },
    hasInput() {
      return (this.hadDescription || (this.description && this.description.length > 0)) && this.userInput
    },
    valid() {
      return this.hasInput && this.description.length <= 750
    },
    actionTitle() {
      return this.asmtDescription && this.asmtDescription.length > 0 ? 'Update' : 'Add'
    },
  },

  watch: {
    asmtDescription() {
      this.syncDescription()
    },
  },

  methods: {
    save() {
      this.smSaving()
      this.amstSetDescription({ value: this.description })
        .then(() => {
          this.smSaved()
        })
        .catch((error) =>
          EventBus.$emit(Events.ERROR, {
            type: CONST.ERROR,
            error,
            text: 'Failed to save description',
            subtext: 'Please refresh the page',
          })
        )
        .finally(() => this.close())
    },
    close() {
      this.showDialog = false
      this.userInput = false
      this.syncDescription()
    },
    syncDescription() {
      this.description = this.asmtDescription
    },
  },
}
</script>

<style lang="scss" scope>
@import '@/styles/main';

.ibx-assessment-description-modal {
  .ibx-assessment-description-modal__content {
    text-area {
      height: 200px !important;
    }
  }

  .v-textarea.v-text-field--enclosed.v-text-field--single-line textarea {
    min-height: 120px;
  }

  &__text-area.view-only {
    .v-input__slot {
      padding: 0px 20px !important;

      textarea {
        margin: 0px !important;
        cursor: default;
      }
    }
  }
}
</style>
