<template>
  <div v-if="getShow" class="ibx-app-header__view-only">
    <div class="ibx-app-header__view-only-text">
      <span>You are currently in view-only mode.</span>
    </div>
    <div class="ibx-app-header__view-only-close" @click="onClickClose">
      <font-awesome-icon :icon="['fal', 'times']" />
    </div>
  </div>
</template>

<script>
import { PermsMixin, AsmtMixin } from '@mixins'

export default {
  name: 'IbxAppHeaderViewOnly',

  mixins: [PermsMixin, AsmtMixin],

  data: () => ({
    show: true,
  }),

  computed: {
    getShow() {
      return this.asmtHasID && this.show && this.permIsViewOnly
    },
  },

  methods: {
    onClickClose() {
      this.show = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/main';
.ibx-app-header {
  &__view-only {
    background: $N110;
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &-text {
      color: $N00;
      font-family: 'CerebriSans', Roboto, sans-serif;
      position: relative;
      left: 45%;
    }
    &-close {
      color: $N00;
      cursor: pointer;
      margin: 0 25px 0 auto;
    }
  }
}
</style>
