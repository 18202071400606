<template>
  <div class="ibx-add-to-fly" :class="classes" :style="styles" ref="saturnV">
    <!-- <svg
            v-svg
            symbol="Multiple Choice_1"
            class="itemTypeIcon  elevation-0"
        ></svg> -->
  </div>
</template>

<script>
import anime from 'animejs'
import { Events, EventBus } from '@events'

export default {
  name: 'IbxAddToFly',

  data: () => ({
    from: { x: 100, y: 100 },
    to: { x: 1283, y: 60 },
    ready: false,
  }),

  computed: {
    classes() {
      return this.ready ? 'ibx-add-to-fly--ready' : ''
    },
    styles() {
      return { opacity: this.ready ? 1 : 0 }
    },
  },

  methods: {
    launch() {
      const saturnVEl = this.$refs.saturnV
      const duration = 500

      const ani = anime({
        targets: saturnVEl,
        duration: duration,
        top: [this.from.y, this.to.y],
        left: [this.from.x, this.to.x],
        rotate: ['0', '90deg'],
        scaleX: [
          { value: 1.3, duration: duration / 2, easing: 'easeOutExpo' },
          { value: 0.5, duration: duration / 2 },
        ],
        scaleY: [
          { value: 1.3, duration: duration / 2, easing: 'easeOutExpo' },
          { value: 0.5, duration: duration / 2 },
        ],
        opacity: [
          { value: 1, duration: (duration / 4) * 3 },
          { value: 0, duration: duration / 4 },
        ],
        easing: 'easeOutSine',
      })

      ani.finished.then(this.landed)
    },
    tMinusZero({ fromEl, toEl }) {
      // this is temporary for create assessment button
      toEl = document.getElementById('IbxAddToDestination')
      if (toEl) {
        const toRect = toEl.getBoundingClientRect()
        this.to = { x: toRect.right - 25, y: toRect.top }
      }

      const fromRect = fromEl.getBoundingClientRect()
      this.from = { x: fromRect.left, y: fromRect.top }
      this.ready = true
      this.$nextTick(this.launch)
    },
    landed() {
      this.ready = false
    },
  },

  mounted() {
    EventBus.$on(Events.ADD_ITEM, this.tMinusZero)
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.ibx-add-to-fly {
  // display: none;
  position: fixed;
  width: 30px;
  height: 30px;
  z-index: 1000;
  transform-origin: center center;

  &--ready {
    display: block;

    opacity: 1;
  }

  .itemTypeIcon {
    width: 30px;
    height: 30px;
    fill: white;
    padding: 2px 2px;
    background-color: $G200;
    border-radius: 5px;
    margin-right: 20px;
  }
}
</style>
