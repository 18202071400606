<template>
  <div class="x-content-card-wrapper">
    <v-card-title class="x-content-card-header">
      <!-- TITLE/ICON -->
      <div class="x-content-card-header__title" :class="titleClasses">
        <span v-if="preTitle" class="x-content-card-header__title__pre-title">
          <slot name="pre-title">Q1</slot>
        </span>
        <slot name="title">
          <div v-if="titleIcon" class="x-content-card-header__title__icon">
            <font-awesome-icon
              v-if="titleIcon === 'Passage_1'"
              :icon="['fas', 'book']"
              class="icon icon-passage"
              v-bind="getTitleIconAttrs"
              color="#fff"
            ></font-awesome-icon>
            <svgicon v-else v-bind="getTitleIconAttrs" class="icon"></svgicon>
          </div>
          <div class="x-content-card-header__title__label" v-bind="getTitleAttrs">
            {{ cardTitle }}
          </div>
        </slot>
        <slot name="isDraft" v-if="isDraftItem">
          <v-chip class="draft-label" color="#433CA9"> Draft </v-chip>
        </slot>
      </div>

      <!-- ACTIONS (RIGHT) -->
      <div class="x-content-card-header__actions">
        <!-- BUTTONS -->

        <slot name="actions">
          <v-tooltip
            bottom
            slot="append"
            v-for="(action, i) in actions"
            :key="`action-${i}`"
            :disabled="!action.tooltip"
            content-class="custom-tooltip"
            open-delay="0"
            close-delay="0"
            transition="fade"
          >
            <template v-slot:activator="{ on: tooltip }">
              <x-btn
                small
                v-bind="getActionAttrs(action, i)"
                @click="() => onActionClick(action.id || i)"
                v-on="{ ...tooltip }"
                :id="`action-${i}`"
                >{{ action.name }}</x-btn
              >
            </template>
            <span>{{ action.tooltip }}</span>
          </v-tooltip>
        </slot>

        <!-- MORE DROPDOWN -->
        <slot name="auxilliary-actions">
          <v-menu v-if="hasAuxilliaryActions" bottom offset-y auto transition="fade" :z-index="getMenuZIndex">
            <template v-slot:activator="{ on: menu }">
              <v-btn class="auxilliary-actions__activator" v-on="{ ...menu }" text icon small>
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <template>
                <v-list-item
                  v-for="(aux, i) in auxilliaryActions"
                  :key="`aux-${i}`"
                  @click="() => onAuxilliaryActionClick(aux, i)"
                  :disabled="checkIfDisabled(aux)"
                >
                  <v-tooltip
                    v-if="checkIfDisabled(aux)"
                    bottom
                    open-delay="0"
                    close-delay="0"
                    transition="fade"
                    content-class="custom-tooltip"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-content v-bind="attrs" v-on="on">
                        <v-list-item-title>{{ aux.name }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <span>This type of item can't be edited after it is published.</span>
                  </v-tooltip>
                  <v-list-item-content v-else>
                    <v-list-item-title>{{ aux.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </slot>

        <div v-if="close" class="x-content-card-header__actions__close" @click="onCloseClick">
          <font-awesome-icon :icon="['fal', 'times']" />
        </div>
      </div>
    </v-card-title>
    <div class="x-content-card-items">
      <!-- STANDARDS (CHIP) -->
      <div class="x-content-card-header__standards" id="header-standards-menu" v-if="standards">
        <slot name="standards">
          <v-menu v-model="showStandards" offset-y nudge-left="5" :z-index="getMenuZIndex" :disabled="!standards">
            <template v-slot:activator="{ on: menu }">
              <v-tooltip
                bottom
                open-delay="0"
                close-delay="0"
                transition="fade"
                content-class="custom-tooltip"
                :disabled="!hasStandards"
              >
                <template v-slot:activator="{ on: tooltip }">
                  <v-chip
                    class="standards-pointer label"
                    v-bind="getStandardAttrs(standards)"
                    v-on="standards != null ? { ...tooltip, ...menu } : {}"
                    ><span>{{ getStandardLabel }}</span>
                    <font-awesome-icon
                      :color="standards.items.length ? '#fff' : '#000'"
                      class="ml-2 media-type-label"
                      :icon="['fas', 'caret-down']"
                    />
                  </v-chip>
                </template>
                <span>Click to expand</span>
              </v-tooltip>
            </template>

            <v-list class="x-content-card-header__standards-list">
              <v-list-item v-if="getFilteredStandards.length">
                <v-list-item-content class="standard-label"> Filtered Standards </v-list-item-content>
              </v-list-item>
              <v-list-item v-for="item in getFilteredStandards" :key="item.id" avatar>
                <v-list-item-content class="chip-standard-item">
                  {{ stdLabel(getStandard(item)) }}
                </v-list-item-content>
                <v-list-item-avatar class="chip-standard-info">
                  <v-tooltip right open-delay="0" close-delay="0" transition="fade" content-class="custom-tooltip">
                    <template v-slot:activator="{ on: tooltip }">
                      <font-awesome-icon :icon="['fas', 'info-circle']" v-on="{ ...tooltip }" />
                    </template>
                    <ibx-standard-tooltip :standard="getStandard(item)" :loading="!standardDataLoaded" />
                  </v-tooltip>
                </v-list-item-avatar>
              </v-list-item>
              <v-list-item>
                <v-list-item-content class="standard-label">
                  <span v-if="getFilteredStandards.length">Additional Standards</span>
                  <span v-else>Standards</span>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-for="item in getNonBaseStandards" :key="item.id" avatar>
                <v-list-item-content class="chip-standard-item">
                  {{ stdLabel(getStandard(item)) }}
                </v-list-item-content>
                <v-list-item-avatar class="chip-standard-info">
                  <v-tooltip right open-delay="0" close-delay="0" transition="fade" content-class="custom-tooltip">
                    <template v-slot:activator="{ on: tooltip }">
                      <font-awesome-icon :icon="['fas', 'info-circle']" v-on="{ ...tooltip }" />
                    </template>
                    <ibx-standard-tooltip :standard="getStandard(item)" :loading="!standardDataLoaded" />
                  </v-tooltip>
                </v-list-item-avatar>
              </v-list-item>
            </v-list>
          </v-menu>
        </slot>
      </div>
      <!-- DETAILS (CHIPS) -->

      <div class="x-content-card-header__details">
        <slot name="details">
          <v-tooltip
            bottom
            open-delay="0"
            close-delay="0"
            transition="fade"
            content-class="custom-tooltip"
            v-for="(detail, i) in getDetails"
            :key="`detail-${i}`"
          >
            <template v-slot:activator="{ on: tooltip }">
              <v-chip class="label" v-on="{ ...tooltip }" v-bind="getDetailAttrs(detail)">{{
                getDetailLabel(detail)
              }}</v-chip>
            </template>
            <span>{{ detail.name }}</span>
          </v-tooltip>
        </slot>
      </div>

      <!-- Genres (CHIP) -->

      <div class="x-content-card-header__genres" id="header-genres-menu" v-if="passageGenres">
        <slot name="genres">
          <v-menu offset-y>
            <v-tooltip
              bottom
              open-delay="0"
              close-delay="0"
              content-class="custom-tooltip"
              :disabled="!hasMutlipleGenres"
            >
              <template v-slot:activator="{ on: tooltip }">
                <v-chip
                  class="genres-pointer label"
                  v-bind="getDetailAttrs(passageGenres)"
                  v-on="{ ...tooltip, ...menu }"
                  >{{ getGenreLabel }}</v-chip
                >
              </template>
              <span>Click to expand</span>
            </v-tooltip>
            <v-list class="x-content-card-header__genres-list" v-if="passageGenres.items.length > 1">
              <v-list-item v-for="item in passageGenres.items" :key="item.id">
                <v-list-item-content>
                  {{ item.name }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </slot>
      </div>
      <slot name="mediaType">
        <v-tooltip
          bottom
          open-delay="0"
          close-delay="0"
          transition="fade"
          content-class="custom-tooltip"
          v-if="showIcons"
        >
          <template v-slot:activator="{ on: tooltip }">
            <v-chip class="label" v-on="{ ...tooltip }">
              <font-awesome-icon class="media-type-label" :icon="['fal', 'desktop']" />
              <font-awesome-icon class="media-type-label" :icon="['fal', 'file-alt']" v-if="showPrintIcon" />
            </v-chip>
          </template>
          <span>{{ showPrintIcon ? 'Paper & Web' : 'Web only' }}</span>
        </v-tooltip>
      </slot>
      <v-tooltip bottom open-delay="0" close-delay="0" transition="fade" content-class="custom-tooltip" max-width="200">
        <template v-slot:activator="{ on: tooltip }">
          <slot name="itemInUse" v-if="inUse">
            <v-chip v-on="{ ...tooltip }" class="label">Item In Use</v-chip>
          </slot>
        </template>
        <span>This item is currently in use on an assessment you have published.</span>
      </v-tooltip>

      <!-- item lock -->
      <span class="input mr-6 ml-auto" v-if="featureFlag(FLAG.ITEM_LOCKS) && canLock">
        <v-tooltip bottom open-delay="0" close-delay="0" transition="fade" content-class="custom-tooltip">
          <template v-slot:activator="{ on: tooltip }">
            <div v-on="{ ...tooltip }">
              <x-switch color="#008272" class="lock" icon="lock" :value="locked" @change="onLockItem"></x-switch>
            </div>
          </template>
          <span>
            When an item is locked, it is only accessible to users with the permission Itembank - Lock, Unlock, & Use
            Locked Item.
          </span>
        </v-tooltip>
      </span>
    </div>
  </div>
</template>

<script>
import * as VCard from 'vuetify/es5/components/VCard'
import * as VChip from 'vuetify/es5/components/VChip'
import * as VMenu from 'vuetify/es5/components/VMenu'
import * as VList from 'vuetify/es5/components/VList'
import * as VTooltip from 'vuetify/es5/components/VTooltip'
import XBtn from '@xLib/XBtn'
import { Events } from '@events'
import { pluralizeString } from '@helpers'
import { mapGetters, mapActions } from 'vuex'
import CONST from '@constants'
import IbxStandardTooltip from '@components/ibx/IbxStandardTooltip'
import { StandardLabelMixin } from '@mixins'
import XSwitch from '@xLib/XSwitch'
import { FLAG } from '@constants'

const shortMetaNames = [
  { name: 'Depth of Knowledge', shortName: 'DOK' },
  { name: 'Lexile', shortName: 'LEX:' },
  { name: 'Word Count', shortName: 'WC:' },
]

export default {
  name: 'XContentCardHeader',

  mixins: [StandardLabelMixin],

  components: {
    ...VCard,
    ...VChip,
    ...VMenu,
    ...VList,
    ...VTooltip,
    XBtn,
    IbxStandardTooltip,
    XSwitch,
  },

  props: {
    cardTitle: { type: String, default: 'Title' },
    titleColor: { type: String, default: '#008272' },
    titleIcon: { type: String, default: '' },
    details: { type: Array, default: () => [] },
    standards: { type: Object, default: null },
    passageGenres: { type: Object, default: null },
    actions: { type: Array, default: () => [] },
    auxilliaryActions: { type: Array, default: () => [] },
    close: { type: Boolean, default: false },
    view: { type: String, default: null },
    dragClass: { type: String, default: 'drag-handle' },
    inUse: { type: Boolean, default: false },
    media: { type: Object, default: null },
    locked: { type: Boolean, default: false },
    showLock: { type: Boolean, default: false },
    canLock: { type: Boolean, default: false },
    preTitle: { type: Boolean, default: false },
    isDraft: { type: Boolean, default: false },
    itemType: { type: String, default: '' },
  },

  data: () => ({
    value: false,
    standardData: [], // these specific kind of things should not be in this component... oh well
    showStandards: false,
  }),

  watch: {
    showStandards(show) {
      if (show && !this.standardDataLoaded) {
        this.fetchStandards()
      }
    },
  },

  computed: {
    ...mapGetters('user', ['userSelection']),
    ...mapGetters('ui', ['isItemFullScreen']),
    ...mapGetters('features', {
      featureFlag: 'flag',
    }),
    standardDataLoaded() {
      return this.standardData.length > 0
    },
    getDetails() {
      return this.details.filter((o) => Boolean(o.id))
    },
    hasAuxilliaryActions() {
      return this.auxilliaryActions.length > 0
    },
    getTitleAttrs() {
      return {
        style: { color: '#000' },
      }
    },
    getTitleIconAttrs() {
      return {
        name: this.titleIcon,
        style: { 'background-color': this.titleColor },
      }
    },
    titleClasses() {
      return this.view == 'close' ? [this.dragClass] : [this.dragClass]
    },
    getGenreLabel() {
      if (this.passageGenres.items.length > 1) {
        return `${this.passageGenres.items.length} Genres`
      } else {
        return `${this.passageGenres.items[0].name}`
      }
    },
    getStandardLabel() {
      return this.standards.items.length > 0 ? pluralizeString(this.standards.items.length, 'Standard') : '0 Standards'
    },
    getFilteredStandards() {
      return this.standards.items.filter((s) => this.getFilteredUserSelections.includes(s.id))
    },
    getNonBaseStandards() {
      return this.standards.items.filter((s) => !this.getFilteredUserSelections.includes(s.id))
    },
    hasMutlipleGenres() {
      return this.passageGenres && this.passageGenres.items.length > 1
    },
    hasStandards() {
      return this.standards && this.standards.items.length > 0
    },
    hasMediaType() {
      return this.media && this.media.items.length > 0
    },
    isDraftItem() {
      return this.isDraft
    },
    showIcons() {
      return this.itemType ? true : false
    },
    showPrintIcon() {
      const printItemTypes = this.featureFlag(FLAG.PRINT_ITEM_TYPES).itemTypes
      return printItemTypes.includes(this.itemType)
    },
    getFilteredUserSelections() {
      const selections = this.userSelection(CONST.CONTEXT_BROWSE_FILTERS).value
      return selections.selectedFilters ? selections.selectedFilters.standard || [] : []
    },
    getMenuZIndex() {
      // for v-menu to show in ui, but not above ibx header
      return this.isItemFullScreen ? 9999 : 7
    },
    FLAG() {
      return FLAG
    },
  },

  methods: {
    ...mapActions('standardsItems', {
      standardItemsGetItems: 'getItems',
    }),
    getStandardAttrs(detail) {
      // colored chip if it's standards or has more than one item
      return {
        color: this.hasStandards ? '#747F9E' : '#E0E1E6',
        'text-color': this.hasStandards ? '#FFFFFF' : '#000000',
      }
    },
    getDetailAttrs(detail) {
      // colored chip if it's standards or has more than one item
      return {
        color: this.hasMultipleItems(detail) ? '#747F9E' : '#E0E1E6',
        'text-color': this.hasMultipleItems(detail) ? '#FFFFFF' : '#000000',
      }
    },
    getDetailLabel(detail) {
      if (detail.items) {
        var hasShortName = shortMetaNames.some((x) => x.name === detail.name)
        var shortLabel = hasShortName
          ? this.getShortFullLabel(shortMetaNames.find((x) => x.name === detail.name).shortName, detail.items[0])
          : detail.name

        return hasShortName ? shortLabel : pluralizeString(detail.items.length, detail.name)
      }
    },
    getShortFullLabel(name, value) {
      if (name === 'DOK') return `${name} ${value.name[0]}`
      // to only get the number of DOK
      else return `${name} ${value.name}`
    },
    getActionAttrs(action, index) {
      return {
        color: action.color ? action.color : 'tertiary',
        icon: action.icon,
        data: { id: action.id || index }, // action id to emit on click
      }
    },
    hasMultipleItems(detail) {
      return detail && detail.items ? detail.items.length > 1 : false
    },
    onActionClick(id) {
      this.$emit(Events.ACTION, { id })
    },
    onAuxilliaryActionClick(aux, index) {
      if (aux.id == Events.EDIT_ITEM && aux.disabled) {
        return
      }
      this.$emit(Events.ACTION, { id: aux.id || index, data: aux.data })
      this.$emit(Events.CLOSE)
      this.$emit('close-passage-card')
    },
    checkIfDisabled(aux) {
      return aux.id == Events.EDIT_ITEM && aux.disabled ? true : false
    },
    onCloseClick() {
      this.$emit(Events.CLOSE)
    },
    async fetchStandards() {
      const guids = this.standards.items.map((o) => o.id)
      this.standardData = await this.standardItemsGetItems(guids)
    },
    getStandard(item) {
      return this.standardData.find((o) => o.guid == item.id) || null
    },
    onLockItem(v) {
      if (this.canLock) this.$emit('lock-item', v)
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/main';
.x-content-card-wrapper {
  width: 100%;
}
.x-content-card-items {
  align-items: center;
  display: flex;
  border-top: 1px solid $N20;
  margin-top: 10px;
  padding-top: 10px !important;
  .media-type-label {
    margin: 5px;
  }
  .v-chip,
  .theme--light {
    margin: 0px 4px !important;
  }
  .v-input {
    display: flex;
    align-items: center;
    margin: 0 0 0 0 !important;
    padding: 0 0 0 0;

    .v-input__control {
      .v-messages {
        display: none;
      }
      .v-input__slot {
        margin: 0 0 0 0;
        .v-input--selection-controls__input {
          margin: 0 0 0 0 !important;

          .v-icon {
            margin: 0 0 0 0 !important;
          }
          .v-input--selection-controls__ripple {
            display: none;
          }
        }
      }
    }
  }
}
.x-content-card-header,
.x-content-card-header.v-card__title {
  padding: 0px !important;
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;

  .v-chip,
  .theme--light {
    margin: 0px 4px !important;
  }
  .draft-label {
    .v-chip__content {
      color: white;
    }
  }

  &__title__pre-title {
    margin-right: 16px;
    font-size: 16px;
    font-weight: 800;
  }

  &__title {
    margin-right: 32px;
    display: flex;
    align-items: center;
  }

  &__title__label {
    font-family: 'CerebriSans', Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
  }

  &__title__icon {
    display: flex;
    align-items: center;

    .icon {
      width: 30px;
      height: 30px;
      fill: white;
      padding: 2px 2px;
      border-radius: 5px;
      margin-right: 20px;

      &-passage {
        padding: 5px !important;
      }
    }
  }

  // todo: pointer for genres and standards
  // &__standards {
  //     .standards-pointer {
  //         .v-chip__content {
  //             cursor: pointer;
  //         }
  //     }
  // }

  &__details {
    //...
  }

  &__actions {
    margin-left: auto;
    display: flex;
    align-items: center;

    &__close {
      color: $N90;
      font-size: 16px;
      margin-left: 8px;
      margin-right: 5px;

      cursor: pointer;
    }
    .input {
      display: flex;
      align-items: center;
      margin-left: 6px;

      .input-label {
        font-family: 'CerebriSans', Roboto, sans-serif;
        font-size: 12px;
        font-weight: 500;
        margin-right: 7px;
      }
    }
    .ibx-asmt-item-card__auxilliary-actions__lock {
      .v-input--selection-controls {
        &__ripple {
          display: none;
        }
      }
    }
  }

  .auxilliary-actions__activator {
    background: none;
    border-radius: 50% !important;
  }

  .drag-handle {
    cursor: grab;
  }
}

.x-content-card-header__standards-list,
.x-content-card-header__genres-list {
  .v-list-item {
    font-family: 'CerebriSans', Roboto, sans-serif;
    font-size: 12px !important;
    height: 40px;
    max-height: 40px;
  }

  .standard-label {
    font-weight: bold;
  }
}

.v-menu__content {
  border-radius: 5px;
  .v-list-item.v-list-item--disabled {
    pointer-events: auto;
  }
}

.label,
.v-chip.label,
.v-chip.label.theme--light {
  border-radius: 4px !important;
}

.standards-pointer span {
  cursor: pointer !important;
}
</style>
