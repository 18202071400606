import { render, staticRenderFns } from "./_IbxAssessmentItemCard.vue?vue&type=template&id=d27eaece"
import script from "./_IbxAssessmentItemCard.vue?vue&type=script&lang=ts"
export * from "./_IbxAssessmentItemCard.vue?vue&type=script&lang=ts"
import style0 from "./_IbxAssessmentItemCard.vue?vue&type=style&index=0&id=d27eaece&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports