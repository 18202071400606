
// @ts-nocheck
import { Vue, Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import ItemAuthorItemMenu from '@components/itemAuthor/ItemAuthorItemMenu'
import ItemAuthorPlayer from '@components/itemAuthor/ItemAuthorPlayer'
import ItemAuthorBase from '@components/itemAuthor/base/ItemAuthorBase'
import { StateAuthorItems, StateAuth, StateFeatures } from '@/helpers/state'
import _ from 'lodash'
import { PieAuthorItemService } from '@/services/pie'
import { IBXService } from '@/services/ibx/IBXService'
import { ItemAuthor } from '../ibx/base/Item'
import { EventBus, Events } from '@events'
import { CONST, FLAG } from '@constants'
import { Id } from '@/components/ibx/base/Types'
import { calculateItemWeight, supportsRubric } from '@helpers/itemHelpers'
import { AsmtMixin } from '@mixins'

type Mutation = {
  itemId: Id
  key: string
  value: any
}

type PassageAff = {
  passageRevId: Id
  remoteId: Id
  link: boolean
}

@Component({
  components: {
    ItemAuthorItemMenu,
    ItemAuthorPlayer,
  },
})
export default class ItemAuthorItemContainer extends Mixins(
  Vue,
  ItemAuthorBase,
  StateAuthorItems,
  StateAuth,
  StateFeatures,
  AsmtMixin
) {
  @Prop({ default: 0 }) index: number

  private pieItemService: PieAuthorItemService = null

  /**
   * Used when user is done editing/creating an commits
   */
  private commit: boolean = false

  /**
   * action taken when commiting
   */
  private commitSaveAction: string = null

  /**
   * Item mutation keys. Keeps track of
   * mutations while debouncing.
   */
  private mutations: string[] = []

  /**
   * save item debounce wrapper
   */
  private onSaveItem = _.debounce(this.saveItem, 500)

  /**
   * passage aff change debounce wrapper
   */
  private onPassageAffChange = _.debounce(this.passageAffChange, 500)

  /**
   * Item in process of saving
   */
  private saving: boolean = false

  /**
   * Passage affiliation data to queue
   * fo runsaved new items.
   */
  private passageAffQueue: PassageAff = null

  /**
   * Menu Tab selected content
   */
  private menuTabView: String = null

  /**
   * Index of item when menu tab clicked
   */
  private menuIndex: number = 0

  /**
   * Has unsaved mutations
   */
  get hasItemChange(): boolean {
    return Boolean(this?.mutations?.length)
  }

  /**
   * Has unsaved passage affiliation change
   */
  get hasPassageAffChange(): boolean {
    return Boolean(this?.item?.passageAffMutated)
  }

  /**
   * Passage menu is active flag
   */
  get passageEditMenuActive(): boolean {
    return this.menuTabView == 'edit'
  }

  /**
   * Return item mutable meta data.
   * Merge merge selected banks.
   */
  get mutableMeta(): any {
    const meta = _.cloneDeep(this.item.mutatableMeta)
    const selectedBanks = this.authorSelectedBanks.map((id) => ({
      id: String(id),
    }))
    meta.bank.items = selectedBanks
    return meta
  }

  /**
   * Dynamic container clasess
   */
  get containerClass(): string[] {
    return this.passageEditMenuActive ? ['item-author-item-container--passage-editing'] : []
  }

  /**
   * Set store item saving state
   */
  @Watch('saving')
  onSaving(saving) {
    this.authorSetSaving({
      Id: this.itemId,
      saving,
    })
  }

  @Watch('passageEditMenuActive')
  onPassageMenuActiveChange(v: boolean) {
    if (v) this.$emit('passage-edit')
    this.$refs?.itemMenu?.expandPassage(v)
  }

  /**
   * Emits error event
   * @param error
   */
  onError(error: string) {
    this.$emit('error', { error: `authoring: ${error}`, text: 'We encountered an error while saving.' })
  }

  /**
   * Clear traked item errors
   */
  clearErrors() {
    this.authorTrackItemError({
      itemId: this.itemId,
    })
  }

  /**
   * Item change handler.
   * Track mutations, update store, then debounce save
   * if autosave and not committing (done aciton)
   * @param mutations array of mutations
   * @param save trigger immediate save
   */
  onItemChange(mutations: Mutation[], save?: boolean) {
    if (mutations?.length) {
      this.changeItem(mutations)

      // immediate save or debounced save
      if (save) this.saveItem()
      else if (this.autosave && !this.commit) this.onSaveItem()
    }
  }

  /**
   * Update item and track mutations
   * If multiple choice, don't allow user to add more
   * than 9 choices & hide PIE's 'Add Choice' button
   * @param mutations array of mutations
   */
  changeItem(mutations: Mutation[]) {
    if (mutations?.length) {
      this.authorUpdateItem(mutations)
      mutations.forEach(this.trackMutation)
    }
  }

  /**
   * Track mutations
   * @param mutation
   */
  trackMutation(mutation: Mutation) {
    this.mutations = _.uniq([...this.mutations, mutation.key])
  }

  setContentConfigError({ config }: { config: any }) {
    if (Object.keys(config).length) {
      config.models = config.models.map((o: any) => {
        o.errors = {}
        return o
      })
    }
    return config
  }
  /**
   * Called externally with publish/draft/discard-changes option
   * @param action save action type
   */
  async commitAction(action: string): Promise<any> {
    this.commitSaveAction = action
    this.commit = true
    let saveType = null
    let data = null
    const contentConfig = this.setContentConfigError({
      config: _.cloneDeep(this.item.contentConfig),
    })
    // action handlers
    switch (action) {
      case CONST.PUBLISH: // set to published, save if has change
      case CONST.PUBLISH_NEW:
        this.onItemChange([
          {
            itemId: this.itemId,
            key: 'published',
            value: true,
          },
          {
            itemId: this.itemId,
            key: 'contentConfig', // required for pie publish
            value: _.cloneDeep(contentConfig),
          },
        ])
        saveType = this.hasItemChange ? 'save' : null
        break
      case CONST.DRAFT: // save if has change
        saveType = this.hasItemChange || this.authorBanksChanged ? 'save' : null
        break
      case CONST.DISCARD_CHANGES: // unset store changed flag for item, don't save
        this.authorSetChanged({
          Id: this.itemId,
          changed: false,
        })
        saveType = null
        break
      case CONST.DELETE: // delete item
        saveType = 'delete'
        this.authorUpdateItem([
          {
            itemId: this.itemId,
          },
        ])
        break
    }

    // save action. Wait if saving is in progress
    try {
      await this.waitForSave()
      if (saveType == 'delete') {
        data = await this.deleteItem()
      } else {
        data = await this.saveItem(action)
      }
    } catch (error) {
      throw { error }
    } finally {
      this.commit = false
    }

    return { item: data.item, action }
  }

  /**
   * Wait for saving to complete
   * @returns Promise resolve promise when saving is done
   */
  async waitForSave(): Promise<any> {
    if (this.saving) {
      return new Promise((resolve) => {
        const unwatch = this.$watch('saving', (saving) => {
          if (!saving) {
            unwatch()
            resolve(saving)
          }
        })
      })
    } else {
      return Promise.resolve(this.saving)
    }
  }

  /**
   * Save item mutations if any
   * @param action commit action taken on save and exit
   */
  async saveItem(action: string): Promise<any> {
    // allow if committing else run checkitemRevisionCreate
    const allow: boolean = !this.commit
      ? this.hasItemChange && !this.saving && this.mutations?.length
      : Boolean(this.mutations?.length)

    if (allow || this.authorBanksChanged) {
      this.saving = true
      try {
        this.clearErrors()

        const mutations = this.mutations // request mutations
        this.mutations = []

        // create/update item then update store
        if (this.isNew) {
          const clientId = this.item.itemId
          const { item } = await this.createItem(mutations)
          await this.authorOnItemCreated({
            clientId, // old temp itemId
            itemId: item.itemId, // new itemId
            itemData: item,
          })
        } else {
          const { item } = await this.updateItem(mutations)
          await this.authorOnItemUpdated({
            itemId: item.itemId,
            itemData: item,
          })
        }

        // if committing (done action) return item
        if (this.commit) {
          // save passage aff if needed
          await this.checkSavePassageAff(action)
          return { item: this.item }
        }
      } catch (error) {
        this.authorTrackItemError({
          itemId: this.itemId,
          error,
        })
        this.onError(error)
      } finally {
        this.saving = false
      }
    } else {
      // save passage aff if needed
      await this.checkSavePassageAff(action)
      return { item: this.item }
    }
  }

  /**
   * Check and save item unsaved item passage affiliation change.
   */
  async checkSavePassageAff(action: string): Promise<any> {
    if (this.passageAffQueue) {
      try {
        await this.savePassageAff(this.passageAffQueue)
      } catch (error) {
        this.authorTrackItemError({
          itemId: this.itemId,
          error,
        })
        throw error
      } finally {
        if (this.commit) {
          this.passageAffQueue = null
        }
      }

      await this.checkPassageDataRefresh(action)
    }
  }

  /**
   * Refresh item/passage data if item has passage and we're publishing
   */
  async checkPassageDataRefresh(action: string): Promise<any> {
    if (this.item?.passage && ['publish', 'publish-new'].includes(action)) {
      try {
        const { itemId, itemRevId } = this.item
        const { item } = await IBXService.itemRevisions(itemRevId)
        this.authorHydrateItemPassageAff({
          itemId,
          itemData: item,
        })
      } catch (error) {
        console.warn(error)
      }
    }
  }

  /**
   * Create PIE and IBX item.
   * @param mutations mutation for this request
   * @throws error from PIE or IBX API
   */
  async createItem(mutations: string[] = []): Promise<any> {
    // enqueue passage link if needed
    if (this.hasPassageAffChange) {
      this.enqueuePassageAff({
        passageRevId: this.item.passageAff?.passageRevId,
        remoteId: this.item.passageAff?.remoteId,
        link: true,
      })
    }

    // create pie item
    const { data } = await this.createPieItem(mutations)
    const version = this.pieItemService.getVersionString(data.version)
    const remoteId = data.id
    if (!version) throw `Invalid PIE semver: ${version}`
    return await this.createIbxItem(remoteId, version)
  }

  /**
   * Update PIE and IBX item
   * @param mutations mutation for this request
   */
  async updateItem(mutations: string[] = []): Promise<any> {
    const data = await this.updatePieItem(mutations)
    const version = data.version || this.item?.version
    if (!version) throw `Invalid PIE semver: ${version}`
    return await this.updateIbxItem(version, mutations)
  }

  /**
   * Create PIE item.
   * Update meta data if mutated
   * @param mutations mutation for this request
   */
  async createPieItem(mutations: string[] = []): Promise<any> {
    const params: any = {
      itemVersionedID: '',
      itemConfig: this.item.contentConfig,
    }

    if (mutations.includes('meta')) params.itemMetaData = this.pieMetaData
    if (mutations.includes('published')) params.release = this.item.published

    return await this.pieItemService.saveItem(params)
  }

  /**
   * Create IBX item.
   * @param remoteId PIE item id
   * @param version PIE item version
   */
  async createIbxItem(remoteId: String, version: String): Promise<any> {
    if (!remoteId) {
      throw 'RemoteId required for item creation'
    }

    return await IBXService.itemCreate({
      itemType: this.item.itemType,
      meta: this.mutableMeta,
      published: this.item.published || false,
      remoteId: remoteId,
      version: version,
    })
  }

  /**
   * Update PIE item
   * @param mutations mutation for this request
   */
  async updatePieItem(mutations: string[] = []): Promise<{ id: string; version: string }> {
    const params: any = {
      itemVersionedID: this.item.remoteId, // no version so we release without errors
    }
    const mutatedMeta = Boolean(_.intersection(ItemAuthor.PieMutableMetaKeys, mutations).length)

    if (mutations.includes('contentConfig')) params.itemConfig = this.item.contentConfig
    if (mutations.includes('published')) params.release = this.item.published
    if (mutatedMeta) params.itemMetaData = this.pieMetaData

    // save if mutated else return data
    const mutated = Object.keys(params).length > 1
    if (mutated) {
      let { data } = await this.pieItemService.saveItem(params)
      data.version = data.versioned
        ? this.pieItemService.getVersionString(data.version)
        : data.versionedId.split('@')[1]
      return data
    } else {
      return {
        id: this.item.remoteId,
        version: this.item.version,
      }
    }
  }

  /**
   * Update IBX item
   * @param version pie item version
   * @param mutations mutation for this request
   */
  async updateIbxItem(version: string, mutations: string[] = []): Promise<any> {
    if (!version) throw 'version required for item update'

    const publish = this.item.publishedGenesis || this.item.published
    const params: any = {
      itemType: this.item.itemType,
      published: publish,
      version: version,
      meta: this.mutableMeta,
    }

    // update assessments
    if (this.commitSaveAction == CONST.PUBLISH) {
      params.updateAssessments = true

      if (this.featureFlag(FLAG.ITEM_REVISION_OPTIONS)) {
        params.updateAssessmentsOption = this.authorItemRevUpdateOption
      }

      if (supportsRubric(this.item.itemType)) {
        params.weight = calculateItemWeight(this.item.contentConfig)
      }
    }

    const mutatedMeta = Boolean(_.intersection(ItemAuthor.MutatbleMetaKeys, mutations).length)

    if (mutations.includes('itemType')) params.itemType = this.item.itemType

    // create revision for published items else patch revision
    return await (publish
      ? IBXService.itemRevisionCreate(this.item.itemId, params)
      : IBXService.itemRevisionUpdate(this.item.itemRevId, params))
  }

  /**
   * Passage affiliation change handler.
   * Sets item state (in store).
   * Saves passage affiliation if not new uncreated item.
   * @param passageRevId ibx passageRevId
   * @param remoteId pie stimulus id
   * @param link link/unlink flag
   */
  passageAffChange({ passageRevId, remoteId, link }: { passageRevId: Id; remoteId: Id; link: boolean }) {
    // update state
    this.authorSetItemPassageLink({
      itemId: this.item.itemId,
      passageRevId,
      remoteId,
      link,
    })

    // enqueue passage aff
    if (this.hasPassageAffChange) {
      this.enqueuePassageAff({
        passageRevId,
        remoteId,
        link,
      })
    } else {
      this.passageAffQueue = null
    }
  }

  /**
   * Enqueue passage aff change
   * @param data PassageAff
   */
  enqueuePassageAff(data: PassageAff) {
    this.passageAffQueue = data
    // this.onItemChange()
  }

  /**
   * Save passage affiliation and update ibx item version
   * @param passageRevId ibx passageRevId
   * @param remoteId pie stimulus id
   * @param link link/unlink flag
   */
  async savePassageAff({ passageRevId, remoteId, link }: PassageAff) {
    this.authorSetPassageAffSaving(true)
    this.saving = true
    try {
      // save passage
      const { data } = await this.savePiePassageAff({ remoteId, link })
      await this.saveIbxPassageAff({
        passageRevId,
        link,
      })

      // update ibx item version
      const remoteItemVersion = data?.versionedId?.split('@')[1]
      const { item } = await this.updateIbxItem(remoteItemVersion)

      // hydrate store/state
      this.authorOnItemPassageUpdated({
        itemId: this.item.itemId,
        itemData: item,
      })
    } catch (error) {
      console.warn('PASSAGE LINK ERROR:', error)
    } finally {
      this.authorSetPassageAffSaving(false)
      this.saving = false
    }
  }

  /**
   * Save PIE passage affiliation
   * @param remoteId pie stimulus id (stimulusVersionedID)
   * @param link link/unlink flag
   */
  async savePiePassageAff({ remoteId, link }: { remoteId: Id; link: boolean }) {
    const itemVersionedID = this.item.remoteIdVersioned
    if (!itemVersionedID) throw 'Item versionedID required for stimulus link'

    return await this.pieItemService.saveItem({
      itemVersionedID,
      stimulusVersionedID: remoteId as string,
      linkStimulus: link,
    })
  }

  /**
   * Save Ibx passage affiliation
   * @param passageRevId
   * @param link link flag
   */
  async saveIbxPassageAff({ passageRevId, link }: { passageRevId: Id; link: boolean }) {
    if (!passageRevId) throw 'passageRevId required for passage/item affiliation'
    const itemRevId = this.item.itemRevId

    return await (link
      ? IBXService.passageRevisionItemsAdd(passageRevId, [{ itemRevId }])
      : IBXService.passageRevisionItemsRemove(passageRevId, [{ itemRevId }]))
  }

  /**
   * Delete item
   */
  async deleteItem(): Promise<any> {
    if (this.item.itemRevId && !this.item.isNew) {
      this.authorTrackItemActions({
        itemId: this.item.itemId,
        action: 'delete',
      })
      return await IBXService.itemDelete(this.item.itemId)
    } else {
      return { item: this.item }
    }
  }

  /**
   * On menu change handler
   * Forces save if autosave and has changes
   * Open/close of menu, check if add choice button needs disabling
   * Design tab click event, only shows with open of menu
   * @param tabView active menu tab
   */
  onMenuChange({ tabView, index }: { tabView?: String; index?: Number }) {
    if (this.authorAutosave && this.hasItemChange) this.$nextTick(this.saveItem)

    this.menuTabView = tabView || null
  }

  /**
   * Vue hook
   */
  created() {
    this.commitSaveAction = null
    EventBus.$on(Events.AUTHOR_ITEMS_SAVE_ACTION, this.onAuthorItemsSaveAction)
    this.pieItemService = new PieAuthorItemService({
      token: this.authPieToken,
      apiUrl: process.env.VUE_APP_PIE_URI,
    })
    this.menuIndex = this.index
  }
  /**
   * Vue hook
   */
  beforeDestroy() {
    this.commitSaveAction = null
    EventBus.$off(Events.AUTHOR_ITEMS_SAVE_ACTION, this.onAuthorItemsSaveAction)
  }
}
