import { Node } from 'tiptap'
import TextEditorVideo from '@components/itemAuthor/passages/TextEditor/TextEditorVideo.vue'
import CONST from '@constants'

export default class Video extends Node {
  private defaultWidth: number = CONST.EMBED_VIDEO_WIDTH
  private defaultHeight: number = CONST.EMBED_VIDEO_HEIGHT

  get name() {
    return 'video'
  }

  get schema() {
    return {
      attrs: {
        src: {
          default: null,
        },
        width: {
          default: this.defaultWidth,
        },
        height: {
          default: this.defaultHeight,
        },
        url: {
          default: null,
        },
      },
      draggable: false,
      group: 'block',
      selectable: false,
      parseDOM: [
        {
          tag: 'iframe',
          getAttrs: (dom) => {
            return {
              src: dom.getAttribute('src'),
              url: dom.getAttribute('url'),
              width: dom.getAttribute('width') || this.defaultWidth,
              height: dom.getAttribute('height') || this.defaultHeight,
            }
          },
        },
      ],
      toDOM: (node) => {
        return [
          'iframe',
          {
            src: node.attrs.src,
            url: node.attrs.url,
            frameborder: 0,
            allowfullscreen: 'true',
            allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
            width: node.attrs.width,
            height: node.attrs.height,
          },
        ]
      },
    }
  }

  get view() {
    return TextEditorVideo
  }

  commands({ type }) {
    return (attrs) => (state, dispatch) => {
      const { selection } = state
      const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos
      const node = type.create(attrs)
      const transaction = state.tr.insert(position, node)
      dispatch(transaction)
    }
  }
}
