/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'Multi-Part_1': {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<rect pid="0" x="21" y="21" width="68.57" height="68.57" rx="7.75" _fill="#152452"/><rect pid="1" x="102.43" y="21" width="68.57" height="150" rx="6.7" _fill="#152452"/><rect pid="2" x="21" y="102.43" width="68.57" height="68.57" rx="7.14" _fill="#152452"/>',
  },
})
