<template>
  <transition name="scale-transition" origin="center center">
    <div class="ibx-preview-asmt-modal">
      <v-layout class="ibx-preview-asmt-modal__modal">
        <v-toolbar flat class="ibx-preview-asmt-modal__header">
          Preview Assessment
          <v-spacer></v-spacer>
          <v-btn color="return-btn" @click="hide"> Close </v-btn>
        </v-toolbar>

        <v-flex class="ibx-preview-asmt-modal__content" ref="previewAllContent">
          <div v-if="hasItems">
            <ibx-preview-groups
              v-for="(o, i) in sortedGroupedItems"
              :key="`${getGroupId(i)}`"
              :group="o"
              :asmtHasPassage="asmtHasPassage"
              :id="getGroupId(i)"
              lazy
              :lazyLoadTrigger="lazyLoadTriggers[getGroupId(i)]"
              @loaded="onItemLoaded"
              @load-error="onItemLoaded"
            />
          </div>
          <div v-else class="preview__no-items">
            <img src="assets/img/no-items.png" alt="No Items Added" />
            <div class="text-wrapper">
              <h1>No Items Added</h1>
              <p>
                Looks like there are no items added to your assessment yet! Add some items to see a preview of your
                assessment.
              </p>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </div>
  </transition>
</template>

<script>
import { AsmtMixin, ItemListMixin } from '@mixins'
import { EventBus, Events } from '@events'
import * as VDialog from 'vuetify/es5/components/VDialog'
import * as VCard from 'vuetify/es5/components/VCard'
import * as VToolbar from 'vuetify/es5/components/VToolbar'
import _ from 'lodash'

export default {
  name: 'IbxPreviewAsmtModal',

  components: {
    ...VDialog,
    ...VCard,
    ...VToolbar,
    'ibx-preview-groups': () => import('@components/ibx/previewAsmt/IbxPreviewGroups'),
  },
  mixins: [AsmtMixin, ItemListMixin],

  data: () => ({
    asmtHasPassage: false,
    sortedGroupedItems: [],
  }),

  methods: {
    getSortedGroupedItems() {
      let index = 1
      let testHasPassage = false
      const sortedItems = _.sortBy(this.asmtItems, [(o) => o.order])
      const groupedData = sortedItems.reduce((m, o, i) => {
        const hasPassage = Boolean(o.item.passage)

        // group items for passage
        if (hasPassage) {
          testHasPassage = true
          let passage = m.find((mo) => mo.passageId == o.item.passage.passageId)
          if (!passage) {
            // create passage object & set order to first item order value
            passage = {
              ...o.item.passage,
              order: o.order, // get first order
              items: [],
              startNumber: index,
            }
            m.push(passage)
          }

          // add item to passage items list
          passage.items.push(o)
          index += passage.items.length
        } else {
          var a = {}
          a.items = []
          a.startNumber = index
          a.items.push(o)
          m.push(a) // item with no passage, but same format as groups items in a passage
          index += a.items.length
        }

        return m
      }, [])

      this.setAsmtHasPassage(testHasPassage)
      return groupedData
    },
    getGroupId(index) {
      return this.getCardId({ itemRevId: index })
    },
    hasPassage(item) {
      return Boolean(item.passageRevId)
    },
    setAsmtHasPassage(value) {
      this.asmtHasPassage = value
    },
    hide() {
      EventBus.$emit(Events.ASSESSMENT_PREVIEW, false)
    },
    initQueue() {
      this.viewThreshold = 2000
      this.initItemListQueue({
        context: 'asmt-preview',
        items: this.sortedGroupedItems.map((o, i) => ({ itemRevId: i })), // map for async queue
        max: 20,
        debounce: 0,
      })
      // enqueue all items (only a max of 3 will load at one time)
      this.$nextTick(() => {
        this.asyncQ.addItems(this.sortedGroupedItems.map((o, i) => this.getCardId({ itemRevId: i })))
      })
    },
  },

  created() {
    this.hasItems = this.asmtHasItems
    this.sortedGroupedItems = this.getSortedGroupedItems()
  },

  mounted() {
    this.initQueue()
  },
}
</script>

<style lang="scss">
@import '@/styles/main';
.ibx-preview-asmt-modal {
  $bg-color: #006c90;
  touch-action: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; // make sure modal is above rest of app

  &__modal {
    background: #f7f7f7;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  &__header {
    background: $B121 !important;
    color: white !important;
    box-shadow: none;
    font-size: 20px;
    font-weight: 500;
    font-family: 'CerebriSans', Roboto, sans-serif;

    .return-btn {
      background-color: #ffffff;
      color: #008272 !important;
      flex-shrink: 0;
    }
  }

  &__content {
    background-color: #f7f7f7;
    overflow-y: auto;
    padding: 24px 0;
  }

  .preview__no-items {
    width: 400px;
    margin: 64px auto 0 auto !important;
    text-align: center;
    font-family: 'CerebriSans', Roboto, sans-serif;

    img {
      height: 200px;
    }

    .text-wrapper {
      width: 250px;
      margin: 0 auto;
    }
  }

  .ibx-preview-asmt-modal__header.v-toolbar.theme--light {
    height: 64px;
    max-height: 64px;

    .v-toolbar__content {
      padding: 0px 24px;
    }
  }
}
</style>
