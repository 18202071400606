import axios from 'axios'
const CancelToken = axios.CancelToken
let cancel
let CSRFToken
import { Events, EventBus } from '@events'
import CONST from '@constants'

/**
 * Setup HTTP connection & interceptors
 */
const HTTP = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_URI}/api/v1/`,
  headers: {},
  withCredentials: process.env.VUE_APP_AUTHX_DISABLED ? false : true,
})

/**
 * Set data dirty to false.
 * Check if event bus has listener.
 * This removes window beforeunload handler
 * to allow auth redirect without prompt.
 */
const checkDirtyData = () => {
  const busEvents = EventBus._events || {}
  if (busEvents[Events.DATA_DIRTY]) {
    EventBus.$emit(Events.DATA_DIRTY, false)
  }
}

/**
 * HTTP Error interceptor
 */
const httpResponseErrorInterceptor = (error) => {
  switch (error.response.status) {
    case 409:
      var urlRegex = /^assessments\/[0-9]+(\/questions|'')?$/i
      var isMatch = urlRegex.test(error.response.config.url)
      var errorObj = { subtext: CONST.REFRESH_PAGE }
      if (isMatch) {
        errorObj.subtext = CONST.ASSESSMENT_OUTDATED_ERROR
      }
      return Promise.reject(errorObj)

    case 401:
      checkDirtyData()

      if (cancel) {
        cancel()
      }

      if (window.MessageBus) {
        window.MessageBus.postMessage(MessageBus.EVENTS.AUTHX_401)
      }
      return Promise.reject(error)

    default:
      return Promise.reject(error)
  }
}

/**
 * HTTP interceptor
 */
const httpResponseInterceptor = (response) => {
  if (response.headers['x-csrf-token']) {
    CSRFToken = response.headers['x-csrf-token']
  }

  return response
}

const authXRefresh = () => {
  window.frames.op_session_iframe.contentWindow.postMessage('refresh-session', process.env.VUE_APP_AUTHX_OP_URI)
}
HTTP.interceptors.response.use(httpResponseInterceptor, httpResponseErrorInterceptor)

/**
 * Get headers for POST, PATCH, PUT, AND DELETE methods.
 */
const getMutationHeaders = (endpoint) => {
  return {
    headers: {
      'X-CSRF-Token': CSRFToken,
    },
  }
}

/**
 * HTTP interface
 */
const _get = async ({ endpoint = '', params = {} }) => {
  const cancelToken = new CancelToken((c) => (cancel = c))
  const { data } = await HTTP.get(endpoint, { params, cancelToken })
  authXRefresh()
  return data
}
const _post = async ({ endpoint = '', body, config = {} }) => {
  const { data } = await HTTP.post(endpoint, body, config)
  authXRefresh()
  return data
}
const _patch = async ({ endpoint = '', body, config = {} }) => {
  const { data } = await HTTP.patch(endpoint, body, config)
  authXRefresh()
  return data
}
const _put = async ({ endpoint = '', body, config = {} }) => {
  const { data } = await HTTP.put(endpoint, body, config)
  authXRefresh()
  return data
}
const _delete = async ({ endpoint = '', body, config = {} }) => {
  const { data } = await HTTP.delete(endpoint, { data: body, ...config })
  authXRefresh()
  return data
}

/**
 * IBX API endpoints
 */
const IBXService = {
  /**
   * Initialize App
   */
  init: async () => {
    const { token } = await IBXService.pieToken()
    return token
  },

  /**
   * Cancel request
   * @param requestName name of request method to cancel
   */
  cancel: async (requestName) => {
    if (cancel) {
      cancel({ cancel: true, requestName })
    }
  },

  /**
   * Get filters collection
   */
  pieToken: async () => {
    return await _get({
      endpoint: 'auth/pie/token',
    })
  },

  /**
   * Get filters collection
   */
  filters: async () => {
    return await _get({
      endpoint: 'filters',
    })
  },

  /**
   * Banks
   */
  banks: async (params = {}) => {
    return await _get({
      endpoint: 'banks',
      params: params,
    })
  },

  /**
   * Attributes
   */
  attributes: async ({ resource, params }) => {
    return await _get({
      endpoint: `attributes/${resource}`,
      params: params || {},
    })
  },

  /**
   * Get items collection
   * return data and params (used for validating response)
   */
  items: async (filters = {}) => {
    const data = await _get({
      endpoint: 'items',
      params: filters,
    })

    return Object.assign({}, data, { params: { filters } })
  },

  /**
   * Get items by itemId
   */
  item: async ({ itemId }) => {
    return await _get({
      endpoint: `items/${itemId}`,
    })
  },

  /**
   * Get item by itemRevId
   * @param {number} itemRevId required
   */
  itemRevisions: async (itemRevId) => {
    if (!itemRevId) {
      throw 'itemRevId required for /items/revisions endpoint'
    }
    return await _get({
      endpoint: `/items/revisions/${itemRevId}`,
    })
  },

  /**
   * Patch items collection
   * @itemId requred
   * @props properties to update
   */
  updateItems: async ({ itemId, props = {} }) => {
    return await _patch({
      endpoint: `items/${itemId}`,
      body: { item: { ...props } },
      config: { ...getMutationHeaders() },
    })
  },

  /**
   * POST Email
   * @data requred
   */
  sendEmail: async ({ data = {} }) => {
    return await _post({
      endpoint: 'items/email',
      body: data,
      config: { ...getMutationHeaders() },
    })
  },

  /**
   * Get assessment resource by assessmentId
   */
  assessment: async ({ assessmentId }) => {
    return await _get({
      endpoint: `assessments/${assessmentId}`,
    })
  },

  /**
   * Create assessment resource
   * @title required
   */
  assessmentCreate: async (p = { title: '' }) => {
    return await _post({
      endpoint: 'assessments',
      body: { assessment: { ...p } },
    })
  },

  /**
   * Duplicate Assessment
   * @Assessment Id required
   */
  assessmentDuplicate: async (assessmentId) => {
    return await _post({
      endpoint: `assessments/duplicate/${assessmentId}`,
    })
  },

  /**
   * Delete assessment resource
   * @assessmentId required
   */
  assessmentDelete: async ({ assessmentId }) => {
    return await _delete({
      endpoint: `assessments/${assessmentId}`,
    })
  },

  /**
   * Get all possible assessment tags
   */
  assessmentTags: async () => {
    return await _get({
      endpoint: 'assessments/tags',
    })
  },

  /**
   * Update assessemt
   * @assessmentId required
   * @props properties to update
   */
  assessmentUpdate: async ({ assessmentId, props = {} }) => {
    return await _patch({
      endpoint: `assessments/${assessmentId}`,
      body: { assessment: { ...props } },
    })
  },

  /**
   * Get assessment items collection
   * @assessmentId required
   */
  assessmentItems: async ({ assessmentId }) => {
    return await _get({
      endpoint: `assessments/${assessmentId}/questions`,
    })
  },

  /**
   * Add assessment items
   * @assessmentId required
   * @items: array of { itemRevId }
   */
  assessmentAddItems: async ({ assessmentId, questions = [] }) => {
    return await _post({
      endpoint: `assessments/${assessmentId}/questions`,
      body: { questions },
    })
  },

  /**
   * Remove assessment items
   * @assessmentId required
   * @items: array of { itemRevId }
   */
  assessmentRemoveItems: async ({ assessmentId, questions = [] }) => {
    return await _delete({
      endpoint: `assessments/${assessmentId}/questions`,
      body: { questions },
    })
  },

  /**
   * Update/patch Assessment Items
   * @assessmentId required
   * @items: array of { itemRevId, order }
   * order requires all items with order property
   */
  assessmentUpdateItems: async ({ assessmentId, questions = [] }) => {
    return await _patch({
      endpoint: `assessments/${assessmentId}/questions`,
      body: { questions },
    })
  },

  /**
   * Question Groups
   * returns all user custom question groups
   * and all assessment enabled auto question groups
   */
  assessmentQuestionGroups: async ({ assessmentId }) => {
    return await _get({
      endpoint: `assessments/${assessmentId}/question-groups`,
    })
  },

  /**
   * Assessent Materials
   */
  assessmentMaterials: async ({ assessmentId }) => {
    return await _get({
      endpoint: `assessments/${assessmentId}/materials`,
    })
  },

  /**
   * Assessent Material delete
   */
  assessmentMaterialDelete: async ({ assessmentId, materialId }) => {
    return await _delete({
      endpoint: `assessments/${assessmentId}/materials/${materialId}`,
    })
  },

  /**
   * Get materials endpoint
   */
  getMaterialsEndpoint: ({ assessmentId }) => {
    return `${process.env.VUE_APP_BASE_URI}/api/v1/assessments/${assessmentId}/materials`
  },

  /**
   * Get materials endpoint
   */
  getBookletsEndpoint: ({ assessmentId }) => {
    return `${process.env.VUE_APP_BASE_URI}/api/v1/assessments/${assessmentId}/booklets/download`
  },

  /**
   * Notify assessment for immediate sync to atd
   */
  assessmentNotify: async ({ assessmentId }) => {
    return await _post({
      endpoint: `assessments/${assessmentId}/notify`,
    })
  },

  /**
   * Get standards by guids
   */
  standards: async ({ guids }) => {
    // prevent loading all standards
    if (!guids || !guids.length) {
      return Promise.resolve({ data: [], meta: {} })
    }
    return await _get({
      endpoint: `stannis/standards`,
      params: { guid: guids },
    })
  },

  /**
   * Get User Selections
   * @context filter by context
   */
  userSelections: async ({ context = [] }) => {
    return await _get({
      endpoint: `user-selections`,
      params: { context: context },
    })
  },
  /**
   * Create User Selections
   * @context required
   */
  userSelectionsCreate: async ({ context, value = {} }) => {
    return await _post({
      endpoint: `user-selections`,
      body: {
        userSelection: {
          context,
          value: JSON.stringify(value), // remove stringify once backend returns json for value
        },
      },
    })
  },

  /**
   * Update User Selections
   * @userSelectionId required
   */
  userSelectionsUpdate: async ({ userSelectionId, value = {} }) => {
    return await _patch({
      endpoint: `user-selections/${userSelectionId}`,
      body: {
        userSelection: {
          value: JSON.stringify(value), // remove stringify once backend returns json for value
        },
      },
    })
  },

  /**
   * Assessment Atuo Question Groups
   * returns data question group data
   */
  assessmentAutoQuestionGroups: async ({ assessmentId }) => {
    return await _get({
      endpoint: `assessments/${assessmentId}/auto-question-groups`,
    })
  },

  /**
   * Get IBX Application Configs
   */
  configs: async () => {
    return await _get({
      endpoint: `configs`,
    })
  },

  /**
   * Create Item
   */
  itemCreate: async (item) => {
    return await _post({
      endpoint: 'items',
      body: { item },
      config: { ...getMutationHeaders() },
    })
  },

  /**
   * Create Item Revision
   */
  itemRevisionCreate: async (itemId, item) => {
    return await _post({
      endpoint: `items/${itemId}/revisions`,
      body: { item },
      config: { ...getMutationHeaders() },
    })
  },

  /**
   * Update Item Revision
   */
  itemRevisionUpdate: async (itemRevisionId, item) => {
    return await _patch({
      endpoint: `/items/revisions/${itemRevisionId}`,
      body: { item },
      config: { ...getMutationHeaders() },
    })
  },

  /**
   * Delete Item by itemId
   */
  itemDelete: async (itemId) => {
    return await _delete({
      endpoint: `items/${itemId}`,
      config: { ...getMutationHeaders() },
    })
  },

  /**
   * Create Passage
   */
  passageCreate: async (passage) => {
    return await _post({
      endpoint: 'passages',
      body: { passage },
    })
  },

  /**
   * Get Passage by passageId
   */
  passage: async (passageId) => {
    return await _get({
      endpoint: `passages/${passageId}`,
    })
  },

  /**
   * Delete Passage by passageId
   */
  passageDelete: async (passageId) => {
    return await _delete({
      endpoint: `passages/${passageId}`,
    })
  },

  /**
   * Get Passage by passageRevisionId
   */
  passageRevision: async (passageRevisionId) => {
    return await _get({
      endpoint: `passages/revisions/${passageRevisionId}`,
    })
  },
  /**
   * Create Passage Revision
   */
  passageRevisionCreate: async (passageId, passage) => {
    return await _post({
      endpoint: `passages/${passageId}/revisions`,
      body: { passage },
    })
  },

  /**
   * Update Passage Revision
   */
  passageRevisionUpdate: async (passageRevisionId, passage) => {
    return await _patch({
      endpoint: `/passages/revisions/${passageRevisionId}`,
      body: { passage },
    })
  },

  /**
   * Get all passage items
   */
  passageRevisionItems: async (passageRevId) => {
    return await _get({
      endpoint: `/passages/revisions/${passageRevId}/items`,
    })
  },

  /**
   * Add Passage items(s) Alignment
   */
  passageRevisionItemsPut: async (passageRevId, items = []) => {
    return await _put({
      endpoint: `/passages/revisions/${passageRevId}/items`,
      body: { items },
    })
  },

  /**
   * Add Passage items(s) Alignment
   */
  passageRevisionItemsAdd: async (passageRevId, items = []) => {
    return await _post({
      endpoint: `/passages/revisions/${passageRevId}/items`,
      body: { items },
    })
  },

  /**
   *  Remove Passage items(s) Alignment
   */
  passageRevisionItemsRemove: async (passageRevId, items = []) => {
    return await _delete({
      endpoint: `/passages/revisions/${passageRevId}/items`,
      body: { items },
    })
  },

  /**
   * Search Passage by Title
   */
  passageSearchTitle: async ({ passageTitle }) => {
    return await _get({
      endpoint: `passages/search?title=${passageTitle}`,
    })
  },
}

export default IBXService
export { IBXService, httpResponseErrorInterceptor }
