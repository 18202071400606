import { Subject } from 'rxjs'
import { debounceTime, takeUntil } from 'rxjs/operators'
import _ from 'lodash'

class AsyncQueue {
  constructor(o = { max: 3, observer: null, debounce: 1000 }) {
    this.subscriber$ = new Subject() // unsubscribe observable
    this.items$ = new Subject() // items observable
    this.queue$ = new Subject() // queue observable
    this.observer = o.observer // que observer
    this.max = o.max || 3 // max queued items
    this.queue = [] // queud item ids
    this.items = [] // items list [{ id, active, done }]
    this.debounce = o.debounce || 1000 // debounce time
    this.subscribe() // initialize subscriptions
  }

  subscribe() {
    // subscribe to queue
    if (this.observer) {
      this.queue$.pipe(takeUntil(this.subscriber$)).subscribe(this.observer)
    }

    // subscribe to items
    this.items$.pipe(takeUntil(this.subscriber$), debounceTime(1000)).subscribe(() => this.onItemsAdded())
  }

  unsubscribe() {
    this.subscriber$.next()
  }

  addItems(items) {
    const ids = this.items.map((o) => o.id)
    const newItems = items.filter((id) => !ids.includes(id))
    this.items = [...this.items, ...newItems.map(this.createItem)]
    this.items$.next(this.items) // push to item$ stream
  }

  onItemsAdded() {
    if (this.queue.length < this.max) this.nextItems(-1)
  }

  createItem(id) {
    return { id, active: false, done: false }
  }

  enqueItems(itemsToQueue) {
    const ids = itemsToQueue.map((o) => o.id)
    this.items.forEach((o) => {
      if (ids.includes(o.id)) o.active = true
    })
    this.queue = _.uniq([...this.queue, ...ids])
    this.queue$.next(ids)
  }

  onItemDone(id) {
    const item = this.items.find((o) => o.id == id)
    if (item) Object.assign(item, { done: true })
    this.queue = this.queue.filter((qid) => qid != id)
    this.nextItems(id)
  }

  nextItems(id) {
    const nextItems = this.items
      .filter((o) => o.id != id && !this.queue.includes(o.id) && !o.done)
      .slice(0, this.max - this.queue.length)
    if (nextItems.length) this.enqueItems(nextItems)
  }

  getItemIds() {
    return this.items.map((o) => o.id)
  }

  reset() {
    this.items = []
    this.queue = []
  }
}

/* ------------------------------------------------------------------
 * This code will be part of the unit tests
 * ------------------------------------------------------------------ */
// const renderMock = async (id, time) => {
//     const promise = new Promise((resolve, reject) => {
//         setTimeout(() => {
//             resolve(id)
//         }, time)
//     })
//     return promise
// }
// let count = 0
// function startRender(ids=[]) { count ++
//     if (count > 100) return
//     ids.forEach(id => renderMock(id, random(5000)).then(id => asyncQ.onItemDone(id)))
// }
// function random(n, b = 1000) {
//     return Math.trunc(Math.random() * (b-n) + n)
// }

// init queue
// const asyncQ = new AsyncQueue({ max: 3, observer: startRender })
// asyncQ.addItems([1, 2, 3, 4])
// // asyncQ.addItems([4, 5, 6, 7])
// // asyncQ.addItems([7])
// // asyncQ.addItems([4, 5, 6, 7])
// // asyncQ.addItems([4, 5, 6, 7, 8])
// // //asyncQ.start()
// // // push new items after a delay
// setTimeout(() => {
//     asyncQ.unsubscribe()
//     asyncQ.addItems([8, 9, 10, 11])
//     asyncQ.subscribe()
// }, 5000)

/* ---------------------------------------------------------------- */

export default AsyncQueue
export { AsyncQueue }
