import { Subject, race } from 'rxjs'
import { map, debounceTime, takeUntil, skipWhile, tap, catchError } from 'rxjs/operators'

const makeDebouncedQueueSession = ({ id, data = '', time = 1000 }) => {
  if (!id) throw 'ID is required'

  const _id = id
  const _time = time
  const _maxSnapshot = 2
  const _subscriber$ = new Subject()
  const _debounce$ = new Subject()
  const _force$ = new Subject()
  let _data = []
  let _race$ = null

  function _destroy() {
    _subscriber$.unsubscribe()
    _debounce$.unsubscribe()
    _force$.unsubscribe()
    _data = []
  }

  function _addData(value) {
    if (_data.length === _maxSnapshot) _data = []
    _data.push(value)
  }

  function _force(value) {
    if (value) _addData(value)
    _force$.next(_getSnapshot())
  }

  function _unsubscribe(complete) {
    if (complete) {
      _force$.next(_getSnapshot())
    }

    _subscriber$.next()
    _subscriber$.unsubscribe()
  }

  function _getSnapshot(index) {
    index = !index ? _data.length - 1 : index
    return _data[index]
  }

  const api = {
    getID: () => {
      return _id
    },

    add: ({ value = '', force = false, execute = true }) => {
      _addData(value)

      if (force) {
        _force$.next(_getSnapshot())
      } else if (execute) {
        _debounce$.next(_getSnapshot())
      }

      return api
    },

    force: (value) => {
      _force(value)
      return api
    },

    complete: () => {
      return api
    },

    subscribe: (fn, time) => {
      if (!fn) return
      time = time || _time

      _race$ = race(_debounce$.pipe(takeUntil(_subscriber$), debounceTime(time)), _force$.pipe(takeUntil(_subscriber$)))
        .pipe(takeUntil(_subscriber$))
        .subscribe(fn)

      return api
    },

    unsubscribe: (complete) => {
      _unsubscribe(complete)
      return api
    },

    destroy: () => {
      _destroy()
      return api
    },
  }

  return api
}

const makeDebouncedQueue = () => {
  const _sessionMap = new Map()

  function _sessionFactory({ id, time }) {
    return _sessionMap.has(id)
      ? _sessionMap.get(id)
      : _sessionMap.set(id, makeDebouncedQueueSession({ id, time })).get(id)
  }

  function _unsubscribeSession(session, complete) {
    if (session) {
      session.unsubscribe(complete).destroy()
      _sessionMap.delete(session.getID())
    }
  }

  function _unsubscribe({ id, complete }) {
    const session = _sessionFactory({ id })
    _unsubscribeSession(session, complete)
  }

  function _unsubscribeAll(complete = false) {
    _sessionMap.forEach((session) => {
      _unsubscribeSession(session, complete)
    })
  }

  const api = {
    subscribe: ({ id, time }, fn) => {
      const session = _sessionFactory({ id, time })
      if (fn) session.subscribe(fn)
    },

    add: ({ id, value = '', force = false, execute = true }) => {
      const session = _sessionFactory({ id })
      session.add({ value, force, execute })
    },

    force: ({ id, value }) => {
      const session = _sessionFactory({ id })
      session.force(value)
    },

    unsubscribe: ({ id, complete = false }) => {
      _unsubscribe({ id, complete })
      return api
    },

    unsubscribeAll: (p = { complete: false }) => {
      _unsubscribeAll(p.complete)
      return api
    },
  }

  return api
}

export { makeDebouncedQueue }
