/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'Matching Table_2': {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M171.51 32.5h-151c-6.627 0-12 5.373-12 12v103c0 6.627 5.373 12 12 12h151c6.627 0 12-5.373 12-12v-103c0-6.627-5.373-12-12-12zm-50.84 117H71.33v-31.83h49.34v31.83zm-49.34-38.83h49.34V81.33H71.33v29.34zm49.34-36.34H71.33V42.5h49.34v31.83zm50.83 75.18a2 2 0 002-2v-29.84h-45.83v31.84h43.83zm2-38.84h-45.83V81.33h45.83v29.34zm-45.83-36.34h45.83l.01-29.83a2 2 0 00-2-2h-43.84v31.83z" _fill="#152452"/>',
  },
})
