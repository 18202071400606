import { Subject } from 'rxjs'
import { takeUntil, skipWhile } from 'rxjs/operators'
import { cloneDeep } from 'lodash'

const makeOhSnap = (o = { max: 1 }) => {
  const _previous$ = new Subject()
  const _next$ = new Subject()
  const _subscriber$ = new Subject()
  const _max = o.max || 2
  let _cursor = null
  let _snapshots = []

  function _getSnapshot() {
    return _snapshots[_cursor] || _snapshots[_snapshots.length - 1]
  }

  const api = {
    snapshot: (data) => {
      if (!data) return
      if (_snapshots.length > _max) _snapshots.shift()
      _snapshots.push(cloneDeep(data))
      _cursor = _snapshots.length - 1
      _next$.next(_getSnapshot())
    },

    getCurrent: () => {
      return _getSnapshot()
    },

    moveToPrevious: () => {
      if (_cursor > 0) {
        _cursor--
        _snapshots.pop()
      }

      _previous$.next(_getSnapshot())
    },

    clear: () => {
      _snapshots = []
      _cursor = 0
    },

    destroy: () => {
      _subscriber$.next()
      _subscriber$.unsubscribe()
      _snapshots = []
    },

    subscribe: ({ previous = true, next = false }, fn) => {
      if (!fn || typeof fn !== 'function') return

      if (previous) {
        _previous$
          .pipe(
            takeUntil(_subscriber$),
            skipWhile((d) => d === undefined)
          )
          .subscribe(fn)
      }

      if (next) {
        _next$
          .pipe(
            takeUntil(_subscriber$),
            skipWhile((d) => d === undefined)
          )
          .subscribe(fn)
      }
    },

    unsuscribe: () => {
      _subscriber$.next()
      _subscriber$.unsubscribe()
    },
  }

  return api
}

// const t = makeOhSnap()
// t.subscribe({ next: false, previous: true }, d => console.log(d))

// let o1 = { k: 1, v: 11 }
// let o2 = { k: 2, v: 22 }
// let o3 = { k: 3, v: 33 }
// let o4 = { k: 4, v: 44 }

// t.snapshot(o1)
// t.moveToPrevious()
// t.snapshot(o2)
// t.snapshot(o3)
// t.snapshot(o4)
// t.moveToPrevious()
// t.moveToPrevious()
// t.moveToPrevious()
// t.moveToPrevious()
// // t.snapshot(o2)
// t.snapshot(o3)
// t.moveToPrevious()
// //t.unsuscribe()
// t.snapshot(o4)
// t.moveToPrevious()

//console.log(t.getCurrent())

// t.test(o1)
// t.test(o2)
// t.test(o3)
// t.test(o4).log()

export { makeOhSnap }
