/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'Open Response_1': {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M33.09 20.5h125.82c6.953 0 12.59 5.637 12.59 12.59v125.82c0 6.953-5.637 12.59-12.59 12.59H33.09c-6.953 0-12.59-5.637-12.59-12.59V33.09c0-6.953 5.637-12.59 12.59-12.59zm127.651 10.759a2.588 2.588 0 00-1.831-.759H33.09a2.59 2.59 0 00-2.59 2.59v125.82a2.588 2.588 0 002.59 2.59h125.82c.687 0 1.346-.273 1.831-.759a2.587 2.587 0 00.759-1.831V33.09a2.59 2.59 0 00-.759-1.831z" _fill="#152452"/><rect pid="1" x="48.5" y="87.75" width="17.5" height="17.5" rx="8.75" _fill="#152452"/><rect pid="2" x="77.25" y="87.75" width="71.25" height="17.5" rx="8.75" _fill="#152452"/><rect pid="3" x="48.5" y="46.5" width="100" height="17.5" rx="8.75" _fill="#152452"/><rect pid="4" x="48.5" y="129" width="17.5" height="17.5" rx="8.75" _fill="#152452"/><rect pid="5" x="77.25" y="129" width="71.25" height="17.5" rx="8.75" _fill="#152452"/>',
  },
})
