import { Id, IEntityData, Entity } from './Types'
import Vue from 'vue'

export const metaProps = [
  'media',
  'passage_category',
  'passage_genre',
  'passage_subgenre',
  'flesch_kincaid',
  'level_of_meaning',
  'structure',
  'knowledge_demands',
  'language_clarity',
  'copyright_owner',
  'author_name',
  'original_source',
]

/*
 * Passage entity data interface
 * IEntityData holds generic entity data structure
 */
export interface IPassageData extends IEntityData {
  items: object | any
  passageGroup: boolean
  passageId: Id
  passageRevId: Id
  sections: object
  title: string
}

/**
 * Passage Entity class
 */
export class Passage extends Entity<IPassageData> {
  constructor(data: IPassageData) {
    super(data)
  }

  /**
   * Passage data getters
   */
  get id(): Id {
    return this.data.passageId
  }
  get items(): object {
    return this.data.items
  }
  get passageGroup(): boolean {
    return this.data.passageGroup
  }
  get passageId(): Id {
    return this.data.passageId
  }
  get passageRevId(): Id {
    return this.data.passageRevId
  }
  get revisionId(): Id {
    return this.data.passageRevId
  }
  get sections(): object {
    return this.data.sections
  }
  get title(): string {
    return this.data.title
  }
  get meta(): any {
    return this.data.meta
  }
}

/**
 * Passage class for authoring
 * Extends Item with set method
 */
export class PassageAuthor extends Passage {
  /**
   * Creates new PassageAuthor
   * @param options passage data options
   */
  static create(options: any = {}, itemId?: Id): PassageAuthor {
    const data: IPassageData = {
      passageId: null,
      passageRevId: null,
      createdAt: null,
      inUse: false,
      items: [],
      passageGroup: null,
      sections: null,
      externalIds: [],
      legacyIds: [],
      meta: {},
      omittedAt: null,
      published: null,
      revisionId: null,
      remoteId: null,
      remoteIdName: null,
      remoteIdVersioned: null,
      source: null,
      title: null,
      teacherInstructions: null,
      updatedAt: null,
      user: {},
      version: null,
    }
    return new PassageAuthor(data, itemId)
  }

  constructor(data: IPassageData, itemId?: Id) {
    super(data)
  }

  /**
   * Item association
   */
  public itemId: Id = null

  /**
   * Set item data properties for key/value pair
   * @param k item data key
   * @param v value for item data[key] or data.meta[k]
   */
  set(k: string, v: any) {
    if (metaProps.includes(k)) {
      const value = Array.isArray(v)
        ? v // must be { id }[] type
        : [{ id: v }]

      Vue.set(this.data.meta, k, {
        id: k,
        items: value,
        name: null,
      })
    } else {
      this.data[k] = v
    }
  }

  /**
   * Hydrate passage data
   * @param data item data
   */
  public hydrate(data: IPassageData) {
    this.data = data
  }

  /**
   * Get pasage data obj
   */
  get dataObj(): IPassageData {
    return this.data
  }

  /**
   * Get index for new item
   */
  get newIndex(): number {
    return this.data.newIndex
  }
}
