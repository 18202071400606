
import * as VTextarea from 'vuetify/es5/components/VTextarea'
import IbxPassage from '@components/ibx/IbxPassage'
import PassagesDetailsCard from '@components/itemAuthor/passages/PassagesDetailsCard'
// new
import { Vue, Component, Prop, Mixins, Watch } from 'vue-property-decorator'
import { Id } from '@/components/ibx/base/Types'
import { ItemPassageAff } from '@/components/ibx/base/Item'
import { PassageAuthor, Passage } from '@/components/ibx/base/Passage'
import { StateAuthorItems } from '@/helpers/state'

@Component({
  components: {
    ...VTextarea,
    IbxPassage,
    PassagesDetailsCard,
  },
})
export default class PassageViewer extends Mixins(Vue, StateAuthorItems) {
  @Prop({ required: true }) passage: Passage | PassageAuthor
  @Prop({ default: false }) isLinked: boolean // initial linked state

  /**
   * Linked option
   */
  private linked: boolean = false

  @Watch('isLinked')
  onIsLinkedChange(v) {
    this.linked = v
  }

  get linkText(): string {
    return this.linked ? 'Unlink Passage' : 'Link Passage'
  }

  /**
   * Link action
   */
  linkPassage() {
    this.linked = !this.linked
    this.$emit('passage-link-item', {
      passageRevId: this.passage.passageRevId,
      remoteId: this.passage.remoteId,
      link: this.linked,
    })
  }

  onCancel() {
    this.$emit('cancel')
  }

  /**
   * Set link option to initial linked state
   */
  mounted() {
    this.linked = this.isLinked
  }
}
