<template>
  <div class="ibx-passage-meta">
    <div class="ibx-passage-meta__item">
      <div class="header" @click="toggleItems()">
        <font-awesome-icon :icon="['fas', itemActivatorIcons()]" class="icon" />
        <span class="label">Detailed Information</span>
      </div>
      <div class="content" v-show="itemIsActive()">
        <div>
          <span v-if="hasPassageId">{{ passageId }}</span>
          <span v-if="hasPassageRevId">, {{ passageRevId }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PassageDetailsCard',

  props: {
    item: { type: Object, default: () => ({}) },
  },
  data: () => ({
    activeItems: false,
  }),

  computed: {
    hasPassageId() {
      return Boolean(this.item?.passageId)
    },
    hasPassageRevId() {
      return Boolean(this.item?.passageRevId)
    },
    passageId() {
      return this.passageIdLabel(this.item?.passageId)
    },
    passageRevId() {
      return this.passageRevIdLabel(this.item?.passageRevId)
    },
  },
  methods: {
    toggleItems() {
      this.activeItems = !this.activeItems
    },
    itemActivatorIcons() {
      return this.activeItems ? 'chevron-down' : 'chevron-right'
    },
    itemIsActive() {
      return this.activeItems
    },
    passageIdLabel(v) {
      return `Passage ID ${v}`
    },
    passageRevIdLabel(v) {
      return `Passage Rev ID ${v}`
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.ibx-passage-meta {
  display: block;
  font-size: 12px;
  padding: 24px;
  &__item {
    display: block;
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
    .header {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      cursor: pointer;
      .label {
        margin-left: 5px;
      }
      .icon {
        font-size: 12px;
        width: 14px;
      }
    }
    .content {
      display: block;
      font-size: 12px;
      line-height: 20px;
      margin-top: 10px;
    }
  }
}
</style>
