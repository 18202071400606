<template>
  <div class="x-pagination">
    <span class="x-pagination__nav">
      <font-awesome-icon :icon="['far', 'chevron-left']" @click="prev" />
    </span>
    <span class="x-pagination__pages">
      <span v-for="(o, i) in getPages" :key="`page_${i}`" v-bind="getPageAttributes(o)" @click="gotToPage(o)">
        {{ getPageLabel(o) }}
      </span>
    </span>
    <span class="x-pagination__nav">
      <font-awesome-icon :icon="['far', 'chevron-right']" @click="next" />
    </span>
  </div>
</template>

<script>
/* component deps */
import _ from 'lodash'
import { Events } from '@events'

export default {
  name: 'XPagination',

  props: {
    count: { type: Number, default: null }, // total items in all pages
    currentPage: { type: Number, required: true }, // current page
    size: { type: Number, required: true }, // items per page
    pages: { type: Number, required: true }, // total pages,
    threshold: { type: Number, default: 5 }, // page truncate point
    loading: { type: Boolean, default: false }, // content loading
  },

  data: () => ({
    current: 5,
  }),

  watch: {
    currentPage() {
      this.syncCurrent()
    },
  },

  computed: {
    isTrucated() {
      return this.pages - this.threshold > 2
    },
    getPages() {
      const current = Number(this.current)
      let range = []
      const getOffsetRange = () => {
        const offset = current + 3 - this.pages
        const start = offset < 1 ? current : current - offset
        const end = offset >= 0 ? start + 4 : start + 3
        const endItems = offset >= 0 ? [] : [-1, this.pages]
        return [1, -1, ..._.range(start, end), ...endItems]
      }

      if (this.isTrucated) {
        // -1 value indiactes an ellipsis/more indicator
        range = this.current < this.threshold ? [..._.range(1, this.threshold + 1), -1, this.pages] : getOffsetRange()
      } else {
        range = _.range(1, this.pages + 1)
      }

      return range.map(this.pageMapper)
    },
    getLastPage() {
      return this.pageMapper(this.pages)
    },
  },

  methods: {
    pageMapper(n) {
      return {
        page: n > 0 ? n : false,
        label: n > 0 ? n : '...',
        current: n == this.current,
      }
    },
    getPageLabel(data) {
      return data.label
    },
    getPageAttributes(data) {
      const classes = ['x-pagination__item']
      if (data.current) classes.push('x-pagination__item__page--current')
      if (data.page) classes.push('x-pagination__item__page')
      else classes.push('x-pagination__item__more')

      return {
        class: classes,
      }
    },
    prev() {
      if (this.loading) return
      this.current = this.current - 1 > 1 ? this.current - 1 : 1
      this.$emit(Events.CHANGE_PAGE, this.current)
    },
    next() {
      if (this.loading) return
      this.current = this.current + 1 <= this.pages ? this.current + 1 : this.pages
      this.$emit(Events.CHANGE_PAGE, this.current)
    },
    gotToPage(data) {
      if (this.loading || !data.page) return
      this.current = data.page
      this.$emit(Events.CHANGE_PAGE, this.current)
    },
    syncCurrent() {
      this.current = this.currentPage
    },
  },

  created() {
    this.syncCurrent()
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.x-pagination {
  display: flex;
  align-items: center;
  color: $B400;
  user-select: none;

  .x-pagination__pages {
    margin: 0px 10px;
  }

  &__nav {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    width: 20px;
    height: 17px;
  }

  .x-pagination__item {
    font-size: 14px;
    margin: 0px 10px;
    line-height: 17px;

    &__page {
      color: $B400;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      &--current {
        color: $N130;
        font-weight: 900;
        cursor: default;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__more {
      font-size: 16px;
    }
  }
}
</style>
