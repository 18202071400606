/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'Evidence-Based Selected Response_1': {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M32.46 21.11h127.08c6.329 0 11.46 5.13 11.46 11.46v46.86c0 6.33-5.131 11.46-11.46 11.46H32.46C26.13 90.89 21 85.76 21 79.43V32.57c0-6.33 5.13-11.46 11.46-11.46zm129.527 9.013a3.46 3.46 0 00-2.447-1.013H32.46A3.46 3.46 0 0029 32.57v46.86a3.46 3.46 0 003.46 3.46h127.08a3.46 3.46 0 003.46-3.46V32.57c0-.918-.365-1.798-1.013-2.447zM32.55 101.06h127.16v.05a11.283 11.283 0 017.983 3.3 11.284 11.284 0 013.307 7.98v47.22a11.28 11.28 0 01-11.29 11.33H32.29A11.281 11.281 0 0121 159.66v-47a11.549 11.549 0 0111.55-11.6zM163 112.39a3.29 3.29 0 00-3.29-3.28v-.05H32.55a3.55 3.55 0 00-3.55 3.55v47a3.29 3.29 0 003.29 3.28h127.42a3.29 3.29 0 003.29-3.28v-47.22z" _fill="#152452"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M74.93 135.37a9.75 9.75 0 004.25-3.87 11.331 11.331 0 001.46-5.8 11.83 11.83 0 00-4-9.41c-2.667-2.313-6.393-3.47-11.18-3.47H50.39v47.58h19.22c4.627 0 8.193-1.19 10.7-3.57a12.64 12.64 0 003.75-9.61c0-6.287-3.043-10.237-9.13-11.85zm-16.21-15.28h6.81a7.522 7.522 0 015.28 1.71 5.79 5.79 0 011.86 4.47A6.55 6.55 0 0171 130.9a6.23 6.23 0 01-4.79 1.81h-7.49v-12.62zM68.05 153c2.55 0 4.46-.63 5.75-1.91v-.04a6.44 6.44 0 001.99-4.76 6.62 6.62 0 00-2-4.9c-1.333-1.293-3.333-1.94-6-1.94h-9.07V153h9.33zM146.75 121.43l-19.13 33.14-19.13-33.14h38.26zM146.75 40.43l-19.13 33.14-19.13-33.14h38.26zM60.22 31.82h10.49l17.5 47.58h-8.83l-3.66-10.32H55.2L51.55 79.4h-8.86l17.53-47.58zm5.24 8.32l-7.67 21.68h15.34l-7.67-21.68z" _fill="#152452"/>',
  },
})
