
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'

@Component({})
export default class App extends Mixins(Vue) {
  @Prop({ default: '' })
  title: string

  @Prop({ default: null })
  icon: string

  @Prop({ default: '' })
  item: string

  @Prop({ default: '' })
  text: string

  get iconOptions() {
    return ['fal', this.icon]
  }
}
