<template>
  <div class="fullscreen-spinner">
    <div class="center-screen" style="text-align: center">
      <i class="fa fa-circle-notch fa-spin fa-3x fa-fw"></i>
      <div>Saving</div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style>
.center-screen {
  position: absolute;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9 !important;
}
</style>
