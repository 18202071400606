import { IBXService } from '@services/ibx/IBXService'
import { StoreOptions } from 'vuex'
import Vue from 'vue'

interface IState {
  initialzed: boolean
  filters: object[]
  activeFilters: string[]
  expandedFilters: string[]
  filtersOrder: string[]
  selectedFilters: any
  loading: boolean
  prevBaseStdGuids: string[]
}
class State implements IState {
  initialzed: boolean = false
  filters: object[] = []
  activeFilters: string[] = []
  expandedFilters: string[] = []
  filtersOrder: string[] = []
  selectedFilters: any = {}
  loading: boolean = true
  prevBaseStdGuids: string[] = []
}

const browseFilters = {
  namespaced: true,

  state: new State(),

  getters: {
    initialzed: (state: IState): boolean => state.initialzed,
    filters: (state: IState): object[] => state.filters,
    activeFilters: (state: IState): string[] => state.activeFilters,
    expandedFilters: (state: IState): string[] => state.expandedFilters,
    filtersOrder: (state: IState): string[] => state.filtersOrder,
    selectedFilters: (state: IState): object => state.selectedFilters,
    loading: (state: IState): boolean => state.loading,
    prevBaseStdGuids: (state: IState): string[] => state.prevBaseStdGuids,
  },

  mutations: {
    setInitialized: (state: IState, value: boolean) => {
      state.initialzed = value
    },
    setFilters: (state: IState, value: object[]) => {
      state.filters = [
        {
          id: 'standard',
          name: 'Standard',
          items: [],
        },
        ...value,
      ]
    },
    setActiveFilters: (state: IState, ids: string[]) => {
      state.activeFilters = ids
      const userActive = ids.length > 0

      // if not default filters, remove selected inactive filters
      if (userActive) {
        Object.keys(state.selectedFilters).forEach((k: any) => {
          if (!ids.includes(k)) {
            Vue.delete(state.selectedFilters, k)
          }
        })
      }
    },
    setExpandedFilters: (state: IState, ids: string[]) => {
      state.expandedFilters = ids
    },
    setFiltersOrder: (state: IState, ids: string[]) => {
      state.filtersOrder = ids
    },
    setSelectedFilters: (state: IState, { id, itemIds = [] }: any) => {
      if (id) {
        Vue.set(state.selectedFilters, id, itemIds)
      }
    },
    setAllSelectedFilters: (state: IState, obj: object = {}) => {
      state.selectedFilters = obj
    },
    setLoading: (state: IState, value: boolean) => {
      state.loading = value
    },
    setPrevBaseStdGuids: (state: IState, value: string[] = []) => {
      state.prevBaseStdGuids = value
    },
  },

  actions: {
    init: async ({ commit, dispatch }) => {
      await dispatch('fetchFilters')
      commit('setInitialized', true)
    },
    fetchFilters: async ({ commit }) => {
      try {
        let { activeFilters = [], filters = [] } = await IBXService.filters()

        // remove standards mock data
        filters = filters.map((o: any) => {
          if (o.id == 'standard') o.items = []
          return o
        })
        commit('setFilters', filters)
        commit('setLoading', false)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    setActiveFilters: ({ commit }, ids: string[]) => {
      commit('setActiveFilters', ids)
    },
    setExpandedFilters: ({ commit }, ids: string[]) => {
      commit('setExpandedFilters', ids)
    },
    setFiltersOrder: ({ commit }, ids: string[]) => {
      commit('setFiltersOrder', ids)
    },
    setSelectedFilters: ({ commit }, { id, itemIds = [] }: any) => {
      commit('setSelectedFilters', { id, itemIds })
    },
    setAllSelectedFilters: ({ commit, state }, data: object) => {
      commit('setAllSelectedFilters', data)
    },
    revertSelected: () => {
      //... hook for mediator
    },
    setPrevBaseStdGuids: ({ commit }, value: string[]) => {
      commit('setPrevBaseStdGuids', value)
    },
  },
} as StoreOptions<IState>

export { browseFilters, IState }
