const ItemTypeIconMapper = (itemTypeName) => {
  switch (itemTypeName) {
    case 'Multiple Choice':
      return 'Multiple Choice_1'
    case 'Multiple Select':
      return 'Multiple Select_1'
    case 'Multple Select':
      return 'Multiple Select_1' // todo: remove when db get's updated
    case 'Constructed Response':
      return 'Constructed Response_1'
    case 'Drag in the Blank':
      return 'Drag and Drop_1'
    case 'Evidence-Based Selected Response':
      return 'Evidence-Based Selected Response_1'
    case 'Explicit Constructed Response':
      return 'Explicit Constructed Response_1'
    case 'Hot Spot':
      return 'Hot Spot_1'
    case 'Hot Text':
      return 'Hot Text_1'
    case 'Image Drag and Drop':
      return 'cloze-association'
    case 'Inline Dropdown':
      return 'Inline Dropdown_1'
    case 'Matching Table':
      return 'Matching Table_1'
    case 'Math Equation Response':
      return 'Math Equation Response_1'
    case 'Multiple Binary':
      return 'Multiple Binary'
    case 'Charting':
      return 'Charting_1'
    case 'Classification':
      return 'Classification_1'
    case 'Graphing':
      return 'Graphing_1'
    case 'Match List':
      return 'Match List_1'
    case 'Multi-Part':
      return 'Multi-Part_1'
    case 'Number Line':
      return 'Number Line_1'
    case 'Ordering':
      return 'Ordering_1'
    case 'Drawing Response':
      return 'Drawing Response_1'
    case 'MCA':
      return 'Multiple Select_1'
    case 'MCAO':
      return 'Multiple Select_2'
    case 'LMC':
      return 'Multiple Choice_1'
    case 'CR':
      return 'Constructed Response_1'
    case 'CL':
      return 'Classification_1'
    case 'GP':
      return 'Graphing_1"'
    case 'TKH':
      return 'Hot Spot_1'

    default:
      return 'Multiple Choice_1'
  }
}

// TODO: update list with correct abbreviations
const ItemTypeLabelMapper = (itemTypeName) => {
  switch (itemTypeName) {
    case 'Multiple Choice':
      return 'MC'
    case 'Multiple Select':
      return 'MS'
    case 'Multple Select':
      return 'MS' // todo: remove when db get's updated
    case 'Constructed Response':
      return 'CR'
    case 'Drag in the Blank':
      return 'DB'
    case 'Evidence-Based Selected Response':
      return 'EBSR'
    case 'Explicit Constructed Response':
      return 'ECR'
    case 'Hot Spot':
      return 'HS'
    case 'Hot Text':
      return 'HT'
    case 'Inline Dropdown':
      return 'ID'
    case 'Image Drag and Drop':
      return 'IDD'
    case 'Matching Table':
      return 'MT'
    case 'Math Equation Response':
      return 'MER'
    case 'Multiple Binary':
      return 'MB'
    case 'Charting':
      return 'CH'
    case 'Classification':
      return 'CL'
    case 'Graphing':
      return 'GP'
    case 'Match List':
      return 'ML'
    case 'Multi-Part':
      return 'MP'
    case 'Number Line':
      return 'NL'
    case 'Ordering':
      return 'OL'
    case 'Drawing Response':
      return 'DR'
    case 'MCA':
      return 'MCA'
    case 'MCAO':
      return 'MCAO'
    case 'LMC':
      return 'LMC'
    case 'CR':
      return 'CR'
    case 'CL':
      return 'CL'
    case 'GP':
      return 'GP'
    case 'TKH':
      return 'TKH'
    default:
      return 'MC'
  }
}

export { ItemTypeIconMapper, ItemTypeLabelMapper }
