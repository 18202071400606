<template>
  <div class="ibx-app-loading">
    <div class="ibx-app-loading__message" v-show="showLoading">
      <font-awesome-icon :icon="['fad', 'spinner-third']" class="icon fa-spin" />
      <div class="text">Loading Item Bank</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AppLoading',

  inject: ['appReady'],

  data: () => ({
    showLoading: false,
    timer: null,
    threshold: 2000,
    attempts: 0,
  }),

  computed: {
    ...mapGetters('config', ['config']),
  },

  methods: {
    ...mapActions('config', {
      configInit: 'init',
    }),
    init() {
      this.attempts++
      if (this.attempts > 2) {
        window.MessageBus.postMessage(MessageBus.EVENTS.AUTHX_LOGOUT)
      } else {
        this.load()
      }
    },
    load() {
      this.configInit()
        .then(() => this.appReady(this.config))
        .catch((e) => console.log(e))
    },
  },

  beforeDestroy() {
    this.$parent.$off('init', this.init)
    this.$el.parentNode.removeChild(this.$el)
  },

  created() {
    this.$parent.$on('init', this.init)
  },
  mounted() {
    // show loading message delayed beyond threshold
    this.timer = setTimeout(() => {
      this.showLoading = true
      clearTimeout(this.timer)
    }, this.threshold)
  },
}
</script>

<style lang="scss">
@font-face {
  font-family: 'CerebriSans';
  src: url('./assets/fonts/cerebri/CerebriSans-Light.woff2') format('woff2'),
    url('./assets/fonts/cerebri/CerebriSans-Light.woff') format('woff'),
    url('./assets/fonts/cerebri/CerebriSans-Light.ttf') format('truetype');
  font-weight: 300;
}
.ibx-app-loading {
  font-family: 'CerebriSans', Roboto, sans-serif;
  &__message {
    margin: auto auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;

    .icon {
      font-size: 52px;
      color: #0049ff;
    }

    .text {
      margin-top: 16px;
      font-size: 16px;
    }
  }
}
</style>
