/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'Multiple Choice_2': {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<rect pid="0" x="20.5" y="21" width="71" height="71" rx="14.59" _fill="#152452"/><rect pid="1" x="100.5" y="21" width="71" height="71" rx="14.16" _fill="#152452"/><rect pid="2" x="20.5" y="100" width="71" height="71" rx="14.44" _fill="#152452"/><rect pid="3" x="100.5" y="100" width="71" height="71" rx="14.31" _fill="#152452"/><path pid="4" fill-rule="evenodd" clip-rule="evenodd" d="M131.91 75.43a4.101 4.101 0 002.84-1.43l25.8-29.59a4.114 4.114 0 00-6.2-5.41l-23.08 26.5L118.34 54a4.112 4.112 0 00-5.46 6.15l16 14.23a4.099 4.099 0 002.73 1l.3.05zM32.04 56.06C32.04 42.772 42.812 32 56.1 32a24.06 24.06 0 0124.06 24.06c0 13.288-10.772 24.06-24.06 24.06-13.288 0-24.06-10.772-24.06-24.06zm40.58 0c0-9.124-7.396-16.52-16.52-16.52-9.12.011-16.509 7.4-16.52 16.52 0 9.124 7.396 16.52 16.52 16.52 9.124 0 16.52-7.396 16.52-16.52zM33.871 125.853A24.06 24.06 0 0156.1 111c13.288 0 24.06 10.772 24.06 24.06a24.06 24.06 0 11-46.288-9.207zm38.749 9.207c0-9.124-7.396-16.52-16.52-16.52-9.12.011-16.509 7.401-16.52 16.52 0 9.124 7.396 16.52 16.52 16.52 9.124 0 16.52-7.396 16.52-16.52zM113.871 125.853A24.06 24.06 0 01136.1 111c13.288 0 24.06 10.772 24.06 24.06a24.06 24.06 0 11-46.289-9.207zm38.749 9.207c0-9.124-7.396-16.52-16.52-16.52-9.119.011-16.509 7.401-16.52 16.52 0 9.124 7.396 16.52 16.52 16.52 9.124 0 16.52-7.396 16.52-16.52z" _fill="#fff"/>',
  },
})
