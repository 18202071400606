
// @ts-nocheck
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class AsmtPrintQuestion extends Mixins(Vue) {
  @Prop({ default: null }) item: any // item config/model
  @Prop({ default: () => ({ mode: 'student' }) }) options: any // item options

  private initialized: boolean = false

  @Watch('options')
  optionsChange() {
    this.init()
    this.setPlayerConfig()
  }

  async init() {
    if (!this.initialized) {
      const player = this.$refs.player
      player.missingElement = (pkg, message) =>
        class extends HTMLElement {
          connectedCallback() {
            this.innerHTML = `<div style="border: solid 1px darkred; margin: 10px; padding: 10px;"><div style="color: red">Item is not printable.</div><br/><div style="font-size:0.8em; color: darkred;"></div></div>`
          }
        }

      player.resolve = (tagName, pkg) => {
        const [_, n, v] = pkg.match(/@pie-element\/(.*?)@(.*)/)
        const typeVersion = `${n}@${v}`

        return Promise.resolve({
          tagName,
          pkg,
          url: `https://cdn.jsdelivr.net/npm/@pie-element/${typeVersion}/module/print.js`,
          module: true,
        })
      }

      this.initialized = true
    }
  }

  setPlayerConfig() {
    const player = this.$refs.player
    if (player && this.item) {
      player.config = { item: this.item, options: this.options }
    }
  }

  mounted() {
    this.optionsChange()
  }
}
