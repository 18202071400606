/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  compact: {
    width: 15,
    height: 14,
    viewBox: '0 0 15 14',
    data: '<rect pid="0" width="15" height="4" rx="1" _fill="#0049FF"/><rect pid="1" y="5" width="15" height="4" rx="1" _fill="#0049FF"/><rect pid="2" y="10" width="15" height="4" rx="1" _fill="#0049FF"/>',
  },
})
