/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  Summary: {
    width: 25,
    height: 19,
    viewBox: '0 0 25 19',
    data: '<path pid="0" _fill="#F7941E" class="summary-icon-0" d="M.586 8.328h11.67v4.541H.586z"/><path pid="1" _fill="#27AAE1" class="summary-icon-1" d="M.586 12.869h11.67v4.59H.586z"/><path pid="2" _fill="#8DC63F" class="summary-icon-2" d="M12.793 3.299h11.67V7.84h-11.67z"/><path pid="3" _fill="#27AAE1" class="summary-icon-3" d="M12.793 8.084h11.67v4.541h-11.67z"/><path pid="4" _fill="#1C75BC" class="summary-icon-4" d="M12.793 12.918h11.67v4.541h-11.67z"/><path pid="5" d="M24.512 2.664h-.489V1.2a.488.488 0 00-.488-.488h-2.93a.488.488 0 00-.488.488v1.465h-3.223V1.2a.488.488 0 00-.488-.488h-2.93a.488.488 0 00-.488.488v1.465H12.5a.488.488 0 00-.488.488v4.395h-.977V6.082a.488.488 0 00-.488-.488h-2.93a.488.488 0 00-.488.488v1.465H4.883V6.082a.488.488 0 00-.488-.488h-2.93a.488.488 0 00-.488.488v1.465H.488A.488.488 0 000 8.035v9.766c0 .27.219.488.488.488h24.024c.27 0 .488-.219.488-.488V3.152a.488.488 0 00-.488-.488zm-11.524 5.86h11.035v3.906H12.988V8.523zm8.106-6.836h1.953v.976h-1.953v-.977zm-7.13 0h1.954v.976h-1.953v-.977zm10.06 1.953v3.906H12.987V3.64h11.035zM8.104 6.57h1.954v.976H8.105V6.57zm-6.152 0h1.953v.976H1.953V6.57zm-.976 6.835h2.246a.488.488 0 100-.976H.977V8.523h11.035v3.907H7.129a.488.488 0 100 .976h4.883v3.906H.977v-3.906zm23.046 3.906H12.988v-3.906h11.035v3.906z" _fill="#000"/><path pid="6" d="M5.176 12.43a.489.489 0 100 .977.489.489 0 000-.977z" _fill="#000"/>',
  },
})
