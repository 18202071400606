/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'Multi-Part_2': {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<rect pid="0" x="20.5" y="20.5" width="151" height="151" rx="12.59" _fill="#152452"/><rect pid="1" x="43.5" y="43.5" width="48" height="48" rx="5.43" _fill="#fff"/><rect pid="2" x="100.5" y="43.5" width="48" height="105" rx="4.69" _fill="#fff"/><rect pid="3" x="43.5" y="100.5" width="48" height="48" rx="5" _fill="#fff"/>',
  },
})
