<template>
  <div v-if="load" class="x-filter-radio-list">
    <v-radio-group :mandatory="false" v-model="model">
      <v-radio
        v-for="(item, i) in items"
        :key="i"
        :label="getItemLabel(item)"
        :value="getItemId(item)"
        @change="(id) => change(id, true)"
      ></v-radio>
    </v-radio-group>
  </div>
  <div v-else>loading...</div>
</template>

<script>
import { Events } from '@events'
import xFilterMixin from '../mixins/xFilterMixin'
import xFilterListMixin from '../mixins/xFilterListMixin'
import lazyLoadMixin from '@mixins/lazyLoadMixin'
import * as VRadioGroup from 'vuetify/es5/components/VRadioGroup'

export default {
  name: 'XFilterRadioList',

  mixins: [xFilterMixin, xFilterListMixin, lazyLoadMixin],

  components: {
    ...VRadioGroup,
  },

  data: () => ({
    model: '',
  }),

  watch: {
    selectedItems(v) {
      this.syncModel()
    },
  },

  methods: {
    lazyLoad() {
      this.load = true
      this.$emit(Events.LOADED, true)
    },
    syncModel() {
      this.model = this.selectedItems[0]
    },
    onChange(id) {},
  },

  created() {
    this.syncModel()
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.x-filter-radio-list {
  &__item {
    //...
  }

  &__control {
    //...
  }
}
</style>
