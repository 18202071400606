
import { Vue, Component, Mixins } from 'vue-property-decorator'
import { StateAuthorItems } from '@/helpers/state'

@Component
export default class ItemAuthorActionsAsmtOptions extends Mixins(Vue, StateAuthorItems) {
  get asmtUpdateOption() {
    return this.authorItemRevUpdateOption
  }

  set asmtUpdateOption(v) {
    this.authorSetItemRevUpdateOption(v)
  }
}
