import { IBXService } from '@services/ibx/IBXService'
import { StoreOptions } from 'vuex'

interface IState {
  config: any
  initialized: boolean
  appUrl: string
}

class State implements IState {
  config: any = {}
  initialized: boolean = false
  appUrl: string = ''
}

const parseBaseURL = (str: string = '') => {
  const url = new URL(str)
  return url.origin
}

const config = {
  namespaced: true,
  state: new State(),

  getters: {
    config: (state: IState) => state.config,
    appExitUrl: (state, IState) => state.config.appExitUrl,
    appPublishUrl: (state: IState) => state.config.appPublishUrl,
    appDraftUrl: (state: IState) => state.config.appDraftUrl,
    user: (state: IState) => state.config.user || {},
    initialized: (state: IState) => state.initialized,
    helpUrl: (state: IState) => state.config.helpUrl,
    pendo: (state: IState) => state.config.pendo || {},
    launchdarkly: (state: IState) => state.config.launchdarkly || {},
    appHomeUrl: (state: IState) => state.appUrl,
  },

  mutations: {
    setConfig: (state: IState, value: object) => {
      state.config = Object.assign({ helpUrl: 'https://support.illuminateed.com/hc/en-us' }, value)
    },
    setInitialized: (state: IState, value: boolean) => {
      state.initialized = value
    },
    unsetProp: (state: IState, key: string) => {
      Reflect.deleteProperty(state.config, 'launchdarkly')
    },
    setAppUrl: (state: IState, url: string) => {
      state.appUrl = parseBaseURL(url)
    },
  },

  actions: {
    init: async ({ dispatch, commit, getters }, config) => {
      if (config) {
        commit('setInitialized', true)
        commit('setConfig', config)
        commit('setAppUrl', getters.appExitUrl)
      } else if (!getters.initialized) {
        commit('setInitialized', true)
        return dispatch('fetchConfig').catch((e) => {
          commit('setInitialized', false)
          return Promise.reject(e)
        })
      }
    },
    fetchConfig: async ({ commit }) => {
      const config = await IBXService.configs()
      commit('setConfig', config)
    },
  },
} as StoreOptions<IState>

export { config, IState }
