import { EventBus, Events } from '@events'

const makeAsmtMediator = (store) => {
  store.subscribe(({ type }, state, v) => {
    if (!state.asmt.initialized && type !== 'amst/setInitialized') {
      type = null
    }

    switch (type) {
      case 'asmt/setDirty':
        EventBus.$emit(Events.DATA_DIRTY, state.asmt.dirty)
        break
    }
  })
}

export { makeAsmtMediator }
