import { PieService } from '@illuminateeducation/illuminate-components-assessments'

export enum PreReleaseType {
  PRERELEASE = 'prerelease',
  PREMAJOR = 'premajor',
  PREMINOR = 'preminor',
  PREPATCH = 'prepatch',
}

export enum ReleaseType {
  RELEASE = 'release',
  MAJOR = 'major',
  MINOR = 'minor',
  PATCH = 'patch',
}

export enum ItemOptions {
  CONFIG = 'config',
  METADATA = 'metadata',
  PASSAGE = 'passage',
}

export type FetchItemConditions = {
  config: boolean
  passage: boolean
  metadata: boolean
}

export type Semver = {
  major: number
  minor: number
  patch: number
  prerelease: {
    tag: string | null
    version: number | null
  }
}

export type ResponseData = {
  data: {
    [key: string]: any
    versionedId: string
  }
  responseData: {
    [key: string]: any
  }
}

/**
 * Abstract class for bootstrapping PIE API
 */
export abstract class PieApi {
  /**
   * PieService for GraphQL queries
   */
  protected pieService: any = null

  /**
   * @param token pie token
   * @param apiUrl pie api url
   */
  constructor({ token, apiUrl }: { token: string; apiUrl: string }) {
    this.pieService = new PieService({ token, apiUrl })
  }

  /**
   * Convert Semver to string
   * @param version sematic version object
   */
  protected getVersionedIdString({ id, version }: { id: string; version: Semver }): string {
    const core = `${version.major}.${version.minor}.${version.patch}`
    const preTag = version?.prerelease?.tag || ''
    const preVersion = String(version?.prerelease?.version) || ''
    const preRelease = preTag && preVersion && preVersion != 'null' ? `-${preTag}.${preVersion}` : ''
    return `${id}@${this.getVersionString(version)}`
  }

  public getVersionString(version: Semver): string {
    const core = `${version.major}.${version.minor}.${version.patch}`
    const preTag = version?.prerelease?.tag || ''
    const preVersion = String(version?.prerelease?.version) || ''
    const preRelease = preTag && preVersion && preVersion != 'null' ? `-${preTag}.${preVersion}` : ''
    return `${core}${preRelease}`
  }
}
