/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  Graphing_2: {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M46.5 149.5v22H33.09c-6.953 0-12.59-5.637-12.59-12.59v-9.41h26zM57.05 141.5l-2.55.9v-.9h2.55z" _fill="#152452"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M171.5 33.09v125.82c0 6.953-5.637 12.59-12.59 12.59H54.5v-22h85.28v8l20.72-12-20.72-12v8H57.05l1-.38c26.08-9.71 62.22-33.37 86.26-90.85l-3.7-1.54c-23.44 56.05-58.48 79.11-83.76 88.57-.8.3-1.6.59-2.39.86V50.22h8L50.5 29.5l-12 20.72h8v90.44c-1.08.31-2.14.58-3.19.84H20.5V33.09c0-6.953 5.637-12.59 12.59-12.59h125.82c6.953 0 12.59 5.637 12.59 12.59z" _fill="#152452"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M92.68 31.05l-6.61 17.19-6.23-17.19h-8l10.77 25.07-6 13.38h7.43l16.12-38.45h-7.48zM162.49 101.58h-8.39l-5.59 7.61-5.51-7.61h-8.5l9.92 12-9.92 12.11h8.5l5.56-7.57 5.55 7.57h8.39l-9.94-12.08 9.93-12.03z" _fill="#fff"/><path pid="3" fill-rule="evenodd" clip-rule="evenodd" d="M139.78 157.47v-8H54.5v22h-8v-22h-26v-8h26V50.22h-8l12-20.72 12 20.72h-8v87.94c.79-.27 1.59-.56 2.39-.86 25.28-9.46 60.32-32.52 83.76-88.57l3.7 1.54c-24 57.48-60.18 81.14-86.26 90.85l-1 .38H54.5v.9l2.55-.9h82.73v-8l20.72 12-20.72 11.97z" _fill="#fff"/><path pid="4" fill-rule="evenodd" clip-rule="evenodd" d="M57.05 141.5l-2.55.9v-.9h2.55z" _fill="#fff"/><path pid="5" fill-rule="evenodd" clip-rule="evenodd" d="M57.05 141.5l-2.55.9v-.9h2.55z" _fill="#fff"/>',
  },
})
