import { ApolloClient, gql } from 'apollo-boost'
import { InMemoryCache, NormalizedCacheObject, defaultDataIdFromObject } from 'apollo-cache-inmemory'
import { persistCache } from 'apollo-cache-persist'
import { PersistentStorage, PersistedData } from 'apollo-cache-persist/types'
import { ApolloLink } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { CONTENT_ITEM, CONTENT_ITEM_META, STIMULUS } from './graphql/queries'

/**
 * Create ApolloClient
 */
const createClient = async ({ token, cacheId }: { token: string; cacheId: string }): Promise<ApolloClient<any>> => {
  const httpLink = new HttpLink({ uri: `${process.env.VUE_APP_PIE_URI}/api/legacy/graphql` })

  // auth & cors
  const authMiddleware = (token) => {
    return new ApolloLink((operation, forward) => {
      if (token) {
        operation.setContext({
          headers: {
            Authorization: `Bearer ${token}`,
          },
          fetchOptions: {
            mode: 'cors',
          },
        })
      }

      return forward(operation).map((response) => {
        if (response.errors?.length) {
          const missingResourceError = response.errors.some(
            (o) => o.message?.toLocaleLowerCase() == 'cannot find the specified resource'
          )

          if (missingResourceError) {
            response.errors = []
          }
        }
        return response
      })
    })
  }

  // caching and persistence
  const cache = new InMemoryCache({
    dataIdFromObject: (obj: any) => {
      switch (obj.__typename) {
        case 'ContentItem':
          return `ContentItem:${cacheId}-${obj.id}@${obj.version.major}.${obj.version.minor}.${obj.version.patch}`
        default:
          return defaultDataIdFromObject(obj)
      }
    },
  })
  await persistCache({
    cache,
    storage: window.localStorage as PersistentStorage<PersistedData<NormalizedCacheObject>>,
  })

  // client
  return new ApolloClient({
    link: authMiddleware(token).concat(httpLink),
    cache,
  })
}

const checkCacheId = (remoteId: string = 'v1'): { cacheId: string; isNewCacheId: boolean } => {
  const key = 'ibx-apollo-cache-id'
  const localId = localStorage.getItem(key)

  if (localId !== remoteId) {
    localStorage.setItem(key, remoteId)
    return { cacheId: remoteId, isNewCacheId: true }
  }

  return { cacheId: localId, isNewCacheId: false }
}

/**
 * PIE GraphQL Singleton
 */
export class PieApi {
  static Instance: PieApi = null
  static Token: string = null

  private client = null

  constructor() {
    if (!PieApi.Instance) {
      PieApi.Instance = this
    }

    return PieApi.Instance
  }

  async init(token, remoteCacheId) {
    const { cacheId, isNewCacheId } = checkCacheId(remoteCacheId)
    PieApi.Token = token
    this.client = await createClient({ token: PieApi.Token, cacheId: cacheId })

    if (isNewCacheId) {
      this.clearAllCache()
    }
  }

  async fetchContentItems(versionItemIDs = [], cacheFirst = true) {
    if (!versionItemIDs?.length) {
      return Promise.resolve([])
    }

    const query = this.generateQueryForContentItemsVersionedIds(versionItemIDs)
    return this.client.query({
      query,
      variables: {},
      fetchPolicy: this.getFetchPolicy(cacheFirst),
      errorPolicy: 'all',
    })
  }

  async fetchStimuli(versionItemIDs = [], cacheFirst = true) {
    if (!versionItemIDs?.length) {
      return Promise.resolve([])
    }

    const query = this.generateQueryForStimuliVersionedIds(versionItemIDs)
    return this.client.query({
      query,
      variables: {},
      fetchPolicy: this.getFetchPolicy(cacheFirst),
      errorPolicy: 'all',
    })
  }

  async contentItem(versionedID, cacheFirst = true) {
    return this.client.query({
      query: CONTENT_ITEM,
      variables: { versionedID },
      fetchPolicy: this.getFetchPolicy(cacheFirst),
      errorPolicy: 'all',
    })
  }
  async contentItemMeta(versionedID, cacheFirst = true) {
    return this.client.query({
      query: CONTENT_ITEM_META,
      variables: { versionedID },
      fetchPolicy: this.getFetchPolicy(cacheFirst),
      errorPolicy: 'all',
    })
  }

  async fetchContentItemMeta(versionItemIDs = [], cacheFirst = true) {
    if (!versionItemIDs?.length) {
      return Promise.resolve([])
    }

    const query = this.generateQueryForContentItemMetaData(versionItemIDs)
    return this.client.query({
      query,
      variables: {},
      fetchPolicy: this.getFetchPolicy(cacheFirst),
      errorPolicy: 'all',
    })
  }

  async stimulus(versionedID, cacheFirst = true) {
    return this.client.query({
      query: STIMULUS,
      variables: { versionedID },
      fetchPolicy: this.getFetchPolicy(cacheFirst),
      errorPolicy: 'all',
    })
  }

  /**
   * Clear contentItem or stimulus cache by versionedId
   */
  clearCache(versionedId: string) {
    if (versionedId) {
      const cache = this.client.cache
      Object.keys(cache.data.data).forEach((key) => key.match(versionedId) && cache.data.delete(key))
    }
  }

  clearAllCache() {
    this.client.clearStore()
  }

  /**
   * cache-first: will check the cache before fetching
   * network-only: will fetch first then update the cache
   */
  getFetchPolicy(cacheFirst: boolean = true): string {
    return cacheFirst ? 'cache-first' : 'network-only'
  }

  generateQueryForContentItemsVersionedIds(versionItemIDs = []) {
    const body = versionItemIDs.reduce((m, id, i) => {
      m += `item${i + 1}: contentItem (versionedID: "${id}") {
                id 
                config
                version {
                  major
                  minor
                  patch
                  prerelease
                }
            }`
      return m
    }, '')

    return gql`query {${body}}`
  }

  generateQueryForStimuliVersionedIds(versionItemIDs = []) {
    const body = versionItemIDs.reduce((m, id, i) => {
      m += `stimulus${i + 1}: stimulus (versionedID: "${id}") {
                id 
                config
                version {
                  major
                  minor
                  patch
                  prerelease
                }
            }`
      return m
    }, '')

    return gql`query {${body}}`
  }

  generateQueryForContentItemMetaData(versionItemIDs = []) {
    const body = versionItemIDs.reduce((m, id, i) => {
      m += `item${i + 1}: contentItemMetaData (id: "${id}")`
      return m
    }, '')

    return gql`query {${body}}`
  }
}
