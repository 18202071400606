/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'Hot Text_1': {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M33.09 20.5h125.82c6.953 0 12.59 5.637 12.59 12.59v125.82c0 6.953-5.637 12.59-12.59 12.59H33.09c-6.953 0-12.59-5.637-12.59-12.59V33.09c0-6.953 5.637-12.59 12.59-12.59zm127.651 10.759a2.588 2.588 0 00-1.831-.759H33.09a2.59 2.59 0 00-2.59 2.59v125.82a2.588 2.588 0 002.59 2.59h125.82c.687 0 1.346-.273 1.831-.759a2.587 2.587 0 00.759-1.831V33.09a2.59 2.59 0 00-.759-1.831z" _fill="#152452"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M88 69.63h10.57v50.8H88V99.55H66.54v20.88H56v-50.8h10.54v20.75H88V69.63zM141.18 69.63v9.28h-12.06v41.52h-10.44V78.91h-12.13v-9.28h34.63z" _fill="#152452"/>',
  },
})
