<template>
  <div class="build-qgs">
    <x-multiselect
      class="build-qgs--control"
      :items="items"
      :selectedItems="selectedItems"
      stickySelections
      search
      label="Question Groups"
      :maxItems="20"
      canCreate
      auxilliaryAction
      v-bind="$attrs"
      @change="onSelectedChange"
      @create-item="onCreateItem"
      @auxilliary-action="onSettingsClick"
      ref="multiselect"
    />
  </div>
</template>

<script>
import { AsmtMixin } from '@mixins'
import XMultiselect from '@components/xLib/XMultiselect'
import { Events, EventBus } from '@events'
import CONST from '@constants'
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'BuildQuestionGrous',

  mixins: [AsmtMixin],

  components: {
    XMultiselect,
  },

  props: {
    itemRevId: { type: [String, Number], required: true },
  },

  data: () => ({
    selected: [],
  }),

  computed: {
    ...mapGetters('asmtSettings', ['itemTypeQuestionGroupsEnabled']),
    /**
     * Return all available question groups.
     * If assessement is view only return only selected question groups
     */
    items() {
      const itemType = this.asmtGlobalQuestionGroupObjectByItemRevId(this.itemRevId)
      let all = [...this.asmtGlobalQuestionGroupObjects]

      if (this.itemTypeQuestionGroupsEnabled) {
        const itemTypeQG = this.asmtGlobalQuestionGroupObjectByItemRevId(this.itemRevId)
        all = [itemTypeQG, ...all]
      }

      return this.amstViewOnly ? all.slice(0, 50).filter((o) => this.selectedItems.includes(o.id)) : all
    },
    selectedItems() {
      return [...this.asmtItemCustomQuestionGroups(this.itemRevId), ...this.asmtItemAutoQuestionGroups(this.itemRevId)]
    },
  },

  methods: {
    setSelectedQuestionGroups({ qg, selected = true, add = false, type = 'custom' }) {
      let itemCustomQGs = this.asmtItemCustomQuestionGroups(this.itemRevId)
      let itemAutoQGs = this.asmtItemAutoQuestionGroups(this.itemRevId)

      if (type == 'custom' || type == 'item_type') {
        itemCustomQGs = selected ? _.uniq([...itemCustomQGs, qg]) : itemCustomQGs.filter((v) => v != qg)
      }

      if (type == 'auto') {
        itemAutoQGs = selected ? _.uniq([...itemAutoQGs, qg]) : itemAutoQGs.filter((v) => v != qg)
      }

      // update ui state (item)
      this.asmtSetItemCustomQuestionGroups({
        itemRevId: this.itemRevId,
        value: itemCustomQGs,
      })
      if (selected && type == 'custom') this.amstAddCustomQuestionGroup(qg)
      this.asmtSetItemAutoQuestionGroups({
        itemRevId: this.itemRevId,
        value: itemAutoQGs,
      })

      // update backend
      EventBus.$emit(Events.ASSESSMENT_ITEM_CHANGE, {
        itemRevId: this.itemRevId,
        key: 'customQuestionGroups',
        value: itemCustomQGs,
      })
      EventBus.$emit(Events.ASSESSMENT_ITEM_CHANGE, {
        itemRevId: this.itemRevId,
        key: 'autoQuestionGroups',
        value: itemAutoQGs,
      })
    },
    onCreateItem({ item }) {
      this.setSelectedQuestionGroups({ qg: item, selected: true, add: true })
    },
    onSelectedChange({ id, selected, data }) {
      this.setSelectedQuestionGroups({ qg: id, selected, type: data.type })
    },
    onSettingsClick() {
      this.$refs.multiselect.show(false)
      EventBus.$emit(Events.ASSESSMENT_SETTINGS, CONST.SETTINGS_QUESTION_GROUPS_TAB)
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.build-qgs {
  &__controls {
    //....
  }
}
</style>
