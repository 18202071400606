<template>
  <v-btn class="ibx-button" :class="selectedClass" :outlined="!selected" v-bind="$attrs" v-on="$listeners" small>
    <slot name="label">{{ label || '' }}</slot>
  </v-btn>
</template>

<script>
export default {
  name: 'IbxButton',

  props: {
    label: { type: String, default: '' },
    selected: { type: Boolean, default: false },
  },

  computed: {
    selectedClass() {
      return this.selected ? 'selected' : ''
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/components/IbxButton.scss';
</style>
