<template>
  <v-navigation-drawer v-model="drawer" fixed right app>
    <v-list dense> </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'BrowseSidebar',

  data: () => ({
    drawer: false,
  }),
}
</script>
