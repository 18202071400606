/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'Match List_2': {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<rect pid="0" x="183.5" y="159.5" width="175" height="127" rx="12.43" transform="rotate(180 183.5 159.5)" _fill="#152452"/><rect pid="1" x="29.5" y="55.5" width="135" height="17" rx="8.5" _fill="#fff"/><rect pid="2" x="108.5" y="89" width="56" height="17" rx="8.5" _fill="#fff"/><rect pid="3" x="108.5" y="122.5" width="56" height="17" rx="8.5" _fill="#fff"/><rect pid="4" x="29.5" y="89" width="56" height="17" rx="8.5" _fill="#fff"/><rect pid="5" x="29.5" y="122.5" width="56" height="17" rx="8.5" _fill="#fff"/>',
  },
})
