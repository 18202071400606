<template>
  <div class="ibx-button-list">
    <v-layout row wrap align-end justify-start>
      <ibx-button
        v-for="(item, i) in items"
        :key="i"
        xs6
        class="ibx-chip-list-btn"
        :class="getItemClass(i)"
        :selected="showSelected(item[itemId])"
        @click="(selected) => change(item[itemId], !isSelected(item[itemId]))"
      >
        <template slot="label">{{ item[itemLabel] }}</template>
      </ibx-button>
    </v-layout>
  </div>
</template>

<script>
import IbxButton from './IbxButton'

export default {
  name: 'IbxButtonList',

  components: {
    IbxButton,
  },

  inject: {
    getItemClass: { default: () => () => '' },
  },

  props: {
    items: { type: Array, default: () => [] },
    selected: { type: Array, default: () => [] },
    itemLabel: { type: String, default: 'name' },
    itemId: { type: String, default: 'id' },
    meta: { type: Object, default: () => ({}) },
    allSelected: { type: Boolean, default: false },
  },

  computed: {
    selections() {
      return this.selected
    },
  },

  watch: {
    selected(val) {
      this.allSelected = false
    },
  },

  methods: {
    change(id, selected) {
      this.allSelected = false
      this.$emit('change', { id, selected })
    },
    isSelected(id) {
      return this.selections.includes(id)
    },
    showSelected(id) {
      return this.isSelected(id) && !this.allSelected
    },
  },

  mounted() {
    //this.allSelected = (this.selected.length === this.items.length)
  },
}
</script>

<style lang="scss">
@import '@/styles/components/IbxButtonList.scss';
</style>
