<template>
  <div class="build-item">
    <div class="build-item__selector">
      <v-checkbox
        v-if="!isPassage && !permIsPublished && !permIsViewOnly"
        class="checkbox"
        color="info"
        :value="selected"
        @change="onSelect"
      />
    </div>
    <!-- {{ itemRevId }} -->
    <component :is="cardType" context="build" :itemRevId="itemRevId" v-bind="$attrs" v-on="$listeners"></component>
  </div>
</template>

<script>
import * as VCard from 'vuetify/es5/components/VCard'
import * as VCheckbox from 'vuetify/es5/components/VCheckbox'
import { PermsMixin } from '@mixins'

export default {
  name: 'BuildItem',

  mixins: [PermsMixin],

  components: {
    ...VCheckbox,
    'ibx-assessment-item-card': () => import('@components/ibx/_IbxAssessmentItemCard'),
    'ibx-assessment-passage-card': () => import('@components/ibx/_IbxAssessmentPassageCard'),
  },

  props: {
    itemRevId: { type: [String, Number], default: null },
    selected: { type: Boolean, default: false },
  },

  data: () => ({
    isPassage: false,
  }),

  computed: {
    cardType() {
      return this.isPassage ? 'ibx-assessment-passage-card' : 'ibx-assessment-item-card'
    },
  },

  methods: {
    onSelect(selected) {
      this.$emit('item-selected', {
        itemRevId: this.itemRevId,
        selected,
      })
    },
  },

  created() {
    this.isPassage = Boolean(this.$attrs.passage)
  },
}
</script>

<style lang="scss">
@import '@/styles/main';
.build-item {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &__selector {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;

    * {
      margin: 0px !important;
      font-size: 24px !important;
    }

    .checkbox {
      .v-input__control {
        width: 100% !important;
        .v-input__slot {
          width: 100% !important;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .v-input--selection-controls__ripple {
        left: -3px;
        width: 30px;
        height: 30px;
        top: calc(50% - 16px);
      }
    }

    .v-input.checkbox.v-input--is-dirty {
      .v-icon {
        color: #008272 !important;
      }
    }
  }

  .build-item-card {
    flex-grow: 1;

    &__content {
      display: flex;
      padding: 57px 0px 0px 0px;
      height: 150px;
      overflow: hidden;

      .dragger {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 25px;
        font-size: 22px;
        color: $N30;

        &:hover {
          opacity: 0.8;
        }

        .drag-icon {
          cursor: move;
        }
      }
    }

    &__item {
      width: 100%;
      padding: 24px 24px 24px 13px;
    }

    &__actions-bottom {
      min-height: 50px;
      border-top: 1px solid $N20;
    }
  }
}

.test-item-header {
  display: flex;
  background: grey;
  width: 100%;

  &__col {
    flex: 1 1 auto;
    border: 1px solid red;
    background: pink;

    &--1 {
      background: greenyellow;
      flex: 0 0 50px;
    }

    &--2 {
      background: greenyellow;
      flex: 0 0 auto;

      .content {
        width: 200px;
        background: pink;
      }
    }

    &--3 {
      background: greenyellow;
    }

    &--4 {
      background: greenyellow;
    }

    &--5 {
      background: greenyellow;
    }
  }
}

.test {
  width: 100%;
}
</style>
