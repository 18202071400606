
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'
import * as VDialog from 'vuetify/es5/components/VDialog'
import XBtn from '@/components/xLib/XBtn'

@Component({
  components: {
    ...VDialog,
    XBtn,
  },
})
export default class ItemAuthorPassageConfirmDialog extends Mixins(Vue) {
  @Prop({ default: false }) show: boolean
  @Prop({ default: null }) confirmAction: string | number
  @Prop({ default: 'all' }) passageItemCount: string | number

  onSave() {
    this.$emit('confirm-action', {
      action: this.confirmAction,
      save: true,
    })
  }

  onCancel() {
    this.$emit('confirm-action', {
      action: this.confirmAction,
      save: false,
    })
  }
}
