/**
 * These helper classes are used for quick state module
 * implementation via class exted or Mixins
 */
import { Vue, Component } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import { ItemAuthor, ItemPassageAff } from '@/components/ibx/base/Item'
import { PassageAuthor, Passage, IPassageData } from '@/components/ibx/base/Passage'
import { Id, Bank, ItemConfigs } from '@/components/ibx/base/Types'
import { ItemAuthoringMode, ItemAuthorSaveAction, MenuTab } from '@/state/modules/authorItems'
import { FLAG } from '@constants'

/**
 * Store action type
 */
export type StoreAction = (data?: any) => Promise<any>
export type StoreMutation = (data?: any) => void

/**
 * Auth Module Helper Class
 */
@Component
export class StateAuth extends Vue {
  @Getter('pieToken', { namespace: 'auth' }) authPieToken: string
  @Action('init', { namespace: 'auth' }) authInit: StoreAction
}

/**
 * Browse Items Module Helper Class
 */
@Component
export class StateBrowseFilters extends Vue {
  @Getter('filters', { namespace: 'browseFilters' }) browseFilters: string
}

/**
 * AuthorItems Module Helper Class
 */
@Component
export class StateAuthorItems extends Vue {
  // getters
  @Getter('items', { namespace: 'authorItems' }) authorItems: ItemAuthor[]
  @Getter('autosave', { namespace: 'authorItems' }) authorAutosave: boolean
  @Getter('selectedBanks', { namespace: 'itemAuthor' }) authorSelectedBanks: string[]
  @Getter('getItem', { namespace: 'authorItems' }) authorGetItem: (itemId: Id) => ItemAuthor
  @Getter('changed', { namespace: 'authorItems' }) authorChanged: Id[]
  @Getter('hasChange', { namespace: 'authorItems' }) authorHasChange: boolean
  @Getter('saving', { namespace: 'authorItems' }) authorSaving: Id[]
  @Getter('isSaving', { namespace: 'authorItems' }) authorIsSaving: boolean
  @Getter('getItemPassage', { namespace: 'authorItems' }) authorGetItemPassage: (itemId: Id) => IPassageData
  @Getter('getItemPassageAff', { namespace: 'authorItems' }) authorGetItemPassageAff: (itemId: Id) => ItemPassageAff
  @Getter('mode', { namespace: 'authorItems' }) authorMode: ItemAuthoringMode
  @Getter('getEditItem', { namespace: 'authorItems' }) authorGetEditItem: ItemAuthoringMode
  @Getter('menuTab', { namespace: 'authorItems' }) authorMenuTab: MenuTab
  @Getter('menuTabView', { namespace: 'authorItems' }) authorMenuTabView: String
  @Getter('loading', { namespace: 'authorItems' }) authorLoading: boolean
  @Getter('ogItemRevIdMap', { namespace: 'authorItems' }) authorOgItemRevIdMap: { [key: number]: any }
  @Getter('itemsMutated', { namespace: 'authorItems' }) authorItemsMutated: { [key: number]: any }
  @Getter('itemActions', { namespace: 'authorItems' }) authorItemActions: { [key: number]: string[] }
  @Getter('hasErrors', { namespace: 'authorItems' }) authorHasErrors: boolean
  @Getter('itemErrors', { namespace: 'authorItems' }) authorItemErrors: { [key: number]: any }
  @Getter('passageAffSaving', { namespace: 'authorItems' }) authorPassageAffSaving: boolean
  @Getter('active', { namespace: 'authorItems' }) authorActive: boolean
  @Getter('itemLimit', { namespace: 'authorItems' }) authorItemLimit: boolean
  @Getter('itemLimitMessage', { namespace: 'authorItems' }) authorItemLimitMessage: string
  @Getter('banksChanged', { namespace: 'authorItems' }) authorBanksChanged: boolean
  @Getter('saveAction', { namespace: 'authorItems' }) authorSaveAction: ItemAuthorSaveAction
  @Getter('inheritPassage', { namespace: 'authorItems' }) authorInheritPassage: boolean
  @Getter('auxiliaryActions', { namespace: 'authorItems' }) authorAuxiliaryActions: boolean
  @Getter('itemRevUpdateOption', { namespace: 'authorItems' }) authorItemRevUpdateOption: string
  @Getter('hasNoStandard', { namespace: 'authorItems' }) authorHasNoStandard: boolean

  // mutations
  @Mutation('setMenuTab', { namespace: 'authorItems' }) authorSetMenuTab: StoreMutation
  @Mutation('setMenuTabView', { namespace: 'authorItems' }) authorSetMenuTabView: StoreMutation
  @Mutation('setSaving', { namespace: 'authorItems' }) authorSetSaving: StoreMutation
  @Mutation('setChanged', { namespace: 'authorItems' }) authorSetChanged: StoreMutation
  @Mutation('setLoading', { namespace: 'authorItems' }) authorSetLoading: StoreMutation
  @Mutation('setPassageAffSaving', { namespace: 'authorItems' }) authorSetPassageAffSaving: StoreMutation
  @Mutation('setActive', { namespace: 'authorItems' }) authorSetActive: StoreMutation
  @Mutation('trackItemError', { namespace: 'authorItems' }) authorTrackItemError: StoreMutation
  @Mutation('trackItemActions', { namespace: 'authorItems' }) authorTrackItemActions: StoreMutation
  @Mutation('setSaveAction', { namespace: 'authorItems' }) authorSetSaveAction: StoreMutation
  @Mutation('setInheritPassage', { namespace: 'authorItems' }) authorSetInheritPassage: StoreMutation
  @Mutation('setItemRevUpdateOption', { namespace: 'authorItems' }) authorSetItemRevUpdateOption: StoreMutation
  @Mutation('hydrateItemPassageAff', { namespace: 'authorItems' }) authorHydrateItemPassageAff: StoreMutation

  // actions
  @Action('init', { namespace: 'authorItems' }) authorInit: StoreAction
  @Action('authorItems', { namespace: 'authorItems' }) authorAuthorItems: StoreAction
  @Action('fetchBanks', { namespace: 'authorItems' }) authorFetchBanks: StoreAction
  @Action('onItemCreated', { namespace: 'authorItems' }) authorOnItemCreated: StoreAction
  @Action('onItemUpdated', { namespace: 'authorItems' }) authorOnItemUpdated: StoreAction
  @Action('onItemPassageUpdated', { namespace: 'authorItems' }) authorOnItemPassageUpdated: StoreAction
  @Action('setItems', { namespace: 'authorItems' }) authorSetItems: StoreAction
  @Action('updateItem', { namespace: 'authorItems' }) authorUpdateItem: StoreAction
  @Action('createItems', { namespace: 'authorItems' }) authorCreateItems: StoreAction
  @Action('removeItems', { namespace: 'authorItems' }) authorRemoveItems: StoreAction
  @Action('resetItems', { namespace: 'authorItems' }) authorResetItems: StoreAction
  @Action('reset', { namespace: 'authorItems' }) authorReset: StoreAction
  @Action('setItemPassageLink', { namespace: 'authorItems' }) authorSetItemPassageLink: StoreAction
  @Action('authorDuplicateItem', { namespace: 'authorItems' }) authorAuthorDuplicateItem: StoreAction
  @Action('onAuxiliaryAction', { namespace: 'authorItems' }) authorOnAuxiliaryAction: StoreAction
  @Action('setNoStandard', { namespace: 'authorItems' }) authorSetNoStandard: StoreAction
}

/**
 * Auth Module Helper Class
 */
@Component
export class StateAuthorPassages extends Vue {
  // getters
  @Getter('attributes', { namespace: 'authorPassages' }) authorPassageAttributes: any[]
  @Getter('attributeValues', { namespace: 'authorPassages' }) authorPassageAttributeValues: any[]
  @Getter('advancedAttributes', { namespace: 'authorPassages' }) authorPassageAdvancedAttributes: any[]
  @Getter('getPassage', { namespace: 'authorPassages' }) authorPassageGetPassage: IPassageData[]

  // mutations
  @Mutation('addPassage', { namespace: 'authorPassages' }) authorPassageAddPassage: StoreMutation
  @Mutation('updatePassage', { namespace: 'authorPassages' }) authorPassageUpdatePassage: StoreMutation

  // actions
  @Action('init', { namespace: 'authorPassages' }) authorPassageInit: StoreAction
}

/**
 * ItemConfigs Module Helper Class
 */
@Component
export class StateItemConfigs extends Vue {
  // actions
  @Action('getItemConfig', { namespace: 'itemConfigs' }) itemConfigsGetItemConfig: ({
    versionedId,
    cacheFirst,
  }: {
    versionedId: string
    cacheFirst: boolean
  }) => any
  @Action('getItemMetaConfig', { namespace: 'itemConfigs' }) itemConfigsGetItemMetaConfig: ({
    versionedId,
    cacheFirst,
  }: {
    versionedId: string
    cacheFirst: boolean
  }) => any
  @Action('getPassageConfig', { namespace: 'itemConfigs' }) itemConfigsGetPassageConfig: ({
    remoteId,
    cacheFirst,
  }: {
    remoteId: string
    cacheFirst: boolean
  }) => any
}

/**
 * Features Module Helper Class
 */
@Component
export class StateFeatures extends Vue {
  /**
   * FLAG Constants getter
   */
  get FLAG(): { [key: string]: string } {
    return FLAG
  }

  // getters
  @Getter('flags', { namespace: 'features' }) featureFlags: (itemRevId: Id) => any
  @Getter('flag', { namespace: 'features' }) featureFlag: (key: string) => any
  @Getter('supportedItemTypes', { namespace: 'features' }) featureSupportedItemTypes: { [key: string]: boolean }

  // actions
  @Action('init', { namespace: 'features' }) featuresInit: StoreAction
  @Action('setFlags', { namespace: 'features' }) featuresSetFlags: StoreAction
  @Action('setFlag', { namespace: 'features' }) featuresSetFlag: StoreAction
}
