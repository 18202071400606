const FilterEvents = {
  FILTER_ADD: 'filter-add',
  FILTER_CHANGE: 'filter-change',
  FILTER_SELECT_ALL: 'filter-select-all',
  FILTER_EXPAND: 'filter-expand',
  FILTER_COLLAPSE: 'filter-collapse',
  FILTER_REMOVE: 'filter-remove',
  FILTER_SEARCH: 'filter-search',
  FILTER_SEARCHING: 'filter-searching',
  FILTERS_ORDER: 'filters-order',
  FILTER_AUX_ACTION: 'filter-aux-action',
}

export { FilterEvents }
