import { IBXService } from '@services/ibx/IBXService'
import { StoreOptions } from 'vuex'

interface IState {
  initialized: boolean
  materials: object[]
}

class State implements IState {
  initialized: boolean = false
  materials: object[] = []
}

const asmtMaterials = {
  namespaced: true,
  state: new State(),

  getters: {
    initialized: (state: IState) => state.initialized,
    materials: (state: IState) => state.materials,
  },

  mutations: {
    setInitialized: (state: IState, value: boolean = false) => {
      state.initialized = value
    },
    setMaterials: (state: IState, value: object[] = []) => {
      state.materials = value
    },
    addMaterial: (state: IState, material: object) => {
      if (material) {
        state.materials.push(material)
      }
    },
    removeMaterial: (state: IState, materialId: number) => {
      state.materials = state.materials.filter((o: any) => o.materialId !== materialId)
    },
  },

  actions: {
    fetchMaterials: async ({ commit, rootState }: { commit: any; rootState: any }) => {
      try {
        const { materials } = await IBXService.assessmentMaterials({
          assessmentId: rootState.asmt.assessmentId,
        })
        commit('setMaterials', materials)
        commit('setInitialized', true)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    addMaterial: async ({ commit }, material: object) => {
      commit('addMaterial', material)
    },
    removeMaterial: async ({ commit, rootState }: { commit: any; rootState: any }, materialId: number) => {
      const response = IBXService.assessmentMaterialDelete({
        assessmentId: rootState.asmt.assessmentId,
        materialId,
      })
      commit('removeMaterial', materialId)
      return response
    },
  },
} as StoreOptions<IState>

export { asmtMaterials, IState }
