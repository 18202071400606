<template>
  <div class="ibx-items-toolbar">
    <div class="ibx-items-toolbar__label">
      <slot name="label"></slot>
    </div>
    <div class="ibx-items-toolbar__actions">
      <slot name="actions"></slot>
      <div class="ibx-items-toolbar__actions__view">
        <span class="label">View</span>
        <v-tooltip bottom open-delay="0" close-delay="0" transition="fade" content-class="custom-tooltip">
          <template v-slot:activator="{ on }">
            <span
              v-on="on"
              v-if="isBuild"
              class="icon-wrap"
              :class="view == 'close' ? 'selected' : ''"
              @click="onViewChange('close')"
            >
              <svg
                class="icon"
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="-0.00012207" width="14" height="2" rx="0.5" fill="#008272" />
                <rect x="-0.00012207" y="12" width="14" height="2" rx="0.5" fill="#008272" />
                <rect x="-0.00012207" y="4" width="14" height="2" rx="0.5" fill="#008272" />
                <rect x="-0.00012207" y="8" width="14" height="2" rx="0.5" fill="#008272" />
              </svg>
            </span>
          </template>
          <span>Compact View</span>
        </v-tooltip>

        <v-tooltip bottom open-delay="0" close-delay="0" transition="fade" content-class="custom-tooltip">
          <template v-slot:activator="{ on }">
            <span
              v-on="on"
              class="icon-wrap"
              :class="view == 'collapse' ? 'selected' : ''"
              @click="onViewChange('collapse')"
            >
              <svg
                class="icon"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="-0.00012207" width="15" height="4" rx="1" fill="#008272" />
                <rect x="-0.00012207" y="5" width="15" height="4" rx="1" fill="#008272" />
                <rect x="-0.00012207" y="10" width="15" height="4" rx="1" fill="#008272" />
              </svg>
            </span>
          </template>
          <span>Collapsed View</span>
        </v-tooltip>
        <v-tooltip bottom open-delay="0" close-delay="0" transition="fade" content-class="custom-tooltip">
          <template v-slot:activator="{ on }">
            <span
              v-on="on"
              class="icon-wrap"
              :class="view == 'expand' ? 'selected' : ''"
              @click="onViewChange('expand')"
            >
              <svg
                class="icon"
                width="15"
                height="14"
                viewBox="0 0 15 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="-0.00012207" width="15" height="14" rx="1" fill="#008272" />
              </svg>
            </span>
          </template>
          <span>Expanded View</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import CONST from '@constants'
import { mapGetters, mapActions } from 'vuex'
import * as VTooltip from 'vuetify/es5/components/VTooltip'

export default {
  name: 'IbxItemsToolbar',

  components: {
    ...VTooltip,
  },

  props: {
    context: { type: String, default: CONST.MODE_BROWSE },
  },

  computed: {
    ...mapGetters('ui', ['browseItemsListView', 'buildItemsListView']),
    isBuild() {
      return this.context == CONST.MODE_BUILD
    },
    view() {
      return this.isBuild ? this.buildItemsListView : this.browseItemsListView
    },
  },

  methods: {
    ...mapActions('ui', ['setBrowseItemsListView', 'setBuildItemsListView']),
    onViewChange(view = CONST.COLLAPSE) {
      return this.isBuild ? this.setBuildItemsListView(view) : this.setBrowseItemsListView(view)
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.ibx-items-toolbar {
  display: flex;
  align-items: center;
  height: 48px;
  background: white;
  padding: 0px 20px;
  border-radius: 4px;
  border: 1px solid $N20;
  font-family: 'CerebriSans', Roboto, sans-serif;

  &__selector {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 48px;
    margin-left: -63px !important;

    * {
      margin: 0px !important;
      font-size: 24px !important;
    }

    .checkbox {
      margin-top: 10px !important;

      .v-input__control {
        flex-direction: row;
      }
      .v-input__slot {
        justify-content: center !important;
      }

      .v-input--selection-controls__ripple {
        left: -3px;
        width: 30px;
        height: 30px;
        top: calc(50% - 16px);
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    margin-left: auto;
    color: $N60;
    padding-right: 3px;

    &__view {
      display: flex;
      align-items: center;
      cursor: default;

      .label {
        margin-right: 10px;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.2px;
        color: $N60;
      }

      .icon-wrap {
        display: flex;
        align-items: center;
        opacity: 0.3;

        &.selected {
          opacity: 1;
        }

        .icon {
          margin-left: 8px;
          color: $B75;

          transition: opacity 0.3s ease-in-out;
          cursor: pointer;
        }
      }
    }
  }

  &__label {
    // margin-left: 15px;
    font-size: 14px;
  }
}
</style>
