<template>
  <div class="holder">
    <div id="player-holder"></div>
  </div>
</template>

<script>
import { IBXService } from '@services/ibx/IBXService'
//import { StoreOptions } from "vuex";

/* eslint-disable */

export default {
  name: 'PieItemPlayer',

  props: {
    itemId: { type: [String, Number], required: true },
    initialMode: { type: String, default: 'preview' },
  },

  /* actions: {
    getAuthToken: IBXService.getAuthToken()
  }, */

  methods: {
    renderDemoItem() {
      // Token will be generated server side, hard-code for now;
      const token =
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2kiOiIwZGVkYTUyMC1iYWMyLTExZTgtODAyMi1iOWFlMDc4MzI2ZjUiLCJqdGkiOiIzYTA5ODdjMC1mMzRiLTExZTgtYjcxNS1iZDU1N2ZkZWE1OWQiLCJpYXQiOjE1NDM0MzY1MDUsImV4cCI6MTU0MzUyMjkwNSwic2NvcGVzIjpbXX0.h6ZzV4F1WhImJhMR3d-SXAD_4g6NOUi60xhyBzuW3OY'

      //Create player DOM element
      const player = document.createElement('pie-api-player')

      //Set params
      player.setAttribute('token', token)
      player.setAttribute('item-id', this.itemId)

      /*
          Initial Mode: [gather | view | evaluate | preview]
          1. gather: (default) the user generates a response by interacting with the item
          2. view: the user views a response/interaction without being able to modify it
          3. evaluate: the user sees an evaluation of the response [where applicable]
          4. preview a user previews an item, e.g. when considering it for inclusion in an 
      */
      player.setAttribute('initial-mode', 'view')

      //Inject player into this component
      this.$el.appendChild(player)

      //We can interact with the player by adding event handlers.
      player.addEventListener('session-changed', (event) => {
        player
          .score()
          .then((result) => {
            //console.log('score: ', result);
          })
          .catch((e) => {
            //console.error('error: ', e)
          })
      })

      player.addEventListener('session-created', (event) => {
        /* event.detail
                   {id: "generated_session_id"} */
        console.log(event)
      })

      player.addEventListener('session-changed', (event) => {
        /* event.detail.complete: true if this question type has been answered
                   detail.component: the question type that has been answered  */
        console.log(event)
      })
    },
  },

  mounted() {
    this.renderDemoItem()
  },
}
</script>

<style scope>
.holder {
  margin: 10px;
  padding: 30px;
  background-color: white;
  border-style: solid;
  border-width: 3px;
  border-color: darkgray;
}
</style>
