import { render, staticRenderFns } from "./IbxReportIssueModal.vue?vue&type=template&id=6d7727dd"
import script from "./IbxReportIssueModal.vue?vue&type=script&lang=js"
export * from "./IbxReportIssueModal.vue?vue&type=script&lang=js"
import style0 from "./IbxReportIssueModal.vue?vue&type=style&index=0&id=6d7727dd&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports