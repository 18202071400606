<template>
  <v-layout class="standards-dialog" row justify-center>
    <v-dialog v-model="show_dialog" class="standards-dialog-modal" persistent max-width="80%">
      <template v-slot:activator="{ on }">
        <span
          class="x-filter-auxilliary-items__item__label"
          v-on="on"
          color="primary"
          v-if="!customActivator"
          @click="onEditClick"
          >Edit Base Standards</span
        >
      </template>
      <v-card class="standards-dialog__content">
        <v-card-title class="standards-title" primary-title>
          <div>My Standards</div>
          <v-spacer></v-spacer>
          <ibx-saving-message context="stannis" class="standards-dialog__saving" color="#0EA449" />
          <font-awesome-icon class="close-icon" :icon="['far', 'times']" @click="onHide" />
        </v-card-title>
        <stannis />
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn class="cancel-btn" flat color="black" @click.native="onHide">Cancel</v-btn>
                    <v-btn color="primary" @click="getSelectedStandards(); onHide();">Confirm</v-btn> -->
          <x-btn color="primary" @click="onHide">Done</x-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import * as VDialog from 'vuetify/es5/components/VDialog'
import * as VCard from 'vuetify/es5/components/VCard'
import { Events, EventBus } from '@events'
import IbxSavingMessage from '@components/ibx/IbxSavingMessage'
import { SavingMessageMixin } from '@mixins'
import CONST from '@constants'
import { standards } from '../../../tests/unit/_mocks/state/standards'

export default {
  name: 'StandardsDialog',

  mixins: [SavingMessageMixin],

  components: {
    ...VDialog,
    ...VCard,
    IbxSavingMessage,
  },

  props: {
    show: { type: Boolean, default: false }, // trigger show_dialog via prop
    customActivator: { type: Boolean, default: false }, // if true hide the active button
  },

  data() {
    return {
      show_dialog: false,
    }
  },

  watch: {
    show(v) {
      this.syncShow()
      this.setStannisActive(v)
    },
  },

  computed: {
    ...mapGetters('standards', {
      stannisGet: 'get',
      stannisInitialized: 'getInitialized',
    }),
    ...mapGetters('user', {
      userUserSelection: 'userSelection',
      userBaseStds: 'baseStds',
    }),
    selectedStandards() {
      return this.stannisGet('standards_get')
    },
  },

  methods: {
    ...mapActions('standards', {
      stannisInit: 'init',
      stannisSetProcessingRequest: 'setProcessingRequest',
      stannisSetHasError: 'setHasError',
    }),
    ...mapMutations('standards', {
      stannisSetFilteredDocuments: 'mutateFilteredDocuments',
      stannisSetFilteredSubjects: 'mutateFilteredSubjects',
      stannisSetFilteredGradeLevelSets: 'mutateFilteredGradeLevelSets',
      stannisSelectedStandards: 'mutateSelectedStandards',
    }),
    ...mapActions('ui', ['setStannisActive']),
    ...mapActions('browseFilters', {
      browseFiltersSetPrevBaseStdGuids: 'setPrevBaseStdGuids',
    }),
    /// we need to rethink this
    getSelectedStandards() {
      this.$emit('selectedStandardsChanged', this.stannisGet('selected_standards'))
      this.$emit(Events.STANNIS_CHANGE)
      //EventBus.$emit(Events.STANNIS_CHANGE)
    },
    syncShow() {
      if (this.show) this.setPrevBaseStds()
      this.show_dialog = this.show
    },
    onShow() {
      this.show_dialog = true
      this.$emit(Events.STANNIS_SHOW)
      EventBus.$emit(Events.STANNIS_SHOW)
    },
    onHide() {
      this.show_dialog = false
      this.$emit(Events.STANNIS_HIDE)
      EventBus.$emit(Events.STANNIS_HIDE)
    },
    onStannisChange() {
      this.smSaved()
    },
    initStannis() {
      if (!this.stannisInitialized) {
        // set user base standards
        const baseStandards = this.userUserSelection(CONST.CONTEXT_BASE_STANDARDS).value
        this.stannisSetFilteredDocuments(baseStandards.documents || [])
        this.stannisSetFilteredSubjects(baseStandards.subjects || [])
        this.stannisSetFilteredGradeLevelSets(baseStandards.grade_level_sets || [])
        this.stannisSelectedStandards(baseStandards.selected_standards || [])

        this.stannisInit()
          .catch(() => this.stannisSetHasError(true))
          .finally(() => this.stannisSetProcessingRequest(false))
      }
    },
    setPrevBaseStds() {
      this.browseFiltersSetPrevBaseStdGuids(this.userBaseStds.map((o) => o.guid))
    },
    onEditClick() {
      this.setPrevBaseStds()
    },
  },

  created() {
    EventBus.$on(Events.STANNIS_CHANGE, this.onStannisChange)
    this.syncShow()
  },

  beforeDestroy() {
    EventBus.$off(Events.STANNIS_CHANGE, this.onStannisChange)
  },

  mounted() {
    this.saveContext = 'stannis'
    const unwatch = this.$watch('show_dialog', (v) => {
      if (v) {
        this.initStannis()
        unwatch()
      }
    })

    if (this.show_dialog) {
      this.initStannis()
      unwatch()
    }
  },
}
</script>

<style lang="scss">
.standards-dialog {
  .v--card {
    border: none !important;
  }

  &__saving {
    margin-right: 32px;
  }

  &__content {
    #standardsApp {
      margin: 0 !important;
      padding: 0 !important;

      .standards-list-component {
        border: none !important;
      }

      .standards-list-group-list {
        border: 1px solid #e0e1e6 !important;
        border-radius: 8px 8px 0px 0px;
      }
    }

    .standards-filters {
      box-sizing: border-box;
      margin: 0 !important;
      padding: 0px 20px;
      display: flex;

      .standards-filters-component {
        margin-bottom: 10px;
      }

      .standards-filters-component__search {
        .stannis_search {
          margin-left: 12px !important;
          width: 100px;
        }
      }
    }

    .standards-view__list {
      box-sizing: border-box;
      height: 430px !important;
      min-height: 300px;
      overflow-y: auto;
      overflow-x: hidden;
      padding-left: 30px !important;
      margin: 0 !important;
    }

    .standards-view__selected {
      .selected-stds {
        padding: 15px !important;
        margin-right: 32px !important;
      }
    }

    .selected-stds {
      box-sizing: border-box;
      height: 430px !important;
      min-height: 300px;
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 40px !important;
      margin: 0 !important;
    }

    .standards-title {
      font-size: 18px;
      font-weight: 600;

      .close-icon {
        color: #7e8494;
        font-size: 24px;
        margin-right: 5px;
        cursor: pointer;

        &:hover {
          color: #050f2d;
        }
      }
    }

    .cancel-btn {
      background-color: white !important;
    }
  }

  .x-filter-auxilliary-items__item__label {
    white-space: nowrap;
    font-weight: 400;
    cursor: pointer;
  }
}
</style>
