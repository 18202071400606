<template>
  <div class="x-filter-auxilliary-items">
    <div
      v-for="(o, i) in items"
      :key="`auxitem-${i}`"
      class="x-filter-auxilliary-items__item"
      :style="{ color: getAuxColor(o) }"
      @click="onItemClick(o)"
    >
      <!--<font-awesome-icon
                v-if="o.icon" 
                :icon="o.icon" 
                class="x-filter-auxilliary-items__item__icon"
                :style="{ color: getAuxColor(o) }" />-->
      <span class="x-filter-auxilliary-items__item__label">{{ getItemLabel(o) }}</span>
    </div>
  </div>
</template>

<script>
import xFilterMixin from '../mixins/xFilterMixin'
import { FilterEvents } from '../xFilterEvents'

export default {
  name: 'XFilterAuxItems',

  mixins: [xFilterMixin],

  methods: {
    onItemClick(item) {
      this.$emit(FilterEvents.FILTER_AUX_ACTION, { id: item.id })
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/main';
</style>
