import { PieApi, PreReleaseType, ReleaseType, ResponseData } from './PieAuthorServiceBase'
import { wait } from '@/helpers'

/**
 * Handles item interfacing with PIE API GraphQL
 */
export class PieAuthorPassageService extends PieApi {
  /**
   * Generate variables for save mutations
   */
  private makeQueryVariables({
    config,
    name = '',
    releaseType = ReleaseType.PATCH,
    versionedID = '',
  }: {
    config: any
    name?: string
    releaseType?: ReleaseType
    versionedID?: string
  }): any {
    return {
      versionedID,
      input: {
        name,
        config,
        releaseType,
      },
    }
  }

  private saveMutation(): string {
    return `
            _saveStimulus: saveStimulus(
                versionedID: $versionedID
                input: $input
            ) {
                ...stimulus
            }
        `
  }

  /**
   * Get query fragments
   * @returns GraphQL string
   */
  private fragments(): string {
    return `
            fragment stimulus on Stimulus {
                id
                version {
                    major
                    minor
                    patch
                    prerelease
                }
                config
            }
        `
  }

  private makeMutationQuery({
    versionedID = '',
    release = false,
    config = null,
  }: {
    versionedID: string
    release?: boolean
    config?: any
  }): string {
    return `
            mutation save(
                $versionedID: VersionedID!
                ${config ? '$input: StimulusInput!' : ''}
            ) {
                ${config ? this.saveMutation() : ''}
            }
            ${this.fragments()}
        `
  }

  /**
   * Marshal response data.
   * Returns data object contantaining the passage id for last mutation that altered the passage version
   * return orginal passage id if not provided from mutations
   * @param responseData data returned from response
   * @param versionedID  passage id passed when running query
   * @return ResponseData
   */
  private marshalResponseData(responseData: any, versionedID?: string): ResponseData {
    let data = null
    if (responseData._saveStimulus) data = responseData._saveStimulus
    else if (responseData._stimulus) data = responseData._stimulus
    else data = Object.assign({}, responseData, { id: versionedID })

    const versionedId =
      data.id && data.version ? this.getVersionedIdString({ id: data.id, version: data.version }) : versionedID
    return {
      data: Object.assign({}, data, { versionedId }),
      responseData,
    }
  }

  /**
   * Exponential retry for PIE requests
   * @param query GraphQL query
   * @param versionedID passage id
   * @param depth Current number of retries for recursion
   * @returns response data
   * @throws { Error } If max retries fail
   */
  private async savePassageWithRetry({
    query,
    variables = {},
    depth = 0,
  }: {
    query: string
    variables: any
    depth?: number
  }): Promise<any> {
    try {
      const data = await this.pieService.query({
        query,
        variables,
      })

      return data
    } catch (e) {
      if (depth > 2) {
        throw e
      }

      // backoff = 500, 1000, 2000
      await wait(2 ** depth * 500)
      const data = await this.savePassageWithRetry({ query, variables, depth: depth + 1 })
      return data
    }
  }

  /**
   * Save/upsert passage
   */
  public async savePassage({
    config,
    name = '',
    versionedID = '',
    releaseType = ReleaseType.PATCH,
    release = false,
  }: {
    config?: any
    name?: string
    versionedID?: string
    releaseType?: ReleaseType
    release?: boolean
  }): Promise<any> {
    const query = this.makeMutationQuery({
      config,
      release,
      versionedID,
    })
    const variables = this.makeQueryVariables({
      config,
      name,
      releaseType,
      versionedID,
    })

    try {
      const responseData = await this.savePassageWithRetry({
        query,
        variables,
      })
      return this.marshalResponseData(responseData, versionedID)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  /**
   * Generate fetch passage query
   * @returns string
   */
  private makeQuery(): string {
    return `
            query passage($versionedID: VersionedID!) {
                _stimulus: stimulus(versionedID: $versionedID) {
                    ...stimulus
                }
            }
            ${this.fragments()}
        `
  }

  /**
   * Fetch passage.
   * @returns Promise<any>
   */
  public async getPassage({ versionedID }: { versionedID: string }): Promise<any> {
    try {
      const responseData = await this.pieService.query({
        query: this.makeQuery(),
        variables: { versionedID },
      })
      return this.marshalResponseData(responseData, versionedID)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
