
// @ts-nocheck
import * as VCheckbox from 'vuetify/es5/components/VCheckbox'
import * as VHover from 'vuetify/es5/components/VHover'
import StandardsDialog from '@components/standards/StandardsDialog'
import { mapGetters, mapActions } from 'vuex'
import CONST from '@constants'
import _ from 'lodash'
import IbxStandardTooltip from '@components/ibx/IbxStandardTooltip'
import { StandardLabelMixin } from '@/mixins'

// new
import { Vue, Component, Mixins } from 'vue-property-decorator'
import { StateAuthorItems } from '@/helpers/state'
import ItemAuthorBase from '@components/itemAuthor/base/ItemAuthorBase'

@Component({
  components: {
    ...VCheckbox,
    ...VHover,
    StandardsDialog,
    IbxStandardTooltip,
  },
  methods: {
    ...mapActions('standardsItems', {
      standardItemsGetItems: 'getItems',
    }),
  },
  computed: {
    ...mapGetters('standards', {
      stannisGet: 'get',
      stannisGetFiltered: 'getFiltered',
      stannisInitialized: 'getInitialized',
    }),
    ...mapGetters('user', {
      userUserSelection: 'userSelection',
    }),
  },
})
export default class ItemAthorStandardsMenu extends Mixins(Vue, StateAuthorItems, ItemAuthorBase, StandardLabelMixin) {
  private ready: boolean = false
  private standardsData: any[] = []
  /**
   * Get guid for item aligned standards
   */
  get itemStandards(): string[] {
    const standards = this?.item?.standards || []
    return standards.map((o) => o.id)
  }

  /**
   * Get data for item aligned standards
   */
  get itemStandardsData(): any[] {
    return this.standardsData.filter((o) => this.itemStandards.includes(o.guid))
  }

  /**
   * Get base standards from user base standards
   */
  get baseStandards(): any[] {
    return this.userBaseStandards
  }

  get hasBaseStandards(): boolean {
    return this.baseStandards.length > 0
  }

  get hasAlignedStandards(): boolean {
    return Boolean(this.itemStandards.length)
  }

  /*
   * Get base standards from user selection if stannis not initialized
   */
  get userBaseStandards() {
    if (this.stannisInitialized) {
      return this.stannisGet('selected_standards')
    } else {
      const usBaseStandards = this.userUserSelection(CONST.CONTEXT_BASE_STANDARDS)
      return usBaseStandards && usBaseStandards.value && usBaseStandards.value.selected_standards
        ? usBaseStandards.value.selected_standards
        : []
    }
  }

  /**
   * Initialize component.
   * Load standards data
   */
  async init() {
    await this.loadStandardsData()
    this.ready = true
  }

  /**
   * Fetch and set standards data
   * for both base and item standards
   */
  async loadStandardsData() {
    const allGuids = _.uniq([...this.baseStandards.map((o) => o.guid), ...this.itemStandards])
    if (allGuids.length) {
      this.standardsData = await this.standardItemsGetItems(allGuids)
    }
  }

  /**
   * Standard change handler.
   * Toggle standard by guid
   * @param guid standard guid
   */
  toggleStandard(guid: string) {
    if (!guid) {
      this.authorHasNoStandard(this.itemId)
        ? this.authorSetNoStandard({ itemId: this.itemId, data: false })
        : this.authorSetNoStandard({ itemId: this.itemId, data: true })
      this.emitChanges([])
    } else {
      const standards = this.hasStandard(guid)
        ? this.itemStandards.filter((v) => v != guid)
        : _.uniq([guid, ...this.itemStandards])
      this.emitChanges(standards)
      this.authorSetNoStandard({ itemId: this.itemId, data: false })
    }
  }

  emitChanges(standards: string) {
    this.$emit('change', [
      {
        itemId: this.itemId,
        key: 'standard',
        value: standards.map((id) => ({ id })),
      },
    ])
  }
  /**
   * Check is item has standard
   * @param guid standard guid
   */
  hasStandard(guid: string): boolean {
    return this.itemStandards.includes(guid)
  }

  /**
   * Base standards change handler.
   * Updates base standards then load all standards data.
   * Pushes to authoring auxiliary actions for tracking.
   */
  async onStannisHide() {
    this.authorOnAuxiliaryAction({ standards: true })
    await this.loadStandardsData()
  }

  /**
   * Get standard icon based on state
   */
  getIcon(guid, hover): string {
    if (!guid) {
      const prefix = this.authorHasNoStandard(this.itemId) ? 'far' : 'fal'
      const icon = this.authorHasNoStandard(this.itemId) ? (hover ? 'minus' : 'check') : 'plus'
      return [prefix, icon]
    } else {
      const hasStandard = this.hasStandard(guid)
      const prefix = hasStandard ? 'far' : 'fal'
      const icon = hasStandard ? (hover ? 'minus' : 'check') : 'plus'
      return [prefix, icon]
    }
  }
  /**
   * get standard data object
   */
  getStandard(item): any {
    if (this.standardsData?.length) return this.standardsData.find((o) => o.guid == item.guid) || null
  }

  updateNoStandards() {
    if (this.isEditMode) {
      this.hasAlignedStandards
        ? this.authorSetNoStandard({ itemId: this.itemId, data: false })
        : this.authorSetNoStandard({ itemId: this.itemId, data: true })
    } else {
      this.authorSetNoStandard({ itemId: this.itemId, data: false })
    }
  }

  mounted() {
    this.init()
    this.updateNoStandards()
  }
}
