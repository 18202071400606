import Vue from 'vue'
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'

const tooltipDefaults = {
  placement: 'top',
  autoHide: false,
  hideOnTargetClick: false,
  html: true,
  trigger: 'hover',
}

const plugin = {
  install(Vue, options) {
    /* inject functionality into components */
    Vue.mixin({
      methods: {
        tooltip(tooltipOptions) {
          return Object.assign({}, tooltipDefaults, tooltipOptions)
        },
      },
    })
  },
}

// VTooltip.options.defaultClass = 'ibx-tooltip'
Vue.directive('tooltip', VTooltip)
Vue.use(plugin)
