
// @ts-nocheck
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'
import { IPassageData, Passage } from '@components/ibx/base/Passage'
import AsmtPrintPlayer from './AsmtPrintPlayer'
import _ from 'lodash'
import { StateItemConfigs } from '@helpers/state'
import AsmtPrintQuestion from './AsmtPrintQuestion'

@Component({
  components: {
    AsmtPrintQuestion,
    AsmtPrintPlayer,
  },
})
export default class AsmtPrintPassage extends Mixins(Vue, StateItemConfigs) {
  @Prop({ default: null }) payload: any
  @Prop({ default: 'view' }) mode: string
  @Prop({ default: 'student' }) role: string
  @Prop({ default: '12' }) layout: string

  private passage: Passage = {}
  private items = []
  private passageConfig: any = null

  get passageRevId() {
    return this.payload?.passageRevId
  }

  get chunked() {
    const chunkSize = this.layout == '6' ? 2 : 1
    return _.chunk(_.toArray(this.items), chunkSize)
  }

  get passageItemsSortable() {
    const sortedItems = _.sortBy(this.passage.items, [
      function (o) {
        return o.order
      },
    ])
    const itemsMap = sortedItems.reduce((m, o) => {
      m[o.itemRevId] = { ...o, visited: false }
      return m
    }, {})
    const data = sortedItems.map((o, index, arr) => {
      return {
        ...o,
        type: 'item',
        data: itemsMap[o.itemRevId],
        classes: ['passage-item'],
      }
    })
    return data
  }

  get passagePlayerOptions() {
    return {
      role: this.role,
    }
  }

  get ready() {
    return Boolean(this.passageConfig)
  }

  get remoteId() {
    return this.passage?.remoteId
  }

  /**
   * Intialize item and set player props
   */
  async init() {
    this.passage = new Passage(this.payload) || {}
    this.items = this.passageItemsSortable
    await this.setPassageConfig()
  }

  async setPassageConfig() {
    try {
      const { data } = await this.itemConfigsGetPassageConfig({ remoteId: this.remoteId })
      this.passageConfig = data?.stimulus?.config
    } catch (error) {
      console.warn(error)
    }
  }

  created() {
    this.init()
  }
}
