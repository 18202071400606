import { Id, IEntityData, Entity, metaProps } from './Types'
import Vue from 'vue'
import _ from 'lodash'

/*
 * Item entity data interface
 * IEntityData holds generic entity data structure
 */
export interface IItemData extends IEntityData {
  itemId: Id
  itemRevId: Id
  itemType: string
  locked: boolean
  passage: object
  stem: string
  contentConfig: any
  configSettings: any
}

/**
 *  Item Entity class
 */
export class Item extends Entity<IItemData> {
  constructor(data: IItemData) {
    super(data)
  }

  /**
   * Item data getters
   */
  get contentConfig(): any {
    return this.data.contentConfig
  }
  get configSettings(): any {
    return this.data.configSettings
  }
  get id(): Id {
    return this.data.itemId
  }
  get inUse(): boolean {
    return this.data.inUse
  }
  get itemId(): Id {
    return this.data.itemId
  }
  get itemRevId(): Id {
    return this.data.itemRevId
  }
  get itemType(): string {
    return this.data.itemType
  }
  get locked(): boolean {
    return this.data.locked
  }
  get passage(): object | any {
    return this.data.passage
  }
  get revisionId(): Id {
    return this.data.itemRevId
  }
  get stem(): string {
    return this.data.stem
  }
  get standards(): any[] {
    return this.meta?.standard?.items || []
  }

  /**
   * Helpers
   */
  get systemTags(): any[] {
    return [
      this.meta.blooms_taxonomy,
      this.meta.depth_of_knowledge,
      this.meta.difficulty,
      this.meta.parcc_claim,
      this.meta.parcc_secondary_claim,
      this.meta.parcc_subclaim,
      this.meta.parcc_secondary_subclaim,
      this.meta.revised_blooms_taxonomy,
      this.meta.responseLanguage,
      this.meta.sbac_claim,
      this.meta.sbac_subclaim,
      this.meta.sbac_secondary_claim,
      this.meta.sbac_claim_target,
      this.meta.sbac_secondary_claimTarget,
      this.meta.sbac_content_category,
      this.meta.sbac_secondary_contentCategory,
    ].filter((o: any) => o != null)
  }

  /**
   * Get meta data Id by key
   * @param key meta data key
   * @returns Id meta data Id
   */
  public getMetaId(key: Id): Id {
    return this?.meta?.[key]?.items?.[0]?.id || null
  }

  /**
   * Check if item has meta data type
   * @param key meta data key
   */
  public hasMetaDataType(key: Id): boolean {
    return Boolean(this.meta[key])
  }
}

/**
 * Used for linking/unlinking passage/items
 * passageId = IBX passageId
 * remoteId = PIE stimulus id
 */
export type ItemPassageAff = {
  passageRevId?: Id
  remoteId?: Id
  oldPassageRevId?: Id
  oldRemoteId?: Id
}
export type ItemPassageAffAction = ItemPassageAff & { itemId: Id; link: boolean }

/**
 * Item class for authoring
 * Extends Item with set method
 */
export class ItemAuthor extends Item {
  /**
   * Get Pie mutatable meta data keys
   */
  public static PieMutableMetaKeys: string[] = ['k12_abStandard', 'standard']

  /**
   * Get Ibx mutatable meta data keys
   * */
  public static MutatbleMetaKeys: string[] = ['bank', 'depth_of_knowledge', 'difficulty', 'standard']

  constructor(data: IItemData) {
    super(data)
    this._clientId = data.itemId
    this.initPassageAff()
  }

  private _clientId: Id = null
  private _hasNoStandard: boolean = false

  /**
   * Item passage affiliation
   */
  private _passageAff: ItemPassageAff = null

  /**
   * passageAff initial state
   * Derive passage aff from item.passage if data not provided.
   */
  public initPassageAff(data?: ItemPassageAff) {
    if (data) {
      this._passageAff = data
    } else {
      this._passageAff = {
        passageRevId: this.passage?.passageRevId || null,
        remoteId: this.passage?.remoteId || null,
        oldPassageRevId: this.isNew ? null : this.passage?.passageRevId || null,
        oldRemoteId: this.isNew ? null : this.passage?.remoteId || null,
      }
    }
  }

  /**
   * Check if item is new (uncreated)
   */
  public get isNew(): boolean {
    return !this.itemRevId
  }

  /**
   * Get mutable metadata with defaults
   */
  public get mutatableMeta(): any {
    const meta = this.data?.meta || {}
    return {
      bank: meta.bank ? meta.bank : { items: [] },
      depth_of_knowledge: meta.depth_of_knowledge ? meta.depth_of_knowledge : { items: [] },
      difficulty: meta.difficulty ? meta.difficulty : { items: [] },
      standard: meta.standard ? meta.standard : { items: [] },
      revised_blooms_taxonomy: meta.revised_blooms_taxonomy ? meta.revised_blooms_taxonomy : { items: [] },
      language: meta.language ? meta.language : { items: [] },
    }
  }

  /**
   * Check if property belongs to item meta data
   * @param k property key
   */
  public isMetaProp(k: string): boolean {
    return metaProps.has(k)
  }

  /**
   * Set item data properties for key/value pair
   * @param k item data key
   * @param v value for item data[key] or data.meta[k]
   */
  public set(k: string, v: any) {
    if (this.isMetaProp(k)) {
      const value =
        v && !Array.isArray(v) // must be { id }[] type
          ? [{ id: v }]
          : v

      Vue.set(this.data.meta, k, {
        id: k,
        items: value,
        name: null,
      })
    } else {
      Vue.set(this.data, k, v)
    }
  }

  /**
   * Hydrate item data and set configs/settings
   * @param data item data
   * @param passageAff passage affiliation data (optional)
   */
  public hydrate(data: IItemData, passageAff?: ItemPassageAff) {
    this._publishedGenesis = data.published
    data.contentConfig = _.cloneDeep(this.contentConfig)
    data.configSettings = _.cloneDeep(this.configSettings)
    Vue.set(this, 'data', data)
    this.initPassageAff(passageAff)
  }

  /**
   * On Item update hydrate critical properties
   * @param data item data
   */
  public onItemUpdate(data: IItemData) {
    this._publishedGenesis = data.published
    this.set('version', data.version)
    this.set('itemRevId', data.itemRevId)
    this.set('remoteIdVersioned', data.remoteIdVersioned)
    this.set('meta', data.meta)
  }

  public get hasNoStandard(): boolean {
    return this._hasNoStandard
  }

  public set hasNoStandard(value: boolean) {
    this._hasNoStandard = value
  }

  public get clientId(): Id {
    return this._clientId
  }

  /**
   * Get index for new item
   */
  public get newIndex(): number {
    return this.data.newIndex
  }

  /**
   * Checks if passage aff changed
   */
  public get passageAffMutated(): boolean {
    return (
      this._passageAff.passageRevId != this._passageAff.oldPassageRevId ||
      this._passageAff.remoteId != this._passageAff.oldRemoteId
    )
  }

  /**
   * Passage affiliation getter
   */
  public get passageAff(): ItemPassageAff {
    return this._passageAff
  }

  /**
   * Link Passage to item
   * @param passageRevId passage revision Id
   * @param remoteId passage remote Id
   * @param link link flag
   */
  public linkPassage({ passageRevId, remoteId, link }: { passageRevId?: Id; remoteId?: Id; link: boolean }) {
    // Link passage else unset aff.
    // oldPassageId and oldRemoteId will used to determine if unlinking needs be done
    // or if passage affiliation actually mutated by comparing against passageId and remoteId
    if (link) {
      Vue.set(this._passageAff, 'passageRevId', passageRevId)
      Vue.set(this._passageAff, 'remoteId', remoteId)
    } else {
      Vue.set(this._passageAff, 'passageRevId', null)
      Vue.set(this._passageAff, 'remoteId', null)
    }
  }
}
