import { StoreOptions } from 'vuex'
import Vue from 'vue'
import { IBXService } from '@services/ibx/IBXService'
import CONST from '@constants'
import _ from 'lodash'

interface IState {
  initialized: boolean
  userSelections: any
  contexts: string[]
}

class State implements IState {
  initialized: boolean = false
  userSelections: any = {}
  contexts: string[] = [CONST.CONTEXT_BASE_STANDARDS, CONST.CONTEXT_BROWSE_FILTERS, CONST.CONTEXT_PRINT_OPTIONS]
}

const user = {
  namespaced: true,
  state: new State(),

  getters: {
    initialized: (state: IState) => state.initialized,
    userSelections: (state: IState) => state.userSelections,
    userSelection: (state: IState) => {
      return (context: string) => state.userSelections[context] || { value: {} }
    },
    contexts: (state: IState) => state.contexts,
    baseStds: (_, getters: any): any[] => {
      const usBaseStandards = getters.userSelection(CONST.CONTEXT_BASE_STANDARDS)
      return usBaseStandards?.value?.selected_standards || []
    },
  },

  mutations: {
    setInitialized: (state: IState, value: boolean = false) => {
      state.initialized = value
    },
    setUserSelections: (state: IState, value: object = {}) => {
      state.userSelections = value
    },
    createUserSelection: (state: IState, { context, data }: { context: string; data: object }) => {
      Vue.set(state.userSelections, context, data)
    },
    setUserSelection: (state: IState, { context = '', value }) => {
      Vue.set(state.userSelections[context], 'value', value)
    },
  },

  actions: {
    fetchUserSelections: async ({ commit, getters }) => {
      if (getters.initialized) {
        return Promise.resolve()
      }

      let { userSelections } = await IBXService.userSelections({
        context: getters.contexts,
      })
      userSelections = _.keyBy(
        userSelections.map((o: any) => {
          return Object.assign({}, o, {
            value: JSON.parse(o.value), // remove this mapper once backend returns json for value
          })
        }),
        'context'
      )
      commit('setUserSelections', userSelections)
      commit('setInitialized', true)
    },
    setUserSelection: async ({ commit, getters }, { context, value }) => {
      const userSelection = getters.userSelections[context]
      if (userSelection) {
        const rdata = await IBXService.userSelectionsUpdate({
          userSelectionId: userSelection.userSelectionId,
          value,
        })
        commit('setUserSelection', {
          context,
          value: JSON.parse(rdata.userSelection.value),
        })
      } else {
        const rdata = await IBXService.userSelectionsCreate({
          context,
          value,
        })
        const data = Object.assign({}, rdata.userSelection, {
          value: JSON.parse(rdata.userSelection.value),
        })
        commit('createUserSelection', { context, data })
      }
    },
  },
} as StoreOptions<IState>

export { user, IState }
