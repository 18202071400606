<template>
  <div>
    <v-menu
      class="x-multiselect"
      v-model="menu"
      bottom
      offset-y
      origin="left"
      right
      :close-on-content-click="false"
      content-class="x-multiselect__menu"
      :disabled="disabled"
    >
      <template v-slot:activator="{ on: menu }">
        <x-btn class="x-multiselect__activator" v-on="{ ...menu }" small v-bind="activatorAttrs">
          <font-awesome-icon v-if="!hasSelections" :icon="['far', icon]" class="x-multiselect__activator__icon" />
          <span v-else class="x-multiselect__activator__count">{{ selectedCountText }}</span
          >{{ label }}
        </x-btn>
      </template>

      <div :class="getContainerClasses">
        <div class="x-multiselect__search" v-if="load && search && !viewOnly">
          <transition name="transition-fade">
            <div v-if="showAdded" class="x-multiselect__search__added" @click="onAddedTextClick">
              <font-awesome-icon :icon="['fas', 'check']" class="x-multiselect__search__added__icon" />
              Added<strong class="text">{{ addedText }}</strong>
            </div>
          </transition>
          <v-text-field
            class="x-multiselect__field no-outline"
            :class="textFieldClasses"
            v-model="query"
            label="Search"
            solo
            small
            color="#B3B7C5"
            hide-details
            v-bind="$attrs"
            v-stream:keyup.native="keyup$"
            ref="search"
          />
          <font-awesome-icon
            v-if="auxilliaryAction"
            :icon="getAuxilliaryIcon"
            class="x-multiselect__search__aux"
            @click="onAuxilliaryAction"
          />
        </div>
        <div class="x-multiselect__items" v-if="load">
          <div v-if="getItems.length < 1 && !isSearch" class="x-multiselect__no-items">No Items</div>
          <v-tooltip
            v-for="(item, i) in getItems"
            :key="i"
            right
            open-delay="0"
            close-delay="0"
            transition="fade"
            content-class="custom-tooltip"
          >
            <template v-slot:activator="{ on }">
              <div>
                <div v-on="on" class="x-multiselect__item" :class="getItemClasses(item)" @click="onItemClick(item)">
                  <span class="x-multiselect__item__icon" v-if="!viewOnly">
                    <font-awesome-icon :icon="['fas', 'check']" class="ibx-back-to-top__icon" />
                  </span>
                  <span class="x-multiselect__item__label">{{ item.name }}</span>
                  <span class="x-multiselect__item__badge" v-if="itemHasBadge(item)">{{ getItemBadge(item) }}</span>
                </div>
                <div v-if="isSearch && getItems.length < 1" class="x-multiselect__no-results">No results</div>
              </div>
            </template>
            {{ item.name }}
          </v-tooltip>
        </div>

        <div v-if="!load" class="x-multiselect__loading">Loading...</div>
      </div>
    </v-menu>
  </div>
</template>

<script>
import * as VMenu from 'vuetify/es5/components/VMenu'
import * as VTextField from 'vuetify/es5/components/VTextField'
import { Events } from '@events'
import _ from 'lodash'
import '@plugins/vue-rx'
import { Subject, empty, timer } from 'rxjs'
import { tap, switchMap, takeUntil, debounceTime } from 'rxjs/operators'

export default {
  name: 'XMultiselect',

  props: {
    items: { type: Array, default: () => [] },
    selectedItems: { type: Array, default: () => [] },
    label: { type: String, default: 'Select' },
    icon: { type: String, default: 'plus' },
    maxHeight: { type: Number, default: 500 },
    stickySelections: { type: Boolean, default: false },
    search: { type: Boolean, default: false },
    maxItems: { type: Number, default: 50 },
    canCreate: { type: Boolean, default: false },
    auxilliaryAction: { type: Boolean, default: false },
    auxilliaryIcon: { type: String, default: 'cog' },
    disabled: { type: Boolean, default: false },
    viewOnly: { type: Boolean, default: false },
  },

  components: {
    ...VMenu,
    ...VTextField,
  },

  data: () => ({
    menu: false,
    selected: [],
    query: '',
    isSearch: false,
    searchDelay: 1000,
    addedText: '',
    showAdded: false,
    load: false,
  }),

  computed: {
    sticky() {
      return this.stickySelections || this.items.length > this.maxItems
    },
    getContainerClasses() {
      const classes = ['x-multiselect__container']
      if (this.viewOnly) {
        classes.push('x-multiselect__container--view-only')
      }
      return classes
    },
    getItems() {
      const sort = (o) => o.name.toLowerCase()

      if (this.isSearch) {
        return _.sortBy(
          this.items.filter((o) => {
            const check = new RegExp(this.query.replace(/\s+/g, ' '), 'ig')
            const match = o.name.match(check) !== null
            return match
          }),
          sort
        )
      } else if (this.sticky) {
        const selectedItems = _.sortBy(
          this.items.filter((o) => this.selected.includes(o.id)),
          sort
        )
        let items = _.sortBy(
          this.items.slice(0, this.maxItems + selectedItems.length).filter((o) => !this.selected.includes(o.id)),
          sort
        )
        return (items = [...selectedItems, ...items])
      } else {
        return _.sortBy(this.items.slice(0, this.maxItems), sort)
      }
    },
    activatorAttrs() {
      return {
        color: this.hasSelections ? '#6A78A1' : 'secondary-2',
      }
    },
    selectedCount() {
      return this.selected.length
    },
    selectedCountText() {
      return this.selected.length > 0 ? this.selected.length : ''
    },
    hasSelections() {
      return this.selectedCount > 0
    },
    itemsToLowerCaseStrings() {
      return this.items.map((o) => o.name.toLowerCase())
    },
    readyToAddItem() {
      // if searching and query not extant in items
      const extant = this.itemsToLowerCaseStrings.includes(this.query)
      return this.canCreate && this.query && !extant
    },
    textFieldClasses() {
      return this.readyToAddItem ? ['x-multiselect__field__add'] : []
    },
    getAuxilliaryIcon() {
      return ['fas', this.auxilliaryIcon]
    },
  },

  watch: {
    selectedItems() {
      this.syncSelected()
    },
    menu(v) {
      if (v) {
        this.query = ''
        this.isSearch = false
      }
    },
  },

  methods: {
    getItemClasses(item) {
      return this.isSelected(item.id) ? ['x-multiselect__item--selected'] : []
    },
    isSelected(id) {
      return this.selected.includes(id)
    },
    onItemClick({ id }) {
      if (!this.viewOnly) {
        const data = this.items.find((o) => o.id == id)
        const isSelected = this.isSelected(id)
        this.selected = isSelected ? this.selected.filter((v) => v != id) : _.uniq([...this.selected, id])

        this.$emit(Events.CHANGE, {
          id: id,
          selected: !isSelected,
          selectedItems: this.selected,
          data: data,
        })
      }
    },
    syncSelected() {
      this.selected = this.selectedItems
    },
    doSearch() {
      this.isSearch = Boolean(this.query)
    },
    checkCreate() {
      if (this.readyToAddItem) {
        this.createItem()
      }
    },
    createItem() {
      this.$emit('create-item', {
        item: this.query,
      })
      this.addedText = this.query
      this.query = ''
      this.isSearch = false
      this.showAdded = true
      setTimeout(() => (this.showAdded = false), 2000)
    },
    onAddedTextClick() {
      this.showAdded = false
      this.$refs.search.focus()
    },
    onAuxilliaryAction() {
      this.$emit('auxilliary-action')
    },
    lazyLoad() {
      this.load = true
    },
    itemHasBadge(item) {
      return item.badge
    },
    getItemBadge(item) {
      return item.badge || ''
    },
    show(show) {
      this.menu = show
    },
  },

  subscriptions() {
    if (!this.viewOnly) {
      this.keyup$ = new Subject()

      return {
        onKeyUp: this.keyup$.pipe(
          switchMap(({ event }) => {
            if (event.key == 'Enter') {
              this.checkCreate()
              return empty()
            }
            return [event]
          }),
          tap(() => this.doSearch())
        ),
      }
    }
  },

  created() {
    this.syncSelected()
  },

  mounted() {
    if (this.lazy && !this.menu) {
      const unwatch = this.$watch('menu', (v) => {
        if (v) {
          this.lazyLoad()
          unwatch()
        }
      })
    } else {
      this.lazyLoad()
    }
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.x-multiselect {
  @mixin display-flex {
    display: flex;
    align-items: center;
  }

  &__menu {
    background: white;
    width: 240px;
    height: auto !important;
    border-radius: 5px;
    overflow: hidden;
    font-family: 'CerebriSans', Roboto, sans-serif;
    z-index: 2 !important;
  }

  &__loading {
    padding: 12px;
  }

  &__search {
    @include display-flex;
    position: relative;
    z-index: 1;
    border-bottom: 1px solid $N20;
    padding-top: 4px;

    .theme--light.v-text-field--outlined > .v-input__control > .v-input__slot {
      &:hover {
        border: none !important;
      }
    }

    &__plus {
      display: none;
      @include display-flex;
      color: $N30;
      width: 32px;
      height: 26px;
      padding: 0px 12px;
      background: pink;
    }

    &__aux {
      color: $N60;
      margin: -4px 12px 0px 12px;
      cursor: pointer;
      font-size: 18px;
    }

    &__added {
      @include display-flex;
      position: absolute;
      z-index: 2;
      width: 204px;
      height: 90%;
      background: white;
      padding: 0px 12px;
      color: $N50;
      overflow: hidden;

      &__icon {
        color: $G200;
        margin-right: 5px;
      }

      .text {
        display: inline-block;
        margin-left: 4px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &__activator {
    &__icon {
      width: 20px;
    }
    &__count {
      width: 20px;
    }
  }

  &__container {
    max-height: 310px;
    // min-height: 310px;
    overflow-y: scroll;
  }

  &__items {
    padding: 8px 0;
    min-width: 240px;
  }

  &__no-items {
    padding: 8px 16px;
  }

  &__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 32px;
    padding: 8px 8px 8px 30px;
    position: relative;
    line-height: 32px;

    &:hover {
      background: $N10;
    }

    &__icon {
      display: none;
      position: absolute;
      color: $G200;
      left: 8px;
    }

    &__label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90%;
    }

    &__badge {
      white-space: nowrap;
      overflow: hidden;
      width: 25%;
      font-size: 10px;
      text-align: right;
      color: $B300;
      padding-left: 5px;
    }

    &--selected {
      .x-multiselect__item__icon {
        display: block;
      }
    }
  }
}

// duplicated from XFilterSearch
// TODO: centralize these styles
.x-multiselect {
  .v-text-field {
    font-size: 14px;
  }

  &__field {
    .v-input__control > .v-input__slot {
      border: none !important;
      outline: none !important;
      margin-bottom: 0px;
      min-height: 36px;
      height: 36px;

      label {
        font-size: 14px;
        color: $N40;
        top: 6px;

        &.v-label--active {
          display: none;
        }
      }

      input {
        margin-top: 0px;
        height: 36px;
      }

      &:hover {
        border: 1px solid $N40 !important;
      }
    }
  }
}

// View Only
.x-multiselect__container--view-only {
  .x-multiselect__item {
    cursor: default;
  }
}
</style>
