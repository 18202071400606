import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// REGULAR ---------------------------------------- //

import {
  faBell as farBell,
  faComment as farComment,
  faChevronRight as farChevronRight,
  faChevronLeft as farChevronLeft,
  faChevronUp as farChevronUp,
  faChevronDown as farChevronDown,
  faGripLines as farGripLines,
  faRectangleWide as farRectangleWide,
  faSearch as farSearch,
  faBars as farBars,
  faPlus as farPlus,
  faMinus as farMinus,
  faCheck as farCheck,
  faTimes as farTimes,
  faSlidersH as farSlidersH,
  faEllipsisV as farEllipsisV,
  faCog as farCog,
  faUsersCog as farUsersCog,
  faCaretDown as farCaretDown,
  faExpandAlt as farExpandAlt,
  faExpand as farExpand,
  faEye as farEye,
  faBook as farBook,
  faWrench as farWrench,
  faTags as farTags,
  faLayerGroup as farLayerGroup,
  faTrash as farTrash,
  faCopy as farCopy,
  faEdit as farEdit,
  faSave as farSave,
  faLayerPlus as farLayerPlus,
  faCheckCircle as farCheckCircle,
  faInfoCircle as farInfoCircle,
  faFileDownload as farFileDownload,
  faFileExport as farFileExport,
  faFileAlt as farFileAlt,
  faCircleNotch as farCircleNotch,
  faDesktop as farDesktop,
  faChevronDoubleRight as farChevronDoubleRight,
  faFilePdf as farFilePdf,
  faExclamationTriangle as farExclamationTriangle,
  faBan as farBan,
  faSpinnerThird as farSpinnerThird,
  faFilmAlt as farFilmAlt,
  faVolume as farVolume,
  faExclamationCircle as farExclamationCircle,
} from '@fortawesome/pro-regular-svg-icons'

// SOLID  ---------------------------------------- //

import {
  faBell as fasBell,
  faComment as fasComment,
  faChevronRight as fasChevronRight,
  faChevronLeft as fasChevronLeft,
  faChevronUp as fasChevronUp,
  faChevronDown as fasChevronDown,
  faGripLines as fasGripLines,
  faRectangleWide as fasRectangleWide,
  faSearch as fasSearch,
  faBars as fasBars,
  faPlus as fasPlus,
  faMinus as fasMinus,
  faCheck as fasCheck,
  faTimes as fasTimes,
  faSlidersH as fasSlidersH,
  faEllipsisV as fasEllipsisV,
  faCog as fasCog,
  faUsersCog as fasUsersCog,
  faCaretDown as fasCaretDown,
  faExpandAlt as fasExpandAlt,
  faExpand as fasExpand,
  faEye as fasEye,
  faBook as fasBook,
  faWrench as fasWrench,
  faTags as fasTags,
  faLayerGroup as fasLayerGroup,
  faTrash as fasTrash,
  faCopy as fasCopy,
  faEdit as fasEdit,
  faSave as fasSave,
  faLayerPlus as fasLayerPlus,
  faCheckCircle as fasCheckCircle,
  faInfoCircle as fasInfoCircle,
  faFileDownload as fasFileDownload,
  faFileExport as fasFileExport,
  faFileAlt as fasFileAlt,
  faCircleNotch as fasCircleNotch,
  faDesktop as fasDesktop,
  faChevronDoubleRight as fasChevronDoubleRight,
  faFile as fasFile,
  faExclamationTriangle as fasExclamationTriangle,
  faBan as fasBan,
  faBold as fasBold,
  faUnderline as fasUnderline,
  faItalic as fasItalic,
  faStrikethrough as fasStrikethrough,
  faH1 as fasH1,
  faH2 as fasH2,
  faQuestionCircle as fasQuestionCircle,
  faListUl as fasListUl,
  faListOl as fasListOl,
  faQuoteRight as fasQuoteRight,
  faSpinnerThird as fasSpinnerThird,
  faFilmAlt as fasFilmAlt,
  faVolume as fasVolume,
  faExclamationCircle as fasExclamationCircle,
} from '@fortawesome/pro-solid-svg-icons'

// LIGHT  ---------------------------------------- //

import {
  faBell as falBell,
  faComment as falComment,
  faChevronRight as falChevronRight,
  faChevronLeft as falChevronLeft,
  faChevronUp as falChevronUp,
  faChevronDown as falChevronDown,
  faGripLines as falGripLines,
  faRectangleWide as falRectangleWide,
  faSearch as falSearch,
  faBars as falBars,
  faPlus as falPlus,
  faMinus as falMinus,
  faCheck as falCheck,
  faTimes as falTimes,
  faSlidersH as falSlidersH,
  faEllipsisV as falEllipsisV,
  faCog as falCog,
  faUsersCog as falUsersCog,
  faCaretDown as falCaretDown,
  faExpandAlt as falExpandAlt,
  faExpand as falExpand,
  faEye as falEye,
  faBook as falBook,
  faWrench as falWrench,
  faTags as falTags,
  faLayerGroup as falLayerGroup,
  faTrash as falTrash,
  faCopy as falCopy,
  faEdit as falEdit,
  faSave as falSave,
  faLayerPlus as falLayerPlus,
  faCheckCircle as falCheckCircle,
  faInfoCircle as falInfoCircle,
  faFileDownload as falFileDownload,
  faFileExport as falFileExport,
  faFileAlt as falFileAlt,
  faCircleNotch as falCircleNotch,
  faDesktop as falDesktop,
  faChevronDoubleRight as falChevronDoubleRight,
  faUpload as falUpload,
  faArrowDown as falArrowDown,
  faExclamationTriangle as falExclamationTriangle,
  faBan as falBan,
  faSpinnerThird as falSpinnerThird,
  faFilmAlt as falFilmAlt,
  faVolume as falVolume,
  faExclamationCircle as falExclamationCircle,
} from '@fortawesome/pro-light-svg-icons'

// DUOTONE  ---------------------------------------- //

import { faSpinnerThird as fadSpinnerThird } from '@fortawesome/pro-duotone-svg-icons'

// REGULAR  ---------------------------------------- //

library.add(
  farBell,
  farComment,
  farChevronRight,
  farChevronLeft,
  farChevronUp,
  farChevronDown,
  farGripLines,
  farRectangleWide,
  farSearch,
  farBars,
  farPlus,
  farMinus,
  farCheck,
  farTimes,
  farSlidersH,
  farEllipsisV,
  farCog,
  farUsersCog,
  farCaretDown,
  farExpandAlt,
  farExpand,
  farEye,
  farBook,
  farWrench,
  farTags,
  farLayerGroup,
  farTrash,
  farCopy,
  farEdit,
  farSave,
  farLayerPlus,
  farCheckCircle,
  farInfoCircle,
  farFileDownload,
  farFileExport,
  farFileAlt,
  farCircleNotch,
  farDesktop,
  farChevronDoubleRight,
  farFilePdf,
  farExclamationTriangle,
  farBan,
  farSpinnerThird,
  farFilmAlt,
  farVolume,
  farExclamationCircle
)

// SOLID  ---------------------------------------- //

library.add(
  fasBell,
  fasComment,
  fasChevronRight,
  fasChevronLeft,
  fasChevronUp,
  fasChevronDown,
  fasGripLines,
  fasRectangleWide,
  fasSearch,
  fasBars,
  fasPlus,
  fasMinus,
  fasCheck,
  fasTimes,
  fasSlidersH,
  fasEllipsisV,
  fasCog,
  fasUsersCog,
  fasCaretDown,
  fasExpandAlt,
  fasExpand,
  fasEye,
  fasBook,
  fasWrench,
  fasTags,
  fasLayerGroup,
  fasTrash,
  fasCopy,
  fasEdit,
  fasSave,
  fasLayerPlus,
  fasCheckCircle,
  fasInfoCircle,
  fasFileDownload,
  fasFileExport,
  fasFileAlt,
  fasCircleNotch,
  fasDesktop,
  fasChevronDoubleRight,
  fasFile,
  fasExclamationTriangle,
  fasBan,
  fasBold,
  fasUnderline,
  fasItalic,
  fasStrikethrough,
  fasH1,
  fasH2,
  fasQuestionCircle,
  fasListUl,
  fasListOl,
  fasQuoteRight,
  fasSpinnerThird,
  fasFilmAlt,
  fasVolume,
  fasExclamationCircle
)

// LIGHT  ---------------------------------------- //

library.add(
  falBell,
  falComment,
  falChevronRight,
  falChevronLeft,
  falChevronUp,
  falChevronDown,
  falGripLines,
  falRectangleWide,
  falSearch,
  falBars,
  falPlus,
  falMinus,
  falCheck,
  falTimes,
  falSlidersH,
  falEllipsisV,
  falCog,
  falUsersCog,
  falCaretDown,
  falExpandAlt,
  falExpand,
  falEye,
  falBook,
  falWrench,
  falTags,
  falLayerGroup,
  falTrash,
  falCopy,
  falEdit,
  falSave,
  falLayerPlus,
  falCheckCircle,
  falInfoCircle,
  falFileDownload,
  falFileExport,
  falFileAlt,
  falCircleNotch,
  falDesktop,
  falChevronDoubleRight,
  falUpload,
  falArrowDown,
  falExclamationTriangle,
  falBan,
  falSpinnerThird,
  falFilmAlt,
  falVolume,
  falExclamationCircle
)

// Duotone  ---------------------------------------- //

library.add(fadSpinnerThird)

// setup vue comp
Vue.component('font-awesome-icon', FontAwesomeIcon)
