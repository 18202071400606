/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'Match List_1': {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M20.93 32.5h150.14a12.419 12.419 0 0112.43 12.43v102.14a12.416 12.416 0 01-3.638 8.792 12.416 12.416 0 01-8.792 3.638H20.93A12.417 12.417 0 018.5 147.07V44.93A12.42 12.42 0 0120.93 32.5zM173.5 44.93a2.43 2.43 0 00-2.43-2.43H20.93a2.43 2.43 0 00-2.43 2.43v102.14a2.43 2.43 0 002.43 2.43h150.14a2.428 2.428 0 002.43-2.43V44.93z" _fill="#152452"/><rect pid="1" x="29.5" y="55.5" width="135" height="17" rx="8.5" _fill="#152452"/><rect pid="2" x="108.5" y="89" width="56" height="17" rx="8.5" _fill="#152452"/><rect pid="3" x="108.5" y="122.5" width="56" height="17" rx="8.5" _fill="#152452"/><rect pid="4" x="29.5" y="89" width="56" height="17" rx="8.5" _fill="#152452"/><rect pid="5" x="29.5" y="122.5" width="56" height="17" rx="8.5" _fill="#152452"/>',
  },
})
