<template>
  <v-list class="ibx-checkbox-list">
    <v-list-item
      v-for="(item, i) in items"
      :key="i"
      class="ibx-checkbox-list-items"
      :class="`${getItemClass(i)} ${getClassID(i)}`"
      ref="row"
    >
      <v-list-item-action>
        <v-checkbox
          class="ibx-checkbox-list-items-item"
          :input-value="isSelected(item[itemId])"
          :label="item[itemLabel]"
          @change="(selected) => change(item[itemId], selected)"
          v-ibx-tooltip="tooltip(item)"
        ></v-checkbox>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import * as VList from 'vuetify/es5/components/VList'
import * as VCheckbox from 'vuetify/es5/components/VCheckbox'

export default {
  name: 'IbxCheckboxList',

  components: {
    ...VList,
    ...VCheckbox,
  },

  inject: {
    getItemClass: { default: () => () => '' },
  },

  props: {
    items: { type: Array, default: () => [] },
    selected: { type: Array, default: () => [] },
    itemLabel: { type: String, default: 'name' },
    itemId: { type: String, default: 'id' },
    meta: { type: Object, default: () => ({}) },
    tooltips: { type: Boolean, default: false },
  },

  computed: {
    selections() {
      return this.selected
    },
  },

  methods: {
    change(id, selected) {
      this.$emit('change', { id, selected })
    },
    isSelected(id) {
      return this.selections.includes(id)
    },
    telemetry() {
      const firstRow = this.$refs.row[0].$el
      const rect = firstRow.getBoundingClientRect()
      const measurements = {
        firstRow: {
          offsetTop: firstRow.offsetTop,
          width: rect.width,
          height: rect.height,
        },
      }
      this.$emit('telemetry', measurements)
    },
    getClassID(index) {
      return `item-${this.items[index][this.itemId]}`
    },
    tooltip(item) {
      if (this.tooltips) {
        return { title: 'Title', content: 'IBX Tooltip', placement: 'right' }
      } else {
        return false
      }
    },
  },

  mounted() {
    this.telemetry()
  },
}
</script>

<style lang="scss">
.ibx-checkbox-list {
  background: red;
  .v-list {
    background: red !important;
  }

  .ibx-checkbox-list-items {
  }

  .v-input * {
    // font-size: 12px !important;
  }
}
</style>
