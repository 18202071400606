/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'Inline Dropdown_2': {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<rect pid="0" x="8.5" y="53.5" width="175" height="85" rx="9.81" _fill="#152452"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M145.5 112.5l19.43-33.66h-38.86l19.43 33.66z" _fill="#fff"/><rect pid="2" x="23.5" y="88.5" width="86" height="15" rx="7.03" _fill="#fff"/>',
  },
})
