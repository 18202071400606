<template>
  <v-snackbar class="x-snackbar" color="#152452" v-model="model" v-bind="$attrs" v-on="$listeners">
    <div class="x-snackbar__content">
      <font-awesome-icon class="x-snackbar__icon" :icon="['fal', getIcon]" :class="getIconColor" />
      <span class="x-snackbar__message">
        <span class="x-snackbar__message__text">{{ text }}</span>
        <span class="x-snackbar__message__subtext">{{ subtext }}</span>
      </span>
      <font-awesome-icon class="x-snackbar__close" :icon="['fal', 'times']" @click="model = false" />
    </div>
  </v-snackbar>
</template>

<script>
import { VSnackbar } from 'vuetify'
export default {
  name: 'XSnackbar',

  components: {
    VSnackbar,
  },

  props: {
    show: { type: Boolean, default: false },
    icon: { type: String, default: '' },
    text: { type: String, default: '' },
    subtext: { type: String, default: '' },
    type: { type: String, default: 'info' },
  },

  computed: {
    model: {
      get() {
        return this.show
      },
      set(v) {
        this.$emit('show', v)
      },
    },
    getType() {
      const types = ['success', 'info', 'warning', 'error']
      return types.includes(this.type) ? this.type : 'info'
    },
    getIcon() {
      switch (this.getType) {
        case 'success':
          return 'check'
        case 'info':
          return 'info-circle'
        case 'warning':
          return 'exclamation-triangle'
        case 'error':
          return 'ban'
        default:
          return 'info-circle'
      }
    },
    getIconColor() {
      return `${this.getType}--text`
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.x-snackbar {
  @mixin flexbox {
    display: flexbox;
    align-items: center;
    justify-content: flex-start;
  }

  &__content {
    @include flexbox;
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
    position: relative;
  }

  &__icon {
    position: absolute;
    left: -7px;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: 18px;

    &.warning--text {
      color: #ffe072 !important;
    }
  }

  &__close {
    position: absolute;
    right: -7px;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: 18px;
    cursor: pointer;
    color: #c0c3cf;
  }

  &__message {
    @include flexbox;
    &__text {
      font-family: 'CerebriSans', Roboto, sans-serif;
      display: block;
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__subtext {
      font-family: 'CerebriSans', Roboto, sans-serif;
      display: block;
      font-size: 12px;
      line-height: 17px;
    }
  }
}
</style>
