
// @ts-nocheck
import { mapGetters } from 'vuex'
import IbxAppHeader from '@components/ibx/IbxAppHeader'
import IbxCreateAssessmentModal from '@components/ibx/IbxCreateAssessmentModal'
import { Events, EventBus } from '@events'
import CONST from '@constants'
import { AsmtMixin, SavingMessageMixin, UserSelectionsMixin, PermsMixin } from '@mixins'
import * as UTIL from '@helpers'
import _ from 'lodash'
import '@icons'
import IbxAssessmentSummary from '@components/ibx/IbxAssessmentSummary'
import XSnackbar from '@components/xLib/XSnackbar'
import IbxWarningModal from '@components/ibx/IbxWarningModal'
import IbxReportIssueModal from '@components/ibx/IbxReportIssueModal'

import IBXService from '@services/ibx/IBXService'
import * as VDialog from 'vuetify/es5/components/VDialog'
import * as VCard from 'vuetify/es5/components/VCard'
import { Vue, Component, Mixins } from 'vue-property-decorator'
import { StateAuthorItems, StateAuth, StateItemConfigs, StateFeatures } from '@/helpers/state'
import { IItemData, Id } from '@/components/ibx/base/Item'
import { PassageAuthor, IPassageData } from '@components/ibx/base/Passage'
import { ItemAuthorSaveAction } from '@/state/modules/authorItems'

const AsmtQ = UTIL.makeDebouncedQueue()

/**
 * Text pluralizer heler
 * @param text text to pluralize
 * @param count contextual count of items
 */
const pluralizer = (text: string, count: number): string => {
  return count > 1 ? `${text}s` : text
}

/**
 * Save action label
 */
const actionLabel = (action: string): string => {
  switch (action) {
    case ItemAuthorSaveAction.DRAFT:
      return 'saved as draft'
    case ItemAuthorSaveAction.PUBLISH:
    case ItemAuthorSaveAction.PUBLISH_NEW:
      return 'saved as published'
    default:
      return 'discarded'
  }
}

@Component({
  components: {
    IbxAssessmentSummary,
    IbxAppHeader,
    IbxCreateAssessmentModal,
    XSnackbar,
    IbxWarningModal,
    IbxReportIssueModal,
    ...VDialog,
    ...VCard,
    'ibx-preview-asmt-modal': () => import('@components/ibx/previewAsmt/IbxPreviewAsmtModal'),
    'asmt-print-dialog': () => import('@components/asmtPrint/AsmtPrintDialog'),
    'item-authoring-dialog': () => import('@components/itemAuthor/ItemAthoringDialog'),
    'passage-authoring-dialog': () => import('@components/itemAuthor/PassageAuthoringDialog'),
  },
  computed: {
    ...mapGetters('asmt', {
      assessmentId: 'assessmentId',
      asmtItems: 'items',
    }),
    ...mapGetters('config', ['appExitUrl']),
  },
})
export default class App extends Mixins(
  Vue,
  StateAuth,
  StateAuthorItems,
  StateItemConfigs,
  AsmtMixin,
  SavingMessageMixin,
  UserSelectionsMixin,
  PermsMixin,
  StateFeatures
) {
  private landing: boolean = true
  private transitionName: string = 'fade'
  private showCreateModal: boolean = false
  private asmtUpdates: any = {}
  private messageShow: string = ''
  private messageText: string = ''
  private messageSubtext: string = ''
  private messageType: string = ''
  private messageTimeout: number = 6000
  private messagePosition: string = 'right'
  private showAsmtPreview: boolean = false
  private showAsmtPrintPreview: boolean = false
  private showAsmtLimit: boolean = false
  private itemAuthoringActive: boolean = false
  private passageAuthoringActive: boolean = false
  private selectedPassageData: IPassageData = {}
  private passageAuthoringAction: string = 'edit'

  get isItemCardView() {
    return this.$route.name === 'itemcard'
  }

  get isInspector() {
    return this.$route.name === 'inspector'
  }

  get messageProps() {
    return {
      show: this.messageShow,
      type: this.messageType,
      text: this.messageText,
      subtext: this.messageSubtext,
      timeout: this.messageTimeout,
      [this.messagePosition]: true,
    }
  }

  get showSummary() {
    // @ts-ignore
    return this.asmtId ? true : this.asmtItemsToAddCount > 0
  }

  getTransitionName(route_to) {
    return route_to.name === 'build' ? 'slide-left' : 'slide-right'
  }

  onRouteGoto({ name, params = {} }) {
    if (name == 'assessment' && this.assessmentId) {
      params.assessmentId = this.assessmentId
    }
    this.$router.push({ name, params })
  }

  onCreateItems({ itemRevIds }) {
    if (this.asmtHasID && this.$route.name == 'assessment') {
      this.onAssessmentAddItems({ itemRevIds })
    } else if (this.$route.name == 'browse') {
      EventBus.$emit(Events.BROWSE_RELOAD_ITEMS)
    }
  }

  /**
   * AsmtQ emit handler
   * from debounced add assessment item action
   * @param itemRevIds returned from queue
   */
  async assessmentAddItems(itemRevIds = []) {
    try {
      await this.asmtSaveItemsToAdd()
      if (this.asmtHasID) {
        this.smSaved()
        this.itemAddedRemovedMessage({
          added: true,
          count: itemRevIds.length,
        })
      }
    } catch (error) {
      if (error != Events.ASSESSMENT_ITEM_LIMIT) {
        this.smDone()
        EventBus.$emit(Events.ERROR, {
          type: CONST.ERROR,
          error,
          text: 'Failed to add item',
          subtext: error?.subtext ? error.subtext : CONST.REFRESH_PAGE,
        })
      }
    }
  }

  /**
   * AsmtQ emit handler
   * from debounced remove assessment item action.
   * @param itemRevIds returned from queue
   */
  async assessmentRemoveItems(itemRevIds = []) {
    try {
      await this.asmtRemoveItems(itemRevIds)
      if (this.asmtHasID) {
        this.smSaved()
        this.itemAddedRemovedMessage({
          added: false,
          count: itemRevIds.length,
        })
      }
    } catch (error) {
      this.smDone()
      EventBus.$emit(Events.ERROR, {
        type: CONST.ERROR,
        error,
        text: 'Failed to remove item',
        subtext: error?.subtext ? error.subtext : CONST.REFRESH_PAGE,
      })
    }
  }

  /**
   * Add item to asmt store
   * If assessment is created save to backend via AsmtQ
   * else items will be added on assessment creation
   * @param itemRevIds array of itemRevIds to add
   */
  async onAssessmentAddItems({ itemRevIds = [] }) {
    if (this.asmtHasID) this.smSaving()
    try {
      await this.asmtAddItems(itemRevIds)
      if (this.asmtHasID) AsmtQ.add({ id: 'browse-asmt-add-items', value: itemRevIds })
    } catch (e) {
      console.warn(e)
    }
  }

  /**
   * Remove item to asmt store
   * If assessment is created save to backed via AsmtQ
   * @param itemRevIds array of itemRevIds to remove
   */
  onAssessmentRemoveItems({ itemRevIds = [] }) {
    if (this.asmtHasID) this.smSaving()
    this.asmtRemoveItemsToAdd(itemRevIds)
    if (this.asmtHasID) AsmtQ.add({ id: 'browse-asmt-remove-items', value: itemRevIds })
  }

  /*
   * @sorted: item data with new orders { itemRevId, order }
   * @subgroup: reordered within a subgroup (only subgroup provided)
   */
  onOrderAssessmentItems({ sorted = [], subgroup = false }) {
    this.smSaving()
    const items = this.asmtItems.map((o) => {
      const match = sorted.find((so) => so.itemRevId == o.itemRevId)
      return match ? Object.assign({}, o, match) : o
    })
    const itemsOrder = items.map(({ itemRevId, order }) => ({
      itemRevId,
      order,
    }))

    this.asmtSetItems(items)
      .then(() => this.asmtUpateItems(itemsOrder))
      .then(() => {
        this.smSaved()
        this.showMessage({
          type: 'success',
          text: 'Questions reordered',
        })
      })
      .catch((error) => {
        this.smDone()
        EventBus.$emit(Events.ERROR, {
          type: CONST.ERROR,
          error,
          text: 'Failed to reorder items',
          subtext: error?.subtext ? error.subtext : CONST.REFRESH_PAGE,
        })
      })
    //TODO: explore flushing/forcing AmstQ (with event) to save queud data before updating order
  }

  /*
   * Queue item data to save
   */
  onAssessmetItemChange({ itemRevId, key, value }) {
    if (this.asmtHasID) {
      this.smSaving()
      const itemData = this.asmtUpdates[itemRevId] || { itemRevId }
      this.asmtUpdates[itemRevId] = Object.assign({}, itemData, {
        [key]: value,
      })
      this.asmtSetDirty(true)
      AsmtQ.add({ id: 'asmt-items', value: this.asmtUpdates })
    }
  }

  /*
   * Save data from queue when queue timer resolves
   */
  onSaveAssessmentItems(data) {
    if (this.asmtHasID) {
      const items = Object.keys(data).map((k) => data[k])
      this.smSaving()
      this.asmtUpateItems(items)
        .then(() => {
          this.asmtUpdates = {}
          this.smSaved()
        })
        .catch((error) =>
          EventBus.$emit(Events.ERROR, {
            type: CONST.ERROR,
            error,
            text: 'Failed to save',
            subtext: 'Please refresh the page',
          })
        )
    }
  }

  /*
   * Assessemt over the limit
   */
  onAssessmetItemLimit() {
    this.showAsmtLimit = true
    this.smDone()
  }

  /*
   * Error handling
   */
  onError(p = { type: 'error', error: '', text: '', subtext: '', timeout: 0 }) {
    EventBus.$emit(Events.DATA_DIRTY, false)
    this.asmtSetDirty(false)
    console.warn(p.error)
    this.showMessage({
      type: 'error',
      text: p.text || 'There was an error with your request',
      subtext: p.subtext || '',
      timeout: p.timeout || 0,
    })
  }

  /*
   * show snackbar message
   */
  showMessage({ type, text, subtext, timeout }) {
    this.messageType = type
    this.messageText = text
    this.messageSubtext = subtext || ''
    this.messageTimeout = Boolean(timeout) || timeout === 0 ? timeout : 6000
    this.messageShow = true
  }

  /*
   * snackbar message for adding/removing items
   */
  itemAddedRemovedMessage({ added, count }) {
    const text = added
      ? `Added ${UTIL.pluralizeString(count, 'item')}`
      : `Removed ${UTIL.pluralizeString(count, 'item')}`
    this.showMessage({
      type: 'success',
      text,
    })
  }

  onWindowBeforeUnload(e) {
    e.preventDefault()
    e.returnValue = ''
  }

  handleWarning(value) {
    switch (value.id) {
      case 'delete-item':
        var itemId = value.data
        IBXService.itemDelete(itemId).then(() => {
          EventBus.$emit(Events.BROWSE_RELOAD_ITEMS)
        })
        break
      default:
        return
    }
  }

  /**
   * Handler for "full screen" dialog
   * Disables/Enables main scrolling to prevent double scroll bars
   * @param {Boolean} active dialog active
   */
  onDialogFull(active, className = 'hideScroll') {
    const htmlEl = document.getElementsByTagName('html')[0]
    if (active) {
      htmlEl.classList.add(className)
    } else {
      htmlEl.classList.remove(className)
    }
  }

  /**
   * Edit item handler.
   * @param itemData Optional. Load item authoring with item
   * @param itemRevId Optional. Fetch item data then load item authoring
   */
  async onAuthorPassageItemsEdit({ itemData, passageRevId }: { itemData: IPassageData; passageRevId?: Id }) {
    this.selectedPassageData = itemData
    this.passageAuthoringAction = 'edit'
    this.showPassageAuthoringDialog()
  }

  async onAuthorPassageItemsCreate() {
    const passageData = PassageAuthor.create().dataObj
    this.selectedPassageData = passageData
    this.passageAuthoringAction = 'create'
    this.showPassageAuthoringDialog()
  }
  /**
   * Show passage authoring dialog
   */
  showPassageAuthoringDialog() {
    this.passageAuthoringActive = true
    this.onDialogFull(true)
  }

  /**
   * Hide item authoring dialog
   * @param action close action taken
   */
  hidePassageAuthoringDialog({ action }: { action: string }) {
    this.passageAuthoringActive = false
    this.onDialogFull(false)
    if (action === 'passage-updated') {
      const successText = `Passage saved.`
      EventBus.$emit(Events.SNACKBAR, {
        type: 'success',
        text: successText,
        timeout: 10000,
      })
    } else {
      EventBus.$emit(Events.SNACKBAR, {
        type: 'info',
        text: 'Passage changes discarded.',
        timeout: 10000,
      })
    }
  }

  /**
   * Edit item handler.
   * @param itemData Optional. Load item authoring with item
   * @param itemRevId Optional. Fetch item data then load item authoring
   */
  async onAuthorItemsEdit({ itemData, itemRevId }: { itemData?: IItemData; itemRevId?: Id }) {
    try {
      if (itemData?.itemRevId) {
        this.startItemAuthoring([itemData])
      } else if (itemRevId) {
        const { items } = await IBXService.items({
          itemRevId: [itemRevId],
        })
        this.startItemAuthoring(items)
      } else {
        throw 'itemData or itemRevId required for editing item'
      }
    } catch (error) {
      this.onError({
        type: 'error',
        error,
        text: error,
      })
    }
  }

  /**
   * Duplicate item handler.
   * @param itemData item data to duplicate
   */
  async onAuthorItemsDuplicate({ itemData }: { itemData: IItemData }) {
    try {
      const { data } = await this.itemConfigsGetItemConfig({ versionedId: itemData.remoteIdVersioned })
      const contentConfig = data?.contentItem?.config
      if (!contentConfig) throw 'No content config found for item to duplicate.'
      await this.authorAuthorDuplicateItem({ contentConfig, itemData })
      this.showItemAuthoringDialog()
    } catch (error) {
      this.onError({
        type: 'error',
        error,
        text: error,
      })
    }
  }

  /**
   * Create item handler.
   * Initializes item authoring with one empty item
   */
  async onAuthorItemsCreate() {
    this.startItemAuthoring()
  }

  /**
   * Initialize and start item authoring
   * @param items items to edit. if empty once item will be created.
   */
  async startItemAuthoring(items: IItemData[] = []) {
    await this.authorAuthorItems(items)
    this.showItemAuthoringDialog()
  }

  /**
   * Show item authoring dialog
   */
  showItemAuthoringDialog() {
    this.itemAuthoringActive = true
    this.onDialogFull(true)
  }
  /**
   * Hide item authoring dialog
   * @param action close action taken
   */
  async hideItemAuthoringDialog({ action }: { action: string }) {
    // make array of mutated items
    const itemErrors = this.authorItemErrors
    const itemActions = this.authorItemActions
    const ogItemMap = this.authorOgItemRevIdMap || {}

    const itemsMutated = _.reduce(
      this.authorItemsMutated,
      (m, itemRevId, itemId) => {
        const actions = itemActions[itemId] || []
        if (!actions.includes('delete')) {
          m.push({
            itemId: Number(itemId),
            itemRevId: Number(itemRevId),
            ogItemRevId: ogItemMap[itemId] || null,
            actions,
          })
        }
        return m
      },
      []
    )

    let oldRevId = itemsMutated[0]?.['ogItemRevId']
    if (
      this.$route.name == 'browse' &&
      oldRevId &&
      this.asmtItemIds.includes(oldRevId) &&
      itemsMutated[0]['actions'].includes('published')
    ) {
      if (this.asmtHasID) {
        await this.asmtFetchAssessmentItems(false)
      } else {
        this.asmtUpdateItemsToAdd({
          oldRevId,
          newRevId: itemsMutated[0]['itemRevId'],
        })
      }
    }
    const authorMode = this.authorMode
    const authorSaveAction = this.authorSaveAction
    const auxiliaryActions = this.authorAuxiliaryActions
    this.itemAuthoringActive = false
    this.onDialogFull(false)
    this.authoringDoneMessage(authorMode, authorSaveAction, itemsMutated, itemErrors)

    EventBus.$emit(Events.EDIT_ITEMS_DONE, {
      authorMode,
      itemsMutated,
      action,
      auxiliaryActions,
    })
    this.authorReset()
  }

  /**
   * Display item authoring done message
   * @param authorMode item authoring mode prior to close
   * @param authorSaveAction action taken when saving and closing
   * @param itemsMutated collection of items that saved
   * @param itemErrors error while saving
   */
  authoringDoneMessage(
    authorMode: string,
    authorSaveAction: ItemAuthorSaveAction,
    itemsMutated: any[] = [],
    itemErrors: any
  ) {
    const erroredItems = Object.keys(itemErrors || {})

    if (erroredItems.length) {
      EventBus.$emit(Events.SNACKBAR, {
        type: 'error',
        text: `We encountered an error while saving.`,
        timeout: 10000,
      })
    } else if (itemsMutated.length) {
      const successText = `${itemsMutated.length} ${pluralizer('item', itemsMutated.length)} ${actionLabel(
        authorSaveAction
      )}.`
      EventBus.$emit(Events.SNACKBAR, {
        type: 'success',
        text: successText,
        timeout: 10000,
      })
    } else {
      EventBus.$emit(Events.SNACKBAR, {
        type: 'info',
        text: 'Item closed, no changes saved',
        timeout: 10000,
      })
    }
  }

  /**
   * Scroll element into view with offset
   * @param containerEl scrolling container
   * @param target target element to scroll into view
   * @param offset element offest amount
   */
  onScrollIntoView({ containerEl, targetEl, offset = 0 }) {
    // DNA-8178: content based scrolling
    // const containerElScrollTop = containerEl?.scrollTop
    // const targetElTop = targetEl?.offsetTop
    // const scrollToY = targetElTop - offset
    // const doScroll = scrollToY < containerElScrollTop
    // if (doScroll && containerEl && targetEl && targetElTop) {
    //   containerEl.scrollTo({
    //     behavior: 'smooth',
    //     top: scrollToY,
    //   })
    // }

    containerEl = window.document.scrollingElement || window.document.body || window.document.documentElement
    const targetTop = targetEl?.getBoundingClientRect()?.top

    if (targetTop && targetTop < offset) {
      const top = targetTop - containerEl.getBoundingClientRect().top - offset
      containerEl.scrollTo({
        behavior: 'smooth',
        top: top,
      })
    }
  }

  created() {
    if (!this.isItemCardView) {
      EventBus.$on(Events.ASSESSMENT_PREVIEW, (show) => {
        this.showAsmtPreview = show
        this.onDialogFull(show)
      })
      EventBus.$on(Events.ASSESSMENT_PRINT_PREVIEW, (show) => {
        this.showAsmtPrintPreview = show
        this.onDialogFull(show, 'hideScrollPrintBooklet')
      })

      EventBus.$on(Events.CREATE_ITEMS, this.onCreateItems)
      EventBus.$on(Events.ADD_ITEMS, this.onAssessmentAddItems)
      EventBus.$on(Events.REMOVE_ITEMS, this.onAssessmentRemoveItems)
      EventBus.$on(Events.REMOVED_ITEMS, this.itemAddedRemovedMessage)
      EventBus.$on(Events.ASSESSMENT_ITEM_CHANGE, this.onAssessmetItemChange)
      EventBus.$on(Events.ASSESSMENT_ITEMS_ORDER, this.onOrderAssessmentItems)
      EventBus.$on(Events.ASSESSMENT_ITEM_LIMIT, this.onAssessmetItemLimit)
      EventBus.$on(Events.ERROR, this.onError)
      EventBus.$on(Events.SNACKBAR, this.showMessage)
      EventBus.$on(Events.DIALOG_FULL, this.onDialogFull)
      EventBus.$on(Events.STATUS_404, () => {
        this.asmtReset()
        this.$router.push({ name: '404' })
      })
      EventBus.$on(Events.DATA_DIRTY, (dirty) => {
        window.removeEventListener('beforeunload', this.onWindowBeforeUnload)
        if (dirty) window.addEventListener('beforeunload', this.onWindowBeforeUnload)
      })
      EventBus.$on(Events.EDIT_ITEM, this.onAuthorItemsEdit)
      EventBus.$on(Events.DUPLICATE_ITEM, this.onAuthorItemsDuplicate)
      EventBus.$on(Events.AUTHOR_ITEMS_CREATE, this.onAuthorItemsCreate)

      EventBus.$on(Events.EDIT_PASSAGE_ITEM, this.onAuthorPassageItemsEdit)
      EventBus.$on(Events.CREATE_PASSAGE_ITEM, this.onAuthorPassageItemsCreate)
      this.$router.beforeEach((to, from, next) => {
        if (!this.landing) this.transitionName = this.getTransitionName(to)
        next()
      })

      this.$router.afterEach(() => {
        this.landing = false
      })
    }
  }

  mounted() {
    if (!this.isItemCardView) {
      EventBus.$on(Events.ROUTE_GOTO, this.onRouteGoto)
      EventBus.$on(Events.SCROLL_INTO_VIEW, this.onScrollIntoView)
      // TODO: Explore a flush/force method to call with an event
      AsmtQ.subscribe({ id: 'asmt-items', time: 1000 }, this.onSaveAssessmentItems)
      AsmtQ.subscribe({ id: 'browse-asmt-add-items', time: 1000 }, this.assessmentAddItems)
      AsmtQ.subscribe({ id: 'browse-asmt-remove-items', time: 0 }, this.assessmentRemoveItems)

      EventBus.$on('AUTHOR_DEV', async () => {
        //testing authoring bootstrap
        const itemRevId = this.$route.query.itemRevId
        let items = []
        if (itemRevId) {
          const response = await IBXService.items({
            itemRevId: [itemRevId],
          })
          items = response.items
        }
        this.startItemAuthoring(items)
      })
    }
  }
}
