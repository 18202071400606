<template>
  <div class="ibx-date-picker">
    <v-menu
      bottom
      offset-x
      transition="scale-transition"
      v-model="showCalendar"
      with="290px"
      maxWidth="290px"
      v-bind="$attrs"
      v-if="ready"
    >
      <template v-slot:activator="{ on: menu }">
        <v-text-field
          class="ibx-date-picker__text-input"
          v-on="{ ...menu }"
          :value="dateInput"
          append-icon="event"
          solo
          outlined
          ref="textField"
          @input="onInputChange"
          :rules="rules"
          :readonly="$attrs.disabled"
        ></v-text-field>
      </template>
      <v-date-picker :value="date" color="primary" no-title scrollable @click:date="onCalChange"></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import * as VDatePicker from 'vuetify/es5/components/VDatePicker'
import { getMoment } from '@helpers'
import '@plugins/vue-rx'
import { tap, debounceTime } from 'rxjs/operators'

let moment

export default {
  name: 'IbxDatePicker',

  components: {
    ...VDatePicker,
  },

  props: {
    model: { type: String, default: null },
    debounce: { type: [Number, String], default: 1000 },
  },

  data: () => ({
    initialized: false,
    inputFormat: 'MM/DD/YYYY',
    calFormat: 'YYYY-MM-DD',
    showCalendar: false,
    date: '',
    dateInput: '',
    ready: false,
  }),

  computed: {
    rules() {
      return [this.validate]
    },
  },

  methods: {
    onCalChange(v) {
      this.dateInput = this.formatDate(v, this.inputFormat, this.calFormat)
      this.date = this.formatDate(v, this.calFormat)
    },
    onInputChange(v) {
      this.showCalendar = true
      const valid = this.validate(v) === true
      if (valid) {
        const fv = this.formatDate(v, this.inputFormat)
        this.date = this.formatDate(fv, this.calFormat, this.inputFormat)
      }
    },
    validate(v) {
      const input = this.isValid(v, this.inputFormat) ? this.formatDate(v, this.inputFormat) : false
      const calValid = this.isValid(input, this.calFormat)
      const valid = v == '' || (input && calValid)
      return valid || `Required format ${this.inputFormat}`
    },
    isValid(v, format) {
      return moment(moment(v).format(format), format, true).isValid()
    },
    formatDate(v, to, from) {
      from = from ? from : to
      return v ? moment(v, from).format(to) : ''
    },
  },

  subscriptions() {
    return {
      change: this.$watchAsObservable('date').pipe(
        debounceTime(Math.trunc(this.debounce)),
        tap(({ newValue }) => {
          if (this.initialized) this.$emit('change', newValue)
          this.initialized = true
        })
      ),
    }
  },

  async created() {
    moment = await getMoment()
    this.ready = true
    if (this.model) {
      this.dateInput = this.formatDate(this.model, this.inputFormat)
      this.date = this.formatDate(this.dateInput, this.calFormat, this.inputFormat)
    } else {
      this.initialized = true
    }
  },
}
</script>

<style lang="scss">
.ibx-date-picker {
  .v-text-field > .v-input__control > .v-input__slot {
    input {
      cursor: pointer;
    }
  }
}
</style>
