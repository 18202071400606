/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'Drag and Drop_2': {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<rect pid="0" x="20.5" y="20.5" width="151" height="151" rx="12.59" _fill="#152452"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M39.86 50.24c.286.06.578.09.87.09a3.89 3.89 0 003.8-3A2.94 2.94 0 0147.28 45a3.91 3.91 0 00-.14-7.81H47a10.72 10.72 0 00-7.08 3h-.73v.07l-.05.06a10.65 10.65 0 00-1.8 3.94 3.91 3.91 0 002.52 5.98zm-3.21 23.41V59.6a3.91 3.91 0 117.81 0v14.05a3.91 3.91 0 11-7.81 0zM103.23 45H89.17v.65a3.91 3.91 0 010-7.81h15.9a10.72 10.72 0 016.08 3.21h.32c.048.048.092.101.134.152l.056.068a3.911 3.911 0 01-3.07 6.33 3.88 3.88 0 01-3.06-1.49 2.91 2.91 0 00-2.3-1.11zm2.94 9.85v14.06a3.91 3.91 0 007.81 0V54.85a3.91 3.91 0 10-7.81 0zM75.09 37.23a3.91 3.91 0 013.91 3.9 3.91 3.91 0 01-3.91 3.91h-14a3.91 3.91 0 110-7.81h14zm-18.52 69.5h-9.19c-.44 0-.874-.099-1.27-.29a3.837 3.837 0 00-1.7-.39 3.9 3.9 0 00-1.72 7.41c.963.487 1.995.824 3.06 1h10.84a3.91 3.91 0 100-7.81l-.02.08zM44.44 87.71V101.76a3.91 3.91 0 11-7.81 0V87.71a3.91 3.91 0 117.81 0zm103.39-13.3H82.5a7.51 7.51 0 00-7.5 7.51v24.81h-4.35a3.91 3.91 0 000 7.81H75v32.72a7.51 7.51 0 007.51 7.51h65.33a7.52 7.52 0 007.52-7.51V81.92a7.518 7.518 0 00-7.53-7.51zm-5.49 42.06a1.61 1.61 0 00.49-1.15h.02a1.6 1.6 0 00-.45-1.15l-8.94-8.41a1.57 1.57 0 00-2.65 1.15v3.68h-10.52v-10.51h3.68a1.58 1.58 0 001.15-2.66l-8.41-8.94a1.6 1.6 0 00-1.15-.49 1.61 1.61 0 00-1.15.49L106 97.42a1.58 1.58 0 001.11 2.66h3.68v10.51h-10.51v-3.68a1.58 1.58 0 00-2.66-1.15l-8.93 8.41a1.57 1.57 0 000 2.3l8.93 8.41a1.58 1.58 0 002.66-1.15v-3.68h10.55v10.51h-3.68a1.58 1.58 0 00-1.15 2.66l8.37 8.93a1.572 1.572 0 002.3 0l8.41-8.93a1.58 1.58 0 00-1.15-2.66h-3.68v-10.51h10.49v3.68a1.58 1.58 0 002.66 1.15l8.94-8.41z" _fill="#fff"/>',
  },
})
