
import { Vue, Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { StateAuthorItems, StateAuth } from '@/helpers/state'
import { Id } from '@/components/ibx/base/Types'
import { ItemAuthor } from '@/components/ibx/base/Item'
import { ItemAuthoringMode } from '@/state/modules/authorItems'
import { ItemPassageAff } from '@/components/ibx/base/Item'

/**
 * Item Authoring base componet
 * for interfacing with AuthorItems store
 */
@Component
export default class ItemAuthorBase extends Mixins(Vue, StateAuthorItems, StateAuth) {
  @Prop({ required: true, default: null }) itemId: Id

  /**
   * Get item from store if exists
   */
  get item(): ItemAuthor {
    return this.itemId ? this.authorGetItem(this.itemId) : null
  }

  /**
   * Item is not yet created
   */
  get isNew(): boolean {
    return this?.item?.isNew
  }

  /**
   * Pie only item meta data
   */
  get pieMetaData(): any {
    const standards = this?.item?.standards || []
    return {
      k12_abStandard: standards.map((o) => o.id),
    }
  }

  /**
   * Item passage affilation object
   */
  get itemPassageAff(): ItemPassageAff {
    return this?.item?.passageAff
  }

  /**
   * Item has passage
   */
  get hasPassageAff(): boolean {
    return Boolean(this.itemPassageAff?.passageRevId)
  }

  /**
   * It is solitary in context of author items list
   */
  get isSolitary(): boolean {
    return this.item && this.authorItems?.length == 1
  }

  /**
   * Authoring is edit mode
   */
  get isEditMode(): boolean {
    return this.authorMode == ItemAuthoringMode.EDIT
  }

  /**
   * Allow autosave
   */
  get autosave(): boolean {
    return this.authorAutosave
  }
}
