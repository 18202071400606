/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  Classification_2: {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M33.09 20.5h125.82c6.953 0 12.59 5.637 12.59 12.59v125.82c0 6.953-5.637 12.59-12.59 12.59H33.09c-6.953 0-12.59-5.637-12.59-12.59V33.09c0-6.953 5.637-12.59 12.59-12.59zm92.98 64.31L98.54 37.14 71 84.81h55.07zm-39.14 66.24H39.5v-47.43h47.43v47.43zm17.89-23.91c0 14.503 11.757 26.26 26.26 26.26 14.503 0 26.26-11.757 26.26-26.26 0-14.503-11.757-26.26-26.26-26.26-14.503 0-26.26 11.757-26.26 26.26z" _fill="#152452"/>',
  },
})
