const filterDefaults = {
  type: 'checkbox',
  name: null, // provided by backend data or by filterOptions
  search: false,
  selectAll: false,
  selectAllHeader: false,
  lazy: true,
  expanded: true,
  collapsable: true,
  canRemove: true,
}

const filterOptions = {
  standard: {
    canRemove: false,
    expanded: true,
    useTooltip: true,
  },
  bank: {
    canRemove: false,
  },
  itemType: {
    canRemove: false,
  },
  depth_of_knowledge: {
    collapsable: false,
    selectAllHeader: true,
    type: 'button',
  },
}
const defaultFilterIds = ['standard', 'bank', 'itemType']
Object.keys(filterOptions).forEach((k) => (filterOptions[k] = Object.assign({}, filterDefaults, filterOptions[k])))

export default filterOptions
export { filterDefaults, filterOptions, defaultFilterIds }
