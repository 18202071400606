
// @ts-nocheck
import { PermsMixin } from '@mixins'
import { mapMutations, mapGetters } from 'vuex'
import * as VBtnToggle from 'vuetify/es5/components/VBtnToggle'
import * as VCard from 'vuetify/es5/components/VCard'
import * as VDivider from 'vuetify/es5/components/VDivider'
import IbxButton from '@components/ibx/IbxButton'
import * as VTooltip from 'vuetify/es5/components/VTooltip'
import IBXService from '@services/ibx/IBXService'
import AuthorStandardsMenu from '@components/itemAuthor/standards/AuthorStandardsMenu'
import AuthorTagsMenu from '@components/itemAuthor/tags/AuthorTagsMenu'
import PassageEditor from '@components/itemAuthor/passages/PassageEditor'
import PassageViewer from '@components/itemAuthor/passages/PassageViewer'
import AuthorPassageSelect from '@components/itemAuthor/passages/AuthorPassageSelect'
import { Events, EventBus } from '@events'
import CONST from '@constants'

// new
import { Vue, Component, Prop, Mixins } from 'vue-property-decorator'
import { StateAuthorItems, StateAuth, StateBrowseFilters, StateAuthorPassages } from '@/helpers/state'
import { Id } from '@/components/ibx/base/Types'
import { ItemAuthor, ItemPassageAff, ItemPassageAffAction } from '@/components/ibx/base/Item'
import { ItemAuthoringMode, MenuTab } from '@/state/modules/authorItems'
import { PassageAuthor, IPassageData } from '@/components/ibx/base/Passage'
import ItemAuthorBase from '@components/itemAuthor/base/ItemAuthorBase'
import ItemAuthorPassageCancelDialog from '@components/itemAuthor/passages/AuthorPassageCancelDialog'
import _ from 'lodash'

@Component({
  components: {
    ...VBtnToggle,
    ...VCard,
    ...VDivider,
    ...VTooltip,
    IbxButton,
    AuthorStandardsMenu,
    AuthorTagsMenu,
    PassageEditor,
    PassageViewer,
    AuthorPassageSelect,
    ItemAuthorPassageCancelDialog,
  },
})
export default class ItemAuthorItemMenu extends Mixins(
  Vue,
  ItemAuthorBase,
  StateAuthorItems,
  StateAuthorPassages,
  PermsMixin
) {
  @Prop({ default: 0 }) index: number

  private initialized: boolean = false
  private passageExpanded: boolean = false
  private itemHover: boolean = true
  private passageView: string = 'select'
  private selectedAPassage: boolean = false
  private createPassage: boolean = false
  private tab: MenuTab = null
  private selectedPassageData: IPassageData = null
  private passageEditorMutated: boolean = false
  private passageExitConfirmAction: string = null
  private editorPassage: PassageAuthor = null
  private passageSaving: boolean = false
  private on: any = null // NOT SURE WHY THIS IS NEEDED TO REMOVE VUE WARNING FOR TOOLTIP ACTIVATOR

  /**
   * Return PassageAuthor object for
   * either selected passage, item passage or null
   */
  get passage(): PassageAuthor {
    if (this.selectedPassageData) {
      return new PassageAuthor(this.selectedPassageData)
    } else if (this.isItemPassageLikend && this.item?.passage) {
      return new PassageAuthor(this.item.passage)
    } else {
      return null
    }
  }

  /**
   * Get current passage affiliation from reactive passage object
   */
  get isPassageLinked() {
    return (
      this.itemPassageAff &&
      this?.passage?.passageRevId &&
      this.itemPassageAff?.passageRevId == this?.passage?.passageRevId
    )
  }

  /**
   * Get item linked passage
   */
  get itemLinkedPassage() {
    if (this.itemPassageAff?.passageRevId == this?.passage?.passageRevId) {
      return this.passage
    } else if (this.item.passage) {
      return this.item.passage
    } else {
      return null
    }
  }

  /**
   * Get item passage affiliation
   */
  get isItemPassageLikend() {
    return this.hasPassageAff
  }

  /**
   * Edit mode check
   */
  get isEditMode(): boolean {
    return this.authorMode == ItemAuthoringMode.EDIT
  }

  /**
   * Item has any systam tag
   */
  get hasTags(): boolean {
    return (
      this?.item?.hasMetaDataType('difficulty') ||
      this?.item?.hasMetaDataType('depth_of_knowledge') ||
      this?.item?.hasMetaDataType('revised_blooms_taxonomy') ||
      this?.item?.hasMetaDataType('language')
    )
  }

  get getPassageMenuTitle() {
    if (this.passage && this.passageView == 'view') return this.passage.title

    if (this.passageView == 'edit' && this.passage?.passageRevId) {
      return 'Edit Passage'
    } else if (this.passageView == 'edit') {
      return 'Create Passage'
    }

    return 'Passage'
  }

  get passageIcon(): string {
    return this.isPassageLinked ? 'fas' : 'fal'
  }

  get tagIcon(): string {
    return this.hasTags ? 'fas' : 'fal'
  }

  get hasAlignedStandards(): boolean {
    return Boolean(this?.item?.standards?.length)
  }

  get standardsIcon(): string {
    return this.hasAlignedStandards ? 'fas' : 'fal'
  }

  get menuTabType(): MenuTab {
    return MenuTab
  }

  get canEditPassage() {
    const passageUser = this.passage?.user?.userId || null
    return this.permUserCanEditPassage(passageUser)
  }

  get questionNum(): string {
    return `Q ${this.index + 1}`
  }

  get passageMenuActive(): boolean {
    return this.authorMenuTab == MenuTab.PASSAGE
  }

  get passageEditing(): boolean {
    return this.passageView == 'edit'
  }

  get confrimPassageExit(): boolean {
    return this.passageMenuActive && this.passageEditing && this.passageEditorMutated
  }
  switchPassageMenu(value) {
    this.createPassage = false
    if (value == 'create') {
      value = 'edit'
      this.createNewPassage()
      this.createPassage = true
    }

    if (value == 'edit') {
      this.editorPassage = this.passage ? new PassageAuthor(_.cloneDeep(this.passage.dataObj)) : null
    }

    // set slected passage data if view
    if (value == 'view' && this?.passage?.dataObj) {
      this.setPassage(this.passage.dataObj)
    }

    this.passageView = value
    this.authorSetMenuTabView(value)
    this.$emit('menu-change', {
      tabView: value,
    })
  }

  /**
   * Select passage and switch to view mode
   */
  passageSelected(passageData: IPassageData) {
    this.setPassage(passageData)
    this.switchPassageMenu('view')
  }

  linkPassage() {
    // link the passage
    this.switchPassageMenu('select')
  }

  editPassage() {
    this.switchPassageMenu('edit')
    this.selectedAPassage = true
  }

  /**
   * Set passage mutated flag.
   * Used for confirming passage editor cancel.
   * @param changed mutated flag
   */
  onPassageEditorChange(changed: boolean) {
    this.passageEditorMutated = changed
  }

  onPassageEditCancelConfirm() {
    if (this.confrimPassageExit) {
      this.passageExitConfirmAction = 'passage-cancel'
    } else {
      this.passageExitConfirmAction = null
      this.onPassageEditCancel()
    }
  }

  onPassageEditCancel() {
    this.passageEditorMutated = false

    if (this.selectedAPassage) {
      this.switchPassageMenu('view')
    } else {
      this.switchPassageMenu('select')
    }

    this.selectedAPassage = false
  }

  expandPassage(expand: boolean = false) {
    this.passageExpanded = expand
  }

  /**
   * Take confirmed action from passage exit dialog
   * @param action action to that was confirmed
   * @param save save or cancel flag
   */
  async onPassageEditorConfirmAction({ action, save }: { action: string; save: boolean }) {
    this.passageExitConfirmAction = null
    if (save) {
      let errorResponse = null
      try {
        this.passageSaving = true
        errorResponse = await this.$refs?.passageEditor?.savePassage()
      } finally {
        this.passageSaving = false
      }
      if (errorResponse != null) return
    }

    switch (action) {
      case 'passage-cancel':
        this.onPassageEditCancel()
        this.passageExitConfirmAction = null
        this.passageEditorMutated = false
        break
      case MenuTab.PASSAGE:
      case MenuTab.STANDARDS:
      case MenuTab.TAGS:
      default:
        action = action == 'collapse' ? undefined : action
        this.passageExitConfirmAction = null
        this.passageEditorMutated = false
        this.onPassageEditCancel()
        this.onMenuChange(action)
    }
  }

  /**
   * Update passage instance data
   * and link/unlink passage
   * @param passage passage data
   * @param link passage link
   */
  async updateAndLinkPassage({ passage, link }: { passage: IPassageData; link: boolean }) {
    this.setPassage(passage)
    this.onLinkPassage({
      passageRevId: passage?.passageRevId,
      remoteId: passage?.remoteId,
      link,
    })
    this.switchPassageMenu('view')
  }

  /**
   * Set selected passage data. Passage getter will
   * then get the appropriate PassageAuthor instance
   * based this data or item passage data.
   * Update passage cach in store module.
   */
  setPassage(passageData: IPassageData) {
    this.selectedPassageData = passageData
    this.authorPassageAddPassage(passageData)
  }

  /**
   * Create new passage. Set selectedPassageData
   * to new empty
   */
  createNewPassage() {
    this.setPassage(PassageAuthor.create().dataObj)
  }

  /**
   * Fetch and load passage data
   */
  async loadPassage(passageRevId) {
    try {
      const { passage } = await IBXService.passageRevision(passageRevId)
      this.setPassage(passage)
    } catch (error) {
      EventBus.$emit(Events.ERROR, { type: CONST.ERROR, error })
    }
  }

  /**
   * Link passage handler
   */
  onLinkPassage({ passageRevId, remoteId, link }: { passageRevId?: Id; remoteId?: Id; link: boolean }) {
    this.$emit('link-passage', {
      itemId: this.itemId,
      passageRevId,
      remoteId, // passage remoteId
      link,
    })
  }

  /**
   * Confirm menu change if needed
   * @param tab menu id
   */
  onMenuChangeConfirm(tab: MenuTab) {
    if (this.confrimPassageExit) {
      this.passageExitConfirmAction = tab || 'collapse'
    } else {
      this.passageExitConfirmAction = null
      this.onMenuChange(tab)
    }
  }

  /**
   * Menu change handler.
   * For passage menu load passage if needed
   * then set passage menu to view option
   * @param tab menu id
   */
  async onMenuChange(tab: MenuTab) {
    const isPassageTab = tab == MenuTab.PASSAGE
    this.tab = tab
    this.authorSetMenuTab(tab)

    // reset global menuTabBiew to current passageView if tab is passage
    if (isPassageTab) this.authorSetMenuTabView(this.passageView)

    if (isPassageTab && this.isPassageLinked) {
      if (!this.passage) {
        await this.loadPassage(this.itemPassageAff.passageRevId)
      }
      this.switchPassageMenu('view')
    }

    if (this.initialized && this.tab !== null) {
      this.$emit('menu-change', {
        tabView: isPassageTab ? this.passageView : null,
        index: this.index,
      })
    }
  }

  /**
   * Load menu if stored in state
   * else load passage menu if passage is linked
   */
  init() {
    this.initialized = false
    const tab = this.authorMenuTab ? this.authorMenuTab : this.isPassageLinked ? MenuTab.PASSAGE : null
    this.onMenuChange(tab)
    this.initialized = true
  }

  /**
   * Vue hook
   */
  mounted() {
    this.init()
  }
}
