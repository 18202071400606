
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import * as VDialog from 'vuetify/es5/components/VDialog'
import * as VCard from 'vuetify/es5/components/VCard'
import * as VForm from 'vuetify/es5/components/VForm'
import getVideoId from 'get-video-id'
import CONST from '@constants'
import _ from 'lodash'
import XBtn from '@/components/xLib/XBtn'

type VideoAttributes = {
  url: string
  width: number
  height: number
}

/**
 * Video URL form validation
 * @param str video url (youtube/vimeo)
 */
const validateURL = (str: string): boolean => {
  const video = getVideoId(str || '')
  let error = false
  let url = null

  try {
    url = new URL(str)
    error = Boolean(url.origin?.match(/music/g))
  } catch {
    error = true
  }

  return !error && video.id && (video.service == 'youtube' || video.service == 'vimeo')
}

@Component({
  components: {
    ...VDialog,
    ...VCard,
    ...VForm,
    XBtn,
  },
})
export default class TextEditorVideoDialog extends Vue {
  @Prop({ default: false }) show: boolean // display dialog
  @Prop({ default: null }) command: any // editor insert commmand
  @Prop({ default: null }) updateCommand: any // editor node view update command
  @Prop({ default: () => ({}) }) attributes: VideoAttributes // video initial attribtues

  /**
   * Watch show prop.
   * Hydrate or reset data
   */
  @Watch('show')
  private watchShow(value: boolean) {
    const form = this.$refs.form as any
    form?.reset()
    this.$nextTick(() => {
      if (value) this.hydrate()
      else this.reset()
    })
  }

  @Watch('url')
  private watchUrl(value: string) {
    this.hyrdrateStartEnd()
  }

  private url: string = null
  private width: number = null
  private height: number = null
  private start: number = null
  private end: number = null
  private editing: boolean = false
  private debounceReloadVideo: any = _.debounce(this.reloadVideo, 1000)
  private lastFocusedInput: string = null
  private urlRules: any = [(v) => !!v || 'Video URL is required.', (v) => validateURL(v) || 'Invalid URL.']

  /**
   * Parsed video src
   */
  private get videoData(): { id: string; service: string } {
    return validateURL(this.url) ? getVideoId(this.url) : { id: null, service: null }
  }

  /**
   * Video id
   */
  private get videoId(): string {
    return this.videoData.id
  }

  /**
   * Video service type
   */
  private get videoService(): string {
    return this.videoData.service
  }

  /**
   * Video URL is valid
   */
  private get isValid(): boolean {
    return Boolean(this.videoId)
  }

  /**
   * Get video start from src
   */
  private get getStart(): number {
    let url = null
    try {
      url = new URL(this.url)
    } catch {
      url = {}
    }

    return this.isVimeo
      ? Math.trunc(url?.hash?.replace(/^#t=/, '') as any) || 0
      : Math.trunc(url?.searchParams?.get('start') as any) || 0
  }

  /**
   * Get video end from src
   */
  private get getEnd(): number {
    try {
      return Math.trunc(this.url ? (new URL(this.url).searchParams.get('end') as any) : 0)
    } catch {
      return 0
    }
  }

  /**
   * Get all iframe attributes
   */
  private get iframeAttrs(): any {
    return {
      src: this.src,
      frameborer: 0,
      allowfullscreen: 'true',
      allow: this.isYouTube
        ? 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
        : 'allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"',
      width: this.width,
      height: this.height,
    }
  }

  /**
   * get iframe src
   */
  private get src(): string {
    if (this.url && this.isYouTube) {
      return this.generateYouTubeSrc(this.url, this.start, this.end)
    } else if (this.url && this.isVimeo) {
      return this.generatVimeoSrc(this.url, this.start)
    } else {
      return ''
    }
  }

  /**
   * YouTube url check
   */
  private get isYouTube(): boolean {
    return this.videoService == 'youtube'
  }

  /**
   * Vimeo url check
   */
  private get isVimeo(): boolean {
    return this.videoService == 'vimeo'
  }

  /**
   * Generate YouTube embed src url
   * @param url Youtube url
   * @param start video start
   * @param end video end
   * @returns string
   */
  private generateYouTubeSrc(url: string, start: number = 0, end: number = 0): string {
    return `https://www.youtube.com/embed/${this.videoId}?start=${start}&end=${end}`
  }

  /**
   * Generate Viemeo embed src url
   * @param url Youtube url
   * @param start video start
   * @returns string
   */
  private generatVimeoSrc(url: string, start = 0): string {
    return `https://player.vimeo.com/video/${this.videoId}#t=${start}`
  }

  /**
   * Insert video action
   */
  private insert() {
    this.$emit('insert', {
      command: this.command,
      data: {
        src: this.src,
        width: this.width,
        height: this.height,
      },
    })
  }

  /**
   * Update video action
   */
  private update() {
    this.$emit('update', {
      updateCommand: this.updateCommand,
      data: {
        src: this.src,
        width: this.width,
        height: this.height,
        start: this.start,
        end: this.end,
      },
      reload: true,
    })
  }

  /**
   * Cancel dialog
   */
  private cancel() {
    this.$emit('cancel')
  }

  /**
   * Hydrate with given attributes
   * or set defaults
   */
  private hydrate() {
    this.url = this.attributes?.url || null
    this.width = this.attributes?.width || CONST.EMBED_VIDEO_WIDTH
    this.height = this.attributes?.height || CONST.EMBED_VIDEO_HEIGHT
    this.hyrdrateStartEnd()
    this.editing = Boolean(this.url)
  }

  /**
   * Hydrate start, end
   * and focus last element if is Vimeo
   */
  private hyrdrateStartEnd() {
    this.start = this.getStart
    this.end = this.getEnd

    this.$nextTick(() => {
      const lastInput = this.$refs[this.lastFocusedInput] as HTMLElement
      if (this.isVimeo && lastInput?.focus) lastInput.focus()
    })
  }

  /**
   * Reset data to defaults
   */
  private reset() {
    this.url = null
    this.width = CONST.EMBED_VIDEO_WIDTH
    this.height = CONST.EMBED_VIDEO_HEIGHT
    this.start = 0
    this.end = 0
    this.editing = false
  }

  /**
   * Reload iframe
   */
  private reloadVideo() {
    const url = this.url
    this.url = ''
    this.$nextTick(() => (this.url = url))
  }

  /**
   * Start changed by user
   */
  private onStartChange() {
    if (this.isVimeo) {
      try {
        const url = new URL(this.url)
        if (this.start) {
          url.hash = `t=${this.start}`
          this.url = url.toString()
        } else {
          this.url = `${url.origin}${url.pathname}`
        }
        this.debounceReloadVideo()
      } catch {
        // do nothing...
      }
    }
  }

  /**
   * On input focus track last input ref
   */
  private onInputFocus(ref: string) {
    this.lastFocusedInput = ref
  }
}
