
import { Entity, IEntityData, EntityContructor } from '@/components/ibx/base/Types.ts'
import { Vue, Component, Prop, Mixins } from 'vue-property-decorator'
import { Id } from '@/components/ibx/base/Types'
import _ from 'lodash'
import { StateFeatures } from '@/helpers/state'

export interface ICardMeta {
  readonly updatedAt: string
  readonly createdAt: string
  readonly id: Id
  readonly hasExternalId: boolean
  readonly externalId: Id
  readonly hasLegacyId: boolean
  readonly legacyId: Id
  readonly remoteId: Id
  readonly revisionId: Id
  readonly source: string
  readonly tags: string
  readonly instructions: string
  readonly hasInstructions: boolean
  readonly language: string
  readonly hasLanguage: boolean
  readonly user: string
  readonly hasRevId: boolean
}

@Component
export default class IbxCardMetaBase<T extends Entity<IEntityData>, U extends IEntityData> extends Mixins(
  Vue,
  StateFeatures
) {
  @Prop({ default: null, required: true })
  data: U

  protected entity: T

  protected initialize(type: EntityContructor<T, U>) {
    this.entity = new type(this.data)
  }

  private activeItems: object = {
    details: false,
  }

  /**
   * Allow multiple display values for meta data
   */
  private metaAllowMultiple: { [k: string]: boolean } = { bank: true }

  get createdAt(): string {
    return this.createdAtLabel(this.entity.createdAt)
  }
  get updatedAt(): string {
    return this.updatedAtLabel(this.entity.updatedAt)
  }
  get hasExternalId(): boolean {
    return Boolean(this.entity.externalId)
  }
  get externalId(): string {
    return this.externalIdLabel(this.entity.externalId)
  }
  get hasLegacyId(): boolean {
    return Boolean(this.entity.legacyId)
  }
  get legacyId(): string {
    return this.legacyIdLabel(this.entity.legacyId)
  }
  get remoteId(): string {
    return this.remoteIdLabel(this.entity.remoteId)
  }
  get remoteIdVersioned(): string {
    return this.remoteIdVersionedLabel(this.entity.remoteIdVersioned)
  }

  get showRevision(): boolean {
    return process.env.NODE_ENV === 'development'
  }
  get source(): string {
    return this.entity.source.toUpperCase()
  }
  get tags(): string {
    const excludeMetaFields = ['language', 'standard']
    // Hide author_name for imported items
    if (this.entity.user === null) excludeMetaFields.push('author_name')
    const values = Object.values(this.entity.meta || {}).filter((o: any): boolean => !excludeMetaFields.includes(o.id))
    return this.tagsDisplay(values, true)
  }
  get hasLanguage(): boolean {
    return this.entity.hasLanguage
  }
  get language(): string {
    return this.hasLanguage ? this.languageLabel(this.entity.language) : ''
  }
  get hasInstructions(): boolean {
    return this.entity.hasTeacherInstructions
  }
  get instructions(): string {
    return this.entity.teacherInstructions
  }
  get user(): string {
    return this.createdByLabel(this.entity.user)
  }
  get hasRevId(): boolean {
    return Boolean(this.entity.revisionId)
  }

  protected formatDate(v): string {
    const d = new Date(Date.parse(v))
    return `${d.toLocaleString('en-us', {
      month: 'short',
    })} ${d.getDate()}, ${d.getFullYear()}`
  }

  protected tagsDisplay(list: any[], sort: boolean = true): string {
    // create single item
    const createTag = (data: { items: any[]; name: string }): string => {
      const value = data.items ? data.items[0].name : 'N/A'
      return `${data.name}: ${value}`
    }

    // create piped tag list
    const createTagList = (data: { items: any[]; name: string }): string => {
      if (!data?.items?.length) {
        return ''
      }
      const items = data.items.map((o: any) => o.name || 'N/A').join(' | ')
      return `${data.name}s: ${items}`
    }

    const tags = (sort ? _.sortBy(list, ['name']) : list)
      .filter((o: any) => o.id !== 'media')
      .map((o: any) => (this.metaAllowMultiple[o.id] && o?.items?.length > 1 ? createTagList(o) : createTag(o)))

    return [...tags, this.mediaTypeLabel()].join(', ')
  }

  protected createdAtLabel(v: string): string {
    return `Created ${this.user} on ${this.formatDate(v)}`
  }

  protected createdByLabel(v: { name: string }): string {
    return v ? 'by ' + v.name : ''
  }

  protected updatedAtLabel(v: string): string {
    return `Last updated on ${this.formatDate(v)}`
  }

  protected idLabel(v: Id, typeLabel: string = 'Item'): string {
    return `${typeLabel} ID ${v}`
  }

  protected externalIdLabel(v: Id): string {
    return `External ID ${v}`
  }

  protected legacyIdLabel(v: Id): string {
    return `Legacy ID ${v}`
  }

  protected remoteIdLabel(v: Id): string {
    return `Remote ID ${v}`
  }

  protected remoteIdVersionedLabel(v: Id): string {
    return `Remote Versioned ID ${v}`
  }

  protected languageLabel(v: string): string {
    return `Language: ${v}`
  }

  protected itemIsActive(k) {
    return this.activeItems[k]
  }

  protected itemActivatorIcons(k) {
    return this.activeItems[k] ? 'chevron-down' : 'chevron-right'
  }

  protected toggleItems(k) {
    this.activeItems[k] = !this.activeItems[k]
  }

  protected mediaTypeLabel() {
    return ''
  }
}
