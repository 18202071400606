import { mapGetters } from 'vuex'

const PermsMixin = {
  computed: {
    ...mapGetters('config', {
      permUser: 'user',
    }),
    ...mapGetters('asmt', {
      permPublished: 'publishedAt',
      permViewOnly: 'viewOnly',
      permAsmtId: 'assessmentId',
    }),
    permIsAsmt() {
      return Boolean(this.permAsmtId)
    },
    permIsViewOnly() {
      return this.permIsAsmt && this.permViewOnly
    },
    permIsPublished() {
      return this.permIsAsmt && Boolean(this.permPublished)
    },
    permCanLockItems() {
      return Boolean(this.permUser.canLockItems)
    },
    permUserId() {
      return this.permUser.user && this.permUser.user.userId
    },
  },

  methods: {
    permUserCanEditItem(itemUserId) {
      return this.permUserId == itemUserId
    },
    permUserCanEditPassage(passageUserId) {
      return this.permUserId == passageUserId
    },
  },
}

export { PermsMixin }
