
// @ts-nocheck
import { AsmtMixin } from '@mixins'
export default {
  name: 'IbxAmstItemValidation',

  mixins: [AsmtMixin],

  props: {
    itemRevId: { type: Number, default: null },
  },

  computed: {
    validationErrorMessage() {
      if (this.itemRevId) {
        const errors = this.asmtGetItemValidationErrors(this.itemRevId)
        const more = errors.length > 1 ? `+ ${errors.length - 1} more.` : ''
        return `${errors[0].message} ${more}`
      } else {
        return ''
      }
    },
  },
}
