<template>
  <v-dialog v-model="show" width="800">
    <v-card id="settingsModal">
      <v-card-title primary-title class="subheading mb-2">
        Assessment Settings
        <font-awesome-icon icon="times" @click.stop="show = false" class="close" />
      </v-card-title>

      <v-card-text v-if="ready">
        <v-tabs v-model="active">
          <div :key="tab" v-for="(data, tab) in getSettings">
            <v-tab :ripple="false"> {{ tab }}</v-tab>
          </div>

          <v-tab-item :transition="false" :reverse-transition="false" :key="tab" v-for="(data, tab) in getSettings">
            <div v-if="tab === 'General'">
              <div class="tab-section" :key="section" v-for="(configs, section) in data">
                <div class="tab-section__title">
                  {{ section }}
                </div>
                <div class="tab-section-item" align-center row wrap :key="field" v-for="(config, field) in configs">
                  <div class="tab-section-item__field">
                    {{ field }}
                  </div>
                  <div class="tab-section-item__controls">
                    <v-text-field
                      :label="config.label"
                      :value="config.value"
                      @input="setTextField($event, config.field)"
                      v-if="config.type === 'text'"
                      solo
                      :placeholder="config.label"
                      :disabled="permIsViewOnly"
                    ></v-text-field>
                    <ibx-date-picker
                      v-else-if="config.type === 'date'"
                      debounce="0"
                      :disabled="permIsViewOnly"
                      :model="administeredAt"
                      @change="onChangeAdministeredAt"
                    ></ibx-date-picker>
                    <x-switch
                      v-else-if="config.type === 'switch'"
                      color="#49C379"
                      class="lock"
                      icon="lock"
                      v-model="locked"
                      :disabled="permIsViewOnly"
                      @change="onLockAllItems"
                    ></x-switch>
                    <v-select
                      item-text="tagName"
                      item-value="tagId"
                      :items="config.items"
                      :label="config.label"
                      :value="getTagValues(config.field)"
                      @input="setTagField($event, config.field)"
                      v-else
                      outlined
                      single-line
                      clearable
                      :multiple="config.multiple"
                      :disabled="permIsViewOnly"
                      color="default"
                    >
                      <template slot="selection" slot-scope="data">
                        {{ getText(data) }}
                        <span
                          v-if="data.index === 1 && data.parent.selectedItems.length > 1"
                          class="grey--text caption others-index"
                          >{{ getOthersText(data.parent.selectedItems.length) }}</span
                        >
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="tab === 'Question Groups'">
              <template v-if="data.length">
                <v-card v-for="group in data" :key="group.autoQuestionGroupId" class="group">
                  <div class="group__header">
                    <div class="col">{{ group.name }}</div>
                    <div class="col"># of Questions</div>
                    <div class="col">Question</div>
                    <div class="col">
                      <v-switch
                        value
                        :input-value="group.enabled"
                        color="#49C379"
                        @change="activateQG($event, group)"
                        :label="checkIfToggleEnabled(group)"
                        :disabled="permIsViewOnly || pendingRequest"
                      ></v-switch>
                    </div>
                  </div>
                  <div class="group__body">
                    <div class="group-item" v-for="qg in group.values" :key="qg.autoQuestionGroupValueId">
                      <div class="col">{{ qg.name }}</div>
                      <div class="col">
                        <v-badge color="grey" class="pl-4">
                          <template v-slot:badge>
                            <span>{{ qg.questions.length }}</span>
                          </template>
                        </v-badge>
                      </div>
                      <div class="col">
                        {{ qg.questions.map((q) => 'Q' + (Number(q.questionOrder) + 1)).join(', ') }}
                      </div>
                      <div class="col"></div>
                    </div>
                  </div>
                </v-card>
              </template>
              <div v-else>No Question Groups</div>
            </div>
          </v-tab-item>
          <div class="line"></div>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { AsmtMixin, SavingMessageMixin, PermsMixin } from '@mixins'
import CONST from '@constants'
import { EventBus, Events } from '@events'
import _ from 'lodash'
import * as UTIL from '@helpers'
import IbxDatePicker from '@components/ibx/IbxDatePicker'
import { getMoment } from '@helpers'
import XSwitch from '@xLib/XSwitch'
import { FLAG } from '@constants'
import { getItemTypeQuestionGroupName, itemTypesMap } from '@/helpers'

const AsmtQ = UTIL.makeDebouncedQueue()
let moment

export default {
  name: 'IbxSettings',

  mixins: [AsmtMixin, SavingMessageMixin, PermsMixin],

  components: {
    IbxDatePicker,
    XSwitch,
  },

  data: () => ({
    active: 0,
    items: [1, 2, 3],
    show: false,
    updatedLocalAsmtId: null,
    updatedTags: null,
    date: false,
    ready: false,
  }),

  watch: {
    show(v) {
      if (!v) {
        this.active = 0
      } else {
        this.fetchAutoQuestionGroups({ assessmentId: this.asmtId })
      }
    },
  },

  computed: {
    ...mapGetters('asmtSettings', [
      'year',
      'scope',
      'subject',
      'grade',
      'locked',
      'autoQuestionGroups',
      'enabledAutoQuestionGroupIds',
    ]),
    ...mapGetters('features', {
      featureFlag: 'flag',
    }),
    ...mapGetters('asmt', {
      pendingRequest: 'pendingRequest',
    }),
    FLAG() {
      return FLAG
    },
    getSettings() {
      const settings = {
        General: {
          TAGS: {
            'Assessment Year': {
              type: 'select',
              items: this.year,
              field: 'academic_year',
              label: 'Select an option',
            },
            Scope: {
              type: 'select',
              items: this.scope,
              field: 'scope',
              label: 'Select an option',
            },
            Subject: {
              type: 'select',
              items: this.subject,
              field: 'subject',
              label: 'Select an option',
            },
            'Grade Levels': {
              type: 'select',
              items: this.grade,
              field: 'grade',
              label: 'Select an option',
              multiple: true,
            },
            'First Date Administered': {
              type: 'date',
              value: this.asmtAdministeredAt,
              field: 'administeredAt',
              label: 'Enter first date adminstered',
            },
            // IBX-2680: temporarily removed
            // 'Local Identifier': {
            //     type: 'text',
            //     value: this.asmtLocalAssessmentId,
            //     field: 'localAssessmentId',
            //     label: 'Enter local identifier'
            // },
          },
        },
        'Question Groups': this.autoQuestionGroups,
      }

      if (this.featureFlag(FLAG.ITEM_LOCKS) && this.permCanLockItems) {
        settings.General.Other = {
          'Lock All Items': {
            type: 'switch',
            value: this.locked,
            field: 'lockAllItems',
            label: 'Toggle Item Lock',
          },
        }
      }

      return settings
    },
    administeredAt() {
      return this.asmtAdministeredAt ? moment(this.asmtAdministeredAt, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''
    },
  },

  methods: {
    ...mapActions('asmtSettings', {
      initSettings: 'init',
      fetchAutoQuestionGroups: 'fetchAutoQuestionGroups',
      enableAutoQuestionGroups: 'enableAutoQuestionGroups',
      disableAutoQUestionGroups: 'disableAutoQUestionGroups',
    }),
    ...mapActions('asmt', {
      setLockAllItems: 'setLockAllItems',
    }),
    ...mapActions('browseItems', {
      browseItemsSetStale: 'setStale',
    }),
    setTagField(value, field) {
      this.smSaving()

      const tags = JSON.parse(JSON.stringify(this.asmtTags))
      let options = []
      let newTags = []

      switch (field) {
        case 'academic_year':
          options = this.year
          break
        case 'subject':
          options = this.subject
          break
        case 'grade':
          options = this.grade
          break
        case 'scope':
          options = this.scope
          break
      }

      if (field == 'grade')
        newTags = _.filter(options, (o) => {
          return value.indexOf(o.tagId) > -1
        })
      else if (value)
        newTags = _.filter(options, (o) => {
          return o.tagId === value.toString()
        })

      if (tags[field]) {
        tags[field].tags = newTags
      } else {
        tags[field] = {}
        tags[field].tags = newTags
      }

      // add tags to queue
      this.updatedTags = Object.assign({}, this.updatedTags, tags)
      const data = [] // debounceQueue uses arrays
      data.push(this.updatedTags)
      AsmtQ.add({ id: 'amst-tags', value: data })
    },
    getTagValues(field) {
      if (this.asmtTags && this.asmtTags[field]) {
        if (field == 'grade') return this.asmtTags[field].tags
        else return this.asmtTags[field].tags[0]
      } else if (field === 'academic_year' && this.asmtTags[field] == undefined) {
        return null
      }
    },
    activateQG(enabled, group) {
      this.asmtSetPendingRequest(true)
      if (group.autoQuestionGroupId == 'item_type') {
        this.activateItemTypeQGs(enabled)
      } else {
        this.activeAutoQGs(enabled, group.autoQuestionGroupId)
      }
    },
    activateItemTypeQGs(enabled) {
      this.asmtSetDirty(true)
      const data = []
      for (let q of this.asmtItems) {
        const itemRevId = q.itemRevId
        const customQuestionGroups = enabled
          ? _.uniq([...q.customQuestionGroups, getItemTypeQuestionGroupName(q.item.itemType)])
          : _.uniq(q.customQuestionGroups.filter((v) => !/^Item Type:/.test(v)))

        // update UI state
        this.asmtSetItemCustomQuestionGroups({
          itemRevId,
          value: customQuestionGroups,
        })

        // queue data
        data.push({
          itemRevId,
          customQuestionGroups,
          autoQuestionGroups: q.autoQuestionGroups,
        })
      }

      AsmtQ.add({
        id: 'amst-question-groups-item-type',
        value: data,
      })
    },
    activeAutoQGs(enabled, autoQuestionGroupId) {
      this.asmtSetDirty(true)
      this.enableAutoQuestionGroups({
        autoQuestionGroupId,
        enabled,
      }).then(() => {
        const data = [this.enabledAutoQuestionGroupIds.filter((v) => v != 'item_type')] // debounceQueue uses arrays
        AsmtQ.add({ id: 'amst-question-groups', value: data })
      })
    },
    getText(data) {
      if ((data.parent.selectedItems.length > 1 && data.index == 0) || data.parent.selectedItems.length == 1)
        return data.item.tagName
    },
    getOthersText(length) {
      return `(+${length - 1} other${length > 2 ? 's' : ''})`
    },
    checkIfToggleEnabled(group) {
      return group.enabled ? 'Enabled' : 'Disabled'
    },
    setTextField(value, field) {
      if (field === 'localAssessmentId') {
        this.smSaving()
        this.asmtSetDirty(true)
        const data = this.updatedLocalAsmtId || { field: value }
        this.updatedLocalAsmtId = Object.assign({}, data, {
          field: value,
        })
        AsmtQ.add({
          id: 'amst-localAsmtId',
          value: this.updatedLocalAsmtId,
        })
      }
    },
    /*
     * Update AsmtMixin and if in autoSaveMode Save data from queue when queue timer resolves
     */
    onSaveAssessmentTags(data) {
      if (this.asmtHasID) {
        const items = Object.keys(data).map((k) => data[k])
        this.smSaving()
        this.asmtSetTags({ value: items[0] })
          .then(() => {
            this.smSaved()
          })
          .catch((error) =>
            EventBus.$emit(Events.ERROR, {
              type: CONST.ERROR,
              error,
              text: 'Failed to save tags',
              subtext: 'Please refresh the page',
            })
          )
      }
    },
    /*
     * Save data from queue when queue timer resolves
     */
    onSaveLocalAsmtId(data) {
      if (this.asmtHasID) {
        const items = Object.keys(data).map((k) => data[k])
        this.smSaving()
        this.asmtSetLocalAssessmentId(items[0])
          .then(() => this.smSaved())
          .catch((error) =>
            EventBus.$emit(Events.ERROR, {
              type: CONST.ERROR,
              error,
              text: 'Failed to save local assessment id',
              subtext: 'Please refresh the page',
            })
          )
      }
    },
    onChangeAdministeredAt(v) {
      const ISODate = v ? new Date(v).toISOString() : null
      AsmtQ.add({ id: 'amst-administeredAt', value: ISODate })
    },
    onSaveAdministeredAt(data) {
      if (this.asmtHasID) {
        this.smSaving()
        this.amstSaveAdministeredAt(data)
          .then(() => this.smSaved())
          .catch((error) =>
            EventBus.$emit(Events.ERROR, {
              type: CONST.ERROR,
              error,
              text: 'Failed to save first date administered',
              subtext: 'Please refresh the page',
            })
          )
      }
    },
    onSaveQuestionGroups(data) {
      if (this.asmtHasID) {
        const items = Object.keys(data).map((k) => data[k])
        this.smSaving()
        this.amstEnableAutoQuestionGroups(items[0])
          .then(() => this.asmtFetchAssessmentItems())
          .then(() => this.asmtSetPendingRequest(false))
          .then(() => this.smSaved())
          .catch((error) => {
            EventBus.$emit(Events.ERROR, {
              type: CONST.ERROR,
              error,
              text: 'Failed to save question groups',
              subtext: 'Please refresh the page',
            })
            this.asmtSetPendingRequest(false)
          })
      }
    },
    onSaveItemTypeQuestionGroups(data) {
      if (this.asmtHasID) {
        this.smSaving()
        this.asmtUpateItems(data)
          .then(() => this.asmtFetchAssessmentItems())
          .then(() => this.asmtSetPendingRequest(false))
          .then(() => this.smSaved())
          .catch((error) => {
            EventBus.$emit(Events.ERROR, {
              type: CONST.ERROR,
              error,
              text: 'Failed to save question groups',
              subtext: 'Please refresh the page',
            })
            this.asmtSetPendingRequest(false)
          })
      }
    },
    onLockAllItems(v) {
      this.browseItemsSetStale(true)
      this.asmtSetLockAllItems(v)
    },
  },
  created() {
    EventBus.$on(Events.ASSESSMENT_SETTINGS, (tab = 0) => {
      this.active = tab
      this.show = true
    })
    EventBus.$on(Events.SHOW_TAGS, (show) => {
      this.active = 0
      this.show = show
    })
  },

  async mounted() {
    moment = await getMoment()
    this.ready = true
    this.initSettings()
    AsmtQ.subscribe({ id: 'amst-tags', time: 0 }, this.onSaveAssessmentTags)
    AsmtQ.subscribe({ id: 'amst-localAsmtId', time: 1000 }, this.onSaveLocalAsmtId)
    AsmtQ.subscribe({ id: 'amst-question-groups', time: 0 }, this.onSaveQuestionGroups)
    AsmtQ.subscribe({ id: 'amst-administeredAt', time: 1000 }, this.onSaveAdministeredAt)
    AsmtQ.subscribe({ id: 'amst-question-groups-item-type', time: 0 }, this.onSaveItemTypeQuestionGroups)
  },
}
</script>

<style lang="scss">
@import '@/styles/main';
#settingsModal {
  height: 600px;
  overflow: auto;

  .subheading {
    font-weight: 600;
    .close {
      cursor: pointer;
      margin-left: auto;
      color: #7e8494;
      max-height: 940px;
      overflow: auto;
    }
  }

  // .groups-card {
  //     margin-top: 16px;
  // }

  // .group-title {
  //     padding-top: 0;
  //     padding-bottom: 0;

  //     .question-groups {
  //         margin-top: 12px;
  //         margin-bottom: 5px;
  //     }
  // }

  .v-tabs__container {
    height: 32px;
    padding-left: 8px;
  }

  .v-tabs {
    .v-tab {
      text-transform: none !important;
      font-weight: 500;
      padding: 6px 12px !important;
      font-size: 14px;
      letter-spacing: normal !important;
    }

    .v-tab--active {
      color: $B300;
      text-transform: none !important;
      &::before {
        display: none !important;
      }
    }

    &-bar {
      height: auto !important;
    }

    &-slider {
      width: calc(100% - 20px);
      margin: 0 auto;
      height: 3px;
      background-color: $B300 !important;
      border: 3px solid $B300 !important;
    }
  }

  .v-window {
    &__container {
      padding: 48px;
    }
  }

  .v-text-field--box .v-input__slot,
  .v-text-field--outlined .v-input__slot {
    min-height: 40px;
    .v-label {
      margin-top: -8px;
    }
  }

  .v-input__slot {
    height: 40px !important;
  }

  .v-text-field.v-text-field--enclosed {
    height: 40px;
    .v-input__append-inner {
      margin-top: 8px;
    }
  }

  .v-text-field {
    &__slot {
      input {
        margin-top: 0px;
      }
    }
  }

  .settings-row {
    padding: 12px 0px;
  }

  .group-title {
    .v-input--selection-controls {
      margin-top: 0px !important;
      .v-label {
        font-size: 14px;
      }
    }

    .v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
      margin-bottom: 0px;
    }

    .v-messages {
      min-height: 0px;
    }
  }

  // .title-header {
  //     border-bottom: 1px solid $N20;
  // }

  .line {
    display: block;
    width: 100%;
    height: 1px;
    background: $N20;
    position: fixed;
    bottom: 0px;
    left: 0;
    z-index: -1;
  }

  .ibx .v-card .v-card__text,
  .v-card__text {
    padding: 0 !important;
  }

  .others-index {
    padding-left: 8px;
  }
}

/** New */
.tab-section {
  display: flex;
  flex-direction: column;

  &__title {
    font-weight: 600;
    color: black;
    flex: 1;
  }

  &-item {
    display: flex;
    align-items: center;
    height: 64px;

    &__field {
      flex: 3;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
    }

    &__controls {
      flex: 6;
      margin-right: 100px;
      .v-input--selection-controls {
        margin-top: 0px;
        padding-top: 0px;

        &__ripple {
          display: none;
        }
      }
    }
  }
}
/** Question Groups */
.group {
  display: block;
  margin-bottom: 15px;
  padding-bottom: 15px;

  .col {
    padding: 0px;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 0px 20px;
    border-bottom: 1px solid $N20;

    .col:nth-child(1) {
      flex: 6;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
    }

    .col:nth-child(n + 2) {
      flex: 2;
      font-size: 14px;
      font-weight: 400;
      white-space: nowrap;

      .v-input {
        margin: 0px !important;

        .v-input__slot {
          margin-bottom: 0px !important;
        }

        .v-messages {
          display: none !important;
        }
      }
    }
  }

  &__body {
    padding: 0 20px;
    font-size: 14px;

    .col {
      padding: 12px 0px;
    }

    .group-item {
      display: flex;

      .col:nth-child(1) {
        flex: 6;
      }

      .col:nth-child(n + 2) {
        flex: 2;
      }
    }
  }

  // .v-input--selection-controls {
  //     .v-input__control .v-input__slot
  // }
}
</style>
