import { StoreOptions } from 'vuex'
import { PieApi } from '@services/pie/PieGqlApi'

interface IVersionedId {
  key: string
  id: string | number
}
class State {}

const itemConfigs = {
  namespaced: true,

  state: new State(),

  getters: {},

  mutations: {},

  actions: {
    getItemConfig: async (_, { versionedId, cacheFirst = true }: { versionedId: string; cacheFirst: boolean }) => {
      const pie = new PieApi()
      return pie.contentItem(versionedId, cacheFirst)
    },
    getItemMetaConfig: async (_, { versionedId, cacheFirst = true }: { versionedId: string; cacheFirst: boolean }) => {
      const pie = new PieApi()
      return pie.contentItemMeta(versionedId, cacheFirst)
    },
    getItemMetaConfigs: async (
      _,
      { versionedIds, cacheFirst = true }: { versionedIds: string[]; cacheFirst: boolean }
    ) => {
      const pie = new PieApi()
      return pie.fetchContentItemMeta(versionedIds, cacheFirst)
    },
    getPassageConfig: async (_, { remoteId, cacheFirst = true }: { remoteId: string; cacheFirst: boolean }) => {
      const pie = new PieApi()
      return pie.stimulus(remoteId, cacheFirst)
    },

    /**
     * Fetch PIE item configs.
     * @param versionedIds IVersionedId { key, id }
     */
    fetchItemConfigs: async (_, versionedIds: IVersionedId[]) => {
      const pie = new PieApi()
      await pie.fetchContentItems(versionedIds.map((o) => o.id))
    },

    /**
     * Fetch PIE passage configs.
     * Request is cancelable via `passage-configs` cancelToken
     * Catch canceled request and return null data
     */
    fetchPassageConfigs: async (_, versionedIds: IVersionedId[]) => {
      const pie = new PieApi()
      await pie.fetchStimuli(versionedIds.map((o) => o.id))
    },

    /**
     * Cancel item configs request if running via `item-configs` cancelToken
     */
    cancelFetchItemsConfigs: async ({ commit, dispatch }) => {},

    /**
     * Cancel passage config request if running via `passage-configs` cancelToken
     */
    cancelFetchPassageConfigs: async ({ commit, dispatch }) => {},

    clearCache: async (_, versionedId: string) => {
      const pie = new PieApi()
      pie.clearCache(versionedId)
    },
  },
} as StoreOptions<State>

export { itemConfigs, State }
