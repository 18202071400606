<template>
  <v-layout class="ibx-preview-passage-groups" row>
    <v-flex v-if="groupHasPassage" class="ibx-preview-passage-groups__passage xs5">
      <v-card class="passage-card">
        <v-card-text>
          <ibx-passage
            :load="load"
            :passage="getPassage"
            initial-mode="view"
            @loaded="onLoaded"
            @player-error="onLoaded"
          />
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex :class="getGroupedItemsClass">
      <ibx-item-card
        class="item-card"
        v-for="question in getGroupedItems"
        :itemRevId="question.itemRevId"
        :itemData="question.item"
        context="provide"
        :key="question.itemRevId"
        noFooter
        heightAuto
        mode="gather"
        role="student"
        lazy
        :lazyLoadTrigger="load"
        @loaded="onLoaded"
        @load-error="onLoaded"
      >
        <span slot="header">
          <strong>Q{{ question.order + 1 }}</strong>
        </span>
      </ibx-item-card>
    </v-flex>
  </v-layout>
</template>

<script>
import IbxPassage from '@components/ibx/IbxPassage'
import IbxItemCard from '@components/ibx/_IbxItemCard'
import { lazyLoadMixin } from '@mixins'
import { mapGetters } from 'vuex'

export default {
  name: 'IbxPreviewGroups',

  mixins: [lazyLoadMixin],

  components: {
    IbxPassage,
    IbxItemCard,
  },

  props: {
    id: { type: [String, Number], default: null },
    group: { type: Object, default: null },
    asmtHasPassage: { type: Boolean, default: false },
  },

  data: () => ({
    load: false,
    loadedItems: 0,
  }),

  computed: {
    getPassage() {
      if (this.groupHasPassage) return this.group
      else return null
    },
    getGroupedItems() {
      return this.group.items
    },
    getGroupedItemsClass() {
      return this.groupHasPassage ? 'ibx-preview-passage-groups__item xs7' : 'ibx-preview-passage-groups__item xs12'
    },
    groupHasPassage() {
      return Boolean(this.group.passageRevId)
    },
    itemsToLoad() {
      return this.groupHasPassage ? 2 : 1
    },
  },

  methods: {
    lazyLoad() {
      this.load = true
    },
    /*
     * Emit if one item and passage loaded (if has passage)
     */
    onLoaded() {
      this.loadedItems++
      if (this.loadedItems >= this.itemsToLoad) {
        this.$emit('loaded', { id: this.id })
      }
    },
  },
}
</script>

<style lang="scss">
.ibx-preview-passage-groups {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;

  &__item {
    margin-left: auto;
    min-width: 500px;
    .item-card {
      padding: 10px;
    }
  }

  &__passage {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 10px;
    touch-action: none;
    min-width: 500px;

    &.no-passage {
      display: none;
    }

    .passage-card {
      height: 740px;
      overflow-y: auto;
    }
  }
}
</style>
