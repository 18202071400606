<template>
  <span class="ibx-menu-fan" :class="klass">
    <slot name="activator">
      <v-chip class="ibx-menu-fan-activator" :dark="dark" :style="activatorStyles" ref="activator" @click="onActivator">
        {{ getLabel }}
        <v-icon v-if="active && !closing" class="ibx-menu-fan-activator-close">cancel</v-icon>
      </v-chip>
    </slot>

    <v-chip
      v-for="(item, i) in items"
      class="ibx-menu-fan-item"
      :key="`item${i}`"
      ref="item"
      @click="(_) => onItemClick(item[itemId])"
      >{{ item[itemLabel] }}</v-chip
    >
  </span>
</template>

<script>
import * as VChip from 'vuetify/es5/components/VChip'
import anime from 'animejs'

export default {
  name: 'IbxMenuFan',

  components: {
    ...VChip,
  },

  props: {
    items: { type: Array, default: () => [] },
    label: { type: String, default: 'Activator' },
    itemLabel: { type: String, default: 'name' },
    itemID: { type: String, default: 'id' },
    dark: { type: Boolean, default: true },
  },

  data: () => ({
    active: false,
    closing: false,
    activatorStyles: {},
  }),

  watch: {
    active(val) {
      if (val) this.closing = false
    },
  },

  computed: {
    klass() {
      return this.active ? 'active' : ''
    },
    getLabel() {
      return !this.active || this.closing ? `${this.items.length} ${this.label}` : `Close`
    },
    itemsWidth() {
      return 200
      // const itemsEl = this.$refs.items
      // const rect    = itemsEl.getBoundingClientRect()
      // return rect.width
    },
  },

  methods: {
    getItemElements() {
      return this.$refs.item.map((vc) => vc.$el)
    },
    onActivator() {
      if (!this.active) {
        this.active = true
        this.$nextTick((_) => this.itemsAnime(true))
      } else {
        this.itemsAnime(false)
      }
    },
    itemsAnime(active) {
      if (active) {
        anime({
          targets: this.getItemElements(),
          translateX: [-50, 0],
          opacity: [0, 1],
          duration: 100,
          delay: function (el, index) {
            return index * 80
          },
        })
      } else {
        this.closing = true
        anime({
          targets: this.getItemElements(),
          translateX: [0, -50],
          opacity: 0,
          duration: 100,
          delay: function (el, index) {
            return index * 80
          },
        }).finished.then((_) => (this.active = false))
      }
    },
    onItemClick(id) {
      this.$emit('item-click', id)
    },
  },

  mounted() {
    const activatorRect = this.$refs.activator.$el.getBoundingClientRect()
    this.activatorStyles = { width: `${activatorRect.width}px` }
  },
}
</script>

<style lang="scss">
.ibx-menu-fan {
  display: inline-block;
  width: auto !important;

  .ibx-menu-fan-activator {
    position: relative;
    display: inline-flex;
    z-index: 2;

    .v-chip__content {
      width: 100%;
    }

    .ibx-menu-fan-activator-close {
      position: absolute;
      right: 5px;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .ibx-menu-fan-item {
    display: inline-block;
    opacity: 0;
    z-index: 1;
  }

  &:not(.active) {
    .ibx-menu-fan-item {
      display: none;
    }
  }
}
</style>
