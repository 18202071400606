
import { Component, Prop, Vue } from 'vue-property-decorator'
import { isSoundcloudURL } from '@/components/itemAuthor/passages/TextEditor/TextEditorHelpers'
import { Events, EventBus } from '@events'

@Component
export default class TextEditorVideoView extends Vue {
  @Prop() private node!: any
  @Prop() private view!: any
  @Prop() private updateAttrs!: (params: any) => void
  @Prop() private getPos!: any

  /**
   * src getter/settter
   */
  private get src() {
    return this.node.attrs.src
  }
  private set src(src) {
    this.updateAttrs({ src })
  }

  /**
   * url getter/setter
   */
  private get url() {
    return this.node.attrs.url
  }
  private set url(url) {
    this.updateAttrs({ url })
  }

  /**
   * width getter/settter
   */
  private get width() {
    return this.node.attrs.width
  }
  private set width(width) {
    this.updateAttrs({ width })
  }

  /**
   * height getter/settter
   */
  private get height() {
    return this.node.attrs.height
  }
  private set height(height) {
    this.updateAttrs({ height })
  }

  /**
   * start getter/settter
   */
  private get start() {
    return this.node.attrs.start || 0
  }
  private set start(start: number) {
    this.updateAttrs({ start })
  }

  /**
   * end getter/settter
   */
  private get end() {
    return this.node.attrs.end || 0
  }
  private set end(end: number) {
    this.updateAttrs({ end })
  }

  /**
   * Audio check
   */
  private get isAudio(): boolean {
    return this.url && isSoundcloudURL(this.url)
  }

  /**
   * Iframe attribtues
   */
  private get iframeAttrs() {
    return {
      src: this.src,
      url: this.url,
      frameborer: 0,
      allowfullscreen: 'true',
      allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
      width: this.width,
      height: this.height,
    }
  }

  /**
   * Show iframe
   */
  private show: boolean = true

  /**
   * Remove node
   */
  private remove() {
    let tr = this.view.state.tr
    let pos = this.getPos()
    tr.delete(pos, pos + this.node.nodeSize)
    this.view.dispatch(tr)
  }

  /**
   * Edit Settings
   */
  private edit() {
    const event = this.isAudio ? Events.SHOW_EMBED_AUDIO_DIALOG : Events.SHOW_EMBED_VIDEO_DIALOG

    EventBus.$emit(event, {
      url: this.isAudio ? this.url : this.src,
      width: this.width,
      height: this.height,
      start: this.start,
      end: this.end,
      updateCommand: this.updateCommand,
    })
  }

  /**
   * Update Editor Node
   * @param data properties to update
   * @param reload reload video (needed for Vimeo update)
   */
  public updateCommand(data: any = {}, reload: boolean = false) {
    if (reload) {
      this.show = false
      this.updateContent(data)
      this.$nextTick(() => (this.show = true))
    } else {
      this.updateContent(data)
    }
  }

  /**
   * Update content
   * @param data properties to update
   */
  private updateContent(data: any = {}) {
    this.url = data.url || this.url
    this.src = data.src || this.src
    this.width = data.width || this.width
    this.height = data.height || this.height
    this.start = data.start || this.start
    this.end = data.end || this.end
  }
}
