import * as UTIL from '@helpers'
import { Events, EventBus } from '@events'
import CONST from '@constants'

const SELECTED_FILTERS = Symbol('browseFilters/selectedFilters')
const ACTIVE_FILTERS = Symbol('browseFilters/ActiveFilters')

const makeBrowseMediator = (store, syncQ) => {
  const snapshots = UTIL.makeOhSnap({ max: 5 })

  function getFiltersSnapshot(store) {
    return {
      activeFilters: store.getters['browseFilters/activeFilters'],
      selectedFilters: store.getters['browseFilters/selectedFilters'],
    }
  }

  function takeFilterSnapshot() {
    snapshots.snapshot(getFiltersSnapshot(store))
  }

  function enqueue(id, data) {
    syncQ.add({ id })
  }

  function onFilterChange() {
    enqueue(SELECTED_FILTERS)
    //takeFilterSnapshot()
    store.dispatch('browseItems/setLoading', true)
  }

  function updateOrder(order = []) {
    store.dispatch('browseFilters/setFiltersOrder', order)
  }

  // browseFilters debounced syncQ (for backend sync)
  syncQ.subscribe({ id: SELECTED_FILTERS }, () => {
    store.commit('browseItems/setCurrentPage', 1)
    store
      .dispatch('browseItems/fetchItems', {
        ...store.getters['browseFilters/selectedFilters'],
        groupByPassage: true,
        page: store.getters['browseItems/currentPage'],
      })
      .catch((error) => EventBus.$emit(Events.ERROR, { type: CONST.ERROR, error, text: CONST.BROWSE_ITEMS_LOAD_ERROR }))
  })

  // browseFilters snapshots (for reverting filters)
  snapshots.subscribe({ previous: true }, ({ selectedFilters, activeFilters }) => {
    if (selectedFilters) {
      store.dispatch('browseFilters/setAllSelectedFilters', selectedFilters)
    }

    if (activeFilters) {
      store.dispatch('browseFilters/setActiveFilters', activeFilters)
    }
  })

  store.subscribe(({ type }, state) => {
    switch (type) {
      case 'standards/mutateFilteredDocuments':
      case 'standards/mutateFilteredSubjects':
      case 'standards/mutateFilteredGradeLevelSets':
      case 'standards/mutateSelectedStandards':
      case 'standards/mutateSelectedStandardsAdd':
      case 'standards/mutateSelectedStandardsRemove':
      case 'standards/mutateAllStandardsRemove':
        if (state.standards.initialized) {
          EventBus.$emit(Events.STANNIS_CHANGE)
        }
        break
    }
  })

  store.subscribe(({ type }, state) => {
    if (!state.browseFilters.initialzed && type !== 'browseFilters/setInitialized') {
      type = null
    }

    switch (type) {
      case 'browseFilters/setInitialized':
        // set initial snapshot
        if (state.browseFilters.initialzed) {
          enqueue(SELECTED_FILTERS)
          //takeFilterSnapshot()
        }
        break
      case 'browseFilters/setAllSelectedFilters':
        onFilterChange()
        break
      case 'browseFilters/setSelectedFilters':
        onFilterChange()
        break
      case 'browseFilters/setActiveFilters':
        updateOrder(state.browseFilters.activeFilters)
        onFilterChange()
        //enqueue(ACTIVE_FILTERS)
        break
      case 'browseFilters/setExapandedFilters':
        //enqueue(ACTIVE_FILTERS)
        break
      case 'browseFilters/setFiltersOrder':
        //enqueue(ACTIVE_FILTERS)
        break
    }
  }),
    store.subscribeAction((action) => {
      switch (action.type) {
        case 'browseFilters/revertSelected':
          //snapshots.moveToPrevious()
          break
      }
    })
}

export { makeBrowseMediator }
