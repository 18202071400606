import { IBXService } from '@services/ibx/IBXService'
import { StoreOptions } from 'vuex'
import { Events, EventBus } from '@events'
import _ from 'lodash'
import Vue from 'vue'
import CONST from '@constants'
import { calculateItemWeight, supportsRubric } from '@helpers/itemHelpers'
import { IItemData } from '@/components/ibx/base/Item'
import { FLAG } from '@constants'
import { PieApi } from '@services/pie/PieGqlApi'
import { getItemTypeQuestionGroupName } from '@/helpers'
import { validate } from '@/helpers/itemValidation'

/**
 * Order items so passage items remain contiguos while
 * keep order as closes are possible to original array.
 * @param items items to reorder
 * @param transformFunc custom item data transform function
 * @returns any[] array of ordered transformed items
 */
const orderQuestions = ({
  items = [],
  transformFunc = () => null,
}: {
  items: IItemData[]
  transformFunc: (itemData: any) => any
}): any[] => {
  const itemRevIds = items.map(({ itemRevId }) => itemRevId)

  // keep track of processed items
  const itemMap = items.reduce((m: any, o: IItemData) => {
    m[o.itemRevId] = {
      ...o,
      visited: false,
    }
    return m
  }, {})

  // reordered items with contiguous passage items
  const data = items.reduce((m: any[], o: IItemData) => {
    const { itemRevId, passage } = o as any

    // skip visted items
    if (itemMap[itemRevId].visited) return m

    if (passage) {
      const passageItemRevIds = passage.items
        .map(({ itemRevId }) => itemRevId)
        .filter((itemRevId) => itemRevIds.includes(itemRevId))

      itemRevIds.forEach((itemRevId) => {
        if (passageItemRevIds.includes(itemRevId) && !itemMap[itemRevId].visited) {
          m.push(
            transformFunc({
              ...itemMap[itemRevId],
              order: m.length,
            })
          )
          itemMap[itemRevId].visited = true
        }
      })
    } else {
      m.push(transformFunc({ ...itemMap[itemRevId], order: m.length }))
      itemMap[itemRevId].visited = true
    }

    return m
  }, [])

  return data
}

interface IState {
  assessmentId: string | number | null
  title: string | null
  description: string | null
  createdAt: string | null
  updatedAt: string | null
  deletedAt: string | null
  publishedAt: string | null
  user: string | null
  tags: object
  items: object[]
  itemsToAdd: string[] | number[]
  itemsToAddData: object
  itemsToAddEnroute: string[] | number[]
  itemValidationErrors: {}
  customQuestionGroups: string[]
  autoQuestionGroups: string[]
  localAssessmentId: string | null
  administeredAt: string | null
  viewOnly: boolean
  dirty: boolean
  initialized: boolean
  lastDirty: Date
  loading: boolean
  canDuplicate: boolean
  omittedQuestions: any[]
  pendingRequest: boolean
}
interface IItem {
  id: string | number
}
class State implements IState {
  assessmentId: string | number | null = null
  title: string | null = ''
  description: string | null = ''
  createdAt: string | null = null
  updatedAt: string | null = null
  deletedAt: string | null = null
  publishedAt: string | null = null
  user: string | null = null
  tags: object = {}
  items: object[] = []
  itemsToAdd: string[] | number[] = []
  itemsToAddData: object = {}
  itemsToAddEnroute: string[] | number[] = [] // items to add that are in a pending request
  itemValidationErrors: {} = {}
  customQuestionGroups: string[] = [] // all user available custom question groups - fetched, not part of assessment data
  autoQuestionGroups: string[] = [] // all assessment enabled auto question groups - fetched, not part of assessment data
  localAssessmentId: string | null = null
  administeredAt: string | null = null
  viewOnly: boolean = false
  dirty: boolean = false
  initialized: boolean = false
  lastDirty: Date = new Date(0)
  loading: boolean = false
  canDuplicate: boolean = true
  omittedQuestions: any[] = []
  pendingRequest: boolean = false
}

const asmt = {
  namespaced: true,

  state: new State(),

  getters: {
    items: (state: IState) => state.items || [],
    itemIds: (state: IState) => _.uniq([...state.items.map((o: any) => o.item.itemRevId), ...state.itemsToAdd]),
    itemsToAdd: (state: IState) => state.itemsToAdd,
    itemsToAddData: (state: IState) => state.itemsToAddData,
    itemsToAddEnroute: (state: IState) => state.itemsToAddEnroute,
    itemValidationErrors: (state: IState) => state.itemValidationErrors,
    assessmentId: (state: IState) => state.assessmentId,
    title: (state: IState) => state.title,
    tags: (state: IState) => state.tags,
    description: (state: IState) => state.description,
    customQuestionGroups: (state: IState) => state.customQuestionGroups,
    autoQuestionGroups: (state: IState) => state.autoQuestionGroups,
    globalQuestionGroupObjects: (state: IState) => {
      const auto = state.autoQuestionGroups.map((v: string) => ({ id: v, name: v, badge: 'Default', type: 'auto' }))
      const custom = state.customQuestionGroups
        .filter((v: string) => !/^Item Type:/.test(v))
        .map((v: string) => ({ id: v, name: v }))
      return _.uniqBy([...auto, ...custom], 'id')
    },
    globalQuestionGroupObjectByItemRevId:
      (state: IState, getters: any) =>
      (itemRevId): any => {
        const question = getters.items.find((q) => q.itemRevId == itemRevId)
        const name = getItemTypeQuestionGroupName(question?.item?.itemType)
        return { id: name, name, badge: 'Default', type: 'item_type' }
      },
    localAssessmentId: (state: IState) => state.localAssessmentId,
    administeredAt: (state: IState) => state.administeredAt,
    viewOnly: (state: IState) => state.viewOnly,
    publishedAt: (state: IState) => state.publishedAt,
    dirty: (state: IState) => state.dirty,
    lastDirty: (state: IState) => state.lastDirty,
    loading: (state: IState) => state.loading,
    itemSupportsRubric:
      (state: IState) =>
      (itemRevId): boolean => {
        const question: any = state.items.find((o: any) => o.itemRevId === itemRevId)
        return supportsRubric(question?.item?.itemType)
      },
    allItemsArePrintable: (state: IState, getters: any, rootState: any, rootGetters: any) => {
      const printableItems = new Set(rootGetters['features/flag'](FLAG.PRINT_ITEM_TYPES).itemTypes)
      return state.items.filter((o: any) => printableItems.has(o.item.itemType) == false).length == 0
    },
    checkAllAsmtItemLockStatus: (state: IState) => !state.items.some((i: any) => i.item.locked == false),
    canDuplicate: (state: IState) => state.canDuplicate,
    hasErrors: (state: IState): boolean => Boolean(state.omittedQuestions?.length),
    omittedQuestions: (state: IState) => state.omittedQuestions || [],
    pendingRequest: (state: IState) => state.pendingRequest,
  },

  mutations: {
    reset: (state: IState) => {
      const initState: any = new State()
      Object.keys(initState).forEach((k: any) => {
        Vue.set(state, k, initState[k])
      })
    },
    setDirty: (state: IState, value: boolean = false) => {
      state.dirty = value
    },
    setLastDirty: (state: IState, value: Date) => {
      state.lastDirty = value
    },
    setInitialized: (state: IState, value: boolean = true) => {
      state.initialized = value
    },
    setAssessmentId: (state: IState, id: string | number) => {
      state.assessmentId = id
    },
    setTags: (state: IState, value: object) => {
      state.tags = value
    },
    addItem: (state: IState, { id }: IItem) => {
      state.itemsToAdd = _.uniq([...state.itemsToAdd, id])
    },
    addItems: (state: IState, itemRevIds: any[] = []) => {
      state.itemsToAdd = _.uniq([...state.itemsToAdd, ...itemRevIds])
    },
    addItemsToAddData: (state: IState, itemsData: object[] = []) => {
      itemsData.forEach((o: any) => {
        Vue.set(state.itemsToAddData, o.itemRevId, o)
        //state.itemsToAddData[o.itemRevId] = o
      })
    },
    addItemToAddEnroute: (state: IState, itemRevIds: any[] = []) => {
      state.itemsToAddEnroute = _.uniq([...state.itemsToAddEnroute, ...itemRevIds])
    },
    removeItemsToAddEndroute: (state: IState, itemRevIds: any[] = []) => {
      state.itemsToAddEnroute = _.difference(state.itemsToAddEnroute, itemRevIds)
    },
    removeItemsToAdd: (state: IState | any, itemRevIds: any[] = []) => {
      state.itemsToAdd = state.itemsToAdd.filter((itemRevId: any) => !itemRevIds.includes(itemRevId))
    },
    updateItemsToAdd: (state: IState | any, { oldRevId, newRevId }) => {
      let itemToAdd = [...state.itemsToAdd]
      itemToAdd[itemToAdd.indexOf(oldRevId)] = newRevId
      state.itemsToAdd = itemToAdd
    },

    seItemsToAdd: (state: IState, itemRevIds: any[] = []) => {
      state.itemsToAdd = itemRevIds
    },
    removeItems: (state: IState, itemRevIds: any[] = []) => {
      state.items = state.items.filter((o: any) => !itemRevIds.includes(o.itemRevId))
    },
    setItems: (state: IState, items: object[] = []) => {
      state.items = items || []
    },
    setTitle: (state: IState, title: string | null = null) => {
      state.title = title
    },
    setDescription: (state: IState, value: string | null = null) => {
      state.description = value
    },
    setItemCustomQuestionGroups: (state: IState, { itemRevId, value }) => {
      const item = state.items.find((o: any) => o.itemRevId == itemRevId)
      if (item) {
        Vue.set(item, 'customQuestionGroups', value)
      }
    },
    setItemAutoQuestionGroups: (state: IState, { itemRevId, value }) => {
      const item = state.items.find((o: any) => o.itemRevId == itemRevId)
      if (item) {
        Vue.set(item, 'autoQuestionGroups', value)
      }
    },
    addCustomQuestionGroup: (state: IState, value) => {
      state.customQuestionGroups = _.uniq([...state.customQuestionGroups, value])
    },
    removeCustomQuestionGroup: (state: IState, value) => {
      state.customQuestionGroups = state.customQuestionGroups.filter((v) => v != value)
    },
    setCustomQuestionGroups: (state: IState, value: string[] = []) => {
      state.customQuestionGroups = value
    },
    setAutoQuestionGroups: (state: IState, value: string[] = []) => {
      state.autoQuestionGroups = value
    },
    setItemStandards: (state: IState, { itemRevId, value }) => {
      const item = state.items.find((o: any) => o.itemRevId == itemRevId)
      if (item) {
        Vue.set(item, 'standards', value)
      }
    },
    setLocalAssessmentId: (state: IState, value: string | null = null) => {
      state.localAssessmentId = value
    },
    setAdministeredAt: (state: IState, value: string | null = null) => {
      state.administeredAt = value
    },
    setViewOnly: (state: IState, value: boolean = true) => {
      state.viewOnly = value
    },
    setPublishedAt: (state: IState, value: string) => {
      state.publishedAt = value
    },
    setItemWeight: (state: IState, { itemRevId, value }) => {
      const item: any = state.items.find((o: any) => o.itemRevId == itemRevId)
      if (item) Vue.set(item, 'weight', value)
    },
    setLoading: (state: IState, value: boolean) => {
      state.loading = value
    },
    setItemPartialScoring: (state: IState, { itemRevId, value }) => {
      const item: any = state.items.find((o: any) => o.itemRevId == itemRevId)
      if (item) Vue.set(item, 'partialScoring', value)
    },
    setLockItemValues: (state: IState, { itemRevId, value }) => {
      const item: any = state.items.find((o: any) => o.itemRevId == itemRevId)
      if (item) Vue.set(item.item, 'locked', value)
    },
    setCanDuplicate: (state: IState, value: boolean) => {
      state.canDuplicate = value
    },
    setOmmitedQuestions: (state: IState, value: [] = []) => {
      state.omittedQuestions = value
    },
    setPendingRequest: (state: IState, value: boolean = false) => {
      state.pendingRequest = value
    },
    setItemValidationErrors: (state: IState, value: {} = {}) => {
      Vue.set(state, 'itemValidationErrors', value)
    },
  },

  actions: {
    reset: async ({ commit }) => {
      commit('reset')
    },
    setPendingRequest: async ({ commit }, value: boolean) => {
      commit('setPendingRequest', value)
    },
    setDirty: async ({ commit }, value: boolean) => {
      commit('setDirty', value)
      if (value) {
        commit('setLastDirty', new Date())
      }
    },
    fetchAssessment: async ({ commit }, { assessmentId }) => {
      const { assessment } = await IBXService.assessment({ assessmentId })
      const questionGroups = await IBXService.assessmentQuestionGroups({ assessmentId })
      commit('setItemValidationErrors', {})
      commit('setAssessmentId', assessment.assessmentId)
      commit('setTitle', assessment.title)
      commit('setDescription', assessment.description)
      commit('setCustomQuestionGroups', questionGroups.customQuestionGroups)
      commit('setAutoQuestionGroups', questionGroups.autoQuestionGroups)
      commit('setTags', assessment.tags)
      commit('setLocalAssessmentId', assessment.localAssessmentId)
      commit('setAdministeredAt', assessment.administeredAt)
      commit('setPublishedAt', assessment.publishedAt)
      commit('setViewOnly', Boolean(assessment.viewOnly))
      commit('setCanDuplicate', Boolean(assessment.canDuplicate))
      commit('setOmmitedQuestions', assessment.omittedQuestions)
      commit('setInitialized', true)
    },
    fetchAssessmentItems: async ({ commit, getters, dispatch, rootGetters }, cache: boolean = true) => {
      let { questions } = await IBXService.assessmentItems({
        assessmentId: getters.assessmentId,
      })
      // fetch/cache configs for items and passages
      const versionedIds = { items: [], passages: [] }
      const passageIdMap = {}
      questions.forEach((q) => {
        versionedIds.items.push({ id: q.item.remoteIdVersioned })

        const passageRemoteId = q.item.passage?.remoteId
        if (passageRemoteId && !passageIdMap[passageRemoteId]) {
          versionedIds.passages.push({ id: passageRemoteId })
          passageIdMap[passageRemoteId] = passageRemoteId
        }
      })

      await Promise.all([
        dispatch('itemConfigs/fetchItemConfigs', versionedIds.items, { root: true }),
        dispatch('itemConfigs/fetchPassageConfigs', versionedIds.passages, { root: true }),
      ])
      commit('setItems', questions)
    },
    createAssessment: async ({ commit, getters }) => {
      try {
        const { assessment } = await IBXService.assessmentCreate({
          title: getters.title,
        })
        commit('setAssessmentId', assessment.assessmentId)
        commit('setTitle', assessment.title)
        commit('setTags', assessment.tags)
        commit('setLocalAssessmentId', assessment.localAssessmentId)
      } catch (error) {
        return Promise.reject('There was an error creating the assessment.')
      }
    },
    deleteAssessment: async ({ getters, commit }) => {
      commit('setItemValidationErrors', {})
      return await IBXService.assessmentDelete({
        assessmentId: getters.assessmentId,
      })
    },
    addItem: async ({ commit }, item: IItem) => {
      // DO WE USE THIS?
      commit('addItem', item)
    },
    addItems: async (
      { commit, getters, rootGetters }: { commit: any; getters: any; rootGetters: any },
      itemRevIds: Array<any> = []
    ) => {
      // check item limit before adding
      const asmtLimit = rootGetters['features/flag'](FLAG.ASMT_LIMIT)
      if (getters.items.length + getters.itemsToAdd.length + itemRevIds.length > asmtLimit) {
        EventBus.$emit(Events.ASSESSMENT_ITEM_LIMIT, asmtLimit)
        return Promise.reject(Events.ASSESSMENT_ITEM_LIMIT)
      }

      // get base standards from user selection
      const userBaseStandards = rootGetters['user/userSelection'](CONST.CONTEXT_BASE_STANDARDS)
      const baseStandards =
        userBaseStandards && userBaseStandards.value && userBaseStandards.value.selected_standards
          ? userBaseStandards.value.selected_standards.map((o: any) => o.guid)
          : []
      let itemData
      if (rootGetters['ui/currentRoute'] === 'build') {
        itemData = rootGetters['authorItems/items'].map((val) => {
          return val.data
        })
      } else {
        itemData = rootGetters['browseItems/getItemsData'](itemRevIds).filter((o: any): any =>
          itemRevIds.includes(o.itemRevId)
        )
      }

      const itemsDataPromises = itemData.map(async (o: any) => {
        // for rubric items set weight to max rubric value
        let weight = 1

        if (supportsRubric(o.itemType)) {
          const pie = new PieApi() // remoteIdVersioned
          const { data } = await pie.contentItem(o.remoteIdVersioned)
          const config = data.contentItem?.config || { config: {} }
          weight = calculateItemWeight(config)
        }

        // standards
        const itemStandards = (o.meta.standard ? o.meta.standard.items : []).map((so: any) => so.id)
        return Object.assign({}, o, { baseStandards: _.intersection(itemStandards, baseStandards) }, { weight })
      })

      const itemsData = await Promise.all(itemsDataPromises)
      commit('addItems', itemRevIds)
      commit('addItemsToAddData', itemsData)
      commit('setDirty', true)
    },
    removeItem: async ({ commit }, item: IItem) => {
      // DO WE USE THIS?
      commit('removeItems', [item.id])
    },
    removeItems: async ({ commit, getters }, itemRevIds: string[] | number[] | any = []) => {
      commit('setDirty', true)
      commit('setItemValidationErrors', {})
      const asmtItemRevIds = getters.items.map((o: any) => o.itemRevId)
      const removableItemsRevIds = _.intersection(asmtItemRevIds, itemRevIds)

      if (removableItemsRevIds.length) {
        const data = await IBXService.assessmentRemoveItems({
          assessmentId: getters.assessmentId,
          questions: removableItemsRevIds.map((itemRevId: any) => ({ itemRevId })),
        })
        commit('removeItems', removableItemsRevIds)
        commit('setItems', data.questions)
      }

      commit('setDirty', false)
    },
    updateItemOrder: async ({ commit, getters }) => {
      const data = await IBXService.assessmentUpdateItems({
        assessmentId: getters.assessmentId,
        questions: _.sortBy(getters.items, 'order').map((o: any, i: number) => {
          return {
            itemRevId: o.itemRevId,
            order: i,
          }
        }),
      })
      commit('setItems', data.questions)
    },
    removeItemsToAdd: async ({ commit }, itemRevIds: string[] | number[] = []) => {
      commit('removeItemsToAdd', itemRevIds)
      commit('setDirty', true)
    },
    updateItemsToAdd: async ({ commit }, { oldRevId, newRevId }) => {
      commit('updateItemsToAdd', { oldRevId, newRevId })
    },
    setTitle: async ({ commit }, title: string | null) => {
      commit('setTitle', title)
    },
    setDescription: async ({ commit, getters }, { value }) => {
      commit('setDirty', true)
      commit('setDescription', value)
      const { assessment } = await IBXService.assessmentUpdate({
        assessmentId: getters.assessmentId,
        props: { description: value },
      })
      commit('setDescription', assessment.description)
      commit('setDirty', false)
    },
    setItems: async ({ commit }, questions: object[] = []) => {
      commit('setItems', questions)
    },
    saveItemsToAdd: async ({ commit, getters, dispatch }) => {
      const existingItemRevIds = _.uniq([...getters.items.map((o: any) => o.itemRevId), ...getters.itemsToAddEnroute])
      const preExistingPassage = getters.items.some((o: any) => !!o.item?.passage)
      const itemRevIds = getters.itemsToAdd.filter((itemRevId: any) => !existingItemRevIds.includes(itemRevId))

      // get item data for existing or new item
      const getItemData = (itemRevId) => {
        const newItemData = {
          itemRevId,
          meta: { standard: { items: [] } },
        }

        return getters.itemsToAddData[itemRevId] || newItemData
      }
      // order newly added questiion so passage items remain contiguos
      const items = orderQuestions({
        items: itemRevIds.map(getItemData),
        transformFunc: ({ itemRevId, baseStandards = [], weight }) => {
          return {
            itemRevId,
            standards: baseStandards,
            weight,
          }
        },
      })

      if (items?.length) {
        commit(
          'addItemToAddEnroute',
          items.map((o) => o.itemRevId)
        )
        try {
          const data = await IBXService.assessmentAddItems({
            assessmentId: getters.assessmentId,
            questions: items,
          })
          commit('setItems', data.questions)
          commit(
            'removeItemsToAddEndroute',
            data.questions?.map((o) => o.itemRevId)
          )

          // if assessment had preexsting passage, reorder
          // all the questions to keep all passage items contiguous
          if (preExistingPassage) {
            await dispatch('reorderItemsOnAdd')
          }
        } catch (error) {
          commit(
            'removeItemsToAddEndroute',
            items.map((o) => o.itemRevId)
          )
          return Promise.reject(error)
        } finally {
          commit('seItemsToAdd', [])
          commit('setDirty', false)
        }
      } else {
        commit('seItemsToAdd', [])
        commit('setDirty', false)
      }
    },
    reorderItemsOnAdd: async ({ commit, dispatch, getters }) => {
      const data = orderQuestions({
        items: getters.items.map(({ item }) => item),
        transformFunc: ({ itemRevId, order }) => {
          return {
            itemRevId,
            order,
          }
        },
      })
      return dispatch('updateItems', data)
    },
    updateItems: async ({ commit, getters }, items: object[]) => {
      commit('setDirty', true)
      commit('setItemValidationErrors', {})
      const data = await IBXService.assessmentUpdateItems({
        assessmentId: getters.assessmentId,
        questions: items,
      })
      commit('setItems', data.questions)
      commit('setDirty', false)
    },
    updateTitle: async ({ commit, getters }, value: string | null) => {
      commit('setDirty', true)
      commit('setTitle', value)
      const { assessment } = await IBXService.assessmentUpdate({
        assessmentId: getters.assessmentId,
        props: { title: value },
      })
      commit('setTitle', assessment.title)
      commit('setDirty', false)
    },
    addCustomQuestionGroup: async ({ commit }, value: string[]) => {
      commit('addCustomQuestionGroup', value)
    },
    removeCustomQuestionGroup: async ({ commit }, value: string[]) => {
      commit('removeCustomQuestionGroup', value)
    },
    setAutoQuestionGroups: async ({ commit }, value: string[]) => {
      commit('setAutoQuestionGroups', value)
    },
    setItemCustomQuestionGroups: async ({ commit, dispatch }, { itemRevId, value }) => {
      commit('setItemCustomQuestionGroups', { itemRevId, value })
      dispatch('asmtSettings/setItemTypeQuestionGroupEnabled', null, { root: true })
    },
    setItemAutoQuestionGroups: async ({ commit }, { itemRevId, value }) => {
      commit('setItemAutoQuestionGroups', { itemRevId, value })
    },
    setItemStandards: async ({ commit }, { itemRevId, value }) => {
      commit('setItemStandards', { itemRevId, value })
    },
    setTags: async ({ commit, getters }, { value }) => {
      commit('setDirty', true)
      commit('setTags', value)
      const { assessment } = await IBXService.assessmentUpdate({
        assessmentId: getters.assessmentId,
        props: { tags: value },
      })
      commit('setTags', assessment.tags)
      commit('setDirty', false)
    },
    setLocalAssessmentId: async ({ commit, getters }, value: string | null) => {
      commit('setDirty', true)
      commit('setLocalAssessmentId', value)
      const { assessment } = await IBXService.assessmentUpdate({
        assessmentId: getters.assessmentId,
        props: { localAssessmentId: value },
      })
      commit('setLocalAssessmentId', assessment.localAssessmentId)
      commit('setDirty', false)
    },
    enableAutoQuestionGroups: async ({ commit, getters }, autoQuestionGroupIds: string[] = []) => {
      commit('setDirty', true)
      await IBXService.assessmentUpdate({
        assessmentId: getters.assessmentId,
        props: { autoQuestionGroups: autoQuestionGroupIds },
      })
      const questionGroups = await IBXService.assessmentQuestionGroups({
        assessmentId: getters.assessmentId,
      })
      commit('setAutoQuestionGroups', questionGroups.autoQuestionGroups)
      commit('setDirty', false)
    },
    setAdministeredAt: async ({ commit, getters }, value) => {
      commit('setDirty', true)
      commit('setAdministeredAt', value)
      await IBXService.assessmentUpdate({
        assessmentId: getters.assessmentId,
        props: { administeredAt: value },
      })
      commit('setDirty', false)
    },
    setPublishedAt: async ({ commit, getters }) => {
      if (getters.publishedAt !== null) {
        return Promise.reject('Assessment is already published')
      }

      const stamp = new Date().toISOString()
      const { assessment } = await IBXService.assessmentUpdate({
        assessmentId: getters.assessmentId,
        props: { publishedAt: stamp },
      })
      commit('setPublishedAt', assessment.publishedAt)
    },
    setItemWeight: async ({ commit }, { itemRevId, value }) => {
      commit('setItemWeight', { itemRevId, value })
    },
    setRubricItemWeight: async ({ commit, getters }, { itemRevId }) => {
      if (getters.itemSupportsRubric(itemRevId)) {
        const question = getters.items.find((o) => o.itemRevId == itemRevId)
        if (question && question.item) {
          const pie = new PieApi() // remoteIdVersioned
          const { data } = await pie.contentItem(question.item.remoteIdVersioned, false)
          const config = data.contentItem?.config || { config: {} }
          commit('setItemWeight', { itemRevId, value: calculateItemWeight(config) })
        }
      }
    },
    setItemPartialScoring: async ({ commit }, { itemRevId, value }) => {
      commit('setItemPartialScoring', { itemRevId, value })
    },
    setLockAllItems: async ({ commit, getters }, locked) => {
      getters.items.forEach((item) => {
        commit('setLockItemValues', { itemRevId: item.item.itemRevId, value: locked })
        IBXService.updateItems({
          itemId: item.item.itemId,
          props: { locked },
        })
          .then(({ item }) => {
            EventBus.$emit(Events.SNACKBAR, {
              type: 'success',
              text: locked ? 'Items Locked' : 'Items Unlocked',
              timeout: 10000,
            })
          })
          .catch((error) => {
            EventBus.$emit(Events.ERROR, {
              type: CONST.ERROR,
              error,
              text: 'Failed to lock item',
              subtext: 'Please refresh the page',
            })
          })
      })
    },
    setLockItem: ({ commit }, { itemRevId, locked }) => {
      commit('setLockItemValues', { itemRevId: itemRevId, value: locked })
    },
    validateItems: async ({ commit, getters }): Promise<{ hasErrors: boolean; errors: any[] }> => {
      commit('setItemValidationErrors', {})
      const sortedItems = _.sortBy(getters.items, [(o) => o.order])
      const validationErrors = await sortedItems.reduce(async (m, o) => {
        try {
          m = await m
          const pie = new PieApi()
          const { data } = await pie.contentItem(o.item.remoteIdVersioned)
          const errors = validate(o.item.itemType, data.contentItem.config)
          if (errors.length) m[o.item.itemRevId] = errors
          return m
        } catch (error) {
          m[o.item.itemRevId] = [{ message: 'Error fetching item config' }]
          return m
        }
      }, {})

      commit('setItemValidationErrors', validationErrors)
      return { hasErrors: Object.keys(validationErrors).length > 0, errors: validationErrors }
    },
    clearValidationErrors: ({ commit }) => {
      commit('setItemValidationErrors', {})
    },
  },
} as StoreOptions<IState>

export { asmt, IState, State }
