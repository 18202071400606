/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'Math Equation Response_1': {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M33.09 20.5h125.82c6.953 0 12.59 5.637 12.59 12.59v125.82c0 6.953-5.637 12.59-12.59 12.59H33.09c-6.953 0-12.59-5.637-12.59-12.59V33.09c0-6.953 5.637-12.59 12.59-12.59zm127.651 10.759a2.588 2.588 0 00-1.831-.759H33.09a2.59 2.59 0 00-2.59 2.59v125.82a2.588 2.588 0 002.59 2.59h125.82c.687 0 1.346-.273 1.831-.759a2.587 2.587 0 00.759-1.831V33.09a2.59 2.59 0 00-.759-1.831z" _fill="#152452"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M83.93 59.46l-9.47 47a3.07 3.07 0 01-5.85.56l-5.89-14.43a3.08 3.08 0 00-2.84-1.91H49.13a3.07 3.07 0 00-3.07 3.07v3.17a3.07 3.07 0 003.07 3.08h4.51a3.08 3.08 0 012.84 1.9l13.28 32.44a3.07 3.07 0 002.84 1.91h2a3.07 3.07 0 003-2.46l13.29-65a3.07 3.07 0 013-2.46H142a3.062 3.062 0 003.07-3.07v-3.19A3.06 3.06 0 00142 57H86.94a3.07 3.07 0 00-3.01 2.46z" _fill="#152452"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M94.46 135.73l17.44-23.37a.599.599 0 000-.75L95 90.32a.62.62 0 01.48-1h10.39a.6.6 0 01.49.25l11.14 14.65a.611.611 0 001 0l11.13-14.65a.62.62 0 01.5-.25h10.39a.619.619 0 01.48 1l-16.86 21.29a.63.63 0 000 .75l17.45 23.37a.62.62 0 01-.49 1h-11a.64.64 0 01-.51-.26l-11.08-15.78a.631.631 0 00-1 0l-11.08 15.78a.64.64 0 01-.51.26H95a.619.619 0 01-.54-1z" _fill="#152452"/>',
  },
})
