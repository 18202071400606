<template>
  <div class="ibx-action-bar" :class="active ? 'ibx-action-bar--active' : ''">
    <div class="ibx-action-bar__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import * as VBtn from 'vuetify/es5/components/VBtn'

export default {
  name: 'IbxActionBar',

  components: {
    ...VBtn,
  },

  props: {
    message: { type: String, default: '' },
    active: { type: Boolean, default: false },
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.ibx-action-bar {
  position: fixed;
  z-index: 5;
  left: 0;
  bottom: -70px;
  transition: bottom 0.2s ease-in-out;
  height: 70px;
  width: 100%;
  background: $N00;
  border-top: 1px solid $N20;

  &--active {
    bottom: 0px;
  }

  &__content {
    display: flex;
    height: 100%;
    max-width: $contentMaxWidth;
    margin: 0 auto;
    padding: 0px 20px;
  }
}
</style>
