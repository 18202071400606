<template>
  <transition name="ibx-modal">
    <div v-if="show" class="ibx-modal" :class="classes">
      <div class="ibx-modal__wrapper" @click="clickAway">
        <div class="ibx-modal__container" :style="getStyles">
          <div class="ibx-modal__header">
            <slot name="header"></slot>
          </div>
          <div class="ibx-modal__content" :class="contentClasses">
            <slot></slot>
          </div>

          <div class="ibx-modal__footer" :class="footerClasses">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { Events, EventBus } from '@events'

export default {
  name: 'IbxModal',

  props: {
    show: { type: Boolean, default: false },
    width: { type: String, default: '80vw' },
    height: { type: String, default: '80vh' },
    minWidth: { type: String, default: '300px' },
    minHeight: { type: String, default: '300px' },
    scroll: { type: Boolean, default: true },
    persistent: { type: Boolean, default: false },
    noPadding: { type: Boolean, default: false },
    noFooterBoder: { type: Boolean, default: false },
  },

  computed: {
    getStyles() {
      const styles = {
        width: this.width,
        height: this.height,
        'min-width': this.minWidth,
        'min-height': this.minHeight,
      }
      const scrollStyles = this.scroll ? { height: this.height, 'min-height': this.height } : {}

      return Object.assign({}, styles, scrollStyles)
    },
    hasHeader() {
      return !!this.$slots['header']
    },
    hasFooter() {
      return !!this.$slots['footer']
    },
    classes() {
      return {
        'ibx-modal--has-header': this.hasHeader,
        'ibx-modal--has-footer': this.hasFooter,
      }
    },
    contentClasses() {
      return {
        'ibx-modal__content--no-padding': this.noPadding,
      }
    },
    footerClasses() {
      return {
        'no-border': this.hasFooter && this.noFooterBoder,
      }
    },
  },

  watch: {
    show(v) {
      EventBus.$emit(Events.DIALOG_FULL, v)
    },
  },

  methods: {
    clickAway(e) {
      if (!this.persistent && e.target.isEqualNode(e.currentTarget)) {
        this.close()
      }
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.ibx-modal {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  transition: opacity 0.3s ease;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__container {
    position: relative;
    min-width: 300px;
    min-height: 300px;
    margin: auto;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    border-radius: 5px;
    overflow: hidden;
  }

  &__header {
    display: none;
  }

  &__content {
    display: block;
    padding: 0px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;

    &--no-padding {
      padding: 0px;
    }
  }

  &__footer {
    display: none;
  }
  .ibx-content-viewer__viewer,
  .ibx-content-viewer__viewer__passage {
    height: 100%;
  }
}

.ibx-modal--has-header {
  .ibx-modal__header {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: absolute;
    top: 0px;
    height: 50px;
    width: 100%;
    width: 100%;
    padding: 10px 20px;
    background: white;
    border-bottom: 1px solid lightgray;

    > * {
      flex: 1;
    }
  }

  .ibx-modal__content {
    margin-top: 50px;
    height: calc(100vh - 50px);
    padding-bottom: 50px;
    z-index: 1;
  }
}

.ibx-modal--has-footer {
  .ibx-modal__content {
    height: calc(100vh - 50px);
  }

  .ibx-modal__footer {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0px;
    height: 50px;
    width: 100%;
    padding: 10px 20px;
    background: white;
    border-top: 1px solid lightgray;
    z-index: 2;

    &.no-border {
      border-top: none;
    }

    > * {
      flex: 1;
    }
  }
}

/*
 * Modal transitions
 */

.ibx-modal-enter {
  opacity: 0;
}

.ibx-modal-leave-active {
  opacity: 0;
}
</style>
