<template>
  <v-dialog class="materials" v-model="show" width="800">
    <v-card id="uploadModal">
      <v-card-title primary-title class="subheading">
        Materials
        <font-awesome-icon icon="times" @click="show = false" class="close" />
      </v-card-title>

      <v-card-text v-if="loaded">
        <vue-dropzone
          v-if="!permIsViewOnly"
          id="material-dropzone"
          :options="dropzoneOptions"
          :useCustomSlot="true"
          @vdropzone-success="onSuccess"
          @vdropzone-error="onError"
          @vdropzone-files-added="onFilesAdded"
          @vdropzone-removed-file="onFileRemoved"
          ref="vueDropzone"
        >
          <v-img :src="materialImg" height="123" contain></v-img>
          <br />
          <div class="title">Drag a file here</div>
          Or click to upload a file from your computer
        </vue-dropzone>
        <br />
        <v-flex xs-12>
          <div class="subheading">List of materials</div>
        </v-flex>
        <v-flex xs-12 :class="{ 'material-list': true, empty: materials.length === 0 || booklets.length === 0 }">
          <div v-if="materials.length === 0 && booklets.length === 0">
            <br />
            <font-awesome-icon icon="file" class="empty-icon" /> No files attached
          </div>
          <v-list v-else>
            <v-list-item v-for="booklet in booklets" :key="booklet.type" @click="onDownloadBooklet(booklet)">
              <v-list-item-avatar size="14">
                <font-awesome-icon :icon="['far', 'file-pdf']" />
              </v-list-item-avatar>

              <v-list-item-content>
                <div class="booklet-label">
                  {{ booklet.label }}
                  <v-chip> BETA </v-chip>
                </div>
              </v-list-item-content>

              <!-- <v-list-item-action>
                                <font-awesome-icon :icon="['fal', 'eye']"/>
                            </v-list-item-action> -->

              <v-list-item-action>
                <font-awesome-icon class="material-list__download" :icon="['fal', 'arrow-down']" />
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-for="material in materials" :key="material.materialId">
              <v-list-item-avatar size="14">
                <font-awesome-icon :icon="['far', 'file-pdf']" />
              </v-list-item-avatar>

              <v-list-item-content>
                {{ material.fileName }}
              </v-list-item-content>

              <!-- <v-list-item-action>
                                <font-awesome-icon :icon="['fal', 'eye']"/>
                            </v-list-item-action> -->

              <v-list-item-action>
                <font-awesome-icon
                  class="material-list__download"
                  :icon="['fal', 'arrow-down']"
                  @click="onDownloadMaterial(material)"
                />
              </v-list-item-action>

              <v-list-item-action>
                <font-awesome-icon
                  v-if="!permIsViewOnly"
                  class="material-list__delete"
                  :icon="['far', 'trash']"
                  @click="onRemoveMaterial(material)"
                />
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-flex>
        <!-- <v-flex xs-12 v-show="!permAutoSave && getFilesNum > 0">
                    <div class="subheading">
                        <font-awesome-icon :icon="['fas', 'check-circle']" class="materials-queued"/>
                        {{getFilesNum}} file{{getPlural}} queued
                    </div>
                    <div class="queued-message">
                        <span>Your materials will be uploaded once you save your assessment</span>
                    </div>
                </v-flex> -->
      </v-card-text>
      <v-card-text v-else> Loading... </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import vueDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import materialImg from '@/assets/svg/Material_Upload.svg'
import { EventBus, Events } from '@events'
import { CONST, FLAG } from '@constants'
import { mapGetters, mapActions } from 'vuex'
import IBXService from '@services/ibx/IBXService'
import { PermsMixin, AsmtMixin } from '@mixins'

export default {
  name: 'IbxMaterials',

  mixins: [PermsMixin, AsmtMixin],

  components: {
    vueDropzone,
  },

  data: () => ({
    show: false,
    materialImg: materialImg,
    loaded: false,
    numFiles: 0,
  }),

  computed: {
    ...mapGetters('features', {
      featureFlag: 'flag',
    }),
    ...mapGetters('asmtMaterials', ['initialized', 'materials']),
    ...mapGetters('asmt', ['assessmentId']),
    dropzoneOptions() {
      return {
        url: IBXService.getMaterialsEndpoint({ assessmentId: this.assessmentId }),
        includeStyling: false,
        paramName: 'material_file',
        // autoProcessQueue: this.permAutoSave, //If false, files will be added to the queue but the queue will not be processed automatically
        // addRemoveLinks: !this.permAutoSave
      }
    },
    getFilesNum() {
      return this.numFiles
    },
    getPlural() {
      return this.numFiles == 1 ? '' : 's'
    },
    booklets() {
      // If new print booklets is enabled then hide the old print booklets
      if (this.featureFlag(FLAG.PRINT_BOOKLETS)) {
        return []
      }
      return [
        { type: 'teacher-edition', label: 'Teacher Booklet', teacherEdition: 1 },
        { type: 'student-edition', label: 'Student Booklet', teacherEdition: 0 },
      ]
    },
  },

  methods: {
    ...mapActions('asmtMaterials', ['fetchMaterials', 'addMaterial', 'removeMaterial']),
    onSuccess(file, response) {
      this.asmtSetDirty(false)
      if (response.material) {
        this.addMaterial(response.material)
      }

      // clear dropzone area
      setTimeout(() => {
        this.$refs.vueDropzone.removeAllFiles()
      }, 2000)
    },
    onError(error) {
      this.asmtSetDirty(false)
      EventBus.$emit(Events.ERROR, { type: CONST.ERROR, error })
    },
    onRemoveMaterial(material) {
      this.removeMaterial(material.materialId)
        .then(() => {
          this.asmtSetDirty(false)
          EventBus.$emit(Events.SNACKBAR, {
            type: 'success',
            text: 'Deleted',
            subtext: `Material ${material.title} has been deleted`,
          })
        })
        .catch(this.onError)
    },
    onDownloadMaterial(material) {
      const uri = `${IBXService.getMaterialsEndpoint({ assessmentId: this.assessmentId })}/${
        material.materialId
      }/download`
      window.open(uri, '_blank')
    },
    onDownloadBooklet(booklet) {
      const uri = `${IBXService.getBookletsEndpoint({ assessmentId: this.assessmentId })}?teacher-edition=${
        booklet.teacherEdition
      }`
      window.open(uri, '_blank')
    },
    onFilesAdded(materials) {
      // materials == list of objects
      this.asmtSetDirty(true)
      this.numFiles = this.$refs.vueDropzone.getQueuedFiles().length
    },
    onFileRemoved(materials) {
      this.numFiles = this.$refs.vueDropzone.getQueuedFiles().length
    },
    // to be used if manually saving materials
    // && autoProcessQueue is false
    // onManualSaveMaterials() {
    //     this.$refs.vueDropzone.processQueue()
    // }
  },

  created() {
    EventBus.$on(Events.ASSESSMENT_MATERIALS, () => (this.show = true))
  },

  mounted() {
    var unwatchLoading = this.$watch('show', (show) => {
      if (show) {
        if (!this.initialized) {
          this.fetchMaterials()
            .then(() => (this.loaded = true))
            .catch(this.onError)
        }
        unwatchLoading()
      }
    })
  },
}
</script>

<style scoped lang="scss">
#uploadModal {
  .subheading {
    font-weight: 600;
    .close {
      cursor: pointer;
      margin-left: auto;
      color: #7e8494;
    }
  }

  #material-dropzone {
    border-style: dashed;
    font-family: 'CerebriSans', Roboto, sans-serif;
    .title {
      font-family: 'CerebriSans', Roboto, sans-serif !important;
    }
  }

  button {
    border-color: #b3b7c5;
    margin-left: 0;
  }

  .material-list {
    font-size: 16px;
    height: 150px;
    overflow: auto;
    padding-right: 15px;

    &.empty {
      color: #c0c3cf;
    }

    .empty-icon {
      margin-right: 10px;
    }

    &__download {
      cursor: pointer;
    }

    &__delete {
      cursor: pointer;
    }

    .booklet-label {
      .v-chip {
        color: white;
        background-color: #ffa200;
        font-weight: bold;
      }
    }
  }

  .v-list__item__action {
    min-width: 30px;
  }

  .v-item__tile__avatar {
    min-width: 25px;
  }

  .materials-queued {
    color: #0ea449;
  }

  .queued-message {
    color: #7e8494;
  }
}
</style>
