<template>
  <v-card class="data-card">
    <v-toolbar dense class="data-list-toolbar">
      <v-toolbar-title class="text-uppercase">{{ listTitle }}</v-toolbar-title>
    </v-toolbar>
    <v-list dense class="data-list">
      <v-list-item class="data-list-item" dense v-for="(item, index) in items" :key="index">
        <v-tooltip
          v-if="title == 'Standards'"
          left
          open-delay="0"
          close-delay="0"
          transition="fade"
          content-class="custom-tooltip"
        >
          <template v-slot:activator="{ on: tooltip }">
            <v-list-item-content v-on="{ ...tooltip }" class="summary-view-item-title">{{
              item.title | truncate
            }}</v-list-item-content>
          </template>
          <ibx-standard-tooltip :standard="getStandard(item)" :loading="!standardDataLoaded" />
        </v-tooltip>
        <v-list-item-content v-else :title="item.title" class="summary-view-item-title">{{
          item.title | truncate
        }}</v-list-item-content>
        <v-list-item-content class="align-end"
          ><v-chip>{{ item.count }}</v-chip></v-list-item-content
        >
      </v-list-item>
      <v-list-item dense v-if="!count">
        <v-list-item-content>{{ emptyText }}</v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import IbxStandardTooltip from '@components/ibx/IbxStandardTooltip'

export default {
  name: 'XQuickDataMode',
  components: { IbxStandardTooltip },
  props: {
    title: { type: String, default: 'Untitled' },
    count: { type: Number, default: 0 },
    items: { type: Array, default: () => [] },
    emptyText: { type: String },
    standards: { type: Array, default: () => [] },
  },
  computed: {
    listTitle() {
      return this.count ? `${this.count} ${this.title}` : this.title
    },
    ...mapGetters('standardsItems', {
      currentStandards: 'items',
    }),
  },
  methods: {
    getStandard(item) {
      return this.currentStandards.find((o) => o.guid == item.guid) || null
    },
    standardDataLoaded() {
      return this.standards.length > 0
    },
  },
  filters: {
    truncate: (text) => {
      return _.truncate(text)
    },
  },
}
</script>

<style lang="scss" scoped>
#summary-view {
  .data-card {
    margin-bottom: 15px;
    border-radius: 0px;
    border: none;
    box-shadow: none;
  }
  .data-list-toolbar {
    margin-bottom: 0px;
    border-bottom: 1px solid #ecedf1;

    .v-toolbar__title {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.8px;
    }
  }
  .data-list {
    padding: 16px 23px 24px 23px;

    .data-list-item {
      height: 30px;
    }

    .v-list-item {
      .v-item__content {
        font-size: 14px;
        line-height: 30px;
      }
    }
  }
}

.summary-view-item-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 215px;
  display: inline;
}
</style>
