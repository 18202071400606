<template>
  <v-btn class="x-btn" :color="color" :outlined="getOutline" v-bind="$attrs" v-on="$listeners">
    <font-awesome-icon v-if="icon" :icon="getIcon" />
    <slot></slot>
    <font-awesome-icon v-if="appendIcon" class="x-btn-append-icon" :icon="getAppendIcon" />
  </v-btn>
</template>

<script>
import { VBtn } from 'vuetify'
import { XThemeColorNames } from '@plugins/XTheme'

const outline = ['secondary', 'secondary-2']

export default {
  name: 'XBtn',
  inheritAttrs: false,

  components: {
    VBtn,
  },

  props: {
    color: { type: String, default: 'primary' }, // theme color
    icon: { type: String, default: null }, // left side icon
    appendIcon: { type: String, default: null }, // right side icon
    iconStyle: { type: String, default: 'regular' }, // fontawesome icon style,
    outline: { type: Boolean, default: false }, // button outlined
    data: { type: null, default: null }, // data to return on click
    on: { default: () => ({}) },
  },

  computed: {
    getOutline() {
      return this.outline || outline.includes(this.color) // some colors default to outline
    },
    getIcon() {
      return [this.getIconsStyle, this.icon]
    },
    getAppendIcon() {
      return [this.getIconsStyle, this.appendIcon]
    },
    getIconsStyle() {
      switch (this.iconStyle) {
        case 'light':
          return 'fal'
        case 'regular':
          return 'far'
        case 'solid':
          return 'fas'
        default:
          return 'fas'
      }
    },
  },

  methods: {
    onClick(event) {
      return this.$emit('click', {
        data: this.data,
        event,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.x-btn.v-btn,
.x-btn.v-btn.theme--light,
.x-btn.v-btn--contained {
  height: 36px;
}

.x-btn.v-btn,
.x-btn.v-btn.theme--light,
.x-btn.v-btn--contained,
.x-btn.v-size--small,
.x-btn.v-size--default {
  $disabled: '--disabled:not(.v-btn--icon):not(.v-btn--flat)';

  &--outlined {
    background-color: transparent !important;
  }

  /* primary  -------------------- */
  &.primary {
    @include btn-colors($N00, $B400);
  }
  &#{$disabled}.primary {
    @include btn-disabled;
  }
  &.branding {
    @include btn-colors($B121, $B121);
  }
  /* secondary  -------------------- */
  &.secondary,
  &.secondary--text {
    @include btn-colors($B300, #e6edff);
  }
  &#{$disabled}.secondary--text {
    @include btn-disabled;
    background: transparent !important;
  }

  /* secondary-2  -------------------- */
  &.secondary-2,
  &.secondary-2--text {
    @include btn-colors(#b3b7c5, $N20);
    .v-btn__content {
      color: black !important;
    }
  }
  &#{$disabled}.secondary--text {
    @include btn-disabled;
    background: transparent !important;
  }
  &.tabular {
    @include btn-colors($N00, $B121);
    background: transparent !important;
    border-radius: 0px;
  }
  /* tertiary  -------------------- */
  &.tertiary {
    @include btn-colors($N130, $N20);
  }
  &#{$disabled}.tertiary {
    @include btn-disabled;
  }

  /* ghost  -------------------- */
  &.ghost {
    @include btn-colors($N130, $N20);
    background: transparent !important;
  }
  &#{$disabled}.ghost {
    @include btn-disabled;
    background: transparent !important;
  }

  /* ghost-pie  -------------------- */
  &.ghost-pie {
    @include btn-colors($B500, #e7edf3);
    background: transparent !important;
  }
  &#{$disabled}.ghost-pie {
    @include btn-disabled;
    background: transparent !important;
  }

  /* danger -------------------- */
  &.danger {
    @include btn-colors($N00, $R400);
  }
  &#{$disabled}.danger {
    @include btn-disabled;
  }

  /* danger -------------------- */
  &.help {
    @include btn-colors($N00, $P400);
  }
  &#{$disabled}.help {
    @include btn-disabled;
  }

  /* success -------------------- */
  &.success {
    @include btn-colors($N00, $G400);
  }
  &#{$disabled}.success {
    @include btn-disabled;
  }

  /* danger -------------------- */
  &.help {
    @include btn-colors($N00, $P400);
  }
  &#{$disabled}.help {
    @include btn-disabled;
  }

  /* warning -------------------- */
  &.warning {
    @include btn-colors($N00, $Y400);
  }
  &#{$disabled}.warning {
    @include btn-disabled;
  }

  /* white -------------------- */
  &.white {
    @include btn-colors($N130, $N10);
  }
  &#{$disabled}.white {
    @include btn-disabled;
  }

  /* passive -------------------- */
  &.passive {
    @include btn-colors($N130, $N30);
  }
  &#{$disabled}.white {
    @include btn-disabled;
  }

  .x-btn-append-icon {
    margin-left: 10px;
    margin-right: 0px;
  }
}

.x-btn.v-size--small {
  height: 32px;
}
</style>
