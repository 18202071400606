
// @ts-nocheck
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { Item } from '@components/ibx/base/Item'
import { mapActions, mapGetters } from 'vuex'
import {
  calculateItemWeight,
  supportsRubric,
  hasRationale,
  rationales,
  correct,
  tagsDisplay,
} from '@helpers/itemHelpers'
import AsmtPrintPlayer from './AsmtPrintPlayer'
import { StateItemConfigs } from '@helpers/state'
import _ from 'lodash'

@Component({
  components: {
    AsmtPrintPlayer,
  },
  methods: {
    ...mapActions('standardsItems', {
      standardItemsGetItems: 'getItems',
    }),
  },
  computed: {
    ...mapGetters('asmt', {
      asmtItems: 'items',
    }),
  },
})
export default class AsmtPrintQuestion extends Mixins(Vue, StateItemConfigs) {
  @Prop({ default: null }) payload: any // question data
  @Prop({ default: 'student' }) role: string

  private mode: string = 'gather'
  private item: Item = {}
  private itemConfig: any = null
  private standardsData: any[] = []

  get itemRevId() {
    return this.payload?.itemRevId
  }

  get questionNum() {
    return this.payload.order + 1
  }

  get itemType() {
    return this.item.itemType
  }

  get itemId() {
    return this.item.itemId
  }

  get revision() {
    return this.item.itemRevId
  }

  get correct() {
    return correct(this.itemConfig)
  }

  get hasRationale() {
    return hasRationale(this.itemConfig)
  }

  get rationales() {
    return rationales(this.itemConfig) || []
  }

  get isRubricItem() {
    return supportsRubric(this.itemType)
  }

  get rubricWeight() {
    return calculateItemWeight(this.itemConfig)
  }
  get hasStandard() {
    return this.standardsData.length
  }

  get playerOptions() {
    return {
      role: this.role,
    }
  }

  get ready() {
    return Boolean(this.itemConfig)
  }

  get remoteIdVersioned() {
    return this.item.remoteIdVersioned
  }

  get getItem() {
    return this.asmtItems.find((data) => data.item.itemId === this.itemId)
  }

  get questionGroups() {
    const item = this.getItem
    return [...item?.autoQuestionGroups, ...item?.customQuestionGroups]
  }

  get tags() {
    const item = this.getItem
    const excludeMetaFields = ['language', 'standard', 'itemType']
    const values = Object.values(item?.item?.meta || {}).filter((o: any): boolean => !excludeMetaFields.includes(o.id))
    return tagsDisplay(values)
  }
  /**
   * Intialize item and set player props
   */
  async init() {
    this.item = new Item(this.payload.item) || {}
    await this.setItemConfig()
    await this.setStandards()
  }

  async setItemConfig() {
    try {
      const { data } = await this.itemConfigsGetItemConfig({ versionedId: this.remoteIdVersioned })
      this.itemConfig = data?.contentItem?.config
    } catch (error) {
      console.warn(error)
    }
  }
  async setStandards() {
    const data = await this.standardItemsGetItems(_.uniq(this.payload.standards))
    this.standardsData = data.map((pio) => pio.code)
  }
  created() {
    this.init()
  }
}
