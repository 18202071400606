/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  Ordering_2: {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M158.91 20.5H33.09c-6.953 0-12.59 5.637-12.59 12.59v125.82c0 6.953 5.637 12.59 12.59 12.59h125.82c6.953 0 12.59-5.637 12.59-12.59V33.09c0-6.953-5.637-12.59-12.59-12.59zM83 45.25h24a8.5 8.5 0 010 17H83a8.5 8.5 0 010-17zm44 42H83a8.5 8.5 0 000 17h44a8.5 8.5 0 000-17zm-78.5 61c-6.075 0-11-4.925-11-11s4.925-11 11-11 11 4.925 11 11-4.925 11-11 11zm-11-52.5c0 6.075 4.925 11 11 11s11-4.925 11-11-4.925-11-11-11-11 4.925-11 11zm11-31c-6.075 0-11-4.925-11-11s4.925-11 11-11 11 4.925 11 11-4.925 11-11 11zm34.5 81h66a8.5 8.5 0 000-17H83a8.5 8.5 0 000 17z" _fill="#152452"/>',
  },
})
