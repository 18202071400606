<template>
  <div class="pie-item">
    <div class="pie-item-holder">
      <!-- <pie-api-player
                :token="token" 
                :item-id="id"
                :initial-mode="initialMode"
                @session-created="onSessionCreated"
                @session-changed="onSessionChanged"
                ref="player">
            </pie-api-player> -->
    </div>

    <!-- TEMP -->
    <loading :items="[50, 50, 50, 50, 50, 50]" :margin="5" />
  </div>
</template>

<script>
import Loading from '@components/ibx/IbxLoadingList'

export default {
  name: 'PieItem',

  components: {
    Loading,
  },

  props: {
    id: { type: [String, Number], required: true },
    token: { type: String, required: true },
    initialMode: { type: String, default: 'preview' },
  },

  data: () => ({
    player: null,
  }),

  methods: {
    onSessionCreated(event) {
      this.loading = false
      this.$emit('loaded')
    },
    onSessionChanged(event) {
      //console.log(`${event.type}: ${JSON.stringify(event.detail)}`)
    },
    changeMode(mode) {
      this.player.setMode(mode)
    },
    score(event) {
      this.player
        .score()
        .then((result) => console.log('score: ', result))
        .catch((e) => console.log('error: ', e))
    },
  },

  mounted() {
    this.player = this.$refs.player
    this.onSessionCreated()
  },
}
</script>

<style lang="scss">
.pie-item {
  display: block;
  min-height: 200px;

  .pie-item-holder {
    display: flex;
    width: 100%;
  }
}
</style>
