<template>
  <v-card-title class="ibx-item-card-header" :class="headerClass" ref="header">
    <div class="ibx-item-card-header__spacer"></div>

    <template v-if="showQuestionLabel">
      <!-- <v-text-field
                class="ibx-item-card-header__itemLabel"
                :value="itemTypeLabel"
                solo
            ></v-text-field> -->
      <!-- <svg
                v-svg
                :symbol="itemTypeIcon"
                class="itemTypeIcon"
            ></svg> -->
    </template>
    <template v-else>
      <svg v-svg :symbol="itemTypeIcon" class="itemTypeIcon"></svg>
      <span class="ibx-item-card__item-type">{{ itemItemTypeLabel }}</span>
    </template>

    <!-- ////// Alignments //////// -->
    <div class="ibx-item-card__alignments">
      <!-- <v-menu
                v-model="standardMenu"
                class="ibx-chip-menu"
                :close-on-content-click="false"
            >
                <v-chip 
                    class="standards" 
                    dark
                    slot="activator"
                >
                    {{ standards.length | pluralize('Standard') }}
                </v-chip>
                <v-card>
                    <div class="ibx-chip-menu__items">
                        <div
                            v-for="(s, i) in standards"
                            :key="i"
                            class="ibx-chip-menu__item ibx-chip-menu__item--selected"      
                        >{{ s | metaName }}
                        </div>
                    </div>
                </v-card>
            </v-menu> -->
      <v-chip class="standards" dark slot="activator">
        {{ itemStandard.items.length | pluralizeString('Standard') }}
      </v-chip>

      <v-chip class="dok">DOK {{ itemDok.items[0].name | itemFilterDokLevel }}</v-chip>

      <v-chip class="Item Bank">
        {{ 1 | pluralizeString('Item Bank') }}
      </v-chip>
    </div>

    <v-spacer></v-spacer>
    <x-btn
      v-if="!noAdd"
      class="ibx-button add-item mr-0"
      :color="isAdded ? 'success' : 'tertiary'"
      @click="addRemoveItem"
      ref="addItem"
    >
      <font-awesome-icon :icon="['fal', isAdded ? 'check' : 'plus']" class="mr-2" />
      <span>{{ !isAdded ? 'Add Item' : 'Item Added' }}</span>
    </x-btn>

    <!-- more menu -->
    <v-menu v-if="optionsMenu.length > 0" bottom offset-y auto transition="slide-y-transition">
      <v-btn class="ibx-item-card-header__more-menu" slot="activator" flat icon lazy>
        <v-icon>more_vert</v-icon>
      </v-btn>
      <v-list>
        <v-list-item v-for="(o, i) in optionsMenu" :key="i" @click="onMoreClick(o.id)">
          <v-list-item-title>{{ o.label }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <div v-if="close" class="ibx-item-card-header__close" @click="$emit('close')">
      <font-awesome-icon :icon="['fal', 'times']" />
    </div>
  </v-card-title>
</template>

<script>
import { AsmtMixin } from '@mixins'
import { ItemMixin } from '@mixins'
import * as VChip from 'vuetify/es5/components/VChip'
import * as VMenu from 'vuetify/es5/components/VMenu'
import * as VList from 'vuetify/es5/components/VList'
import * as VCard from 'vuetify/es5/components/VCard'
import * as VTextField from 'vuetify/es5/components/VTextField'
import { ItemTypeIconMapper } from '@helpers/itemTypeMaps'
import XBtn from '@xLib/XBtn'

export default {
  name: 'IbxItemCardHeader',

  mixins: [AsmtMixin, ItemMixin],

  components: {
    ...VCard,
    ...VChip,
    ...VMenu,
    ...VList,
    ...VTextField,
    XBtn,
  },

  props: {
    headerClass: { type: String, default: 'top' },
    noAdd: { type: Boolean, default: false },
    close: { type: Boolean, default: false },
    optionsMenu: {
      type: Array,
      default: () => [],
      validator: (value) => {
        const isArray = Array.isArray(value)
        let structure = true
        if (isArray && value[0] !== undefined) {
          structure = value[0].id !== undefined && value[0].label !== undefined
        }
        return isArray && structure
      },
    },
    showQuestionLabel: { type: Boolean, default: false },
  },

  data: () => ({
    standardMenu: false,
  }),

  computed: {
    isAdded() {
      return this.asmtItems.some((id) => id === this.itemId)
    },
    itemTypeIcon() {
      return ItemTypeIconMapper(this.itemType)
    },
  },

  methods: {
    addRemoveItem() {
      this.$emit('add-remove-item', {
        fromEl: this.$refs.addItem.$el,
      })
    },
    onMetaToggleItem() {
      if (this.expandable && this.isExpanded) this.expand(true, true, true)
    },
    onMoreClick(id) {
      this.$emit('more-menu-click', id)
    },
  },

  mounted() {
    this.$emit('mounted', this.$refs.header)
  },
}
</script>

<style lang="scss">
@import '@/styles/main';
.ibx-item-card-header {
  position: absolute;
  height: 58px;
  width: 100%;
  background: white;
  border-bottom: 1px solid $N20;
  bottom: 0px;
  font-size: 14px;
  z-index: 3;

  &.sticky {
    box-shadow: 0px 10px 17px -10px rgba(0, 0, 0, 0.2);
    .ibx-item-card-header__spacer {
      display: block;
    }
  }

  &.v-card__title {
    display: flex;
    align-items: center;
    padding: 0px 12px !important;
  }

  &.top {
    top: 0px;
  }
  &.bottom {
    bottom: 0px;
  }

  .ibx-item-card-header__spacer {
    position: absolute;
    display: none;
    height: 20px;
    width: 101%;
    width: calc(100% + 4px);
    left: -2px;
    top: -20px;
    background: $N10;
  }

  .itemTypeIcon {
    width: 30px;
    height: 30px;
    fill: white;
    padding: 2px 2px;
    background-color: $T300;
    border-radius: 5px;
    margin-right: 20px;
  }
  .ibx-item-card__item-type {
    color: $T400;
  }

  .ibx-item-card__alignments {
    margin-left: 20px;

    .v-menu .v-chip {
      margin-right: 0px;
    }

    > *:not(:last-child) {
      margin-right: 10px;
    }
  }

  .add-item {
  }

  &__close {
    color: $N50;
    font-size: 22px;
    cursor: pointer;
  }

  &__more-menu {
    background: none !important;
    margin: 0px -7px 0px 0px !important;

    .v-btn__content {
      color: $N60;
    }
  }

  &____itemLabel {
    width: 30px;
  }
}
</style>
