<template>
  <div class="x-filter-search">
    <v-text-field
      class="x-filter-search__field x-text-field"
      v-model="query"
      label="Search"
      solo
      outlined
      color="#B3B7C5"
      hide-details
      v-bind="$attrs"
      v-stream:keyup.native="keyup$"
    />
  </div>
</template>

<script>
import '@plugins/vue-rx'
import { Subject, empty, timer } from 'rxjs'
import { tap, switchMap, takeUntil } from 'rxjs/operators'
import * as VTextField from 'vuetify/es5/components/VTextField'
import { makeEventBusInjection } from '@events'

export default {
  name: 'XFilterSearch',

  components: {
    ...VTextField,
  },

  inject: {
    ...makeEventBusInjection(),
  },

  props: {
    delay: { type: Number, default: 1000 },
  },

  data: () => ({
    query: '',
  }),

  subscriptions() {
    this.keyup$ = new Subject().pipe(
      tap((_) => this.onKeyup({ event })),
      switchMap(({ event }) => (event.key == 'Enter' ? [event] : empty())),
      tap((_) => this.doSearch())
    )
    this.query$ = this.$watchAsObservable('query')

    return {
      /*
       * debounce side effect (doSearch) by delay length.
       * If there is an emission from enter key other than ENTER don't take.
       * keyup$ will call doSearch on ENTER key
       */
      onQuery: this.query$.pipe(
        switchMap((v) => {
          return timer(this.delay).pipe(takeUntil(this.keyup$))
        }),
        tap((_) => this.doSearch())
      ),
    }
  },

  methods: {
    onKeyup() {
      this.$emit(this.events.FILTER_SEARCHING, true)
    },
    doSearch() {
      this.$emit(this.events.FILTER_SEARCH, this.query)
    },
    clear() {
      this.query = ''
      this.doSearch()
    },
  },

  created() {
    this.eventBus.$on(this.events.FILTER_COLLAPSE, this.clear)
  },

  beforeDestroy() {
    this.eventBus.$off(this.events.FILTER_COLLAPSE, this.clear)
  },
}
</script>

<style lang="scss"></style>
