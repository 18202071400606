
// @ts-nocheck
import { mapActions, mapGetters } from 'vuex'
import * as VCheckbox from 'vuetify/es5/components/VCheckbox'

// new
import { Vue, Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import { StateAuthorItems, StateAuth, StateBrowseFilters } from '@/helpers/state'
import ItemAuthorBase from '@components/itemAuthor/base/ItemAuthorBase'

type meta = {
  id: string
  items: any[]
  name: string
}

@Component({
  components: {
    ...VCheckbox,
  },
  methods: {
    ...mapActions('itemAuthor', ['setUpdated']),
  },
})
export default class ItemAuthorPlayer extends Mixins(
  Vue,
  ItemAuthorBase,
  StateAuthorItems,
  StateAuth,
  StateBrowseFilters
) {
  get dokOptions(): any {
    const dok = this.browseFilters.find((o) => o.id == 'depth_of_knowledge') || { items: [] }

    return dok.items
  }

  get difficultyOptions(): any {
    const difficulty = this.browseFilters.find((o) => o.id == 'difficulty') || { items: [] }
    return difficulty.items
  }

  get revisedBloomOptions(): any {
    const revisedBloom = this.browseFilters.find((o) => o.id == 'revised_blooms_taxonomy') || { items: [] }
    return revisedBloom.items
  }

  get languageOptions(): any {
    const language = this.browseFilters.find((o) => o.id == 'language') || { items: [] }
    return language.items
  }

  get dok(): any {
    return this?.item?.getMetaId('depth_of_knowledge')
  }

  get diffuculty(): any {
    return this?.item?.getMetaId('difficulty')
  }
  get bloom(): any {
    return this?.item?.getMetaId('revised_blooms_taxonomy')
  }
  get language(): any {
    return this?.item?.getMetaId('language')
  }

  /**
   * On tags/meta change
   */
  change({ key, value }: { key: string; value: string | number }) {
    this.$emit('change', [
      {
        itemId: this.itemId,
        key,
        value,
      },
    ])
  }
}
