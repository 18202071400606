<template>
  <div class="imgSelector-modal" v-if="show">
    <div class="modal-content">
      <header class="tab-header"></header>
      <div>
        <vue-dropzone
          ref="myVueDropzone"
          id="dropzone"
          @vdropzone-removed-file="onFileRemoved"
          @vdropzone-file-added="onFileAdded"
          :options="dropzoneOptions"
          :useCustomSlot="true"
        >
          <v-img :src="materialImg" height="123" contain></v-img>
          <br />
          <div class="title">Drag an image here</div>
          <span class="message">Or click to upload an image from your computer</span>
        </vue-dropzone>
      </div>
      <footer class="modal-footer">
        <x-btn style="margin: 0" @click="insertImage" :disabled="!allowImageAdd">Add Image</x-btn>
        <x-btn @click="show = false" color="tertiary">Cancel</x-btn>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PIEService from '@services/PIEService'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import materialImg from '@/assets/svg/Material_Upload.svg'
import { Events, EventBus } from '@events'
import CONST from '@constants'

export default {
  name: 'TextEditorImageSelector',
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      imageSrc: '',
      command: null,
      show: false,
      dropzoneOptions: {
        url: 'url',
        maxFilesize: 20, // 20MB
        maxThumbnailFilesize: 20, // 20MB
        thumbnailWidth: 200,
        addRemoveLinks: true,
        acceptedFiles: 'image/*', //accept all image mime types
        uploadMultiple: false,
        maxFiles: 1,
        method: 'PUT',
        autoProcessQueue: false,
        sending: this.onDropzoneSending,
        maxfilesexceeded: this.onMaxfilesexceeded,
      },
      materialImg: materialImg,
      allowImageAdd: false,
    }
  },
  computed: {
    ...mapGetters('auth', {
      authPieToken: 'pieToken',
    }),
  },
  methods: {
    onFileAdded(file) {
      if (this.$refs.myVueDropzone.getAcceptedFiles().length > 0) {
        return
      }

      let data = this.requestSignedUrl(file)
      data.then(
        (uploadObj) => {
          this.$refs.myVueDropzone.setOption('url', uploadObj.registerUploadRequest.signedURL)
          this.$refs.myVueDropzone.processQueue()
          this.imageSrc = uploadObj.registerUploadRequest.publicURL
        },
        (error) => {
          EventBus.$emit(Events.ERROR, { type: CONST.ERROR, error })
        }
      )
    },

    onDropzoneSending: function (file, xhr) {
      let thisComponent = this
      let _send = xhr.send

      xhr.onerror = function (error) {
        EventBus.$emit(Events.ERROR, { type: CONST.ERROR, error })
      }
      xhr.addEventListener('readystatechange', function () {
        if (this.readyState === 4) {
          thisComponent.allowImageAdd = true
        }
      })

      xhr.send = function () {
        _send.call(xhr, file)
      }
    },

    onMaxfilesexceeded: function (file) {
      console.warn('--onMaxfilesexceeded--')
      this.$refs.myVueDropzone.removeFile(file)

      EventBus.$emit(Events.SNACKBAR, {
        type: 'error',
        text: 'Only one image can be added at a time',
        timeout: 10000,
      })
    },

    async requestSignedUrl(file) {
      let contentType = file.type
      let query = `
            mutation {
                registerUploadRequest(contentType: "${contentType}") {
                    id
                    signedURL
                    publicURL
                }
            }
            `
      return await PIEService.query({
        query: query,
      })
    },

    getPassageData() {
      this.$refs.editor.getPassageData()
    },

    showModal(command) {
      // Add the sent command
      this.command = command
      this.show = true
    },

    insertImage() {
      const data = {
        command: this.command,
        data: {
          src: this.imageSrc,
        },
      }

      this.$emit('onConfirm', data)
      this.closeModal()
    },

    onFileRemoved() {
      if (this.$refs.myVueDropzone.getAcceptedFiles().length != 1) {
        this.allowImageAdd = false
      }
    },

    closeModal() {
      this.show = false
      this.imageSrc = ''
      this.allowImageAdd = false
    },
  },
}
</script>

<style lang="scss">
.imgSelector-modal {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;

  .modal-content {
    width: 90%;
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    margin: 10px 0;
  }

  .modal-footer {
    margin-top: 10px;
  }

  label {
    display: block;
    margin: 0.25em 0;
  }

  button {
    font-family: inherit;
    font-size: 100%;
    padding: 0.5em 1em;
    color: white;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    border: 1px solid #999;
    border: transparent;
    background-color: #e6e6e6;
    text-decoration: none;
    border-radius: 2px;
    cursor: pointer;
  }

  button.danger {
    background: rgb(202, 60, 60);
  }

  button.success {
    background: rgb(28, 184, 65);
  }

  button:disabled {
    opacity: 0.3;
  }

  button + button {
    margin-left: 10px;
  }

  .tab-header {
    display: flex;
    align-items: center;
  }

  .tab-header button {
    color: #222;
    background: none;
    border: 0;
    flex: 1;
    padding: 5px 10px;
    cursor: pointer;
  }

  .tab-header button.active {
    background-color: #222;
    color: #fff;
  }

  #dropzone {
    border-style: dashed;
    margin-bottom: 24px;
  }

  #dropzone,
  .title {
    font-family: 'CerebriSans', Roboto, sans-serif !important;
  }

  .title {
    margin-bottom: 8px;
  }

  .message {
    line-height: 1em;
  }
}
</style>
