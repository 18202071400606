import { StoreOptions } from 'vuex'
// import { config } from '@/clouseau/pie/models'
import _ from 'lodash'

class State {
  playerType: string = 'pie-player'
  itemRemoteId: string
  sessionId: string = null
  sessionOrId: string = 'id'
  assignmentId: string = null
  authorMode: string = 'new'
  authorItemType: string = 'multiple_choice'
  contentType: string = 'item'
}

const clouseau = {
  namespaced: true,

  state: new State(),

  getters: {
    playerType: (state: State): string => state.playerType,
    itemRemoteId: (state: State): string => state.itemRemoteId,
    sessionId: (state: State): string => state.sessionId,
    sessionOrId: (state: State): string => state.sessionOrId,
    assignmentId: (state: State): string => state.assignmentId,
    authorMode: (state: State): string => state.authorMode,
    authorItemType: (state: State): string => state.authorItemType,
    contentType: (state: State): string => state.contentType,
    authorConfig: (): any => {
      //_.cloneDeep(config)
    },
  },

  mutations: {
    setPlayerType: (state: State, value: string) => {
      state.playerType = value
    },
    setItemRemoteId: (state: State, value: string) => {
      state.itemRemoteId = value
    },
    setSessionId: (state: State, value: string) => {
      state.sessionId = value
    },
    setSessionOrId: (state: State, value: string) => {
      state.sessionOrId = value
    },
    setAssignmentId: (state: State, value: string) => {
      state.assignmentId = value
    },
    setAuthorMode: (state: State, value: string) => {
      state.authorMode = value
    },
    setAuthorItemType: (state: State, value: string) => {
      state.authorItemType = value
    },
    setContentType: (state: State, value: string) => {
      state.contentType = value
    },
  },

  actions: {},
} as StoreOptions<State>

export { clouseau, State }
