<template>
  <v-dialog class="ibx-report-success-modal" v-model="showDialog" persistent max-width="600">
    <v-card class="ibx-report-success-modal__content">
      <v-card-title class="headline">
        Issue Submitted
        <v-spacer></v-spacer>
        <font-awesome-icon :icon="['fal', 'times']" class="icon close-discard-dialog" @click="showDialog = false" />
      </v-card-title>
      <v-card-text> Thank you for reporting this issue.</v-card-text>

      <v-card-text
        >You will receive an email confirming your submission, and someone will get back with you once resolved.
      </v-card-text>
      <v-card-actions class="item-author-confirm-dialog__footer">
        <v-spacer></v-spacer>
        <x-btn @click="showDialog = false">Close</x-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as VDialog from 'vuetify/es5/components/VDialog'
import { Events, EventBus } from '@events'

export default {
  name: 'IbxReportSuccessModal',

  components: {
    ...VDialog,
  },
  data: () => ({
    showDialog: false,
  }),

  methods: {
    init() {
      this.showDialog = true
    },
  },

  created() {
    EventBus.$on('success_modal', this.init)
  },
}
</script>
