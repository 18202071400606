import Vue from 'vue'
import { IBXService } from '@services/ibx/IBXService'
import { StoreOptions } from 'vuex'
import hash from 'object-hash'
import _ from 'lodash'
import { PieItemController } from '@illuminateeducation/illuminate-components-assessments'

interface IMetadata {
  count: number
  paging: any
  sort: any
  filter: any
}
interface IState {
  initialzed: boolean
  loading: boolean
  items: object[]
  metadata: IMetadata
  filtersHash: string | null
  passageItems: object[] // stores passage item data when viewing a passage in browse
  stale: boolean // items data has gone stale due actions outise of browse
}
class State implements IState {
  initialzed: boolean = false
  loading: boolean = true
  items: object[] = []
  metadata: IMetadata = { count: 0, paging: {}, sort: null, filter: {} }
  filtersHash: string | null = null
  passageItems: object[] = []
  stale: boolean = false
}
type funcFindItemsData = (itemRevIds: string[] | number[]) => object[]

let pieItemController: any

/**
 * Faux Delay used for debugging async calls
 * @param time delay time
 */
const fauxDelay = (time: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(null)
    }, time)
  })
}

const browseItems = {
  namespaced: true,

  state: new State(),

  getters: {
    initialized: (state: IState): boolean => state.initialzed,
    loading: (state: IState): boolean => state.loading,
    items: (state: IState): object[] => state.items,
    metadata: (state: IState): object => state.metadata,
    currentPage: (state: IState): number => {
      return state.metadata !== undefined && state.metadata.paging !== undefined ? state.metadata.paging.current : 1
    },
    totalItems: (state: IState): number => {
      return state?.metadata?.count || 0
    },
    filtersHash: (state: IState): string | null => state.filtersHash,
    passageItems: (state: IState): object[] => state.passageItems,
    getItemsData: (state: IState): funcFindItemsData => {
      return (itemRevIds: Array<any>): object[] => {
        const itemsData: object[] = state.items.filter((o: any) => itemRevIds.includes(o.itemRevId))
        const passageItemsData: object[] = state.passageItems.filter((o: any) => itemRevIds.includes(o.itemRevId))
        return _.uniqBy([...itemsData, ...passageItemsData], 'itemRevId')
      }
    },
    stale: (state: IState): boolean => state.stale,
  },

  mutations: {
    setInitialized: (state: IState, value: boolean) => {
      state.initialzed = value
    },
    setLoading: (state: IState, value: boolean) => {
      state.loading = value
    },
    setItems: (state: IState, value: object[] = []) => {
      state.items = value
    },
    setMetadata: (state: IState, value: object = {}) => {
      Vue.set(state, 'metadata', value)
    },
    setCurrentPage: (state: IState, value: number) => {
      Vue.set(state.metadata.paging, 'current', value)
    },
    setFiltersHash: (state: IState, value: Object) => {
      state.filtersHash = hash(value)
    },
    setPassageItems: (state: IState, value: object[] = []) => {
      state.passageItems = value
    },
    setStale: (state: IState, value: boolean = false) => {
      state.stale = value
    },
  },

  actions: {
    init: async ({ commit }, { pieToken, pieApiUrl }) => {
      pieItemController = new PieItemController({
        token: pieToken,
        apiUrl: pieApiUrl,
      })
      commit('setInitialized', true)
    },
    setLoading: async ({ commit }, value = true) => {
      commit('setLoading', value)
    },

    /**
     * Fetch items
     * Keeps track of async calls by hashing request filters
     * After each async response we check the has to make sure it's the latest
     * @param filters object { <filter-key>: [array-of-values] }
     */
    fetchItems: async ({ commit, getters, dispatch }, filters: any = {}) => {
      commit('setLoading', false)
      try {
        commit('setLoading', true)
        commit('setFiltersHash', filters)
        commit('setStale', false)

        // cancel previous requests
        IBXService.cancel('items')
        dispatch('itemConfigs/cancelFetchItemsConfigs', null, { root: true })
        dispatch('itemConfigs/cancelFetchPassageConfigs', null, { root: true })

        let { items, metadata, params } = await IBXService.items({
          ...filters,
          requestHash: getters.filtersHash,
        })
        const responseHash = params.filters.requestHash

        // check for latest request and fetch configs if true
        if (responseHash == getters.filtersHash) {
          // prepare item and passage ids for GraphQL
          const versionedIds = items.reduce(
            (m: any, o: any) => {
              if (o.passage) {
                m.passages.push({ key: `_${o.passage.passageRevId}`, id: o.passage.remoteId })
              } else {
                m.items.push({ key: `_${o.itemRevId}`, id: o.remoteIdVersioned })
              }
              return m
            },
            { items: [], passages: [] }
          )

          // fetch configs for items and passages
          await Promise.all([
            dispatch('itemConfigs/fetchItemConfigs', versionedIds.items, { root: true }),
            dispatch('itemConfigs/fetchPassageConfigs', versionedIds.passages, { root: true }),
          ])
          if (responseHash == getters.filtersHash) {
            commit('setItems', items)
            commit('setMetadata', metadata)
          }
        }
        commit('setLoading', false)
      } catch (error: any) {
        if (error?.message?.cancel) {
          return Promise.resolve()
        }
        return Promise.reject(error)
      }
    },
    setCurrentPage: async ({ commit }, value: number) => {
      commit('setCurrentPage', value)
    },
    setStale: async ({ commit }, value: boolean) => {
      commit('setStale', value)
    },
  },
} as StoreOptions<IState>

export { browseItems, IState }
