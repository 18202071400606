/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  List: {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<rect pid="0" width="14" height="2" rx=".5" _fill="#0049FF" fill-opacity=".3"/><rect pid="1" y="12" width="14" height="2" rx=".5" _fill="#0049FF" fill-opacity=".3"/><rect pid="2" y="4" width="14" height="2" rx=".5" _fill="#0049FF" fill-opacity=".3"/><rect pid="3" y="8" width="14" height="2" rx=".5" _fill="#0049FF" fill-opacity=".3"/>',
  },
})
