import { makeDebouncedQueue } from './debouncedQueue'
import { makeOhSnap } from './ohSnap'
import { AsyncQueue } from './asyncQueue'
import { AutoBind } from './decorators'
import { getMoment } from './moment'
import { ibxItemTypes } from '@components/itemAuthor/configs/itemConfigs'

const pushUnique = (arr = [], value) => {
  if (!value) return arr

  const unique = new Set([...arr])
  unique.add(value)
  return [...unique]
}

const mapToObject = (data = new Map()) => {
  const o = {}
  data.forEach((v, k) => (o[k] = v))
  return o
}

const pluralizeString = (count, str) => {
  return count > 1 ? `${count} ${str}s` : `${count} ${str}`
}

const itemTypesMap = ibxItemTypes.reduce((m, o) => {
  m[o.id] = o.name
  return m
}, {})

const getItemTypeQuestionGroupName = (itemType) => {
  return `Item Type: ${itemTypesMap[itemType]}`
}

const wait = (ms = 0) => {
  return new Promise((res) => setTimeout(() => res(true), ms))
}

export {
  pushUnique,
  mapToObject,
  makeDebouncedQueue,
  makeOhSnap,
  pluralizeString,
  AsyncQueue,
  AutoBind,
  getMoment,
  itemTypesMap,
  getItemTypeQuestionGroupName,
  wait,
}
