import { datadogLogs } from '@datadog/browser-logs'

export const initDatadog = () => {
  if (process.env.VUE_APP_DATADOG_CLIENT_RELEASE == 'production' && process.env.VUE_APP_DATADOG_CLIENT_TOKEN) {
    datadogLogs.init({
      clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
      env: process.env.NODE_ENV,
      service: process.env.VUE_APP_DATADOG_CLIENT_SERVICE,
      forwardErrorsToLogs: true,
    })
  }
}
