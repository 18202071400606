
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'
import * as VDialog from 'vuetify/es5/components/VDialog'

@Component({
  components: {
    ...VDialog,
  },
})
export default class ItemAuthorDiscardDialog extends Mixins(Vue) {
  @Prop({ default: false }) show: boolean
  @Prop({ default: null }) action: string
  @Prop({ default: false }) disabled: boolean

  get discardBtnAttrs() {
    return {
      'background-color': '#F02D1F',
      color: '#FFFFFF',
    }
  }
  get cancelBtnAttrs() {
    return {
      'background-color': '#FFFFFF',
      color: '#050F2D',
    }
  }

  cancel() {
    if (!this.disabled) this.$emit('cancel')
  }

  close() {
    if (!this.disabled) this.$emit('close')
  }

  confirm() {
    if (!this.disabled) this.$emit('confirm', this.action)
  }
}
