import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'
import XTheme from '@plugins/XTheme'
import {
  VApp,
  VNavigationDrawer,
  VFooter,
  VList,
  VBtn,
  VIcon,
  VToolbar,
  VSelect,
  VMenu,
  VCard,
  VTextField,
  VChip,
  VSubheader,
  VCheckbox,
  VDialog,
  VTabs,
  VDatePicker,
  VSwitch,
  VBadge,
  VTooltip,
  VImg,
} from 'vuetify/lib'

const opts = {
  icons: {
    iconfont: 'md',
  },
  theme: {
    light: true,
    themes: {
      light: XTheme,
      dark: XTheme,
    },
  },
  components: {
    VApp,
    VNavigationDrawer,
    VFooter,
    VList,
    VBtn,
    VIcon,
    VToolbar,
    VSelect,
    VMenu,
    VCard,
    VChip,
    VTextField,
    VSubheader,
    VCheckbox,
    VDialog,
    VTabs,
    VDatePicker,
    VSwitch,
    VBadge,
    VTooltip,
    VImg,
  },
}

Vue.use(Vuetify)

export default new Vuetify(opts)
