<template>
  <v-dialog
    class="ibx-assessment-description-modal"
    v-model="showDialog"
    persistent
    max-width="500"
    @click:outside="showDialog = false"
  >
    <v-card class="x-assessment-description-modal__content">
      <v-card-title class="headline">Duplicating Your Assessment</v-card-title>
      <v-card-text>
        Duplicating your assessment will create an editable draft. Uploaded assessment materials are not duplicated. We
        recommend reviewing all questions on your draft before publishing.
      </v-card-text>
      <v-card-text>
        <div class="info-note">
          <i class="fa fa-exclamation-circle"></i>
          After clicking Duplicate, you will be taken into your draft assessment.
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <x-btn color="primary" :disabled="isDisabled" @click="duplicateAssessment">Duplicate</x-btn>
        <x-btn color="ghost" @click="showDialog = false">Cancel</x-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { EventBus, Events } from '@events'
import { CONST } from '@constants'
import IBXService from '@services/ibx/IBXService'
import { AsmtMixin } from '@mixins'

export default {
  name: 'IbxDuplicate',

  mixins: [AsmtMixin],

  data: () => ({
    showDialog: false,
    isDisabled: false,
  }),

  methods: {
    duplicateAssessment() {
      this.isDisabled = true
      IBXService.assessmentDuplicate(this.asmtId)
        .then((resp) => {
          this.$router.push(`/assessment/${resp.assessment.assessmentId}`)
          this.showDialog = false
          this.isDisabled = false
          EventBus.$emit(Events.SNACKBAR, {
            type: 'success',
            text: 'Assessment Duplicated Sucessfully',
            timeout: 4000,
          })
        })
        .catch((error) => {
          this.isDisabled = false
          EventBus.$emit(Events.ERROR, {
            type: CONST.ERROR,
            timeout: 4000,
            error,
            text: 'Failed to Duplicate Assessment. Please try again.',
          })
        })
    },
  },

  created() {
    EventBus.$on(Events.ASSESSMENT_DUPLICATE, () => (this.showDialog = true))
  },
}
</script>
