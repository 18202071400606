/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  Charting_2: {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<path pid="0" _fill="#fff" d="M0 0h192v192H0z"/><rect pid="1" x="20.5" y="20.5" width="151" height="151" rx="12.59" _fill="#152452"/><rect pid="2" x="143.28" y="151" width="19.91" height="75.86" rx="9.955" transform="rotate(180 143.28 151)" _fill="#fff"/><rect pid="3" x="106.3" y="151" width="19.91" height="39.83" rx="9.955" transform="rotate(180 106.3 151)" _fill="#fff"/><rect pid="4" x="69.31" y="151" width="19.91" height="52.16" rx="9.955" transform="rotate(180 69.31 151)" _fill="#fff"/><path pid="5" fill-rule="evenodd" clip-rule="evenodd" d="M143.44 51c-.028 5.503-4.497 9.95-10 9.95a9.85 9.85 0 01-6.47-2.4L103.5 79.12a9.95 9.95 0 01-8 15.93c-5.523 0-10-4.477-10-10 .003-.492.037-.983.1-1.47l-17.16-4.5a9.94 9.94 0 111-4.42 10.764 10.764 0 01-.14 1.71l17.22 4.53a9.94 9.94 0 0115-3.79l23.65-20.73A10.001 10.001 0 11143.44 51z" _fill="#fff"/>',
  },
})
