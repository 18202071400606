/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'Hot Spot_2': {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M171.5 33.09v125.82c0 6.953-5.637 12.59-12.59 12.59H33.09c-6.953 0-12.59-5.637-12.59-12.59V33.09c0-6.953 5.637-12.59 12.59-12.59h125.82c6.953 0 12.59 5.637 12.59 12.59z" _fill="#152452"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M171.5 158.86a12.59 12.59 0 01-5 10.05l-38.23-40-26.93 31.57a5.232 5.232 0 01-4.16 1.86 5.31 5.31 0 01-4.06-2l-46.37-59.89-26.25 39.46v-19.13L42 88.44a5.3 5.3 0 018.6-.31l46.9 60.56 26.5-31.08a5.29 5.29 0 017.86-.22l39.64 41.47z" _fill="#fff"/><rect pid="2" x="81.5" y="43" width="67" height="43.57" rx="7.39" _fill="#fff"/>',
  },
})
