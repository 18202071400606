const WindowScrollSpyMixin = {
  props: {
    scrollY_threshold: { type: Number, default: 0 },
  },

  data() {
    return {
      scrollY: 0,
    }
  },

  computed: {
    eventHorizon() {
      return this.scrollY > this.scrollY_threshold
    },
  },

  methods: {
    onScroll(evt, el) {
      this.scrollY = window.scrollY
    },
  },
}

export { WindowScrollSpyMixin }
