<template>
  <v-card class="x-content-card" :class="classes" v-bind="$attrs" v-on="$listeners" ref="cardEl">
    <v-card-title class="x-content-card__header" :style="headerStyles">
      <slot name="card-header">
        <x-content-card-header
          v-bind="$attrs"
          v-on="$listeners"
          :view="view"
          :dragClass="dragClass"
          @close-passage-card="closePassageCard"
        >
          <slot name="header-pre-title" slot="pre-title"></slot>
          <slot name="header-actions" slot="actions"></slot>
          <slot name="auxilliary-actions" slot="actions"></slot>
        </x-content-card-header>
      </slot>
    </v-card-title>

    <v-card-text
      class="x-content-card__content"
      ref="content"
      :style="contentStyles"
      :class="contentClasses"
      v-show="view != 'close'"
    >
      <div class="x-content-card__content__drag" v-if="drag">
        <div :class="dragClass">
          <v-tooltip
            color="#050F2D"
            bottom
            open-delay="0"
            close-delay="0"
            content-class="custom-tooltip"
            transition="none"
          >
            <template v-slot:activator="{ on }">
              <div class="drag-icon" v-on="on">
                <font-awesome-icon :icon="['far', 'ellipsis-v']" class="icon" />
                <font-awesome-icon :icon="['far', 'ellipsis-v']" class="icon" />
              </div>
            </template>
            <span><strong>Drag</strong> to move</span>
          </v-tooltip>
        </div>
      </div>

      <div class="x-content-card__content_container" ref="container">
        <div v-if="load">
          <slot name="content" v-bind="getContentScope"></slot>
        </div>
        <div class="loading" v-if="showLoading">Loading Content...</div>
      </div>
      <v-divider v-show="!showLoading && isExpanded"></v-divider>
      <div class="x-content-card__meta-data_container" ref="metaData">
        <div v-if="!showLoading && isExpanded">
          <slot name="meta-data"></slot>
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="x-content-card__footer" v-if="!noFooter && view != 'close'">
      <div class="x-content-card__footer__controls">
        <x-btn color="ghost" @click="onExpandClick">
          <font-awesome-icon :icon="['fal', expandIcon]" />{{ expandLabel }}
        </x-btn>
        <x-btn v-if="fullscreen" color="ghost" @click="onFullScreenClick">
          <font-awesome-icon :icon="['fal', 'expand']" />Fullscreen
        </x-btn>
      </div>
      <!-- <slot name="footer-auxilliary">...Footer Aux...</slot> -->
    </v-card-actions>
  </v-card>
</template>

<script>
import * as VCard from 'vuetify/es5/components/VCard'
import * as VDivider from 'vuetify/es5/components/VDivider'
import * as VTooltip from 'vuetify/es5/components/VTooltip'
import XContentCardHeader from '@xLib/XContentCard/XContentCardHeader'
import XBtn from '@xLib/XBtn'
import lazyLoadMixin from '@mixins/lazyLoadMixin'
import anime from 'animejs'
import { Events } from '@events'

const ViewModes = {
  COLLAPSE: 'collapse',
  EXPAND: 'expand',
  CLOSE: 'close',
}

export default {
  name: 'XContentCard',

  mixins: [lazyLoadMixin],

  components: {
    ...VCard,
    ...VDivider,
    ...VTooltip,
    XContentCardHeader,
    XBtn,
  },

  props: {
    index: { type: Number, default: null },
    data: { type: null, default: null }, // data for content slot scope
    view: { type: String, default: ViewModes.COLLAPSE }, // expanded/collapsed state
    minHeight: { type: Number, default: 80 }, // collpased min height
    asyncContent: { type: Boolean, default: false }, // async content flag
    noAnimation: { type: Boolean, default: false }, // no expand animation
    noFooter: { type: Boolean, default: false }, // do not render footer
    heightAuto: { type: Boolean, default: false }, // full height
    stickyHeader: { type: Boolean, default: false }, // sticky header (relative card)
    stickyThreshold: { type: Number, default: 0 }, // where the sticky header should stick
    drag: { type: Boolean, default: false }, // show drag handle
    fullscreen: { type: Boolean, default: true }, // show fullscreen button
    dragClass: { type: String, default: 'drag-handle' }, // drag class
  },
  data: () => ({
    viewMode: ViewModes.COLLAPSE, // expanded/collapsed state
    load: false, // load content (for lazy load)
    asyncLoaded: false, // async content loaded
  }),

  computed: {
    isExpanded() {
      return this.viewMode == ViewModes.EXPAND
    },
    expandLabel() {
      return this.isExpanded ? 'Show Less' : 'Show More'
    },
    getContentScope() {
      return {
        data: this.data,
        load: this.load,
        onAsyncContentLoaded: this.onAsyncContentLoaded,
      }
    },
    showLoading() {
      return !this.load || (this.asyncContent && !this.asyncLoaded)
    },
    contentStyles() {
      const paddingTop = this.viewMode == ViewModes.CLOSE ? '0' : '20'
      return this.heightAuto
        ? { height: 'auto' }
        : {
            height: `${this.minHeight}px`,
            'padding-top': `${paddingTop}px`,
          }
    },
    contentClasses() {
      return this.drag ? ['x-content-card__content--has-drag'] : []
    },
    classes() {
      return this.stickyHeader ? ['x-content-card--sticky-header'] : []
    },
    headerStyles() {
      return this.stickyHeader ? { top: `${this.stickyThreshold}px` } : {}
    },
    expandIcon() {
      return this.isExpanded ? 'chevron-up' : 'chevron-down'
    },
  },

  watch: {
    view(v) {
      this.viewMode = v
    },
    viewMode(mode) {
      this.expand(mode)
    },
  },

  methods: {
    lazyLoad() {
      this.load = true
    },
    onExpandClick() {
      this.viewMode = this.isExpanded ? ViewModes.COLLAPSE : ViewModes.EXPAND
      this.isActive = !this.isActive
    },
    expand(mode, onComponentUpdate = false) {
      if (this.heightAuto) return
      const contentEl = this.$refs.content
      const cardEl = this.$refs.cardEl.$el
      const height =
        mode == ViewModes.EXPAND
          ? '100%' //containerRect.height + 40
          : mode == ViewModes.CLOSE
          ? 0
          : `${this.minHeight}px`
      const seconds = 0.5
      const speed = 650 / 0.5 //pixels/seconds (ideal speed)
      let duration = (height / speed) * 1000 // linear growth
      duration = Math.max(750, duration)
      duration = Math.min(duration, 1500)

      // NOTES: might do better animation later
      const ani = anime({
        duration: 0,
        targets: contentEl,
        height: height,
        complete: () => {
          if (!onComponentUpdate && mode == ViewModes.COLLAPSE) {
            this.$emit('show-less', this.index)
          }
        },
      })
    },
    onAsyncContentLoaded() {
      // WE NEED A BETTER HOOK FROM PIE WHEN THE ITEM IS ACTUALLY RENDERED
      this.$nextTick(() => {
        this.asyncLoaded = true
        this.viewMode = this.view
      })
    },
    onFullScreenClick() {
      this.$emit(Events.FULL_SCREEN)
    },
    closePassageCard() {
      this.$emit('close-passage-card')
    },
  },

  mounted() {
    if (!this.asyncContent) this.viewMode = this.view
  },

  updated() {
    // TODO: on drag and drop udpate viewMode to use correct value
    //this.viewMode = this.view
    // set card expand when data/component is udpated
    this.expand(this.viewMode, true)
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.x-content-card {
  border-radius: 5px;

  &--sticky-header {
    z-index: 1;
    border-top: none !important;

    .x-content-card__header {
      top: 0;
      width: 100%;
      z-index: 1;
      background: white;
      border-top: 1px solid $N20;
    }
  }

  &__header,
  &__header.v-card__title {
    padding: 12px 16px !important;
  }

  &__content {
    padding: 20px;
    overflow: hidden;
    display: block;
    position: relative;
    transition: padding-top 0.25s ease-out;

    &--has-drag {
      padding-left: 36px !important;
    }

    &__drag {
      position: absolute;
      width: 30px;
      height: 100%;
      top: 0px;
      left: 0px;

      .drag-handle,
      [class^='drag-handle'] {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: $N30;
        width: 100%;
        height: 100%;

        .drag-icon {
          &:hover {
            background: $N10;
            border-radius: 5px;
            padding: 0px 4px;
          }
          cursor: grab;
          margin-top: 0px;
        }
      }
    }
  }

  &__content_container {
    display: block;
  }

  &__meta-data_container {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  &__footer {
    padding: 12px 16px !important;
  }

  &__footer__controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  p {
    font-family: 'CerebriSans', Roboto, sans-serif;
    font-size: 14px;
  }

  .choice {
    p {
      margin-bottom: 0px;
    }
  }

  .ibx-passage {
    img {
      max-width: 50%;
    }
  }
}

.mockContent {
  position: relative;
  height: 800px;
  background: palevioletred;

  .test {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 20px;
    height: 20px;
    background: lawngreen;
  }
}

.rotate {
  transform: rotate(180deg);
  transition: 0.3s ease all;
}

.loading {
  margin-top: 16px;
  font-family: 'CerebriSans', Roboto, sans-serif;
}

.ibx-item {
  margin: 0px;
}

.ibx-passage > pie-player > pie-passage > div > div {
  padding: 16px 12px !important;
}

.x-content-card__content_container {
  padding: 0 24px;
  overflow-x: auto;
}

.v-item__tile__title {
  font-family: 'CerebriSans', Roboto, sans-serif !important;
  font-size: 14px;
}
</style>
