<template>
  <v-layout class="ibx-warning-modal" row justify-center>
    <v-dialog v-model="showDialog" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">
          {{ title }}
          <v-spacer></v-spacer>
          <font-awesome-icon :icon="['fal', 'times']" class="icon close-discard-dialog" @click="showDialog = false" />
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :style="cancelBtnAttrs" text @click="showDialog = false">Cancel</v-btn>
          <v-btn :style="discardBtnAttrs" light text @click="$emit('exit', { id, data }), (showDialog = false)">{{
            btnLabel
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import * as VDialog from 'vuetify/es5/components/VDialog'
import { mapGetters } from 'vuex'
import { EventBus, Events } from '@events'

export default {
  name: 'IbxWarningModal',

  components: {
    ...VDialog,
  },

  data: () => ({
    showDialog: false,
    title: '',
    message: '',
    btnLabel: '',
    data: null,
    id: '',
  }),

  computed: {
    discardBtnAttrs() {
      return {
        'background-color': '#F02D1F',
        color: '#FFFFFF',
      }
    },
    cancelBtnAttrs() {
      return {
        'background-color': '#FFFFFF',
        color: '#050F2D',
      }
    },
    dialogBtnAttrs() {
      return {
        'background-color': '#FFFFFF',
        color: '#050F2D',
      }
    },
  },
  methods: {
    init({ id, data, title, message, btnLabel }) {
      this.id = id
      this.data = data
      this.title = title
      this.message = message
      this.btnLabel = btnLabel

      this.showDialog = true
    },
  },
  created() {
    EventBus.$on(Events.WARNING_SHOW, this.init)
  },
}
</script>

<style lang="scss" scoped>
.ibx-warning-modal {
  .activator-btn.v-btn {
    color: #050f2d !important;
    background-color: #f5f5f5 !important;
  }
}
.close-icon {
  color: white;
  cursor: pointer;
  font-size: 22px;
}

.close-discard-dialog {
  cursor: pointer;
}
</style>
