<template>
  <div class="ibx-item-card">
    <x-content-card
      :data="getItemData"
      :cardTitle="itemItemTypeLabel"
      :titleIcon="itemItemTypeIcon"
      :details="getDetails"
      :actions="getActions"
      :auxilliaryActions="getAuxilliaryActions"
      :standards="getStandards"
      :inUse="getItemUse"
      :media="getMediaType"
      :itemType="getItemType"
      :locked="itemLocked"
      :isDraft="itemIsDraft"
      asyncContent
      showLock
      :index="index"
      v-bind="$attrs"
      v-on="$listeners"
      @action="onAction"
      @lock-item="onItemLock"
    >
      <slot name="header" slot="card-header"></slot>
      <ibx-item
        v-if="reload"
        slot="content"
        slot-scope="{ onAsyncContentLoaded }"
        :id="itemVendorItemIdVersioned"
        v-bind="$attrs"
        @loaded="() => onLoaded(onAsyncContentLoaded)"
        @player-error="(error) => onPlayerError(onAsyncContentLoaded, error)"
        @playerConfig="(config) => onPlayerConfigLoaded(config)"
      />
      <ibx-card-meta v-if="reload" slot="meta-data" :itemData="item" />
    </x-content-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ItemMixin, SavingMessageMixin, PermsMixin } from '@mixins'
import { XContentCard, XContentCardHeader } from '@xLib/XContentCard'
import IbxItem from '@components/ibx/IbxItem'
import { Events, EventBus } from '@events'
import IBXService from '@services/ibx/IBXService'
import IbxCardMeta from '@components/ibx/cardMeta/IbxCardMeta'
import { CONST } from '@constants'
import { FLAG } from '@/constants'
import { checkIfSimpleRubric } from '@helpers/itemHelpers'

export default {
  name: 'IbxItemCard2',

  mixins: [ItemMixin, PermsMixin],

  components: {
    XContentCard,
    IbxItem,
    IbxCardMeta,
  },

  props: {
    id: { type: [String, Number], default: null }, // unique id
    index: { type: Number, default: 0 }, // index when part of list
    actions: { type: Array, defualt: () => null }, // user defined action buttons
    passageRevId: { type: [String, Number], default: null }, // linked passage
  },

  data: () => ({
    reload: false,
    hasError: false,
    hasSimpleRubric: false,
  }),

  computed: {
    ...mapGetters('asmt', {
      asmtItems: 'items',
      asmtItemIds: 'itemIds',
    }),
    ...mapGetters('features', {
      featureFlag: 'flag',
      supportedItemTypes: 'supportedItemTypes',
    }),
    getItemData() {
      return {
        remoteId: this.itemVendorItemId,
      }
    },
    getDetails() {
      return [this.itemDok]
    },
    getStandards() {
      return this.itemStandard
    },
    getActions() {
      if (this.hasError) {
        return []
      }
      if (this.permIsPublished) {
        return this.isAdded
          ? [
              {
                id: Events.DO_NOTHING,
                name: 'Item Added',
                icon: 'check',
                color: 'primary',
              },
            ]
          : []
      } else {
        return this.actions
          ? this.actions
          : [
              {
                id: this.isAdded ? Events.REMOVE_ITEMS : Events.ADD_ITEMS,
                name: this.isAdded ? 'Item Added' : 'Add Item',
                icon: this.isAdded ? 'check' : 'plus',
                color: this.isAdded ? 'primary' : null,
                tooltip: this.isAdded ? 'Click to remove' : null,
              },
            ]
      }
    },
    getItemUse() {
      return this.item.inUse || false
    },
    getMediaType() {
      return this.itemMediaType
    },
    getItemType() {
      return this.itemType
    },
    isAdded() {
      return this.asmtItemIds.includes(this.itemRevId)
    },
    getAuxilliaryActions() {
      const actions = []
      if (this.permUserCanEditItem(this.itemUserId)) {
        actions.push({
          id: Events.EDIT_ITEM,
          name: 'Edit Item',
          data: this.itemRevId,
          disabled: this.hasSimpleRubric
            ? false
            : this.item.itemType == 'constructed_response' && this.item.published
            ? true
            : false,
        })

        if (!this.itemIsDraft) {
          actions.push({
            id: Events.DUPLICATE_ITEM,
            name: 'Duplicate Item',
            data: this.itemRevId,
          })
        }
        actions.push({
          id: Events.DELETE_ITEM,
          name: this.itemIsDraft ? 'Discard Draft' : 'Delete Item',
          data: this.itemRevId,
        })
      } else if (
        this.checkIfCanDuplicate &&
        this.supportedItemTypes[this.item.itemType] &&
        this.featureFlag(FLAG.CAN_DUPLICATE_ITEMS)
      ) {
        actions.push({
          id: Events.DUPLICATE_ITEM,
          name: 'Duplicate Item',
          data: this.itemRevId,
        })
      }

      if (this.checkFeedbackPerm) {
        actions.push({
          id: Events.REPORT_ISSUE,
          name: 'Report an Issue',
          data: this.itemRevId,
        })
      }
      return actions
    },
  },
  watch: {
    itemId(v) {
      this.reload = false
      this.itemInit()
      this.$nextTick(() => (this.reload = true))
    },
  },

  methods: {
    /*
     * @id: action id (ADD_ITEMS, REMOVE_ITEMS, etc..)
     */
    onAction({ id }) {
      if (id == Events.EDIT_ITEM) {
        EventBus.$emit(id, {
          itemData: this.item,
        })
      } else if (id == Events.DUPLICATE_ITEM) {
        EventBus.$emit(id, {
          itemData: this.item,
        })
      } else if (id == Events.DELETE_ITEM) {
        EventBus.$emit(Events.WARNING_SHOW, {
          id: 'delete-item',
          data: this.item.itemId,
          title: this.itemIsDraft ? 'Discard Draft?' : 'Delete Item?',
          message: this.itemIsDraft
            ? 'You are about to discard a draft item.'
            : 'You are about to delete a published item. That item will remain on all assessments that are currently using it but will no longer be available to be added to assessments moving forward.',
          btnLabel: this.itemIsDraft ? 'Discard' : 'Delete',
        })
      } else if (id == Events.REPORT_ISSUE) {
        EventBus.$emit(Events.REPORT_ISSUE, {
          itemData: this.item,
        })
      } else {
        EventBus.$emit(id, {
          itemRevIds: [this.itemRevId],
        })
      }
    },
    onPlayerConfigLoaded(config) {
      if (config?.contentItem) {
        this.hasSimpleRubric = checkIfSimpleRubric(config?.contentItem?.config)
      }
    },
    onLoaded(onAsyncContentLoaded) {
      if (typeof onAsyncContentLoaded === 'function') {
        onAsyncContentLoaded()
      }
      this.$emit(Events.LOADED, { index: this.index, id: this.id })
    },
    onPlayerError(onAsyncContentLoaded, error) {
      this.hasError = true
      this.onLoaded(onAsyncContentLoaded)
    },
    async onItemLock(locked) {
      IBXService.updateItems({
        itemId: this.item.itemId,
        props: { locked },
      })
        .then(({ item }) => {
          EventBus.$emit(Events.SNACKBAR, {
            type: 'success',
            text: locked ? 'Item Locked' : 'Item Unlocked',
            timeout: 10000,
          })
        })
        .catch((error) => {
          EventBus.$emit(Events.ERROR, {
            type: CONST.ERROR,
            error,
            text: 'Failed to lock item',
            subtext: 'Please refresh the page',
          })
        })
    },
  },

  mounted() {
    this.reload = true
  },
}
</script>

<style lang="scss">
@import '@/styles/main';
</style>
