import { StoreOptions } from 'vuex'
import * as UTIL from '@helpers'
import CONST from '@constants'
import { Events } from '@events'
import Vue from 'vue'

interface IState {
  scrolledY: string[]
  windowScrollY: number
  windowScrollYThreshold: number
  windowScrollYAdapt: number
  browseItemsListView: string
  buildItemsListView: string
  headerIsShort: boolean
  stannisActive: boolean
  saveState: any // object
  isItemFullScreen: boolean
  toolTipProps: any // object
  currentRoute: string
}

class State implements IState {
  scrolledY: string[] = []
  windowScrollY: number = 0
  windowScrollYThreshold: number = 0
  windowScrollYAdapt: number = 20
  browseItemsListView: string = CONST.COLLAPSE
  buildItemsListView: string = CONST.COLLAPSE
  headerIsShort: boolean = false
  stannisActive: boolean = false
  currentRoute: string = 'browse'
  saveState: object = {
    build: '',
    stannis: '',
    authoring: '',
  }
  isItemFullScreen: boolean = false
  toolTipProps: any = {
    'open-delay': '0',
    'close-delay': '0',
    transition: 'fade',
    'content-class': 'custom-tooltip',
  }
}

const savingTO: any = null

const ui = {
  namespaced: true,
  state: new State(),

  getters: {
    scrolledY: (state: IState) => state.scrolledY.length > 0,
    windowScrollY: (state: IState) => state.windowScrollY,
    windowScrollYThreshold: (state: IState) => state.windowScrollYThreshold,
    windowScrollYAdapt: (state: IState) => state.windowScrollYAdapt,
    headerIsShort: (state: IState) => state.headerIsShort,
    browseItemsListView: (state: IState) => state.browseItemsListView,
    buildItemsListView: (state: IState) => state.buildItemsListView,
    stannisActive: (state: IState) => state.stannisActive,
    saveState: (state: IState) => {
      return (context: any) => state.saveState[context]
    },
    saveMessage: (state: IState) => {
      return (context: string) => {
        switch (state.saveState[context]) {
          case Events.SAVE_CHANGED:
            return 'Changed'
          case Events.SAVE_SAVING:
            return 'Saving'
          case Events.SAVE_SAVED:
            return 'Saved'
          default:
            return ''
        }
      }
    },
    isItemFullScreen: (state: IState) => {
      return state.isItemFullScreen
    },
    toolTipProps: (state: State) => {
      return state.toolTipProps
    },
    currentRoute: (state: State) => state.currentRoute,
  },

  mutations: {
    setScrolledY: (state: IState, { id, value }) => {
      if (value) state.scrolledY = UTIL.pushUnique(state.scrolledY, id)
      else state.scrolledY = state.scrolledY.filter((o) => o !== id)
    },
    setWindowScrollY: (state: IState, value: number = 0) => {
      state.windowScrollY = value
    },
    setWindowScrollYThreshold: (state: IState, value: number = 0) => {
      state.windowScrollYThreshold = value
    },
    setWindowScrollYAdapt: (state: IState, value: number = 20) => {
      state.windowScrollYAdapt = value
    },
    setHeaderIsShort: (state: IState, value: boolean) => {
      state.headerIsShort = value
    },
    setBrowseItemsListView: (state: IState, value: string = CONST.COLLAPSE) => {
      state.browseItemsListView = value
    },
    setBuildItemsListView: (state: IState, value: string = CONST.COLLAPSE) => {
      state.buildItemsListView = value
    },
    setStannisActive: (state: IState, value: boolean = false) => {
      state.stannisActive = value
    },
    setSaveState: (state: IState, { value = Events.SAVE_SAVED, context = 'build' }) => {
      Vue.set(state.saveState, context, value)
    },
    setIsItemFullScreen: (state: IState, value: boolean = false) => {
      state.isItemFullScreen = value
    },
    setCurrentRoute: (state: IState, value: string) => {
      state.currentRoute = value
    },
  },

  actions: {
    onWindowScrollY: async ({ commit }, value: number) => {
      commit('setWindowScrollY', value)
    },
    onScrollY: async ({ commit }, { id, value }) => {
      commit('setScrolledY', value)
    },
    setWindowScrollYThreshold: async ({ commit }, value: number) => {
      commit('setWindowScrollYThreshold', value)
    },
    setWindowScrollYAdapt: async ({ commit }, value: number) => {
      commit('setWindowScrollYAdapt', value)
    },
    setHeaderIsShort: async ({ commit }, value: boolean) => {
      commit('setHeaderIsShort', value)
    },
    setBrowseItemsListView: async ({ commit }, value: string) => {
      commit('setBrowseItemsListView', value)
    },
    setBuildItemsListView: async ({ commit }, value: string) => {
      commit('setBuildItemsListView', value)
    },
    setStannisActive: async ({ commit }, value: boolean) => {
      commit('setStannisActive', value)
    },
    setIsItemFullScreen: async ({ commit }, value: boolean) => {
      commit('setIsItemFullScreen', value)
    },
    setSaveState: async ({ commit, getters }, { value, context, interval }) => {
      savingTO && clearTimeout(savingTO)
      commit('setSaveState', { value, context })

      // delay saved message then clear
      if (value == Events.SAVE_SAVED) {
        setTimeout(() => {
          commit('setSaveState', { value: Events.SAVE_DONE, context })
        }, interval || 4000)
      }
    },
    setCurrentRoute: async ({ commit }, value: string) => {
      commit('setCurrentRoute', value)
    },
  },
} as StoreOptions<IState>

export { ui, IState }
