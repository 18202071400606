
// @ts-nocheck
import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'
import PassageEditor from './passages/PassageEditor.vue'
import { IPassageData, PassageAuthor } from '@/components/ibx/base/Passage'
import ItemAuthorDiscardDialog from '@components/itemAuthor/ItemAuthorDiscardDialog'
import ItemAuthorPassageCancelDialog from '@components/itemAuthor/passages/AuthorPassageCancelDialog'
import * as VCard from 'vuetify/es5/components/VCard'
import IbxModal from '@components/ibx/IbxModal'
import XBtn from '@xLib/XBtn'

import { Events, EventBus } from '@events'
import _ from 'lodash'

@Component({
  components: {
    ...VCard,
    XBtn,
    PassageEditor,
    IbxModal,
    ItemAuthorDiscardDialog,
    ItemAuthorPassageCancelDialog,
  },
  computed: {},
})
export default class PassageAuthoringDialog extends Mixins(Vue) {
  @Prop({ default: true }) passageExpanded: boolean
  @Prop({ default: null }) passage: IPassageData
  @Prop({ default: 'edit' }) passageAuthoringAction: string

  private initialized: boolean = false
  private passageEditorMutated: boolean = false
  private editorPassage: PassageAuthor = null
  private passageExitConfirmAction: string = null
  private disableSave: boolean = true

  private on: any = null // NOT SURE WHY THIS IS NEEDED TO REMOVE VUE WARNING FOR TOOLTIP ACTIVATOR

  get passageID(): string {
    return this?.passage?.passageId || ''
  }
  get printBtnAttrs() {
    return {
      color: '#006c90',
      marginLeft: '8px',
    }
  }
  get closeBtnAttrs() {
    return {
      'background-color': '#006c90',
    }
  }
  get confrimPassageExit(): boolean {
    return this.passageEditorMutated // return && this.passageEditing
  }

  get requireSaveConfirmation() {
    return this.passageAuthoringAction == 'edit'
  }

  /**
   * Close dialog, emit close event
   * @param action close action taken
   */
  closeDialog(action?: string) {
    this.$emit('close', { action })
  }

  onPassageEditCancelConfirm() {
    if (this.confrimPassageExit) {
      this.passageExitConfirmAction = 'passage-cancel'
    } else {
      this.passageExitConfirmAction = null
      this.onPassageEditCancel()
    }
  }

  onPassageEditCancel() {
    this.passageEditorMutated = false
    this.closeDialog()
  }

  /**
   * Set passage mutated flag.
   * Used for confirming passage editor cancel.
   * @param changed mutated flag
   */
  onPassageEditorChange(changed: boolean) {
    this.passageEditorMutated = changed
  }

  /**
   * Disable save button
   * @param disable disable flag
   */
  onDisableSave(disable: boolean) {
    this.disableSave = disable
  }

  /**
   * Update passage instance data
   * and link/unlink passage
   * @param passage passage data
   * @param link passage link
   */
  async updateAndLinkPassage({ passage, link }: { passage: IPassageData; link: boolean }) {
    this.closeDialog('passage-updated')
    EventBus.$emit(Events.BROWSE_RELOAD_ITEMS)
  }

  /**
   * Take confirmed action from passage exit dialog
   * @param action action to that was confirmed
   * @param save save or cancel flag
   */
  async onPassageEditorConfirmAction({ action, save }: { action: string; save: boolean }) {
    this.passageExitConfirmAction = null
    if (save) {
      try {
        this.passageSaving = true
        await this.$refs?.passageEditor?.savePassage()
      } finally {
        this.passageSaving = false
      }
    }
    if (action === 'passage-cancel') {
      this.onPassageEditCancel()
    }
  }

  onSavePassage() {
    this.$refs.passageEditor.savePassage(this.requireSaveConfirmation)
  }

  mounted() {
    this.editorPassage = this.passage ? new PassageAuthor(_.cloneDeep(this.passage)) : null
  }
}
