<template>
  <div class="ibx-std-tooltip">
    <template v-if="hasStandard && !loading">
      <div class="ibx-std-tooltip__bread">
        {{ stdLabel(standard) }}
        <font-awesome-icon :icon="['fas', 'chevron-double-right']" class="separator" />
        {{ standard.grade_level_set }}
        <font-awesome-icon :icon="['fas', 'chevron-double-right']" class="separator" />
        {{ standard.subject }}
        <font-awesome-icon :icon="['fas', 'chevron-double-right']" class="separator" />
        {{ standard.document }}
      </div>
      <div class="ibx-std-tooltip__desc">{{ standard.description }}</div>
    </template>
    <template v-if="loading && !hasStandard">Loading...</template>
    <template v-if="!hasStandard && !loading"> No description available </template>
  </div>
</template>

<script>
import { StandardLabelMixin } from '@/mixins'

export default {
  name: 'IbxStandardTooltip',
  mixins: [StandardLabelMixin],

  props: {
    standard: { type: Object, default: null },
    loading: { typw: Boolean, default: false },
  },

  computed: {
    hasStandard() {
      return Boolean(this.standard)
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.ibx-std-tooltip {
  max-width: 270px;

  &__bread {
    margin-bottom: 8px;
    .separator {
      font-size: 8px;
      margin-bottom: 1px;
    }
  }
}
</style>
