import { IBXService } from '@services/ibx/IBXService'
import PIEService from '@services/PIEService'
import { StoreOptions } from 'vuex'
import { PieApi } from '@services/pie/PieGqlApi'
import { FLAG } from '@constants'

interface IState {
  initialzed: boolean
  pieToken: string
  pieTokenDev: string
}

class State implements IState {
  initialzed: boolean = false
  pieToken: string = ''
  pieTokenDev: string = ''
}

const auth = {
  namespaced: true,

  state: new State(),

  getters: {
    initialized: (state: IState) => state.initialzed,
    pieToken: (state: IState) => state.pieToken,
  },

  mutations: {
    setInitialized: (state: IState, value: boolean) => {
      state.initialzed = value
    },
    setPieToken: (state: IState, value: string) => {
      state.pieToken = value
    },
  },

  actions: {
    init: async ({ commit, getters, rootGetters }) => {
      if (!getters.initialized) {
        const pieToken = await IBXService.init()
        PIEService.setToken(pieToken)

        // intialize PieApi singleton
        const pieApiCacheId = rootGetters['features/flag'](FLAG.APOLLO_CACHE_VERSION)
        const pieApi = new PieApi()
        await pieApi.init(pieToken, pieApiCacheId)

        commit('setPieToken', pieToken)
        commit('setInitialized', true)
      }
    },
    fetchPieToken: async ({ commit }) => {
      try {
        const { pieToken } = await IBXService.pieToken()
        PIEService.setToken(pieToken)
        commit('setPieToken', pieToken)
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
} as StoreOptions<IState>

export { auth, IState }
