const xFilterMixin = {
  props: {
    items: { type: Array, dafault: () => [], required: true }, // array of items
    selectedItems: { type: Array, default: () => [] }, // array of selected items ids,
    itemID: { type: String, default: 'id' }, // key/id for each item
    itemLabel: { type: String, default: 'name' }, // key/name for each item label
  },

  computed: {
    hasSelectedItems() {
      return this.selectedItems.length > 0
    },
  },

  methods: {
    getItemId(item) {
      return item[this.itemID]
    },
    getItemLabel(item) {
      return item[this.itemLabel]
    },
    getAuxColor(item) {
      return item.color || '#050F2D'
    },
  },
}

export default xFilterMixin
export { xFilterMixin }
