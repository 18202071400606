
import { Vue, Component, Prop } from 'vue-property-decorator'
import IbxCardMetaPassage from '@/components/ibx/cardMeta/IbxCardMetaPassage.vue'
import IbxCardMetaItem from '@/components/ibx/cardMeta/IbxCardMetaItem.vue'
import { IItemData } from '@/components/ibx/base/Item.ts'
import { IPassageData } from '@/components/ibx/base/Passage.ts'

@Component({
  components: {
    IbxCardMetaPassage,
    IbxCardMetaItem,
  },
})
export default class IbxCardMeta extends Vue {
  @Prop({ default: null })
  passageData: IPassageData

  @Prop({ default: null })
  itemData: IItemData

  get type() {
    return this.passageData ? 'ibx-card-meta-passage' : 'ibx-card-meta-item'
  }

  get attrs() {
    return {
      data: this.passageData ? this.passageData : this.itemData,
    }
  }
}
