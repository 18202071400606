<template>
  <div>
    <div class="btn-type">
      <h4>Primary</h4>
      <x-btn color="primary" @click="onClick" data="1">Primary</x-btn>
      <x-btn color="primary" @click="onClick" disabled>Primary</x-btn>
      <x-btn color="primary" @click="onClick" small>Primary</x-btn>
      <x-btn color="primary" @click="onClick" small icon="search">Primary</x-btn>
      <x-btn color="primary" @click="onClick" icon="search" iconStyle="regular">Primary</x-btn>
    </div>

    <div class="btn-type">
      <h4>Secondary</h4>
      <x-btn color="secondary">Secondary</x-btn>
      <x-btn color="secondary" disabled>Secondary</x-btn>
      <x-btn color="secondary" small>Secondary</x-btn>
      <x-btn color="secondary" icon="search" iconStyle="regular">Secondary</x-btn>
    </div>

    <div class="btn-type">
      <h4>Secondary 2</h4>
      <x-btn color="secondary-2">Secondary</x-btn>
      <x-btn color="secondary-2" disabled>Secondary</x-btn>
      <x-btn color="secondary-2" small>Secondary</x-btn>
      <x-btn color="secondary-2" icon="search" iconStyle="regular">Secondary</x-btn>
    </div>

    <div class="btn-type">
      <h4>Tertiary</h4>
      <x-btn color="tertiary">Tertiary</x-btn>
      <x-btn color="tertiary" disabled>Tertiary</x-btn>
      <x-btn color="tertiary" small>Tertiary</x-btn>
      <x-btn color="tertiary" icon="search" iconStyle="regular">Tertiary</x-btn>
    </div>

    <div class="btn-type">
      <h4>Ghost</h4>
      <x-btn color="ghost">Ghost</x-btn>
      <x-btn color="ghost" disabled>Ghost</x-btn>
      <x-btn color="ghost" small>Ghost</x-btn>
      <x-btn color="ghost" icon="search" iconStyle="regular">Ghost</x-btn>
    </div>

    <div class="btn-type">
      <h4>Danger</h4>
      <x-btn color="danger">Danger</x-btn>
      <x-btn color="danger" disabled>Danger</x-btn>
      <x-btn color="danger" small>Danger</x-btn>
      <x-btn color="danger" icon="search" iconStyle="regular">Danger</x-btn>
    </div>

    <div class="btn-type">
      <h4>Success</h4>
      <x-btn color="success">Success</x-btn>
      <x-btn color="success" disabled>Success</x-btn>
      <x-btn color="success" small>Success</x-btn>
      <x-btn color="success" icon="search" iconStyle="regular">Success</x-btn>
    </div>

    <div class="btn-type">
      <h4>Help</h4>
      <x-btn color="help">Help</x-btn>
      <x-btn color="help" disabled>Help</x-btn>
      <x-btn color="help" small>Help</x-btn>
      <x-btn color="help" icon="search" iconStyle="regular">Help</x-btn>
    </div>

    <div class="btn-type">
      <h4>Warning</h4>
      <x-btn color="warning">Warning</x-btn>
      <x-btn color="warning" disabled>Warning</x-btn>
      <x-btn color="warning" small>Warning</x-btn>
      <x-btn color="warning" icon="search" iconStyle="regular">Warning</x-btn>
    </div>

    <div class="btn-type inverted">
      <h4>White</h4>
      <x-btn color="white">White</x-btn>
      <x-btn color="white" disabled>White</x-btn>
      <x-btn color="white" small>White</x-btn>
      <x-btn color="white" icon="search" iconStyle="regular">White</x-btn>
    </div>
  </div>
</template>

<script>
import XBtn from './index.js'

export default {
  components: {
    XBtn,
  },

  methods: {
    onClick(data) {
      console.log('Clicked: ', data)
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.btn-type {
  margin-bottom: 20px;
  padding: 8px;

  &.inverted {
    background: $B400;
    color: white;
  }

  h4 {
    margin-left: 8px;
  }
}
</style>
