import Vue from 'vue'
import { IBXService } from '@services/ibx/IBXService'
import { StoreOptions } from 'vuex'
import _ from 'lodash'
import { standards } from '../../../tests/unit/_mocks/state/standards'

type Standard = {
  [key: string]: string | number
  guid: string
}

type StandardsMap = {
  [key: string]: Standard
}

class State {
  loading: boolean = true
  items: Standard[] = []
  standardsMap: StandardsMap = {}
}

const standardsItems = {
  namespaced: true,

  state: new State(),

  getters: {
    loading: (state: State): boolean => state.loading,
    items: (state: State): Standard[] => Object.values(state.standardsMap),
    itemsByGuids:
      (state: State) =>
      (guids: string[]): Standard[] =>
        Object.values(state.standardsMap).filter((o: Standard) => guids.some((v) => v == o.guid)),
    standardsMap: (state: State): StandardsMap => state.standardsMap,
  },

  mutations: {
    setLoading: (state: State, value: boolean) => {
      state.loading = value
    },
    setItems: (state: State, items: Standard[] = []) => {
      state.items = items
    },
    addIems: (state: State, items: Standard[] = []) => {
      const itemsMap = _.keyBy(items, 'guid')
      Vue.set(state, 'standardsMap', Object.assign({}, state.standardsMap, itemsMap))
    },
  },

  actions: {
    setLoading: async ({ commit, getters }, value = true) => {
      commit('setLoading', value)
    },

    getItems: async ({ commit, getters }, guids: string[] = []): Promise<any> => {
      const standardsMapGuids = Object.keys(getters.standardsMap)
      const guidsToFetch = _.difference(guids, standardsMapGuids)

      if (guidsToFetch.length) {
        try {
          commit('setLoading', true)
          let { data } = await IBXService.standards({ guids: _.uniq(guidsToFetch) })
          commit('addIems', data)
        } catch (error) {
          console.warn(error)
        }
      }

      return getters.itemsByGuids(guids)
    },
  },
} as StoreOptions<State>

export { standardsItems, State }
