/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'Hot Spot_1': {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M33.09 20.5h125.82c6.953 0 12.59 5.637 12.59 12.59v125.82c-.011 6.949-5.641 12.579-12.59 12.59H33.09c-6.953 0-12.59-5.637-12.59-12.59V33.09c0-6.953 5.637-12.59 12.59-12.59zm127.651 10.759a2.588 2.588 0 00-1.831-.759H33.09a2.59 2.59 0 00-2.59 2.59v125.85a2.59 2.59 0 002.59 2.59h125.82c.613 0 1.206-.22 1.67-.62a2.528 2.528 0 00.92-2V33.09a2.59 2.59 0 00-.759-1.831z" _fill="#152452"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M48.45 87.07h-.41a5.27 5.27 0 00-4 2.35L20.5 124.79v19.13L49 101l46 51.51a5.29 5.29 0 007.42.47l27.23-23.63 37.38 39.11a12.52 12.52 0 004.42-9.57v-1.14l-37.63-39.38a5.31 5.31 0 00-7.3-.34l-27.1 23.52-47.05-52.71a5.27 5.27 0 00-3.92-1.77z" _fill="#152452"/><rect pid="2" x="81.5" y="43" width="67" height="43.57" rx="7.39" _fill="#152452"/>',
  },
})
