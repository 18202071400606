import { Events } from '@events'
import { mapGetters, mapActions } from 'vuex'

const SavingMessageMixin = {
  data: () => ({
    saveContext: 'build',
  }),

  computed: {
    ...mapGetters('ui', {
      smSaveState: 'saveState',
      smSaveMessage: 'saveMessage',
    }),
    smIsSaving() {
      return this.smSaveState(this.saveContext) == Events.SAVE_SAVING
    },
    smIsSaved() {
      return this.smSaveState(this.saveContext) == Events.SAVE_SAVED
    },
  },

  methods: {
    ...mapActions('ui', {
      smSetSaveState: 'setSaveState',
    }),
    smChanged() {
      this.smSetSaveState({
        context: this.saveContext,
        value: Events.SAVE_CHANGED,
      })
    },
    smSaving() {
      this.smSetSaveState({
        context: this.saveContext,
        value: Events.SAVE_SAVING,
      })
    },
    smSaved(interval) {
      this.smSetSaveState({
        context: this.saveContext,
        value: Events.SAVE_SAVED,
        interval,
      })
    },
    smDone() {
      this.smSetSaveState({
        context: this.saveContext,
        value: Events.SAVE_DONE,
      })
    },
    smClear() {
      this.smSetSaveState({
        context: this.saveContext,
        value: Events.CLEAR,
      })
    },
  },
}

export default SavingMessageMixin
export { SavingMessageMixin }
