
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import * as VTextField from 'vuetify/es5/components/VTextField'
import * as VMenu from 'vuetify/es5/components/VMenu'
import XBtn from '@/components/xLib/XBtn'
import _ from 'lodash'

/**
 * Interface/Types
 */
interface ICategory {
  id: string | number
  name: string | number
  default?: boolean
  validate?: string
  validateMessage?: string
}
interface ISearchData {
  category?: string | number
  query: string
}

/**
 * Vue component
 */
@Component({
  components: {
    ...VTextField,
    ...VMenu,
    XBtn,
  },
})
export default class XSearch extends Vue {
  private search: boolean = false
  private query: string = ''
  private inputError: boolean = false
  private selectedCategory: ICategory | null = null

  /**
   * Disable all controls
   */
  @Prop({ default: false })
  disabled: boolean

  /**
   * Search category itemId, externalId, etc...
   */
  @Prop({ default: null })
  categories: ICategory[] | null

  /**
   * Query delimiter
   */
  @Prop({ default: null })
  delimiter: string | null

  /**
   * Text field placeholder
   */
  @Prop({ default: 'Search' })
  placeholder: string

  /**
   * Text field append icon
   */
  @Prop({ default: 'search' })
  icon: string

  /**
   * Do not display Text field append icon
   * type set to Boolean for implicty value
   */
  @Prop({ type: Boolean })
  noIcon: boolean

  /**
   * Inner Prepend text field icon
   */
  @Prop({ type: Boolean })
  prependIcon: boolean

  /**
   * Clear search on reload
   */
  @Prop({ type: Boolean })
  clearSearch: boolean

  /**
   * Clear on clearSearch = true
   */
  @Watch('clearSearch')
  onClearSearch(clearSearch) {
    if (clearSearch) {
      this.onClear()
    }
  }

  /**
   * Text field validation for given category
   */
  get textFieldValidation() {
    const rules = []
    if (this.getCategory?.validate && this.textFieldRules[this.getCategory?.validate]) {
      rules.push(this.textFieldRules[this.getCategory.validate])
    }
    return rules
  }

  /**
   * Text field validation rules
   */
  get textFieldRules() {
    return {
      numeric: (value) => {
        if (value) {
          const vals = this.delimiter ? value.split(this.delimiter) : [value]
          const error = vals.find((v: any) => isNaN(v))
          return !error || this.getCategory?.validateMessage || 'Invalid input'
        } else {
          return true
        }
      },
    }
  }

  /**
   * Text field dynamic attributes
   */
  get textFieldAttrs() {
    const attrs = {}
    if (!this.noIcon) {
      const k = this.prependIcon ? 'prepend-inner-icon' : 'append-icon'
      attrs[k] = this.icon
    }
    return attrs
  }

  /**
   * Get default category with item.default prop
   * or default to first item or null if none
   */
  get defaultCategory() {
    const defaultCategory = this?.categories?.find((o: ICategory) => o.default)
    return defaultCategory ? defaultCategory : this?.categories?.[0] || null
  }

  /**
   * Get selected or defaullt category
   */
  get getCategory() {
    return this.selectedCategory || this.defaultCategory
  }

  /**
   * Get classes for input field
   */
  get fieldClasses() {
    const classes = ['x-search__field']
    if (this.categories) {
      classes.push('x-search__field--has-categories')
    }

    return classes
  }

  onError(error) {
    this.inputError = error
  }

  /**
   * category dropdown change handler
   */
  onCategoryChange(item: ICategory) {
    this.selectedCategory = item || null
  }

  /**
   * Trigger search. Use delimeter if set. Emit search event.
   */
  doSearch() {
    if (!this.inputError && this.query) {
      const query = this.delimiter ? _.uniq(this.query.split(this.delimiter).map((v) => v.trim())) : this.query.trim()
      const data: ISearchData = { query }

      if (this.categories) {
        data.category = this.selectedCategory ? this.selectedCategory?.id : this.defaultCategory?.id || null
      }

      this.$emit('search', data)
    }
  }

  /**
   * Textfield enter key handler
   */
  onEnter() {
    if (!this.search && this.query) {
      this.doSearch()
    }
  }

  /**
   * Clear search query
   */
  onClear() {
    this.clear()
  }

  /**
   * Clear query
   * @param {boolean} emit if true emit clear event
   */
  clear(emit = true) {
    this.search = false
    this.query = ''
    if (emit) {
      this.$emit('clear')
    }
  }

  /**
   * Focus text field
   */
  focus() {
    this.$nextTick(() => (this.$refs.search as HTMLElement).focus())
  }
}
