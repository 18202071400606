<template>
  <v-switch
    class="x-switch"
    :color="color"
    :input-value="value"
    v-bind="$attrs"
    v-on="$listeners"
    ref="switch"
    hide-details
  >
  </v-switch>
</template>

<script>
import * as VSwitch from 'vuetify/es5/components/VSwitch'

export default {
  name: 'XSwitch',

  props: {
    icon: { type: String, default: '' },
    color: { type: String, default: '' },
    value: { type: Boolean, default: false },
  },

  components: {
    ...VSwitch,
  },

  mounted() {
    const switchEl = this.$refs.switch.$el
    const toggleButtonEl = switchEl.querySelector('.v-input--switch__thumb')
    if (this.icon) {
      toggleButtonEl.innerHTML = `<i class="material-icons x-switch__icon ${this.color}">${this.icon}</i>`
    }
  },
}
</script>

<style lang="scss">
@import '@/styles/main';
.x-switch {
  &:not(.v-input--is-dirty) {
    .x-switch__icon {
      color: #e0e1e6;
    }
  }

  &__icon {
    font-size: 12px;
    padding-left: 1px;
    color: #008272; // default color

    &.primary {
      color: #008272;
    }
    &.secondary {
      color: #008272;
    }
    &.secondary-2 {
      color: #b3b7c5;
    }
    &.tertiary {
      color: #ecedf1;
    }
    &.danger {
      color: #f02d1f;
    }
    &.help {
      color: #5a53c9;
    }
    &.success {
      color: #0ea449;
    }
    &.warning {
      color: #ffa200;
    }
    &.white {
      color: #ffffff;
    }
  }
}
</style>
