
// @ts-nocheck
import { mapGetters, mapActions } from 'vuex'
import Loading from '@components/ibx/IbxLoadingList'
import { AsmtMixin, PermsMixin, ItemListMixin } from '@mixins'
import * as UTIL from '@helpers'
import anime from 'animejs'
import { EventBus, Events } from '@events'
import Vue from 'vue'

export default {
  name: 'BrowseItems',

  mixins: [AsmtMixin, PermsMixin, ItemListMixin],

  components: {
    Loading,
    'ibx-item-card': () => import('@components/ibx/_IbxItemCard'),
    'ibx-passage-card': () => import('@components/ibx/_IbxPassageCard'),
  },

  data: () => ({
    viewerActive: false,
    viewerData: null,
    expandedItems: [], // expanded via single card (not global view toggles),
    modalItemIndex: 0,
    lastItemCount: 0,
  }),

  computed: {
    ...mapGetters('browseItems', ['loading', 'items']),
    ...mapGetters('ui', ['browseItemsListView']),
    ...mapGetters('authorItems', {
      authorActive: 'active',
    }),
    classes() {
      return this.hasItems ? '' : 'browse-items--no-items'
    },
    canScrollToTop() {
      return false
    },
    loadingItems() {
      return Array.from({ length: Math.max(this.lastItemCount, 5) }, (_) => 110)
    },
  },

  watch: {
    items(v) {
      this.lastItemCount = v.length
      this.hasItems = this.items.length > 0
      if (!this.asyncQInitialized) this.initQueue()
      else this.resetQueue(this.items)

      this.$nextTick(() => {
        this.scrollToTop().then(() => this.checkItemsInView())
      })
    },
    authorActive(v) {
      if (!v) {
        this.resetQueue(this.items)
        this.$nextTick(() => {
          this.scrollToTop().then(() => this.checkItemsInView())
        })
      }
    },
  },

  methods: {
    cardType(data) {
      if (data.passage) return 'ibx-passage-card'
      else return 'ibx-item-card'
    },
    getCardAttrs(data) {
      if (data.passage) {
        return {
          passage: data.passage,
          passageId: data.passage.passageId,
          passageItems: data.passage.items,
        }
      } else {
        return {
          itemId: data.itemId,
          itemRevId: data.itemRevId,
          canLock: this.permCanLockItems,
          mode: 'view',
          role: 'instructor',
        }
      }
    },
    scrollToTop() {
      const target = window.document.scrollingElement || window.document.body || window.document.documentElement

      const ani = anime({
        targets: target,
        scrollTop: 0,
        duration: 100,
        easing: 'easeInOutQuad',
      })

      return ani.finished // promise
    },

    isItem(data) {
      return Boolean(data.itemId)
    },
    onItemFullScreen(data) {
      this.$emit(Events.ITEM_FULL_SCREEN, data)
    },
    initQueue() {
      this.initItemListQueue({
        context: 'browse',
        items: this.items,
        max: 12,
        debounce: 0,
      })
    },
  },

  created() {
    this.viewThreshold = 2000
  },
}
