<template>
  <div class="ibx-passage">
    <IbxItemError v-if="hasError" />
    <pie-player v-else ref="player" @load-complete="onLoaded" @player-error="onError"></pie-player>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import IbxItemError from './IbxItemError'
import { playerEndpoints } from '@/plugins/pie'

export default {
  name: 'IbxPassage',

  components: {
    IbxItemError,
  },

  props: {
    passage: { type: Object, default: () => ({}) },
  },

  data: () => ({
    hasError: false,
  }),

  computed: {
    remoteId() {
      return this.passage.remoteId
    },
  },

  methods: {
    ...mapActions('itemConfigs', ['getPassageConfig']),
    onLoaded(e) {
      this.$emit('loaded', e)
    },
    onError(error) {
      this.hasError = true
      this.$emit('player-error', error)
      console.warn(error)
    },
    async initPlayer() {
      const player = this.$refs.player

      try {
        const { data } = await this.getPassageConfig({ remoteId: this.remoteId })
        this.config = data.stimulus.config
        player.host = `${process.env.VUE_APP_PIE_PLAYER_ENV}`
        player.bundleHost = `${process.env.VUE_APP_PIE_PLAYER_ENV}`
        player.bundleEndpoints = playerEndpoints
        player.config = this.config
      } catch (error) {
        this.onError(error)
      }
    },
  },

  mounted() {
    this.initPlayer()
  },
}
</script>

<style lang="scss"></style>
