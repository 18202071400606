<template>
  <div>
    <x-filter-group
      v-if="initialzed"
      :data="getFilters"
      :activeFilters="getActiveFilters"
      :expandedFilters="getExpandedFilters"
      :selectedItems="getSelectedFilters"
      :order="getOrder"
      settings
      maxHeight="600"
      maxWidth="100"
      @expand="onExpandFilter"
      @order="onOrderFilters"
      @remove="onRemoveFilter"
      @add="onAddFilter"
      @change="onChangeFilter"
      @aux-action="onFilterAuxAction"
      @clear="onClearFilters"
    />
    <standards-dialog v-if="loadStannis" :show="showStannis" customActivator @stannis-hide="onStannisHide" />
  </div>
</template>

<script>
import { Events, EventBus } from '@events'
import { mapGetters, mapActions } from 'vuex'
import XFilterGroup from '@xLib/XFilter/XFilterGroup'
import XFilter from '@xLib/XFilter'
import { filterOptions, filterDefaults, defaultFilterIds } from '@helpers/filterMap'
import CONST from '@constants'
import { StandardLabelMixin } from '@mixins'
import _ from 'lodash'

export default {
  name: 'BrowseFilters',

  mixins: [StandardLabelMixin],

  components: {
    XFilterGroup,
    'standards-dialog': () => import('@components/standards/StandardsDialog'),
  },

  data: () => ({
    showStannis: false, // show/hide dialog
    loadStannis: false, // load the component (only once)
    filtersChangedWhileInactive: [],
  }),

  computed: {
    ...mapGetters('browseFilters', [
      'initialzed',
      'loading',
      'filters',
      'activeFilters',
      'expandedFilters',
      'filtersOrder',
      'selectedFilters',
      'prevBaseStdGuids',
    ]),
    ...mapGetters('standards', {
      stannisGet: 'get',
      stannisGetFiltered: 'getFiltered',
    }),
    ...mapGetters('user', {
      userUserSelection: 'userSelection',
      userBaseStds: 'baseStds',
    }),
    getMappedBaseStandards() {
      return this.userBaseStds.map((o) => {
        return {
          id: o.guid,
          name: this.stdLabel(o),
          tooltipData: o,
        }
      })
    },
    getStandardAuxilliraryItems() {
      return [
        {
          id: 'stannis',
          name: 'Edit Base Standards',
          icon: 'book',
          color: '#008272',
        },
      ]
    },
    getFilters() {
      return this.filters.map((o, i) => {
        const options = filterOptions[o.id] || filterDefaults
        const items = o.id == 'standard' ? this.getMappedBaseStandards : o.items
        const auxilliaryItems = o.id == 'standard' ? this.getStandardAuxilliraryItems : []
        return Object.assign({}, o, options, { name: o.name, items, auxilliaryItems })
      })
    },
    getDefaultFilters() {
      return defaultFilterIds
    },
    getActiveFilters() {
      //return this.filters.map(o => o.id || o.type)
      // we'll need to put defaults at the top and then filter our defaults from active filters
      return _.uniq([...this.getDefaultFilters, ...this.activeFilters])
    },
    getExpandedFilters() {
      return _.uniq([...this.getDefaultFilters, ...this.activeFilters])
    },
    getSelectedFilters() {
      return Object.keys(this.selectedFilters).map((k) => {
        return {
          id: k,
          items: this.selectedFilters[k],
        }
      })
    },
    getOrder() {
      return this.filtersOrder
    },
  },

  methods: {
    ...mapActions('browseFilters', [
      'setActiveFilters',
      'setExpandedFilters',
      'setFiltersOrder',
      'setSelectedFilters',
      'setAllSelectedFilters',
      'setPrevBaseStdGuids',
    ]),

    /*
     * id: filter id
     * data: any type. Can be array of selected item ids, key value pair, bool, etc..
     */
    onChangeFilter({ id, data }) {
      this.setSelectedFilters({ id, itemIds: data })
      EventBus.$emit(Events.BROWSE_FILTERS_CHANGE)
      //.then(() => console.log(this.selectedFilters))
    },

    /*
     * expandedIds: filter ids that are expanded
     */
    onExpandFilter({ expandedIds }) {
      this.setExpandedFilters(expandedIds)
    },

    /*
     * Reorder filters from filters settings
     * @order: array of ordered filter ids
     */
    onOrderFilters({ order = [] }) {
      this.setFiltersOrder(order)
      EventBus.$emit(Events.BROWSE_FILTERS_CHANGE)
    },

    /*
     * active: array of active filter ids
     * removed: array of removed filter ids
     */
    onRemoveFilter({ active = [], removed = [] }) {
      this.setActiveFilters(active)
      EventBus.$emit(Events.BROWSE_FILTERS_CHANGE)
    },

    /*
     * active: array of active filter ids
     * added: array of added filter ids
     */
    onAddFilter({ active = [], added = [] }) {
      this.setActiveFilters(active)
      EventBus.$emit(Events.BROWSE_FILTERS_CHANGE)
    },

    /*
     * id: filter auxilliary action id
     */
    onFilterAuxAction({ id }) {
      if (id == 'stannis') {
        this.loadStannis = true
        this.$nextTick(() => {
          this.showStannis = true
        })
      }
    },

    onStannisHide() {
      this.showStannis = false
      this.onStandardsChange()
    },

    /**
     * Detect standard changes and emit change event if needed.
     */
    onStandardsChange() {
      // Find selected standards.
      // Newly added standards will be considered as selected.
      // Previously selected standards not in new base standards will be excluded.
      const prevSelectedGuids = this.selectedFilters.standard ? [...this.selectedFilters.standard] : []
      const newBaseGuids = this.stannisGet('selected_standards').map((o) => o.guid)
      const addedGuids = _.difference(newBaseGuids, this.prevBaseStdGuids)
      let selectedGuids = [...prevSelectedGuids, ...addedGuids]

      // only include selected guids that are in new base guids
      selectedGuids = _.intersection(newBaseGuids, selectedGuids)
      const selectionsChanged = !_.isEqual(selectedGuids.sort(), prevSelectedGuids.sort())

      // change filter selections if change detected
      if (selectionsChanged) {
        this.onChangeFilter({
          id: 'standard',
          data: selectedGuids,
        })
      }

      this.setPrevBaseStdGuids([])
      return selectionsChanged
    },

    /**
     * Detect stannis changes while not in browse mode
     */
    onStannisChangedWhileInactive() {
      this.filtersChangedWhileInactive = _.uniq([...this.filtersChangedWhileInactive, 'stannis'])
    },

    /**
     * On component actived check for filter changes that happended externally.
     */
    onActivated() {
      if (this.filtersChangedWhileInactive.includes('stannis')) {
        this.onStannisHide()
      }

      this.filtersChangedWhileInactive = []
      EventBus.$off(Events.STANNIS_HIDE, this.onStannisChangedWhileInactive)
    },

    /**
     * On component deactivated listen for external filter changes while inactive.
     */
    onDeactivated() {
      EventBus.$on(Events.STANNIS_HIDE, this.onStannisChangedWhileInactive)
    },

    /**
     * Clear all filters
     */
    onClearFilters() {
      this.setAllSelectedFilters({})
      EventBus.$emit(Events.BROWSE_FILTERS_CHANGE)
    },
  },

  activated() {
    this.onActivated()
  },

  deactivated() {
    this.onDeactivated()
  },
}
</script>
