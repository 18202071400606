/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'Inline Dropdown_1': {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18.31 53.5h155.38c5.418 0 9.81 4.392 9.81 9.81v65.38c0 5.418-4.392 9.81-9.81 9.81H18.31a9.813 9.813 0 01-9.81-9.81V63.31c0-5.418 4.392-9.81 9.81-9.81zm155.19 75v-65h-155v65h155z" _fill="#152452"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M145.5 112.5l19.43-33.66h-38.86l19.43 33.66z" _fill="#152452"/><rect pid="2" x="23.5" y="88.5" width="86" height="15" rx="7.03" _fill="#152452"/>',
  },
})
