<template>
  <div class="ibx-content-viewer">
    <div v-if="show" class="ibx-content-viewer__nav ibx-content-viewer__back" :disabled="atFirstItem" @click="move(-1)">
      <font-awesome-icon :icon="['fal', 'chevron-left']" class="icon" />
    </div>
    <div
      v-if="show"
      class="ibx-content-viewer__nav ibx-content-viewer__forward"
      :disabled="atLastItem"
      @click="move(1)"
    >
      <font-awesome-icon :icon="['fal', 'chevron-right']" class="icon" />
    </div>

    <ibx-modal
      class="ibx-content-viewer__dialog"
      :show="dialog"
      :width="getWidth"
      :height="getHeight"
      noPadding
      noFooterBoder
      persistent
      v-on="$listeners"
    >
      <v-card class="ibx-content-viewer__dialog__card">
        <div
          :class="
            getContentScope.item && getContentScope.item.passage
              ? 'ibx-content-viewer__viewer__passage'
              : 'ibx-content-viewer__viewer'
          "
        >
          <div class="ibx-content-viewer__viewer__view" ref="view">
            <div class="ibx-content-viewer__viewer__content" ref="viewerContent">
              <div class="ibx-content-viewer__viewer__item" v-if="true">
                <slot name="content" v-bind="getContentScope"></slot>
              </div>
              <div v-else>No Items</div>
            </div>
          </div>
        </div>
      </v-card>
      <div class="ibx-content-viewer__dialog__card__footer" slot="footer">
        <slot name="footer" v-bind="getContentScope"></slot>
      </div>
    </ibx-modal>
  </div>
</template>

<script>
import * as VCard from 'vuetify/es5/components/VCard'
import { Subject } from 'rxjs'
import { tap, throttleTime } from 'rxjs/operators'
import XBtn from '@xLib/XBtn'
import _ from 'lodash'
import IbxModal from '@components/ibx/IbxModal'

export default {
  name: 'IbxContentViewer',

  components: {
    ...VCard,
    IbxModal,
  },

  props: {
    items: { type: Array, default: () => [] },
    startingIndex: { type: Number, default: 0 },
    show: { type: Boolean, default: false },
  },

  data: () => ({
    index: 0,
    loading: false,
    dialog: false,
  }),

  computed: {
    hasItems() {
      return this.items.length > 0
    },
    viewWidth() {
      const viewEl = this.$refs.view
      if (viewEl) return viewEl.getBoundingClientRect().width
      else return 0
    },
    getContentScope() {
      return {
        item: this.items[this.index],
        index: this.index,
        onItemLoaded: this.onItemLoaded,
      }
    },
    atFirstItem() {
      return this.index == 0
    },
    atLastItem() {
      //console.log(this.index, this.items.length, this.index == this.items.length - 1)
      return this.index == this.items.length - 1
    },
    itemSyle() {
      const opacity = this.loading ? 0 : 1
      return { opacity }
    },
    getWidth() {
      return this.getContentScope.item && this.getContentScope.item.passage ? '98vw' : '80vw'
    },
    getHeight() {
      return this.getContentScope.item && this.getContentScope.item.passage ? '96vh' : '90vh'
    },
  },

  watch: {
    show() {
      this.syncIndex()
      this.syncShow()
    },
    startingIndex() {
      this.syncIndex()
    },
  },

  methods: {
    move(amount) {
      if (this.loading) return
      let index = this.index + amount
      if (index < 0) index = 0
      if (index > this.items.length - 1) {
        index = this.items.length - 1
        this.$emit('end-of-the-line-yo', index)
      }
      this.index = index
    },
    syncIndex() {
      this.index = _.inRange(this.startingIndex, 0, this.items.length) ? this.startingIndex : 0
    },
    syncShow() {
      this.dialog = this.show
    },
    onItemLoaded() {
      // console.log("ITEM LOADED")
    },
  },

  mounted() {
    this.syncIndex()
    this.syncShow()
    // console.log(this.items)
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.ibx-content-viewer {
  &__nav {
    position: fixed;
    z-index: 300;
    top: 50%;
    width: 62px;
    height: 62px;
    background: $N10;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.25s;
    cursor: pointer;
    font-size: 24px;
    transition: background-color 0.4s ease-in;

    &:hover {
      background: $N00;
    }

    &[disabled] {
      background: $N10;
      opacity: 0.9;
      .icon {
        opacity: 0.2;
      }
    }
  }

  &__back {
    left: 20px;
    z-index: 99999;
    .icon {
      margin-right: 5px;
    }
  }

  &__forward {
    z-index: 99999;
    right: 20px;
    .icon {
      margin-left: 5px;
    }
  }

  .v-tooltip {
    z-index: 9999;
  }
}

.ibx-content-viewer__dialog {
  &__card.v-card {
    border-radius: 5px !important;
    border: none !important;

    .x-content-card {
      border: none !important;
      box-shadow: none;
    }
  }
}

.ibx-content-viewer__dialog__card {
  position: relative;
  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    > * {
      margin: 0px 0px 0px 8px;
    }
  }
}

.ibx-content-viewer__viewer {
  position: relative;
  width: 80vw;
  height: 90vh;
  padding-bottom: 62px;

  &__passage {
    position: relative;
    width: 98vw;
    height: 96vh;
    overflow-y: auto;
    padding-bottom: 62px;
  }

  &__view {
    display: block;
  }

  &__content {
    display: block;
    width: 100%;
  }

  &__item {
    display: block;
  }
}

/** Media Queries */
@media only screen and (max-width: 1350px) {
  .ibx-content-viewer__dialog {
    .x-content-card-header {
      min-width: 700px !important;
    }
  }
}
</style>
