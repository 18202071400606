/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'Evidence-Based Selected Response_2': {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M159.54 21.11H32.46C26.13 21.11 21 26.24 21 32.57v46.86c0 6.33 5.13 11.46 11.46 11.46h127.08c6.329 0 11.46-5.13 11.46-11.46V32.57c0-6.33-5.131-11.46-11.46-11.46zM79.38 78.4l-3.66-10.32H55.2L51.55 78.4h-8.86l17.53-47.58h10.49l17.5 47.58h-8.83zm29.11-38.97l19.13 33.14 19.13-33.14h-38.26zm-43.03-.34l7.67 21.68H57.79l7.67-21.68zm-6.74 91.62h7.47A6.23 6.23 0 0071 128.9a6.55 6.55 0 001.67-4.63 5.79 5.79 0 00-1.86-4.47 7.522 7.522 0 00-5.28-1.71h-6.81v12.62zm15.03 8.68a6.62 6.62 0 012 4.9 6.44 6.44 0 01-1.92 4.76c-1.29 1.28-3.2 1.91-5.75 1.91h-9.36v-13.51h9.07c2.647 0 4.633.647 5.96 1.94zm85.96-38.28h.02A11.283 11.283 0 01171 112.39v47.22a11.282 11.282 0 01-11.29 11.33H32.29A11.281 11.281 0 0121 159.66v-47c0-6.379 5.171-11.55 11.55-11.55h127.16zm-79.36 53.72c-2.513 2.38-6.08 3.57-10.7 3.57H50.39v-47.58h15.07c4.78 0 8.507 1.157 11.18 3.47a11.83 11.83 0 014 9.41 11.331 11.331 0 01-1.46 5.8 9.75 9.75 0 01-4.25 3.87c6.08 1.607 9.123 5.557 9.13 11.85a12.64 12.64 0 01-3.75 9.61h.04zm28.18-35.4l19.13 33.14 19.13-33.14h-38.26z" _fill="#152452"/>',
  },
})
