
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import * as VDialog from 'vuetify/es5/components/VDialog'
import * as VCard from 'vuetify/es5/components/VCard'
import * as VForm from 'vuetify/es5/components/VForm'
import getVideoId from 'get-video-id'
import CONST from '@constants'
import _ from 'lodash'
import XBtn from '@/components/xLib/XBtn'
import { isSoundcloudURL } from '@/components/itemAuthor/passages/TextEditor/TextEditorHelpers'

type VideoAttributes = {
  url: string
  width: number
  height: number
}

@Component({
  components: {
    ...VDialog,
    ...VCard,
    ...VForm,
    XBtn,
  },
})
export default class TextEditorAudioDialog extends Vue {
  @Prop({ default: false }) show: boolean // display dialog
  @Prop({ default: null }) command: any // editor insert commmand
  @Prop({ default: null }) updateCommand: any // editor node view update command
  @Prop({ default: () => ({}) }) attributes: VideoAttributes // video initial attribtues

  /**
   * Watch show prop.
   * Hydrate or reset data
   */
  @Watch('show')
  private watchShow(value: boolean) {
    const form = this.$refs.form as any
    form?.reset()
    this.$nextTick(() => {
      if (value) this.hydrate()
      else this.reset()
    })
  }

  /**
   * debounce URL data fetch when URL changes
   */
  @Watch('url')
  private watchUrl(value: string) {
    this.error = false
    this.debounceURL()
  }

  private iframeHTML: string = ''
  private error: boolean = false
  private url: string = null
  private editing: boolean = false
  private debounceURL: any = _.debounce(this.fetchURLData, 250)
  private scIframeSrc: any = null

  /**
   * Soundcloud URL check
   */
  private get isSoundcloudURL(): boolean {
    return isSoundcloudURL(this.url)
  }

  /**
   * Video URL is valid
   */
  private get isValid(): boolean {
    return Boolean(this.url) && !this.error && this.isSoundcloudURL
  }

  /**
   * Insert video action
   */
  private insert() {
    this.$emit('insert', {
      command: this.command,
      data: {
        src: this.scIframeSrc,
        url: this.url,
      },
    })
  }

  /**
   * Update video action
   */
  private update() {
    this.$emit('update', {
      updateCommand: this.updateCommand,
      data: {
        src: this.scIframeSrc,
        url: this.url,
      },
    })
  }

  /**
   * Cancel dialog
   */
  private cancel() {
    this.$emit('cancel')
  }

  /**
   * Hydrate with given attributes
   * or set defaults
   */
  private hydrate() {
    this.url = this.attributes?.url || null
    this.editing = Boolean(this.url)
    this.error = false
  }

  /**
   * Reset data to defaults
   */
  private reset() {
    this.url = null
    this.scIframeSrc = null
    this.editing = false
    this.error = false
  }

  /**
   * URL input validation
   */
  private validateURL(): boolean | string {
    if (this.isValid) {
      return true
    } else {
      return 'Invalid URL'
    }
  }

  /**
   * Fetch URL data from Soundclound API.
   */
  private async fetchURLData() {
    if (!this.isValid) return

    try {
      const SC = await import('soundcloud')
      const data = await SC.oEmbed(this.url, { autoplay: false })
      this.setSCIframeSrc(data.html)
      this.iframeHTML = data.html
    } catch (e) {
      const form = this.$refs.form as any
      this.iframeHTML = ''
      this.error = true
      form.validate()
    }
  }

  /**
   * Set Soundcloud iframe attrubutes returns from API
   */
  private setSCIframeSrc(iframeString: string = '') {
    const tmp = document.createElement('div')
    tmp.innerHTML = iframeString.trim()
    const iframe = tmp.getElementsByTagName('iframe')?.[0]
    this.scIframeSrc = iframe?.getAttribute('src')
  }
}
