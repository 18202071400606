import { StoreOptions } from 'vuex'
import { IBXService } from '@services/ibx/IBXService'
import { IPassageData, PassageAuthor } from '@/components/ibx/base/Passage'
import { Id } from '@/components/ibx/base/Types'
import _ from 'lodash'

class State {
  initialized: boolean = false
  attributes: any[] = []
  attributeValues: any[] = []
  passages: IPassageData[] = [] // passage data cache
}

const authorPassages = {
  namespaced: true,
  state: new State(),

  getters: {
    initialized: (state: State): boolean => state.initialized,
    attributes: (state: State): any[] => state.attributes,
    attributeValues: (state: State): any[] => state.attributeValues,

    /**
     * Filter for advanced attributes (settings)
     * and sort by display order
     */
    advancedAttributes: (state: State): any[] => {
      // ordered advanced attributes
      const avd = [
        'media',
        'passage_category',
        'passage_genre',
        'passage_subgenre',
        'flesch_kincaid',
        'level_of_meaning',
        'structure',
        'knowledge_demands',
        'language_clarity',
        'copyright_owner',
        'author_name',
        'original_source',
      ]

      return _.sortBy(
        state.attributes.filter((o) => avd.includes(o.field)),
        (o) => {
          return avd.indexOf(o.field)
        }
      )
    },
    getPassages: (state: State): IPassageData[] => state.passages,
    getPassage: (state: State): ((passageRevId: Id) => IPassageData) => {
      return (passageRevId: Id): IPassageData => {
        return state.passages.find((o: IPassageData) => o.passageRevId == passageRevId) || null
      }
    },
  },

  mutations: {
    setInitialized: (state: State, value: boolean) => {
      state.initialized = value
    },
    setAttributes: (state: State, value: any[]) => {
      state.attributes = value
    },
    setAttributeValues: (state: State, value: any[]) => {
      state.attributeValues = value
    },
    addPassage: (state: State, passage: IPassageData) => {
      if (!state.passages.some((o: IPassageData) => o.passageRevId == passage.passageRevId)) {
        state.passages.push(passage)
      }
    },
    updatePassage: (state: State, { passage, key, value }: { passage: PassageAuthor; key: string; value: any }) => {
      if (passage) passage.set(key, value)
    },
  },

  actions: {
    /**
     * Initialize if not initialized
     */
    init: async ({ commit, getters, dispatch, rootGetters }) => {
      if (!getters.initialized) {
        commit('setInitialized', true)
        // await dispatch('fetchAttributes')
        // await dispatch('fetchAttributeValues')
      }
    },

    /**
     * Fetch passage attribtues
     */
    fetchAttributes: async ({ commit, getters, dispatch }) => {
      const { attributes } = await IBXService.attributes({ resource: 'passages' })
      commit('setAttributes', attributes)
    },

    /**
     * Fetch attribute values.
     * Exclude interger and boolean attributes.
     * Group values by attributeId
     */
    fetchAttributeValues: async ({ commit, getters }) => {
      const filter = ['integer', 'boolean']
      const attributeIds = getters.attributes
        .filter((o: any) => !filter.includes(o.fieldType))
        .map((o: any) => o.attributeId)
      let { values } = await IBXService.attributes({
        resource: 'values',
        params: { attributeId: attributeIds },
      })

      const groupedValues = _.groupBy(values, 'attributeId')
      commit('setAttributeValues', groupedValues)
    },
  },
} as StoreOptions<State>

export { authorPassages }
