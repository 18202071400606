<template>
  <div class="x-filter-checkbox-list">
    <v-list v-if="load && hasItems">
      <v-list-item v-if="selectAll" class="x-filter-checkbox-list__item">
        <v-list-item-action>
          <v-checkbox
            class="x-filter-checkbox-list__control x-filter-checkbox-list__control--select-all"
            :input-value="allSelected"
            label="Select All"
            color="primary"
            @change="onSelectAll"
          ></v-checkbox>
        </v-list-item-action>
      </v-list-item>

      <v-list-item v-for="(item, i) in items" :key="i" class="x-filter-checkbox-list__item">
        <v-list-item-action>
          <v-tooltip
            right
            open-delay="0.5"
            close-delay="0"
            transition="fade"
            content-class="custom-tooltip"
            :disabled="!useTooltip"
          >
            <template v-slot:activator="{ on }">
              <v-checkbox
                v-on="on"
                class="x-filter-checkbox-list__control"
                :input-value="isSelected(getItemId(item))"
                :label="getItemLabel(item)"
                color="primary"
                @change="(selected) => change(getItemId(item), selected)"
              ></v-checkbox>
            </template>
            <component :is="getTooltipComponent" v-bind="getTooltipData(item)" />
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <x-filter-aux-items :items="auxilliaryItems" v-on="$listeners" />
  </div>
</template>

<script>
import { Events } from '@events'
import xFilterMixin from '../mixins/xFilterMixin'
import xFilterListMixin from '../mixins/xFilterListMixin'
import xFilterAuxItems from './XFilterAuxItems'
import lazyLoadMixin from '@mixins/lazyLoadMixin'
import * as VList from 'vuetify/es5/components/VList'
import * as VCheckbox from 'vuetify/es5/components/VCheckbox'
import * as VTooltip from 'vuetify/es5/components/VTooltip'
import IbxStandardTooltip from '@components/ibx/IbxStandardTooltip'

export default {
  name: 'XFilterCheckboxList',

  mixins: [xFilterMixin, xFilterListMixin, lazyLoadMixin],

  components: {
    ...VList,
    ...VCheckbox,
    ...VTooltip,
    xFilterAuxItems,
    IbxStandardTooltip,
  },

  computed: {
    getTooltipComponent() {
      switch (this.id) {
        case 'standard':
          return 'ibx-standard-tooltip'
        default:
          return 'div'
      }
    },
  },

  methods: {
    lazyLoad() {
      this.load = true
      this.$emit(Events.LOADED, true)
    },
    getTooltipData(item) {
      return this.id == 'standard' ? { standard: item.tooltipData } : {}
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.x-filter-checkbox-list {
  color: $N130 !important;
  display: block;

  &__item {
    margin-bottom: 12px;
    padding: 0 0 0 0 !important;

    /* vuetify overrides */
    .v-item__tile.theme--light {
      //height: 32px;
      height: auto;
    }
  }

  .v-input__control .v-input__slot {
    .v-input--selection-controls__input {
      margin-right: 8px !important;
    }
  }
}

.v-label {
  color: $N110 !important;
}

.v-label:hover {
  color: $B300 !important;
}
</style>
