/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  Graphing_1: {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M61.55 137l-2.54.9v-.9h2.54zM97.18 26.55l-6.6 17.19-6.23-17.19h-8l10.76 25.07L81.14 65h7.43l16.11-38.45h-7.5zM167 97.08h-8.4l-5.6 7.61-5.55-7.61H139l9.91 12-9.91 12.16h8.48l5.56-7.57 5.55 7.57H167l-9.94-12.08L167 97.08z" _fill="#152452"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M144.29 153v-8H59v22h-8v-22H25v-8h26V45.72h-8L55 25l12 20.72h-8v87.94c.78-.27 1.59-.56 2.39-.86 25.27-9.46 60.32-32.52 83.76-88.57l3.69 1.54c-24 57.48-60.18 81.14-86.25 90.85l-1.05.38H59v.9l2.54-.9h82.74v-8L165 141l-20.71 12z" _fill="#152452"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M61.55 137l-2.54.9v-.9h2.54z" _fill="#152452"/><path pid="3" fill-rule="evenodd" clip-rule="evenodd" d="M61.55 137l-2.54.9v-.9h2.54z" _fill="#152452"/>',
  },
})
