/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'Explicit Constructed Response_1': {
    width: 192,
    height: 192,
    viewBox: '0 0 192 192',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M41.86 6.48h108.51c5.32 0 9.63 5.87 9.63 13.11v54.82c0 7.24-4.31 13.11-9.63 13.11H41.63c-5.32 0-9.63-5.87-9.63-13.11V19.9c0-7.42 4.41-13.42 9.86-13.42zm-1.29 98h11.06l.17-1.01a2.5 2.5 0 110 5h-9.77a2.683 2.683 0 00-1.42.43c-.387.241-.834.37-1.29.37a2.5 2.5 0 01-1.3-4.64l.26-.15H40.57zm43.97 0h-14a2.5 2.5 0 100 5h14a2.5 2.5 0 100-5zm32.92 0h-14a2.5 2.5 0 100 5h14a2.5 2.5 0 100-5zM34.5 120.15a2.5 2.5 0 00-2.5 2.5v14a2.5 2.5 0 105 0v-14a2.5 2.5 0 00-2.5-2.5zM32 155.57a2.5 2.5 0 115 0v14a2.5 2.5 0 11-5 0v-14zm8.4 24.79a2.5 2.5 0 00-.64 4.91c.168.049.338.089.51.12h13.46a2.5 2.5 0 100-5h-12.1a2.32 2.32 0 01-.58-.08 2.416 2.416 0 00-.65-.08v.13zm32.25.16h14a2.5 2.5 0 110 5h-14a2.5 2.5 0 110-5zm46.91 0h-14a2.5 2.5 0 100 5h14a2.5 2.5 0 100-5zm31.45-.1a2.54 2.54 0 01.72-.1v.03a2.5 2.5 0 01.73 4.89 7.256 7.256 0 01-1.46.28h-12.52a2.5 2.5 0 110-5h11.89c.217.002.434-.032.64-.1zm6.49-27.56a2.492 2.492 0 00-2.5 2.5v14a2.5 2.5 0 105 0v-14a2.492 2.492 0 00-2.5-2.5zm-2.5-30.42a2.5 2.5 0 115 0v14a2.5 2.5 0 11-5 0v-14zm-4.63-17.96h-14a2.5 2.5 0 100 5h14c.438.007.866.131 1.24.36.379.213.805.327 1.24.33a2.5 2.5 0 001.25-4.67 8.825 8.825 0 00-1-.48h-.42a8.257 8.257 0 00-.82-.22h-.09l-.45-.07h-.92l-.03-.25zM61 22.58h26a9 9 0 110 18H61a9 9 0 010-18zm0 47h69a9 9 0 009-9 9 9 0 00-9-9H61a9 9 0 100 18zm69 99H61a9 9 0 01-9-9 9 9 0 019-9h69a9 9 0 110 18zm-69-29h26a9 9 0 009-9 9 9 0 00-9-9H61a9 9 0 00-9 9 9 9 0 009 9z" _fill="#152452"/>',
  },
})
