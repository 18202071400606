<template>
  <v-navigation-drawer
    class="browse-filters-sidebar"
    :clipped="$vuetify.breakpoint.lgAndUp"
    v-model="drawer"
    fixed
    floating
    app
    ref="root"
  >
    <v-container class="browse-filters-sidebar-cointainer" ref="container">
      <browse-filters />
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import BrowseFilters from '@components/browse/BrowseFilters'
import { mapActions } from 'vuex'
import '@plugins/vue-rx'
import { Subject, fromEvent } from 'rxjs'
import { map, tap, debounceTime } from 'rxjs/operators'

export default {
  name: 'BrowseSidebar',

  components: {
    BrowseFilters,
  },

  data: () => ({
    drawer: true,
    scroll$: null,
  }),

  methods: {
    ...mapActions('ui', ['onScrollY']),
    subscribeToScroll() {
      const scollElement = this.$refs.root.$el
      this.scroll$ = fromEvent(scollElement, 'scroll')
      this.scroll$.subscribe(this.onScroll)
    },
    unsubscribeToScroll() {
      this.scroll$.unsubscribe()
    },
    onScroll(evt) {
      this.onScrollY({
        id: 'browe-sidebar',
        value: evt.target.scrollTop >= 14,
      })
    },
  },

  mounted() {
    this.subscribeToScroll()
  },

  beforeDestroy() {
    this.unsubscribeToScroll()
  },
}
</script>

<style lang="scss">
@import '@/styles/_theme.scss';
.browse-filters-sidebar {
  margin-top: $main-header-height !important;

  &.v-navigation-drawer.theme--light {
    background: none;
    background-color: transparent;
  }

  .browse-filters-sidebar-cointainer {
    padding: 16px 16px 16px 30px;
  }
}

.browse-filters {
  .browse-filter {
    margin-bottom: 20px;
  }
}
</style>
