<template>
  <v-select
    class="x-dropdown"
    :items="items"
    :label="label"
    item-text="name"
    items-value="id"
    outlined
    single-line
    append-icon="keyboard_arrow_down"
    hide-details
    solo
    v-bind="$attrs"
    v-on="$listeners"
    return-object
  ></v-select>
</template>

<script>
import * as VSelect from 'vuetify/es5/components/VSelect'
import { Events } from '@events'

export default {
  name: 'XDropdown',

  components: {
    ...VSelect,
  },

  props: {
    items: { type: Array, default: () => [] },
    label: { type: String, default: 'Select' },
  },
}
</script>

<style lang="scss">
@import '@/styles/main';
.x-dropdown {
  &:hover {
    .v-input__control .v-input__slot {
      border: 1px solid $N40 !important;
    }
  }

  .v-input__control .v-input__slot {
    border: 1px solid $N40;
    border-radius: 5px;
    min-height: 36px !important;
    height: 36px !important;
    box-shadow: none !important;

    .v-select__slot {
      .v-label {
        font-size: 13px;
        top: 8px !important;
      }
      .v-select__selection {
        font-size: 13px;
      }
    }
  }
}
.v-select-list.v-card,
.v-select-list.v-card.theme--light {
  .v-list {
    padding: 0px;
    .v-item__tile {
      height: 42px;
      padding: 0px 14px;
      .v-item__tile__title {
        font-size: 14px;
      }
    }
  }
}

.v-item__tile__title {
  font-family: 'CerebriSans', Roboto, sans-serif !important;
  font-size: 14px;
}
</style>
