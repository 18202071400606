<template>
  <v-card class="ibx-selector-expand ibx-selector-expand--contiguous" v-bind="this.$attrs">
    <!-- Title/Dropdown activator -->
    <v-card-title class="ibx-selector-expand__title">
      <span class="ibx-selector-expand__title-toggle" @click="toggle">
        <v-icon>{{ isActive ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</v-icon>
        <span class="label">{{ label }}</span>
      </span>
      <v-spacer />
      <span v-if="selectAll" class="ibx-selector-expand__title-actions select-all" @click="onSelectAll">{{
        allSelected ? 'Deselect All' : 'Select All'
      }}</span>
    </v-card-title>

    <!-- Auxilliary action -->
    <div v-if="auxAction" class="ibx-selector-expand__content-aux" @click="onAuxAction">
      <v-icon outline>assignment</v-icon><span>{{ auxMessage }}</span>
    </div>

    <!-- Summary of items -->
    <v-card-actions v-show="!isActive" class="ibx-selector-expand__summary">
      <div v-if="!reactiveSummary" class="ibx-selector-expand__summary-list">
        {{ selectedSummaryList }}
      </div>
      <ibx-chip-list
        v-else
        :items="selectedItems"
        :itemId="itemId"
        :itemLabel="itemLabel"
        :noneMessage="noneMessage"
        small
        @remove="onRemove"
        @more="onSummaryMore"
      />
    </v-card-actions>

    <!-- content -->
    <v-expand-transition>
      <div v-show="isActive">
        <v-card-text v-show="isActive" class="ibx-selector-expand__card-text" :class="hasMore ? 'hasMore' : ''">
          <div
            v-if="hasMore && !scrolledBottom"
            class="ibx-selector-expand__content-fader"
            :class="hasMore ? 'hasMore' : ''"
          ></div>
          <div
            :id="this.contentID"
            class="ibx-selector-expand__content"
            :class="moreClass"
            :style="contentStyles"
            v-stream:scroll="scroll$"
          >
            <component
              :is="content"
              :items="items"
              :selected="selected"
              :itemId="itemId"
              :itemLabel="itemLabel"
              :tooltips="tooltips"
              @change="onSelectedChange"
              @changeAll="onChangeAll"
              @telemetry="onTelemetry"
              ref="items"
            />
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>

    <!-- Lower actions (show more/less) -->
    <v-card-actions v-show="hasMore" class="ibx-selector-expand__more">
      <div class="ibx-selector-expand__more-action" @click="moreToggle">
        {{ more ? 'Show Less' : 'Show More' }}
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import * as VCard from 'vuetify/es5/components/VCard'
import * as VList from 'vuetify/es5/components/VList'
import * as VInput from 'vuetify/es5/components/VInput'
import '@plugins/vue-scrollto'
import '@plugins/vue-rx'
import { Subject } from 'rxjs'
import { map, tap, debounceTime } from 'rxjs/operators'

export default {
  name: 'IBXSelectExpand',

  components: {
    ...VCard,
    ...VList,
    ...VInput,
    'ibx-checkbox-list': () => import('@components/ibx/IbxCheckboxList'),
    'ibx-button-list': () => import('@components/ibx/IbxButtonList'),
    'ibx-chip-list': () => import('@components/ibx/IbxChipList'),
  },

  provide() {
    return Object.defineProperties(
      {},
      {
        getItemClass: { get: () => this.getItemClass },
      }
    )
  },

  props: {
    id: { type: [String, Number], required: true },
    active: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    title: { type: String, default: '' },
    lazyLoad: { type: Boolean, default: false },
    type: { type: String, default: 'checkbox' },
    items: { type: Array, default: () => [] },
    selected: { type: Array, default: () => [] },
    itemId: { type: String, default: 'id' },
    itemLabel: { type: String, default: 'name' },
    minVisible: { type: Number, default: 3 },
    noneMessage: { type: String, default: 'No Selections' },
    selectAll: { type: Boolean, default: false },
    auxAction: { type: Boolean, default: false },
    auxMessage: { type: String, defualt: '' },
    reactiveSummary: { type: Boolean, default: true },
    tooltips: { type: Boolean, default: false },
  },

  data: () => ({
    load: false,
    more: false,
    scrolled: false,
    scrolledBottom: false,
    allSelected: false,
    cancelScrollTo: function () {},
    telemetry: { firstRow: {} },
  }),

  watch: {
    isActive(value) {
      if (!value && this.more) this.showLess()
    },
    selected(value) {
      this.allSelected = this.selected.length === this.items.length
    },
  },

  computed: {
    isActive: {
      get() {
        return this.active
      },
      set(active) {
        this.$emit('toggle', { id: this.id, active })
      },
    },
    label() {
      const cnt = this.selected.length > 0 ? `(${this.selected.length})` : ''
      return `${this.title} ${cnt}`
    },
    thresholdIndex() {
      switch (this.type) {
        case 'button':
          return 6
        case 'checkbox':
          return 3
        default:
          return 3
      }
    },
    selectedItems() {
      return this.items.filter((o) => this.selected.includes(o[this.itemId]))
    },
    hasMore() {
      return this.isActive && this.items.length > 3
    },
    moreClass() {
      return this.more ? 'more' : 'less'
    },
    contentStyles() {
      const maxHeight = this.more ? `${this.maxHeight}px` : '145px'
      return { maxHeight }
    },
    maxHeight() {
      /* 
                - use telemetry to figure maxHeight
                - use minVisible
                - check for existance of overflow
            */
      const rowHeight = this.telemetry.firstRow.height
      const itemsCount = this.items.length
      const totalHeight = this.$refs.items.$el.getBoundingClientRect().height
      const minHeight = rowHeight * this.minVisible
      const absHeight = 250

      // maybe we need a better way? ;)
      return minHeight < totalHeight ? (totalHeight < absHeight ? totalHeight : absHeight) : minHeight
    },
    contentID() {
      return `ibx-selector-expand__content-${this._uid}`
    },
    content() {
      switch (this.type) {
        case 'button':
          return 'ibx-button-list'
        case 'chip':
          return 'ibx-chip-list'
        case 'checkbox':
          return 'ibx-checkbox-list'
        default:
          return 'ibx-checkbox-list'
      }
    },
    selectedSummaryList() {
      const cnt = this.selectedItems.length
      const max = 4
      const diff = cnt - max
      const items = this.selectedItems.slice(0, max).map((item) => item[this.itemLabel])

      if (diff > 0) items.push(`+${diff} more ${diff > 1 ? 'filters' : 'filter'}`)

      return items.join(', ')
    },
  },

  subscriptions() {
    this.scroll$ = new Subject()

    return {
      scrolled: this.scroll$.pipe(map(({ event }) => event.target.scrollTop > 0)),
      more: this.scroll$.pipe(map(({ event }) => event.target.scrollTop > 0)),
      scrolledBottom: this.scroll$.pipe(
        debounceTime(100),
        map(({ event }) => {
          const contentHeight = this.$refs.items.$el.getBoundingClientRect().height
          const srollPos = event.target.scrollTop
          const diff = contentHeight - this.maxHeight
          const buffer = 10
          return srollPos > Math.max(diff - buffer, 0)
        })
      ),
    }
  },

  methods: {
    loadContent() {
      this.load = true
    },
    toggle() {
      this.isActive = !this.active
    },
    onSelectedChange(data) {
      this.$emit('change', data)
    },
    onChangeAll(data) {
      this.$emit('changeAll', data)
    },
    onRemove({ id }) {
      this.$emit('change', { id, selected: false })
    },
    onAuxAction() {
      this.$emit('auxAction', this.id)
    },
    onSelectAll() {
      this.allSelected = !this.allSelected
      this.$emit('changeAll', {
        selected: this.allSelected,
        ids: this.allSelected ? this.items.map((o) => o[this.itemId]) : [],
      })
    },
    onSummaryMore({ firstOverflowID }) {
      this.isActive = true
      this.$nextTick((_) => this.showMore(`item-${firstOverflowID}`))
    },
    getItemLabel(item) {
      return item[this.itemLabel] || ''
    },
    moreToggle() {
      if (!this.more) this.showMore()
      else this.showLess()
    },
    showLess() {
      this.cancelScrollTo()
      const el = document.querySelector(`#${this.contentID}`)
      el.scrollTop = 0
    },
    showMore(elementClass = 'scroll-threshold') {
      this.cancelScrollTo = this.$scrollTo(`#${this.contentID} .${elementClass}`, 1000, {
        container: `#${this.contentID}`,
        cancelable: true,
        offset: -5,
      })
    },
    getItemClass(index) {
      if (this.type === 'checkbox') {
        if (!this.items) return ''
        if (index === 0) return 'scroll-first'
        if (index === this.items.length - 1) return 'scroll-last'
        if (this.thresholdIndex && this.thresholdIndex === index) return 'scroll-threshold'
        return ''
      } else if (this.type === 'button') {
        if (index === 0) return 'scroll-first'
        if (index === this.items.length - 1) return 'scroll-last'
        if (this.thresholdIndex && this.thresholdIndex === index) return 'scroll-threshold'
        return ''
      }
    },
    onTelemetry(telemetry) {
      this.telemetry = telemetry
    },
  },

  mounted() {
    if (!this.active && this.lazyLoad) {
      const unwatch = this.$watch('active', (v) => {
        if (v && !this.load) {
          this.loadContent()
          unwatch()
        }
      })
    } else {
      this.loadContent()
    }
  },
}
</script>

<style lang="scss">
@import '@/styles/components/IbxSelectorExpand.scss';
</style>
