
// @ts-nocheck
import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { AsmtMixin } from '@mixins'
import AsmtPrintPassage from './AsmtPrintPassage'
import AsmtPrintQuestion from './AsmtPrintQuestion'
import AsmtPrintSettings from './AsmtPrintSettings'

import _ from 'lodash'

@Component({
  components: {
    AsmtPrintPassage,
    AsmtPrintQuestion,
    AsmtPrintSettings,
  },
  computed: {
    ...mapGetters('asmtSettings', {
      asmtPrintOptions: 'printOptions',
    }),
  },
})
export default class AsmtPrint extends Mixins(Vue, AsmtMixin) {
  private mode: string = 'view'
  private role: string = 'instructor'
  private ready: boolean = false
  private drawer: boolean = true
  private group: null = null
  private hasResponseItem: boolean = false

  @Watch('group')
  optionsgroupChange() {
    this.drawer = false
  }

  /**
   * Get array of passages and questions
   * grouped by passage and sorted by order
   * @returns array of passages and questions
   */
  get chunked() {
    const items = _.toArray(this.questions)
    let resItems = []
    let i = 0
    let itemLength = items.length
    while (i < itemLength) {
      if (i <= itemLength - 2 && items[i].type === items[i + 1].type) {
        resItems.push([items[i], items[i + 1]])
        i += 2
      } else {
        resItems.push([items[i]])
        i += 1
      }
    }
    return resItems
  }

  get questions(): { type: string; data: any }[] {
    const sortedItems = _.sortBy(this.asmtItems, [(o) => o.order])
    const data = sortedItems.reduce((m, o, i) => {
      const isPassage = !!o.item?.passage

      if (isPassage) {
        let passageGroup = m.find((mo) => mo.data.passageId == o.item.passage.passageId)
        let passage = passageGroup ? passageGroup.data : null

        if (!passageGroup) {
          passage = {
            ...o.item.passage,
            order: o.order,
            items: [],
          }

          m.push({ type: 'passage', data: passage })
        }

        // add item to passage items
        passage.items.push(o)
        if (this.hasResponseItem === false) {
          this.hasResponseItem = o.item.itemType === 'constructed_response'
        }
      } else {
        m.push({ type: 'item', data: o, classes: ['page-break'] })
        if (this.hasResponseItem === false) {
          this.hasResponseItem = o.item.itemType === 'constructed_response'
        }
      }
      return m
    }, [])

    return data
  }

  /**
   * Return question component type (passage or question)
   * @param type component type
   * @returns component name
   */
  componentType(type: string) {
    return type === 'passage' ? 'asmt-print-passage' : 'asmt-print-question'
  }

  chunkClass(type: string) {
    return type === 'passage' ? '12' : '6'
  }

  bookletEdition(role: string) {
    return role === 'student' ? 'Student Edition' : 'Teacher Edition'
  }

  /**
   * Return true if both item of chunk is question
   * @param chunkItem chunk
   * @returns bool
   */
  bothItem(chunkItem: []) {
    const result = chunkItem.filter((q) => q.type == 'item')
    if (result.length == chunkItem.length) return true
    return false
  }
  /**
   * Return print edition (instructor or student)
   */
  roleType() {
    return this.asmtPrintOptions.role === 'instructor' ? 'Teacher Edition' : 'Student Edition'
  }

  async created() {
    await customElements.whenDefined('pie-print')
    this.ready = true
  }
}
