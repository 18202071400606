<template>
  <div class="ibx-app-content" :class="classes">
    <div class="ibx-app-content__filters">1</div>
    <div class="ibx-app-content__content">
      xx
      <!-- <div v-for="i in 20" :key="i" class="test-item"></div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'IbxAppContent',

  computed: {
    ...mapGetters('ui', ['windowScrollY', 'windowScrollYAdapt']),
    classes() {
      return ''
      //return this.windowScrollY > 20 ? 'scrolled' : ''
    },
  },
}
</script>

<style lang="scss">
// @import '@/styles/main';

// .ibx-app-content {
//     display: flex;
//     align-items: stretch;
//     margin-top: 205px;

//     &__filters {
//         flex: 0 0 300px;
//         background: pink;
//     }

//     &__content {
//         flex: 1;
//         background-color:palevioletred;
//     }

//     &.scrolled {
//         margin-top: 148px;
//     }
// }
</style>
