import { mapActions } from 'vuex'

const AsmtSortableMixin = {
  data: () => ({
    asmtItemsSortable: null,
  }),

  watch: {
    asmtItemsSortable(reOrderedItems) {
      this.asmtSetItems(reOrderedItems.map(({ id }) => id))
    },
  },

  methods: {
    ...mapActions('asmt', {
      asmtSetItems: 'setItems',
    }),
    asmtRemoveSortableItems(ids = []) {
      this.asmtItemsSortable = this.asmtItemsSortable.filter(({ id }) => !ids.includes(id))
    },
  },

  created() {
    this.asmtItemsSortable = this.asmtItems.map((id, order) => ({ id, order: order + 1 }))
  },
}

export { AsmtSortableMixin }
