<template>
  <div class="ibx-passage-card">
    <x-content-card
      :data="getItemData"
      :cardTitle="getPassageLabel"
      titleColor="#FF8E07"
      titleIcon="Passage_1"
      :index="index"
      :details="getDetails"
      :actions="getActions"
      :auxilliaryActions="getAuxilliaryActions"
      :passageGenres="getPassageGenres"
      :media="getMediaType"
      context="provide"
      asyncContent
      v-bind="$attrs"
      v-on="$listeners"
      @action="onAction"
    >
      <ibx-passage
        slot="content"
        slot-scope="{ data, onAsyncContentLoaded }"
        :passage="data.passage"
        initial-mode="view"
        v-bind="$attrs"
        @loaded="() => onLoaded(onAsyncContentLoaded)"
        @player-error="(error) => onPlayerError(onAsyncContentLoaded, error)"
      />
      <ibx-card-meta slot="meta-data" :passageData="passage" />
    </x-content-card>
  </div>
</template>

<script>
import IbxPassage from '@components/ibx/IbxPassage'
import { XContentCard, XContentCardHeader } from '@xLib/XContentCard'
import { Events, EventBus } from '@events'
import { mapGetters } from 'vuex'
import { FLAG } from '@constants'
import { pluralizeString } from '@helpers'
import { AsmtMixin, PermsMixin } from '@mixins'
import IbxCardMeta from '@components/ibx/cardMeta/IbxCardMeta'
import _ from 'lodash'

const metaInterface = {
  id: '',
  name: '',
  items: [],
  meta: {},
}

export default {
  name: 'IbxPassageCard',

  mixins: [AsmtMixin, PermsMixin],

  components: {
    XContentCard,
    IbxPassage,
    IbxCardMeta,
  },

  props: {
    id: { type: [String, Number], default: null }, // unique id
    index: { type: Number, default: 0 }, // index when part of list
    actions: { type: Array, defualt: () => null }, // user defined action buttons
    passage: { type: Object, default: () => ({}) },
    showItemCount: { type: Boolean, default: true }, // show item count in title
  },

  computed: {
    ...mapGetters('features', {
      featureFlag: 'flag',
    }),
    passageId() {
      return this.passage.passageRevId
    },
    passageItems() {
      return this.passage.items || []
    },
    passageItemIds() {
      return this.passage.items.map((o) => o.itemRevId) || []
    },
    getPassageLabel() {
      return this.showItemCount ? `Passage (${pluralizeString(this.passage.items.length, 'Item')})` : 'Passage'
    },
    getItemData() {
      return {
        passage: this.passage,
        passageId: this.passageId,
        passageItems: this.passageItems,
      }
    },
    getDetails() {
      return [this.passage.meta.lexile || metaInterface, this.passage.meta.word_count || metaInterface]
    },
    getMediaType() {
      return this.passage.meta.media
    },
    getPassageGenres() {
      return this.passage.meta.passage_genre
    },
    getActions() {
      return this.actions
        ? this.actions
        : [
            {
              id: 'view-passage-items',
              name: `View ${this.passageItems.length} Items`,
              icon: this.hasSelectedItems ? 'check' : 'eye',
              color: this.hasSelectedItems ? '#008272' : 'tertiary',
              tooltip: null,
            },
          ]
    },
    hasSelectedItems() {
      if (this.asmtHasID) {
        return _.intersection(this.asmtItemIds, this.passageItemIds).length > 0
      } else {
        return _.intersection(this.asmtItemsToAdd, this.passageItemIds).length > 0
      }
    },
    getMetaDetails() {
      return {
        masterId: this.passage.passageId,
        revId: this.passage.passageRevId,
        createdDate: this.passage.createdAt,
        updatedDate: this.passage.updatedAt,
        language: this.passage.meta.language,
        instructions: this.passage.teacherInstructions,
        passageData: this.passage,
        source: this.passage.source,
      }
    },
    getAuxilliaryActions() {
      const actions = []
      const passageUser = this.passage?.user?.userId || null
      if (this.featureFlag(FLAG.PASSAGE_AUTHORING) && this.permUserCanEditPassage(passageUser)) {
        actions.push({
          id: Events.EDIT_PASSAGE_ITEM,
          name: 'Edit Passage',
          data: this.passage.passageRevId,
        })
      }
      return actions
    },
  },

  methods: {
    onAction({ id }) {
      if (id == Events.EDIT_PASSAGE_ITEM) {
        EventBus.$emit(id, {
          itemData: this.passage,
          passageRevId: this.passage.passageRevId,
        })
      } else {
        this.$emit(id, {
          itemId: this.itemId,
        })
      }
    },
    onLoaded(onAsyncContentLoaded) {
      if (typeof onAsyncContentLoaded === 'function') {
        onAsyncContentLoaded()
      }
      this.$emit(Events.LOADED, { index: this.index, id: this.id })
    },
    onPlayerError(onAsyncContentLoaded, error) {
      this.onLoaded(onAsyncContentLoaded)
    },
  },
}
</script>

<style lang="scss">
.build-view.build-view__hide-scroll {
  .ibx-passage-card {
    .v-card__text.x-content-card__content {
      height: 80vh !important;
      overflow-y: auto !important;
    }
  }
  .ibx-modal__content {
    overflow: hidden;
  }
}
@import '@/styles/main';
</style>
