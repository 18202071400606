import { gql } from 'apollo-boost'

export const STIMULUS = gql`
  query stimulus($versionedID: VersionedID!) {
    stimulus(versionedID: $versionedID) {
      id
      config
      version {
        major
        minor
        patch
        prerelease
      }
    }
  }
`

export const CONTENT_ITEM = gql`
  query ContentItem($versionedID: VersionedID!) {
    contentItem(versionedID: $versionedID) {
      id
      config
      version {
        major
        minor
        patch
        prerelease
      }
    }
  }
`

export const CONTENT_ITEM_META = gql`
  query ContentItemMetaData($versionedID: VersionedID!) {
    contentItemMetaData(id: $versionedID)
  }
`

export const CONTENT_ITEMS_SEARCH_BY_VERSIONDED_IDS = gql`
  query ContentItemsSearch($versionedDs: [String]) {
    contentItemsSearch(where: { some: { field: "versionedID", match: $versionedDs } }) {
      aggregate {
        count
      }
      edges {
        node {
          contentItem {
            id
          }
        }
      }
    }
  }
`
