<template>
  <div v-if="getShow" class="ibx-app-notification__view-only">
    <div class="ibx-app-notification__view-only-text">
      <span>{{ label }}</span>
    </div>
    <div class="ibx-app-notification__view-only-close" @click="onClickClose">
      <font-awesome-icon :icon="['fal', 'times']" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'IbxAppNotification',
  props: {
    label: { type: String, default: '' },
    show: { type: Boolean, default: false },
  },

  computed: {
    showClass() {
      return this.show ? 'show' : ''
    },
    getShow() {
      return this.show
    },
  },
  methods: {
    onClickClose() {
      this.$emit('cancel-action')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/main';
.ibx-app-notification {
  &__view-only {
    background: #ffcdd2;
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 8px;

    &-text {
      color: #d32f2f;
      font-weight: 500;
      font-family: 'CerebriSans', Roboto, sans-serif;
      padding: 16px;
    }
    &-close {
      color: #d32f2f;
      cursor: pointer;
      margin: 0 25px 0 auto;
    }
  }
}
</style>
